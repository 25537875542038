
const INTERACTION_KEYS = [
    "DirectPDP",
    "DirectATC",
    "DirectConversion",
]

export function isInteration(detailKey: string) {
    return INTERACTION_KEYS.includes(detailKey);
}
