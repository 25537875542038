import { Button } from '@mantine/core';
import { IconLogout } from "@tabler/icons-react";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = () => {
    const { logout } = useAuth0();

    return (
        <Button
            leftIcon={<IconLogout size={14} />}
            color="red"
            onClick={() => logout({ returnTo: window.location.origin })}>
      Log Out
        </Button>
    );
};

export default LogoutButton;
