import * as rdlTypes from "../../../models/RDLDataTypes";
import { createFormContext, isNotEmpty } from "@mantine/form";

export type OnCreateSegmentType = (values: rdlTypes.UserSegmentMetadata) => void;
export type OnAddUsersToSegmentType = (userSegmentId: string, userIds: string[]) => void;

export interface CreateUserSegmentFormValues {
    userSegmentId?: string,
    userIds: string[],
}

export const validateUserSegmentFormValues = {
    userSegmentId: isNotEmpty('Please enter a name'),
    userIds: isNotEmpty('Please select at least one user'),
};

export const [UserSegmentFormProvider, useUserSegmentFormContext, useUserSegmentForm] =
    createFormContext<CreateUserSegmentFormValues>();
