import {
    ActionIcon,
    Group,
    NumberInput,
    Select,
    Stack,
    TextInput,
    Tooltip
} from "@mantine/core"
import { ColumnTypes } from "../../../models/RDLConfig";
import { DeleteIcon } from "../../DeleteActionIcon";
import { IconPlus } from "@tabler/icons-react";
import { ItemColumns } from "../../../models/RDLConfig";
import { createSelectItemsFromColumns } from "../../../utilities/select-items";
import { newWeightedScoreColumnWeight, useRuleFormContext } from "./rule-form-context";
import { useColumns } from "../../../context/ColumnContext";


export function WeightedScoreInput() {
    const form = useRuleFormContext();
    const columns = useColumns();
    const numericColumns: ItemColumns = Object.fromEntries(
        Object.entries(columns)
            .filter(([, type]) => type === ColumnTypes.Float || type === ColumnTypes.Integer)
            .filter(([name]) => form.values.data.weightedscoreData.weightedscoreName !== name)
    );
    const searchableColumns = createSelectItemsFromColumns(numericColumns);

    return (
        <Stack><>
            <TextInput
                withAsterisk
                placeholder="scores."
                label="Score Name"
                {...form.getInputProps('data.weightedscoreData.weightedscoreName')}
                onChange={(event) => {
                    const value = event.currentTarget.value
                    const newValue = value.startsWith("scores.") ? value : `scores.${value}`;
                    form.setFieldValue('data.weightedscoreData.weightedscoreName', newValue);
                }}
            />
            {form.values.data.weightedscoreData.columnWeights.map((columnWeight, i) => (
                <Group key={columnWeight.key} align="end">
                    <NumberInput
                        withAsterisk
                        placeholder="Enter coefficient"
                        label="Coefficient"
                        precision={4}
                        step={0.0001}
                        {...form.getInputProps(`data.weightedscoreData.columnWeights.${i}.coefficient`)}
                    />
                    <Select
                        withinPortal
                        withAsterisk
                        label="Column name"
                        placeholder="Select column name"
                        data={searchableColumns}
                        searchable
                        {...form.getInputProps(`data.weightedscoreData.columnWeights.${i}.columnName`)}
                    />
                    <DeleteIcon onClick={() => form.removeListItem('data.weightedscoreData.columnWeights', i)} />
                </Group>
            ))}
            <Tooltip
                label={`Add a weighted score`}
                withArrow
                position="top"
            >
                <ActionIcon
                    onClick={() => form.insertListItem('data.weightedscoreData.columnWeights', newWeightedScoreColumnWeight())}
                    variant="light"
                    color="blue"
                >
                    <IconPlus />
                </ActionIcon>
            </Tooltip>
        </></Stack>
    )
}
