import { DateRangeInput } from "./DateRangeInput";
import { FilterInclusivityInput } from "./FilterInclusivityInput";
import { FilterTypes } from "../../../utilities/filters";
import { Group } from "@mantine/core";
import { ListOfCategoriesInput } from "./ListOfCategoriesInput";
import { NumericalRangeInput } from "./NumericalRangeInput";
import { SearchableNameInput } from "./SearchableNameInput";
import { TrueFalseInput } from "./TrueFalseInput";
import { shouldHideAdditionalFilterInputs, useRuleFormContext } from "./rule-form-context";


export function FilterInput() {
    const form = useRuleFormContext();
    const filterType = form.values.data.filterData.filterType;

    if (shouldHideAdditionalFilterInputs(form.values.data.filterData)) {
        return (
            <Group align="end" grow>
                <SearchableNameInput />
                <FilterInclusivityInput />
            </Group>
        );
    }

    return (
        <>
            <Group align="end" grow>
                <SearchableNameInput />
                <FilterInclusivityInput />
            </Group>
            {filterType === FilterTypes.NumericalRange && <NumericalRangeInput />}
            {filterType === FilterTypes.Boolean && <TrueFalseInput />}
            {filterType === FilterTypes.ListOfCategories && <ListOfCategoriesInput />}
            {filterType === FilterTypes.DateRange && <DateRangeInput />}
        </>
    );
}
