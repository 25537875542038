import {
    ActionIcon,
    Card,
    ColorSwatch,
    Flex,
    Group,
    Menu,
    Popover,
    Text,
    useMantineTheme
} from "@mantine/core"
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import {
    IconCheck,
    IconDotsVertical,
    IconEye,
    IconEyeClosed,
    IconTrash
} from "@tabler/icons-react"
import { UserSegment } from "../models/user-segments";
import { chartColorsList } from "../utilities/charts";


interface DraggableSegmentsProps {
    segments: UserSegment[],
    segmentsHandlers: any,
    onDeleteSegment: (index: number, segment: UserSegment) => void
}

function SegmentColorPicker({ segment, onChange }: { segment: UserSegment, onChange: (values: UserSegment) => void }) {
    const theme = useMantineTheme();
    const colors = chartColorsList(theme);
    colors.push(theme.colors.gray)

    return (
        <Group>
            {colors.map((color) => (
                <ColorSwatch
                    key={color[2]}
                    component="button"
                    size="1rem"
                    color={color[2]}
                    sx={{ color: '#fff', cursor: 'pointer' }}
                    onClick={() => onChange({ ...segment, color })}
                >
                    {color[2] === segment.color[2] && <IconCheck />}
                </ColorSwatch>
            ))}
        </Group>
    )
}


function SegmentChip({ segment, onChange, onDelete }: { segment: UserSegment, onChange: (values: UserSegment) => void, onDelete: () => void }) {
    return (
        <Card shadow="sm" p="sm" radius="md" withBorder>
            <Group spacing="xs">
                <Menu shadow="md" width={200} position="bottom-start" withinPortal>
                    <Menu.Target>
                        <ActionIcon variant="transparent" color="gray" mx={-8}>
                            <IconDotsVertical size={16} />
                        </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                        {segment.hidden && <Menu.Item icon={<IconEyeClosed size={16} />} onClick={() => onChange({ ...segment, hidden: false })}>Show</Menu.Item>}
                        {!segment.hidden && <Menu.Item icon={<IconEye size={16} />} onClick={() => onChange({ ...segment, hidden: true })}>Hide</Menu.Item>}
                        <Menu.Item icon={<IconTrash size={16} />} onClick={onDelete}>Permanently Delete</Menu.Item>
                    </Menu.Dropdown>
                </Menu>
                <Popover width={200} position="bottom" withArrow shadow="md" withinPortal>
                    <Popover.Target>
                        <ColorSwatch component="button" size="1rem" color={segment.hidden ? segment.color[0] : segment.color[2]} sx={{ cursor: 'pointer' }} />
                    </Popover.Target>
                    <Popover.Dropdown>
                        <SegmentColorPicker segment={segment} onChange={onChange} />
                    </Popover.Dropdown>
                </Popover>
                <Text size="xs" c={segment.hidden ? "dimmed" : "black"}>{segment.name}</Text>
            </Group>
        </Card>
    )
}


export function DraggableSegments({ segments, segmentsHandlers, onDeleteSegment }: DraggableSegmentsProps) {
    const handlers = segmentsHandlers;

    const onDragEnd = function ({ destination, source }: DropResult) {
        destination && handlers.reorder({ from: source.index, to: destination.index });
    }

    const onEditSubmit = function (index: number, values: UserSegment) {
        handlers.setItem(index, values);
    }

    const fields = segments.map((segment, index) => (
        <Draggable key={segment.key} index={index} draggableId={segment.key}>
            {(provided) => (
                <Group ref={provided.innerRef} mt="xs" {...provided.draggableProps} {...provided.dragHandleProps}>
                    <SegmentChip segment={segment} onChange={(values) => onEditSubmit(index, values)} onDelete={() => onDeleteSegment(index, segment)} />
                </Group>)}
        </Draggable>));

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="dnd-list" direction="horizontal">
                    {(provided) => (
                        <Flex {...provided.droppableProps} ref={provided.innerRef} wrap="wrap" gap="xs">
                            {fields}
                            {provided.placeholder}
                        </Flex>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );
}
