import * as rdlTypes from "../../../models/RDLDataTypes";
import { Alert } from "../../../components/Alert";
import {
    Button,
    Divider,
    Grid,
    Group,
    LoadingOverlay,
    MultiSelect,
    SegmentedControl,
    Select,
    SimpleGrid,
    Space,
    Stack,
    Text,
    Title
} from "@mantine/core"
import { InView } from "react-intersection-observer";
import { ItemCardGrid } from "../../../components/ItemCardGrid";
import { LAYOUT_TO_SIMPLE_GRID_PROPS } from "../../../utilities/layout";
import { PipelinesMetricsBreadcrumbs, createPipelineTitle } from "../../../components/PipelineBreadcrumbs";
import { RubberDuckyLabsApi } from "../../../RDLApi";
import { TraceCard } from "../../../components/TraceCard";
import { isUndefinedOrNull } from "../../../utilities/types";
import { useApiContext } from "../../../App";
import { useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import { useParams } from "react-router-dom";
import FakeDateRangePicker from "../../../components/FakeDateRangePicker";
import RankingChangesComparisonDashboard, { DatasetsMetadata, RankingChangesDataset } from "../../../components/charts/RankingChangesComparisonDashboard";
import _ from "underscore";


const SCROLL_PAGE_SIZE = 20;


function getPipelineStage(stageId: string, pipeline: rdlTypes.RankingPipeline | undefined | null) {
    return pipeline?.stages.find(stage => stage.id.toString() === stageId);
}

function TagPicker(props: {
    disabled: boolean,
    filterTags: string[],
    setFilterTags: (tags: string[]) => void,
}) {
    const { disabled, filterTags, setFilterTags } = props;
    const [filterTagOptions, setfilterTagOptions] = useState<string[]>([]);
    const apiContext = useApiContext();
    useEffect(() => {
        if (!_.isNull(apiContext)) {
            RubberDuckyLabsApi.getInstance(apiContext)
                .getTestDatasetTags(["prod-", "shadow-"])
                .then(setfilterTagOptions)
                .catch(() => setfilterTagOptions([]));
        }

        return () => {
            setfilterTagOptions([]);
        }
    }, [apiContext]);

    return (
        <MultiSelect
            withinPortal
            disabled={disabled || _.isEmpty(filterTagOptions)}
            value={filterTags}
            onChange={setFilterTags}
            label="Filter By Tags"
            placeholder="Select tags"
            data={filterTagOptions.map(tag => ({ value: tag, label: tag }))}
        />
    )
}

export default function PipelinesMetricsPage() {
    const { rankingPipelineId } = useParams();
    const apiContext = useApiContext();
    const [rankingPipeline, setRankingPipeline] = useState<rdlTypes.RankingPipeline | undefined | null>(undefined);
    const [rankingChangesDataset, setRankingChangesDataset] = useState<RankingChangesDataset | undefined>(undefined);
    const [rankingChangesDatasetMetadata, setRankingChangesDatasetMetadata] = useState<DatasetsMetadata | undefined>(undefined);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [apiError, setApiError] = useState<string>('');

    const [selectedItems, setSelectedItems] = useState<rdlTypes.Id[]>([]);
    const [selectedTraceIds, setSelectedTraceIds] = useState<string[]>([]);
    const [scrollLimit, setScrollLimit] = useState(0);
    const [showGrid, setShowGrid] = useState<string>("transactions");


    useEffect(() => {
        if (_.isUndefined(rankingPipelineId)) {
            setRankingPipeline(null);
        } else if (!_.isNull(apiContext)) {
            const integerRankingPipelineId = parseInt(rankingPipelineId);
            RubberDuckyLabsApi.getInstance(apiContext)
                .getRankingPipeline(integerRankingPipelineId)
                .then(setRankingPipeline)
                .catch(() => setRankingPipeline(null))
        }

        return () => {
            setRankingPipeline(undefined);
        }
    }, [apiContext, rankingPipelineId]);


    const form = useForm({
        initialValues: {
            stageId1: "",
            stageId2: "",
            filterTags: [] as string[],
        },
        validate: {
            stageId1: (value) => _.isEmpty(value) ? "Please select a stage" : undefined,
            stageId2: (value) => _.isEmpty(value) ? "Please select a stage" : undefined,
        }
    });

    const onSubmit = () => {
        const stage1 = getPipelineStage(form.values.stageId1, rankingPipeline);
        const stage2 = getPipelineStage(form.values.stageId2, rankingPipeline);

        if (!_.isNull(apiContext) && !_.isUndefined(stage1) && !_.isUndefined(stage2)) {
            setIsLoading(true);
            const pipelineTags1 = stage1.output_config.tags.concat(form.values.filterTags);
            const pipelineTags2 = stage2.output_config.tags.concat(form.values.filterTags);
            RubberDuckyLabsApi.getInstance(apiContext)
                .getAggregateRankingChanges(
                    pipelineTags1.join(","),
                    pipelineTags2.join(","),
                    true,
                )
                .then((rankingChanges) => {
                    setRankingChangesDataset(rankingChanges);
                })
                .catch((error) => {
                    setApiError(error.message);
                })
                .finally(() => {
                    setIsLoading(false);
                });

            setRankingChangesDatasetMetadata({
                dataset1: {
                    displayName: stage1.name,
                    tags: pipelineTags2,
                },
                dataset2: {
                    displayName: stage2.name,
                    tags: pipelineTags2,
                }
            });
        }

        return () => {
            setRankingChangesDataset(undefined);
            setRankingChangesDatasetMetadata(undefined);
        }
    }

    const disabled = _.isUndefined(rankingPipeline) || isLoading;

    const itemCardGrid = <Stack>
        <Text size="md" weight="bold">Selected Items</Text>
        <ItemCardGrid
            cards={
                selectedItems.map((itemId) => ({ id: itemId }))
            }
            size="xl"
        />
    </Stack>;

    const traceCardGrid = (
        <>
            <Text size="md" weight="bold">Selected Transaction Ids</Text>
            <SimpleGrid {...LAYOUT_TO_SIMPLE_GRID_PROPS.lg}>
                {_.zip(selectedTraceIds, selectedItems)
                    .slice(0, scrollLimit)
                    .map(([traceId, itemId]) => (
                        !isUndefinedOrNull(rankingPipeline) &&
                        <TraceCard
                            key={`${traceId} ${itemId}`}
                            traceId={`transactionId:${traceId}`}
                            rankingPipeline={rankingPipeline}
                            selectedItems={[itemId]}
                        />
                    ))}
            </SimpleGrid>
            <InView onChange={(inView) => inView && setScrollLimit(scrollLimit + SCROLL_PAGE_SIZE)} />
        </>
    );

    const showSelected = (
        <Stack align="start">
            <SegmentedControl
                value={showGrid}
                onChange={setShowGrid}
                data={[{ label: "Transactions", value: "transactions" }, { label: "Items", value: "items" }]}
            />
            {showGrid === "items" && itemCardGrid}
            {showGrid === "transactions" && traceCardGrid}
        </Stack>
    )

    return (
        <Stack>
            {_.isNull(rankingPipeline) && <Alert message={`Ranking pipeline ${rankingPipelineId} not found`} onClose={_.noop} />}
            <LoadingOverlay visible={_.isUndefined(rankingPipeline)} />
            <PipelinesMetricsBreadcrumbs rankingPipeline={rankingPipeline} />
            <Title order={1}>{createPipelineTitle(rankingPipeline)} Metrics</Title>
            <Text c="dimmed">View ranking changes across the stages of {createPipelineTitle(rankingPipeline)}</Text>
            <form onSubmit={form.onSubmit(onSubmit)}>
                <Grid align="flex-end">
                    <Grid.Col span={9}>
                        <Group grow>
                            <FakeDateRangePicker disabled={disabled} />
                            <TagPicker
                                disabled={disabled}
                                filterTags={form.values.filterTags}
                                setFilterTags={(tags: string[]) => form.setFieldValue("filterTags", tags)}
                            />
                        </Group>
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <Select
                            withinPortal
                            disabled={disabled}
                            label="Compare Two Stages"
                            placeholder="Stage 1"
                            data={
                                rankingPipeline?.stages.map(stage => ({
                                    value: stage.id.toString(),
                                    label: stage.name,
                                })) ?? []
                            }
                            {...form.getInputProps('stageId1')}
                        />
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <Select
                            withinPortal
                            disabled={disabled}
                            placeholder="Stage 2"
                            data={
                                rankingPipeline?.stages.map(stage => ({
                                    value: stage.id.toString(),
                                    label: stage.name,
                                })) ?? []
                            }
                            {...form.getInputProps('stageId2')}
                        />
                    </Grid.Col>
                    <Grid.Col span={1}>
                        <Button type="submit" loading={isLoading}>Submit</Button>
                    </Grid.Col>
                </Grid>
                <Space h="md" />
            </form>
            <Divider />
            {!_.isEmpty(apiError) && <Alert onClose={() => setApiError('')} message={apiError} />}
            {
                !_.isUndefined(rankingChangesDataset)
                && !_.isUndefined(rankingChangesDatasetMetadata)
                && <RankingChangesComparisonDashboard
                    dataset={rankingChangesDataset}
                    datasetMetadata={rankingChangesDatasetMetadata}
                    setSelectedItems={setSelectedItems}
                    selectedItems={selectedItems}
                    setSelectedTraceIds={setSelectedTraceIds}
                    exactMatch
                />
            }
            <Space h="md" />
            {!_.isEmpty(selectedItems) && !_.isEmpty(selectedTraceIds) && showSelected}
        </Stack>
    )
}
