import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";


import {
    Box,
    Flex,
    Group,
    SegmentedControl,
    Skeleton,
    Stack,
    Text,
    Title
} from '@mantine/core'
import { addDays } from 'date-fns';

import * as rdlTypes from "../models/RDLDataTypes";
import { Alert } from "../components/Alert";
import { ColumnProvider } from "../context/ColumnContext";
import { DateRange, isDateRange } from "../utilities/date-range";
import { DraggableRules } from "../components/forms/rule/RuleModals";
import { DrilldownPagesSharingModal } from "../components/DrilldownPagesSharingModal";
import { EventToggle, EventToggleButtons } from "../components/ToggleEventButton";
import { ItemCardGrid } from "../components/ItemCardGrid";
import { ItemCardProps } from "../components/ItemCard";
import { LayoutSizes } from "../utilities/layout";
import { NotImplemented } from "../utilities/not-implemented";
import { OnSubmitFunctionType, TestDataInput } from "../components/forms/test-data/TestDataModals";
import { RDLApiContext, RubberDuckyLabsApi } from '../RDLApi';
import { RDLConfigType } from "../models/RDLConfig";
import { RuleFormValues, RuleTypes } from "../components/forms/rule/rule-form-context";
import { TestDataFormValues } from "../components/forms/test-data/test-data-form-context";
import { TestDataTypes } from "../components/forms/test-data/testData";
import { applyRule } from "../utilities/rules";
import { getJSONParsedURLSearchParam } from "../utilities/url";
import { isRDLId } from "../utilities/types";
import { isRejected } from "../utilities/promise";
import { parseTestDatasetItems } from "./test-datasets/utils";
import { useApiContext, useItems, useLoadAndCacheItems, useRDLConfig } from "../App";
import { useListState } from "@mantine/hooks";
import MultiColumnLayout from "../components/MultiColumnLayout";
import _ from "underscore";


const DEFAULT_EVENT_LIMIT = 100;


const loadEvents = function (
    apiCall: (after?: rdlTypes.Id) => Promise<rdlTypes.Pagination<rdlTypes.Event>>,
    after?: rdlTypes.Id | null
): Promise<void> {
    if (after === null) {
        return Promise.resolve();
    }

    return apiCall(after).then((response) => loadEvents(apiCall, response.paging.after));
}


type setTestDataFunctionType = (value: ItemCardProps[]) => void;

const loadJsonTestData = function (values: TestDataFormValues): ItemCardProps[] {
    if (values.type !== TestDataTypes.JSON) throw new Error("Invalid test data type");

    const jsonData = values.jsonData;
    return jsonData.map((data: any): ItemCardProps => {
        if (isRDLId(data)) {
            return {
                item: {
                    item_id: data,
                    item_attributes: {},
                },
            };
        }

        return ({
            item: { item_id: data.id ?? data.item_id },
            ...data,
        });
    });
}


export const onSubmitHistoricalUserDataTestData = function (
    clientId: number,
    dateRange: DateRange,
    eventsHandlers: any,
    userEventTypes: rdlTypes.EventType[],
    apiContext: RDLApiContext,
): Promise<PromiseSettledResult<void>[]> {
    const requests = [];
    eventsHandlers.setState([]);
    requests.push(...userEventTypes.map((eventType) =>
        loadEvents(
            (after) => RubberDuckyLabsApi
                .getInstance(apiContext)
                .getEvents(
                    clientId,
                    eventType,
                    dateRange[0],
                    addDays(dateRange[1], 1),
                    DEFAULT_EVENT_LIMIT,
                    after
                )
                .then((response) => {
                    eventsHandlers.append(...response.data);
                    return response;
                })
        )
    ));

    return Promise.allSettled(requests);
}

const onSubmitProductCatalogTestData = function (values: TestDataFormValues, setTestData: setTestDataFunctionType): Promise<void> {
    const apiContext = useApiContext();
    const items = useItems();
    const loadAndCacheItems = useLoadAndCacheItems();

    useEffect(() => {
        loadAndCacheItems(apiContext);
    }, [apiContext]);

    setTestData(items.map((item) => ({ item })));
    return Promise.resolve();
}


const createDisplayCardsAndToggleCount = function (
    rules: RuleFormValues[],
    eventToggles: EventToggle[],
    testData: ItemCardProps[],
    config: RDLConfigType,
): [ItemCardProps[], Map<rdlTypes.EventType, number>] {
    let displayCards: ItemCardProps[] = testData.map((itemCard) => {
        if (itemCard.item === undefined) return itemCard;
        return { ...itemCard, id: itemCard.item.item_id };
    });

    const activeRules = rules.filter((rule) => rule.active);
    for (const rule of activeRules) {
        displayCards = applyRule(rule, displayCards, config);
    }

    const toggleCounts = new Map<rdlTypes.EventType, number>(
        eventToggles.map((eventToggle) => [
            eventToggle.eventType,
            displayCards.filter((card) => card.event?.event_type === eventToggle.eventType).length
        ])
    );

    for (const eventToggle of eventToggles) {
        if (!eventToggle.toggleValue) {
            displayCards = displayCards.filter((card) => card.event?.event_type !== eventToggle.eventType);
        }
    }

    return [displayCards, toggleCounts];
}



const TestDataWidgetSkeleton = function () {
    return (
        <Box>
            <Flex justify="align-start">
                <Skeleton />
            </Flex>
            <Flex justify="center" mb="lg" direction="column">
                <Skeleton />
                <Skeleton />
            </Flex>
        </Box>
    );
}


const TestDataWidget = function (props: {
    setApiError: (value: string) => void,
    config: RDLConfigType,
    testDataInput: TestDataFormValues | undefined,
    setTestDataInput: (values: TestDataFormValues | undefined) => void,
    rules: RuleFormValues[],
    rulesHandlers: any,
    size: LayoutSizes,
    cards: ItemCardProps[],
    onChangeCards: (value: ItemCardProps[]) => void,
}) {
    const apiContext = useApiContext();

    const config = props.config;
    const [testDataInput, setTestDataInput] = [props.testDataInput, props.setTestDataInput];
    const [rules, rulesHandlers] = [props.rules, props.rulesHandlers];
    const [events, eventsHandlers] = useListState<rdlTypes.Event>([]);
    const [testData, setTestData] = useState<ItemCardProps[]>([]);
    const [eventToggles, eventToggleHandlers] = useListState<EventToggle>([]);
    const [user, setUser] = useState<rdlTypes.User | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        eventToggleHandlers.setState(() => config.userEventTypes.map((eventType) => ({
            eventType: eventType,
            toggleValue: true,
        })));
    }, [config, testDataInput]);

    useEffect(() => {
        if (testDataInput !== undefined && config.loaded && !_.isNull(apiContext)) {
            setLoading(true);
            onSubmitTestDataInput(testDataInput).then(() => setLoading(false));
        }
    }, [config, apiContext]);

    const onSubmitTestDataInput: OnSubmitFunctionType = function (values: TestDataFormValues): Promise<void> {
        // TODO(Alexandra): we need a more sustainable way to pass apiContext to onsubmit functions.
        // The paradigm of onSubmit={(values) => onSubmit(apiContext, values)} works well, but is
        // hard to implement here because the OnSubmitFunction type is widely shared.
        // Technically, this code will never even be called because onSubmitTestDataInput
        // is only assigned to a handler is apiContext is not null.
        if (_.isNull(apiContext)) {
            throw new Error('API Context is not loaded');
        }

        switch (values.type) {
        case TestDataTypes.JSON:
            setTestData(loadJsonTestData(values))
            return Promise.resolve();
        case TestDataTypes.ModelAPI:
            throw new NotImplemented();
        case TestDataTypes.HistoricalUserData:
        case TestDataTypes.HistoricalUserDataRecommendationsOnly:
            if (!_.isNumber(values.historicalUserClientId)) {
                return Promise.resolve();
            }
            return RubberDuckyLabsApi.getInstance(apiContext)
                .getUser(values.historicalUserClientId)
                .then((user) => !_.isUndefined(user) && setUser(user))
                .catch((error) => props.setApiError(error.message))
                .then(() => {
                    const dateRange = values.historicalUserDateRange;
                    const clientId = values.historicalUserClientId;
                    if (isDateRange(dateRange) && _.isNumber(clientId)) {
                        return onSubmitHistoricalUserDataTestData(clientId, dateRange, eventsHandlers, config.userEventTypes, apiContext);
                    }
                    return Promise.resolve([]);
                })
                .then((results) => {
                    const rejectedResult = results.find((result) => isRejected(result));
                    if (!_.isUndefined(rejectedResult) && isRejected(rejectedResult)) {
                        props.setApiError(rejectedResult.reason);
                    }
                });
        case TestDataTypes.TestDataset:
            return RubberDuckyLabsApi.getInstance(apiContext)
                .getTestDatasetByName(values.testDatasetName)
                .then(parseTestDatasetItems)
                .then(setTestData);
        case TestDataTypes.ProductCatalog:
            return onSubmitProductCatalogTestData(values, setTestData);
        }
    }

    useEffect(() => {
        const newTestData = events.map((event: rdlTypes.Event): ItemCardProps => (
            {
                item: { item_id: event.item_id, item_attributes: {} },
                event: event,
            }
        ));
        setTestData(newTestData);
        if (newTestData.length > 0 && config.userDrilldownConfig.cardOrderRule !== undefined) {
            if (rules.find((rule) => rule.type === RuleTypes.Sort) === undefined) {
                rulesHandlers.prepend(config.userDrilldownConfig.cardOrderRule);
            }
        }
    }, [events]);

    const [toggleCounts, setToggleCounts] = useState<Map<rdlTypes.EventType, number>>(new Map());

    useEffect(() => {
        const [newCards, newToggleCounts] = createDisplayCardsAndToggleCount(rules, eventToggles, testData, config);
        setToggleCounts(newToggleCounts);
        props.onChangeCards(newCards);
    }, [rules, eventToggles, testData, config]);

    if (_.isNull(apiContext)) return <TestDataWidgetSkeleton />;
    return (
        <ColumnProvider cards={props.cards}>
            <Box>
                <Flex justify="align-start">
                    <TestDataInput
                        disabled={loading}
                        testData={testDataInput}
                        onSubmit={(values) => {
                            setLoading(true);
                            return onSubmitTestDataInput(values)
                                .then(() => setLoading(false))
                                .then(() => setTestDataInput(values))
                        }}
                        onRemove={() => setTestDataInput(undefined)}
                        user={user}
                    />
                </Flex>
                {
                    testDataInput !== undefined && <>
                        <Flex justify="center" mb="lg" direction="column">
                            <DraggableRules rules={rules} ruleHandlers={rulesHandlers} config={config} />
                        </Flex>
                        {testDataInput.type === TestDataTypes.HistoricalUserData &&
                            <EventToggleButtons
                                eventToggles={eventToggles}
                                eventToggleHandlers={eventToggleHandlers}
                                eventToggleCounts={toggleCounts} />}
                        <ItemCardGrid cards={props.cards} size={props.size} />
                    </>
                }
            </Box>
        </ColumnProvider>
    )
}


export default function UserDrilldownPage() {
    const config = useRDLConfig();

    const [apiError, setApiError] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();

    const [testDataInputLeft, setTestDataInputLeft] = useState<TestDataFormValues | undefined>(getJSONParsedURLSearchParam(searchParams, 'testDataInputLeft'));
    const [testDataInputRight, setTestDataInputRight] = useState<TestDataFormValues | undefined>(getJSONParsedURLSearchParam(searchParams, 'testDataInputRight'));

    const [rulesLeft, rulesHandlersLeft] = useListState<RuleFormValues>(getJSONParsedURLSearchParam(searchParams, 'rulesLeft', []));
    const [rulesRight, rulesHandlersRight] = useListState<RuleFormValues>(getJSONParsedURLSearchParam(searchParams, 'rulesRight', []));

    const initialIsRightColumnCollapsed = testDataInputRight === undefined && rulesRight.length === 0;
    const [layout, setLayout] = useState(initialIsRightColumnCollapsed ? '1-column' : '2-column');

    const [cardsRight, setCardsRight] = useState<ItemCardProps[]>([]);
    const [cardsLeft, setCardsLeft] = useState<ItemCardProps[]>([]);

    useEffect(() => {
        setSearchParams({});
    }, []);

    return (
        <Stack spacing="xl">
            {!_.isEmpty(apiError) && <Alert message={apiError} onClose={() => setApiError('')} />}
            <Stack spacing="md" align="flex-start">
                <Group>
                    <Title order={1}>User Drilldown</Title>
                    <DrilldownPagesSharingModal
                        location={location}
                        testDataInputLeft={testDataInputLeft}
                        testDataInputRight={testDataInputRight}
                        rulesLeft={rulesLeft}
                        rulesRight={rulesRight}
                    />
                </Group>
                <Text c="dimmed">Drilldown into how ranking strategies affect user experiences.</Text>
                <Group>
                    <SegmentedControl data={["1-column", "2-column"]} onChange={setLayout} value={layout} />
                </Group>
            </Stack>
            <MultiColumnLayout
                columns={[
                    <TestDataWidget
                        key={1}
                        setApiError={setApiError}
                        config={config}
                        testDataInput={testDataInputLeft}
                        setTestDataInput={setTestDataInputLeft}
                        rules={rulesLeft}
                        rulesHandlers={rulesHandlersLeft}
                        size={layout === "2-column" ? "md" : "xl"}
                        cards={cardsLeft}
                        onChangeCards={setCardsLeft}
                    />,
                    layout === "2-column" && (
                        <TestDataWidget
                            key={2}
                            setApiError={setApiError}
                            config={config}
                            testDataInput={testDataInputRight}
                            setTestDataInput={setTestDataInputRight}
                            rules={rulesRight}
                            rulesHandlers={rulesHandlersRight}
                            size={"md"}
                            cards={cardsRight}
                            onChangeCards={setCardsRight}
                        />
                    )
                ]}
            />
        </Stack>
    );
}
