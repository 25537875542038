import { Box, Image } from '@mantine/core';
import logo from "./static/logoavatar.png";

export function Logo(props: {width?: number}) {
    return (
        <Image
            width={props.width ?? 40}
            radius="md"
            src={logo}
            alt="Rubber Ducky Labs logo"
        />
    );
}


export function LogoForNav() {
    return (
        <Box
            sx={(theme) => ({
                paddingLeft: theme.spacing.xs,
                paddingRight: theme.spacing.xs,
                paddingBottom: theme.spacing.lg,
                borderBottom: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
                }`,
            })}
        >
            <Logo />
        </Box>
    );
}

export function LargeLogo() {
    return <Box
        sx={(theme) => ({
            paddingRight: theme.spacing.lg,
            paddingTop: theme.spacing.lg,
            paddingBottom: theme.spacing.lg,
        })}
    >
        <Logo width={100} />
    </Box>;
}
