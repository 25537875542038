import {
    Anchor,
    AppShell,
    Button,
    Container,
    Group,
    Header,
    Stack,
    Text,
    Title
} from '@mantine/core'
import { LargeLogo, Logo } from '../../_logo';
import { Link } from 'react-router-dom';
import ContactSupportButton from '../../components/ContactSupportButton';
import React, { ErrorInfo, ReactNode } from 'react';


interface CatchAllErrorBoundaryProps {
    children: ReactNode;
}

interface CatchAllErrorBoundaryState {
    hasError: boolean;
}

export function ErrorPage(props: {onReset: () => void}) {
    return (
        <AppShell
            padding="md"
            header={
                <Header height="60" p="xs">
                    <Group position="apart">
                        <Anchor component={Link} to={"/home"} onClick={props.onReset}>
                            <Logo />
                        </Anchor>
                    </Group>
                </Header>
            }
        >
            <Container>
                <Stack>
                    <LargeLogo />
                    <Title order={1}>Quack! Something went wrong.</Title>
                    <Text>
                        {"We're sorry, but something went wrong."}
                    </Text>
                    <Text>
                        {"If this persists, please contact RDL support directly through Slack or by emailing support@rubberduckylabs.io."}
                    </Text>
                    <Group>
                        <Button component={Link} to="/home" onClick={props.onReset}>
                            Return home
                        </Button>
                        <ContactSupportButton />
                    </Group>
                </Stack>
            </Container>
        </AppShell>
    )
}

class CatchAllErrorBoundary extends React.Component<CatchAllErrorBoundaryProps, CatchAllErrorBoundaryState> {
    constructor(props: CatchAllErrorBoundaryProps) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        // TODO: log the error to an error reporting service here
        console.error(error, errorInfo);
        this.setState({ hasError: true });
    }

    resetCatchAllErrorBoundary = (): void => {
        this.setState({ hasError: false });
    };

    render(): React.ReactNode {
        if (this.state.hasError) {
            return <ErrorPage onReset={() => this.resetCatchAllErrorBoundary()} />
        }

        return this.props.children;
    }
}

export default CatchAllErrorBoundary;
