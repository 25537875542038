import * as rdlTypes from "../models/RDLDataTypes";
import {
    Accordion,
    Badge,
    Grid,
    Group,
    Loader,
    SimpleGrid,
    Stack,
    Text,
    ThemeIcon,
    Title,
    Tooltip
} from "@mantine/core"
import { Alert } from "./Alert";
import { IconCircleCheck, IconCircleDotted } from "@tabler/icons-react";
import { Id, Item, TraceItemMetadata } from "../models/RDLDataTypes";
import { ItemCard, ItemCardSubtitle, ItemCardTitle } from "./ItemCard";
import { ItemCardGrid } from "./ItemCardGrid";
import { LAYOUT_TO_SIMPLE_GRID_PROPS } from "../utilities/layout";
import { RubberDuckyLabsApi } from "../RDLApi";
import { TestDataset } from "../models/RDLConfig";
import { isInteration } from "../utilities/details";
import { parseTestDatasetItems } from "../pages/test-datasets/utils";
import { useApiContext } from "../App";
import { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import _ from "underscore";


interface ItemSummaryProps {
    item_id: Id,
    item: Item | null | undefined,
    details: Record<string, any>,
    metadata?: TraceItemMetadata,
    rankingPipeline: rdlTypes.RankingPipeline,
    rankingPipelineData: Map<number, TestDataset[]>,
}

function SectionHeader(props: { title: string }) {
    const { title } = props;
    return <Title order={3}>{title}</Title>
}

function SectionSubHeader(props: { title: string }) {
    const { title } = props;
    return <Title order={4}>{title}</Title>;
}

function InterationsSummary(itemSummary: ItemSummaryProps) {
    const interactions = Object.entries(itemSummary.details)
        .filter(([key]) => isInteration(key));

    return (
        <Stack>
            <SectionHeader title="Interactions" />
            {interactions.length === 0 && <Text c="dimmed" fs="italic" size="sm">No interaction events</Text>}
            <Group>
                {interactions.map(([key, value]) => (
                    value === 1
                        ? <Badge key={key} variant="filled">{key}: {value}</Badge>
                        : <Badge key={key} variant="light">{key}: {value}</Badge>
                ))}
            </Group>
        </Stack>
    );
}


function ItemPresentInPipelineIcon() {
    return (
        <Tooltip label="Item appeared in stage">
            <ThemeIcon variant="light" color="blue">
                <IconCircleCheck />
            </ThemeIcon>
        </Tooltip>
    )
}

function ItemAbsentInPipelineIcon() {
    return (
        <Tooltip label="Item was absent from stage">
            <ThemeIcon variant="light" color="gray">
                <IconCircleDotted />
            </ThemeIcon>
        </Tooltip>
    )
}


function PipelineStagesSummary(itemSummary: ItemSummaryProps) {
    const { rankingPipeline, rankingPipelineData, item_id } = itemSummary;
    const textSize = 'sm';
    const includedStages = rankingPipeline.stages.map(({ id, name }) => {
        const stageIncludesItemId: boolean = rankingPipelineData.get(id)
            ?.map((testDataset) => parseTestDatasetItems(testDataset))
            .flat()
            .map(({ id }) => id?.toString())
            .includes(item_id.toString()) ?? false;
        return (
            <Group key={id}>
                {stageIncludesItemId
                    ? <ItemPresentInPipelineIcon />
                    : <ItemAbsentInPipelineIcon />}
                <Text size={textSize}>{name}</Text>
            </Group>
        );
    })

    return <Stack>
        <SectionHeader title="Stages" />
        {includedStages}
    </Stack>
}


function GeneratedScoresSummary(itemSummary: ItemSummaryProps) {
    const { rankingPipeline, rankingPipelineData, item_id } = itemSummary;
    const stageScores = rankingPipeline.stages
        .map(({ id, name }) => {
            const item = rankingPipelineData.get(id)
                ?.map((testDataset) => parseTestDatasetItems(testDataset))
                .flat()
                .find((item) => item.id?.toString() === item_id.toString());
            return { name: name, scores: _.isUndefined(item) ? {} : item.scores };
        })
        .filter(({ scores }) => !_.isEmpty(scores));

    if (_.isEmpty(stageScores)) {
        return (
            <Stack>
                <SectionHeader title="Generated Scores" />
                <Text c="dimmed" fs="italic" size="sm">No generated scores logged</Text>
            </Stack>
        );
    }
    return (
        <Stack>
            <SectionHeader title="Generated Scores" />
            {!_.isEmpty(stageScores) && <>
                {stageScores.map((stageScore) => (
                    !_.isUndefined(stageScore.scores)
                    && <ReactJson src={stageScore.scores} name={stageScore.name} />
                ))}
            </>}
        </Stack>
    )
}


function RankingMetadataSummary(itemSummary: ItemSummaryProps) {
    const { metadata } = itemSummary;
    const substituteProducts: { id: any }[] | undefined = metadata?.metadata.substituteProducts?.map((product: any) => ({ id: product.sku }));

    if (_.isUndefined(metadata) || (_.isEmpty(metadata.scores) && _.isEmpty(substituteProducts))) {
        return (
            <Stack>
                <SectionHeader title="Ranking Metadata" />
                <Text c="dimmed" fs="italic" size="sm">No additional metadata</Text>
            </Stack>
        );
    }

    return (
        <Stack>
            <SectionHeader title="Ranking Metadata" />
            {
                !_.isUndefined(metadata)
                && !_.isEmpty(metadata.scores) && <>
                    <SectionSubHeader title="Scores" />
                    <ReactJson src={metadata.scores} name="scores" />
                </>}
            {
                !_.isUndefined(substituteProducts)
                && substituteProducts.length > 0
                && <>
                    <SectionSubHeader title="Substitue Products" />
                    <ItemCardGrid cards={substituteProducts} size="sm" />
                </>
            }
        </Stack>
    )
}


function ItemAttributesSummary(itemSummary: ItemSummaryProps) {
    const { item } = itemSummary;

    const itemAttributes = _.isUndefined(item)
        ? { "loading": true }
        : _.isNull(item)
            ? { "error": true }
            : item.item_attributes;

    return (
        <Stack>
            <SectionHeader title="Item Attributes" />
            <ReactJson src={itemAttributes} name="item_attributes" collapsed />
        </Stack>
    )
}


function ItemSummary(itemSummary: ItemSummaryProps) {
    return (
        <SimpleGrid {...LAYOUT_TO_SIMPLE_GRID_PROPS.md}>
            <Stack>
                <InterationsSummary {...itemSummary} />
                <PipelineStagesSummary {...itemSummary} />
            </Stack>
            <Stack>
                <GeneratedScoresSummary {...itemSummary} />
                <RankingMetadataSummary {...itemSummary} />
            </Stack>
            <ItemAttributesSummary {...itemSummary} />
        </SimpleGrid>
    );
}

function ItemRankingDetails(props: {
    transactionId: string,
    item_id: Id,
    testDatasets: TestDataset[],
    rankingPipeline: rdlTypes.RankingPipeline,
    rankingPipelineData: Map<number, TestDataset[]>,
    item: Item | null | undefined,
}) {
    const { rankingPipeline, rankingPipelineData, item } = props;
    const apiContext = useApiContext();
    const [metadata, setMetadata] = useState<TraceItemMetadata | undefined>(undefined);

    const [apiError, setApiError] = useState('');
    const apiErrorAlert = (
        !_.isEmpty(apiError) && <Alert message={apiError} onClose={() => setApiError('')} />
    );

    useEffect(() => {
        if (!_.isNull(apiContext)) {
            const [, traceId] = props.transactionId.split(":");
            if (_.isUndefined(traceId)) {
                setApiError("Invalid transaction ID");
                return () => {
                    setMetadata(undefined);
                };
            }
            RubberDuckyLabsApi.getInstance(apiContext)
                .getTraceItemMetadata(traceId, props.item_id)
                .then((metadata) => {
                    setMetadata(metadata);
                })
                .catch((error) => {
                    setApiError(error.message);
                });
        }

        return () => {
            setMetadata(undefined);
        };
    }, [apiContext, props.transactionId]);

    const itemSummary: ItemSummaryProps = props.testDatasets.reduce(
        (summary: ItemSummaryProps, dataset: TestDataset): ItemSummaryProps => {
            const itemInDataset = dataset.item_data.find((item) => item.item_id === summary.item_id);
            if (itemInDataset !== undefined) {
                const newDetails = {
                    ...itemInDataset.details,
                    ...summary.details,
                }
                return {
                    ...summary,
                    details: newDetails,
                    rankingPipeline,
                    rankingPipelineData,
                    item,
                }
            }
            return {
                ...summary,
                rankingPipeline,
                rankingPipelineData,
                item,
            };
        },
        {
            item_id: props.item_id,
            details: {},
            rankingPipeline,
            rankingPipelineData,
            item: item,
        }
    );

    return (
        <Stack align="flex-start">
            {apiErrorAlert}
            <Grid>
                <Grid.Col xs={6} sm={4} md={3} lg={2}>
                    <ItemCard id={props.item_id} details={itemSummary.details} onClick={_.noop} />
                </Grid.Col>
                <Grid.Col xs={12} sm={8} md={9} lg={10}>
                    <ItemSummary {...itemSummary} metadata={metadata} />
                </Grid.Col>
            </Grid>
        </Stack>
    );
}

export default function ItemRankingAccordionItem(props: {
    transactionId: string,
    item_id: Id,
    testDatasets: TestDataset[],
    rankingPipeline: rdlTypes.RankingPipeline,
    rankingPipelineData: Map<number, TestDataset[]>,
}) {
    const { item_id } = props;
    const apiContext = useApiContext();

    const [item, setItem] = useState<Item | undefined | null>(undefined);
    useEffect(() => {
        if (!_.isNull(apiContext) && item === undefined) {
            RubberDuckyLabsApi.getInstance(apiContext)
                .getItem(item_id)
                .then(setItem)
                .catch(() => {
                    setItem(null);
                });
        }
    }, [item_id]);

    return (
        <Accordion.Item key={item_id} value={item_id.toString()}>
            <Accordion.Control>
                {_.isUndefined(item)
                    ? <Group><Loader size={16} /><Text>{item_id}</Text></Group>
                    : _.isNull(item)
                        ? <Text color="red">{item_id} not found</Text>
                        : <Group align="baseline"><ItemCardTitle item={item} /><ItemCardSubtitle item={item} /></Group>}

            </Accordion.Control>
            <Accordion.Panel>
                <ItemRankingDetails {...props} item={item} />
            </Accordion.Panel>
        </Accordion.Item>
    )
}
