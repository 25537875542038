import * as rdlTypes from "../models/RDLDataTypes";
import { ItemCardProps } from "./ItemCard";
import { PipelineStage } from "../utilities/ranked-cards";
import { RankingPipeline } from "../models/RDLDataTypes";
import PipelineChart from "./charts/PipelineChart";
import _ from "underscore";

export default function ExamplePipelineChart(props: { rankingPipeline: RankingPipeline }) {
    const { rankingPipeline } = props;
    const rankingPipelineStages = _.sortBy(rankingPipeline.stages, 'stage_order');
    const cards: ItemCardProps[] = [];
    const pipeline: PipelineStage[] = rankingPipelineStages
        .map(({ name, stage_type }) => {
            const stage: PipelineStage = {
                type: stage_type,
                cards: [],
                label: name,
            };

            if (stage_type === rdlTypes.RankingPipelineStageType.CandidateGeneration) {
                const newCards = _.range(cards.length, cards.length + 10)
                    .map((item_id) => ({ id: item_id.toString() }));

                cards.push(...newCards);
                stage.cards.push(...newCards);
            } else {
                stage.cards.push(...cards);
            }

            return stage;
        });

    return (
        <PipelineChart
            disableInteractions={true}
            pipelineStageForColors={pipeline.at(-1)}
            pipeline={pipeline}
            selectedItems={[]}
            onClickItem={_.noop}
        />
    );
}
