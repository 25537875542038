import { Select } from "@mantine/core";


import { useFacetFormContext } from "./facet-form-context";


export function DateDataInput() {
    const form = useFacetFormContext();

    return (
        <Select
            withinPortal
            label="Split dates by"
            data={[
                {value: "year", label: "Years"},
                {value: "month", label: "Months"},
                {value: "week", label: "Weeks"},
                {value: "day", label: "Days"},
            ]}
            {...form.getInputProps('data.facetData.facetValues.dateData')}
        />
    );
}
