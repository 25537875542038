import * as rdlTypes from "./RDLDataTypes";

export interface DataOnboardingProject {
    id: rdlTypes.Id,
    organization_id: rdlTypes.Id,
    name: string,
    created: number,
    updated: number | null,
    deleted: boolean,
    description?: string,
}

export interface DataOnboardingProjectCreateData {
    name: string,
    description?: string,
}

export interface DataOnboardingProjectUpdateData {
    name?: string,
    description?: string,
}


export enum DataOnboardingTestAnswerType {
    DATABASE = "database",
}

export enum DataOnboardingTestCategoryL1 {
    METRICS = "metrics",
    PRODUCTS = "products",
    USERS = "users",
    EVENTS = "events",
}

export interface DataOnboardingTest {
    id: string;
    created: number;
    updated: number;
    deleted: boolean;
    title: string;
    description: string;
    category_l1: DataOnboardingTestCategoryL1;
    answer_type: DataOnboardingTestAnswerType;
}

export type DataOnboardingTestCreateData = Omit<DataOnboardingTest, "id" | "created" | "updated" | "deleted"> & {
    title?: string;
    description?: string;
    category_l1?: DataOnboardingTestCategoryL1;
    answer_type?: DataOnboardingTestAnswerType;
};

export enum DataOnboardingDatabaseType {
    GOOGLE_BIGQUERY = "google_bigquery",
    POSTGRES = "postgres",
    SNOWFLAKE = "snowflake",
}

export interface DataOnboardingDatabase {
    id: number;
    organization_id: number;
    created: number;
    updated: number | null;
    deleted: boolean;
    name: string;
    type: DataOnboardingDatabaseType;
    notes: string;
}

export type DataOnboardingDatabaseCreateData = Omit<DataOnboardingDatabase, "id" | "organization_id" | "created" | "updated" | "deleted"> & {
    name?: string;
    type?: DataOnboardingDatabaseType;
    notes?: string;
};

export interface DataOnboardingTestAnswer {
    id: number;
    project_id: number;
    test_id: number;
    created: number;
    updated?: number;
    deleted: boolean;
    type: DataOnboardingTestAnswerType;
    // TODO(Alexandra): this should be abstracted away, since the
    // data for each answer type will be different
    database_id: number;
    query: string;
    response: string;
}

export interface DataOnboardingTestAnswerCreateData {
    type: DataOnboardingTestAnswerType;
    database_id: number;
    query: string;
    response?: string;
}
export interface DataOnboardingTestAnswerUpdateData {
    database_id?: number;
    query?: string;
    response?: string;
}

export enum DataOnboardingProjectTestStatus {
    UNDEFINED = "undefined",
    UPCOMING = "upcoming",
    DUE_SOON = "due_soon",
    OVERDUE = "overdue",
    NEEDS_REMEDIATION = "needs_remediation",
    PENDING_REVIEW = "pending_review",
    OK = "ok"
}

export interface DataOnboardingTestMetadata {
    project_id: number;
    test: DataOnboardingTest;
    owner: rdlTypes.Id | null;
    due_date: number | null;
    comments: string;
    answers: DataOnboardingTestAnswer[];
    status: DataOnboardingProjectTestStatus;
}

export interface DataOnboardingTestMetadataUpdateData {
    owner?: rdlTypes.Id | null;
    due_date?: number | null;
    comments?: string;
}
