import * as rdlTypes from "../../../models/RDLDataTypes";
import { Alert } from "../../Alert";
import {
    Button,
    Group,
    MultiSelect,
    Select,
    TextInput
} from "@mantine/core"
import { RankingPipelineStageFormProvider, useRankingPipelineStageForm, useRankingPipelineStageFormContext } from "./ranking-pipeline-stage-form-context";
import { RubberDuckyLabsApi } from "../../../RDLApi";
import { isNotEmpty } from "@mantine/form";
import { useApiContext } from "../../../App";
import { useState } from "react";
import _ from "underscore";

function InputConfigInput(props: {pipeline: rdlTypes.RankingPipeline}) {
    const { pipeline } = props;
    const form = useRankingPipelineStageFormContext();
    const selectData = pipeline.stages.map(({id, name}) => ({ value: id.toString(), label: name }));

    const { onChange } = form.getInputProps('input_config.ranking_pipeline_stages');

    const newOnChange = (value: string[]) => {
        onChange(value);
        const integer_stage_ids = value.map((id) => parseInt(id));
        form.setFieldValue('input_config.stage_ids', integer_stage_ids);
    };

    return (
        <MultiSelect
            label="Input Stages"
            placeholder="Select Input Stages"
            data={selectData}
            withAsterisk
            withinPortal
            mt="md"
            {...form.getInputProps('input_config.ranking_pipeline_stages')}
            onChange={newOnChange}
        />
    )
}


function TypeInput() {
    const form = useRankingPipelineStageFormContext();

    const selectData = [
        { value: rdlTypes.RankingPipelineStageType.CandidateGeneration, label: 'Candidate Generation' },
        { value: rdlTypes.RankingPipelineStageType.RankedList, label: 'Ranked List' },
        { value: rdlTypes.RankingPipelineStageType.UserInteractions, label: 'User Interactions', disabled: true },
    ];

    return (
        <Select
            label="Type"
            placeholder="Stage Type"
            data={selectData}
            withAsterisk
            withinPortal
            mt="md"
            {...form.getInputProps('stage_type')}
        />
    )
}


function NameInput() {
    const form = useRankingPipelineStageFormContext();

    return (
        <TextInput
            placeholder="Stage Name"
            label="Name"
            withAsterisk
            mt="md"
            {...form.getInputProps('name')}
        />
    )
}

export default function CreateRankingPipelineStageForm(props: { rankingPipeline: rdlTypes.RankingPipeline, onSubmit: () => void }) {
    const { rankingPipeline } = props;
    const apiContext = useApiContext();
    const [apiErrorMessage, setApiErrorMessage] = useState<string|undefined>(undefined);

    const form = useRankingPipelineStageForm({
        initialValues: {
            name: '',
            stage_type: rdlTypes.RankingPipelineStageType.CandidateGeneration,
            input_config: { ranking_pipeline_stages: [], stage_ids: [] },
            output_config: { data_source: rdlTypes.RankingPipelineStageDataSource.TestDataset, tags: [] },
        },
        validate: {
            name: isNotEmpty('Please enter a name'),
            input_config: {
                ranking_pipeline_stages: (value, values) => {
                    if (values.stage_type !== rdlTypes.RankingPipelineStageType.CandidateGeneration) {
                        if (_.isEmpty(value)) {
                            return 'Please select input stages';
                        }
                    }
                    return undefined;
                }
            }
        },
    });

    const onSubmit = (values: rdlTypes.RankingPipelineStageCreateData) => {
        setApiErrorMessage(undefined);
        if (!_.isNull(apiContext)) {
            RubberDuckyLabsApi.getInstance(apiContext)
                .createRankingPipelineStage(rankingPipeline.id, values)
                .then(props.onSubmit)
                .catch((error) => {
                    setApiErrorMessage(error.response?.data?.detail);
                });
        }
    }

    const disabled = _.isNull(apiContext);

    return (
        <RankingPipelineStageFormProvider form={form}>
            <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
                {apiErrorMessage && <Alert message={apiErrorMessage} onClose={() => setApiErrorMessage(undefined)} />}
                <NameInput />
                <TypeInput />
                {form.values.stage_type !== rdlTypes.RankingPipelineStageType.CandidateGeneration && <InputConfigInput pipeline={rankingPipeline} />}
                <Group position="right" mt="md">
                    <Button variant="light" type="submit" disabled={disabled}>Create</Button>
                </Group>

            </form>
        </RankingPipelineStageFormProvider>
    );
}
