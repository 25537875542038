import { SegmentedControl, Text } from "@mantine/core";


import { useRuleFormContext } from "./rule-form-context";


export function TrueFalseInput() {
    const form = useRuleFormContext();

    return (
        <>
            <Text size="sm" weight={500}>Values</Text>
            <SegmentedControl
                data={[
                    { label: 'True', value: 'true' },
                    { label: 'False', value: 'false' },
                ]}
                {...form.getInputProps('data.filterData.filterValues.booleanData')}
            />
        </>
    );
}
