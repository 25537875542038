import * as dateFNS from "date-fns";
import _ from "underscore";

export function formatTimestamp(timestamp: number): string {
    const date = dateFNS.fromUnixTime(timestamp);
    return dateFNS.formatDistanceToNow(date, { addSuffix: true });
}


export function formatUpdatedTimestamp(timestamp: number | null): string {
    if (_.isNull(timestamp)) {
        return "Never";
    }
    return formatTimestamp(timestamp);
}
