import { Chart } from 'react-chartjs-2';
import { Chart as ChartJS, ChartOptions, ScatterController, TooltipItem } from 'chart.js'
import {
    Container,
    Grid,
    Group,
    SegmentedControl,
    SimpleGrid,
    Space,
    TextInput,
    useMantineTheme
} from '@mantine/core'
import { Id } from '../../models/RDLDataTypes';
import { MouseEvent, useRef, useState } from "react";
import { StatisticDisplay } from '../StatisticDisplay';
import { chartColorsList } from '../../utilities/charts';
import { formatPercentage, formatValue } from '../../utilities/format-values';
import _ from "underscore"

ChartJS.register(
    ScatterController,
);


interface Quartile {
    percentile: string,
    value: number,
}

interface Metrics {
    items_added: number,
    items_dropped: number,
    mean: number | null,
    standard_deviation: number | null,
    quartiles: Quartile[],
}
export interface RankingChangesDataset {
    items: {
        item_id: string,
        original_rank: number,
        new_rank: number,
        rank_change: number,
        transaction_id: string,
    }[],
    metrics?: Metrics,
}

interface DatasetMetadata {
    displayName: string,
    tags: string[],
}

export interface DatasetsMetadata {
    dataset1: DatasetMetadata,
    dataset2: DatasetMetadata,
}

const getCount = (yAxisFormat: string, count: number, total: number) => {
    if (yAxisFormat === "Percent") {
        return count / total;
    } else {
        return count;
    }
}

function RankingChangesMetricsDisplay(props: { metrics: Metrics, displayStats: string, yAxisFormat: string, total: number }) {
    const { metrics, displayStats, yAxisFormat, total } = props;

    return (
        <SimpleGrid cols={2}>
            {
                Object.entries(metrics)
                    .filter(([metric]) => {
                        if (["items_added", "items_dropped"].includes(metric)) {
                            return true;
                        }
                        if (displayStats === "Quartiles") {
                            return metric === "quartiles";
                        } else {
                            return metric !== "quartiles";
                        }
                    })
                    .map(([metric, value]) => metric === "quartiles" ? value.map((quartile: Quartile) => [quartile.percentile, quartile.value]) : [[metric, value]])
                    .flat(1)
                    .map(([metric, value]) => {
                        if (_.isNull(value)) {
                            return <StatisticDisplay key={metric} title={metric} value="N/A" />
                        }
                        const maybePercentValue = yAxisFormat === "Percent" && ["items_added", "items_dropped"].includes(metric)
                            ? getCount(yAxisFormat, value, total)
                            : value;

                        return <StatisticDisplay key={metric} title={metric} value={maybePercentValue} />
                    })
            }
        </SimpleGrid>
    )
}

export default function RankingChangesComparisonDashboard(props: {
    dataset: RankingChangesDataset,
    datasetMetadata: DatasetsMetadata,
    setSelectedItems: (item_ids: Id[]) => void,
    selectedItems: Id[],
    setSelectedTraceIds?: (traceIds: string[]) => void,
    exactMatch?: boolean,
}) {
    const itemsList = props.dataset.items;
    const [showZeros, setShowZeros] = useState<string>("show0");
    const [yAxisFormat, setYAxisFormat] = useState<string>("Percent");
    const [displayStats, setDisplayStats] = useState<string>("Quartiles");
    const [topX, setTopX] = useState<string>("192");

    const chartRef = useRef<any>(null);
    const theme = useMantineTheme();
    const colors = chartColorsList(theme);

    const groupedByRankChange = _.groupBy(itemsList, (item) => item.rank_change);
    const total = itemsList.length + (props.dataset.metrics?.items_dropped ?? 0);
    const dataset = Object.entries(groupedByRankChange)
        .map(([rank_change, item_list]) => {
            const topXParsed = parseInt(topX);
            const validItems = item_list
                .filter((item) => item.original_rank <= (isNaN(topXParsed) ? 192 : topXParsed));
            return {
                rank_change: rank_change,
                count: getCount(yAxisFormat, validItems.length, total),
                item_ids: validItems.map((item) => item.item_id),
                transaction_ids: validItems.map((item) => item.transaction_id),
                name: "Rank Change",
            }
        })
        .filter((bar) => showZeros === "show0" || bar.rank_change !== "0");


    const shouldHighlightBar = function (itemIds: Id[]) {
        if (props.exactMatch !== undefined && props.exactMatch) {
            return itemIds.every((item_id) => props.selectedItems.includes(item_id));
        }
        return itemIds.some((item_id) => props.selectedItems.includes(item_id));
    }
    const borderColors = dataset.map((bar) =>
        shouldHighlightBar(bar.item_ids)
            ? theme.fn.rgba(colors[2][9], 0.5)
            : theme.fn.rgba(colors[2][4], 0.5)
    );
    const backgroundColors = dataset.map((bar) =>
        shouldHighlightBar(bar.item_ids)
            ? theme.fn.rgba(colors[2][8], 0.5)
            : theme.fn.rgba(colors[2][3], 0.5)
    );

    const chartOnClick = (event: MouseEvent<HTMLCanvasElement>) => {
        const elements = chartRef.current.getElementsAtEventForMode(event, 'nearest', { intersect: false }, true);
        const first = _.first(elements);
        if (!_.isUndefined(first)) {
            const item_ids = first.element.$context?.raw?.item_ids;
            const transaction_ids = first.element.$context?.raw?.transaction_ids;
            if (!_.isUndefined(item_ids)) props.setSelectedItems(item_ids);
            if (!_.isUndefined(transaction_ids) && !_.isUndefined(props.setSelectedTraceIds)) {
                props.setSelectedTraceIds(transaction_ids);
            }
        }
    }

    const data: any = {
        datasets: [
            {
                type: 'bar' as const,
                label: _.isEmpty(dataset) ? "No data" : "Distribution of Rank Movement",
                data: dataset,
                parsing: {
                    xAxisKey: 'rank_change',
                    yAxisKey: 'count',
                },
                borderColor: borderColors,
                backgroundColor: backgroundColors,
            },
        ],
    };

    const metrics = props.dataset.metrics;
    if (!_.isUndefined(metrics) && !_.isNull(metrics.mean) && !_.isNull(metrics.standard_deviation)) {
        const statsDataset = displayStats === "Quartiles"
            ? metrics.quartiles.map((quartile) => ({
                rank_change: quartile.value.toString(),
                count: 0,
                item_ids: [],
                name: quartile.percentile,
            }))
            : [
                {
                    rank_change: formatValue(metrics.mean),
                    count: 0,
                    item_ids: [],
                    name: "Average",
                },
                {
                    rank_change: formatValue(metrics.mean + metrics.standard_deviation),
                    count: 0,
                    item_ids: [],
                    name: "+1 Standard Deviation",
                },
                {
                    rank_change: formatValue(metrics.mean - metrics.standard_deviation),
                    count: 0,
                    item_ids: [],
                    name: "-1 Standard Deviation",
                },
            ];

        data.datasets.push({
            type: 'scatter' as const,
            label: displayStats,
            data: statsDataset,
            parsing: {
                xAxisKey: 'rank_change',
                yAxisKey: 'count',
            },
            borderColor: theme.colors.blue[5],
            backgroundColor: theme.colors.blue[5],
            pointRadius: 3,
            pointHoverRadius: 6,
        });
    }

    const options: ChartOptions = {
        maintainAspectRatio: false,
        scales: {
            x: {
                title: { display: true, text: "Number of Positions Moved" },
                type: "linear",
            },
            y: {
                title: { display: true, text: yAxisFormat },
                type: "linear",
                ticks: {
                    callback: (value, index) => {
                        if (yAxisFormat === "Percent") {
                            return typeof value === "string" ? value : formatPercentage(value, 2);
                        }
                        return index % 1 === 0 ? value : '';
                    }
                }
            },
        },
        plugins: {
            title: {
                display: true,
                text: `${props.datasetMetadata.dataset1.displayName} vs ${props.datasetMetadata.dataset2.displayName}`,
            },
            tooltip: {
                callbacks: {
                    title: (context: TooltipItem<any>[]) => context.map((ctx) => {
                        if (ctx.parsed.x === 0) {
                            return `No change`;
                        } else if (ctx.parsed.x < 0) {
                            return `Positions lost: (${-ctx.parsed.x})`;
                        } else {
                            return `Positions gained: ${ctx.parsed.x}`
                        }
                    }),
                    label: (context: any) => {
                        if (context.raw.name !== "Rank Change") {
                            return `${context.raw.name}: ${context.parsed.x}`;
                        }
                        return yAxisFormat === "Percent" ? `${formatPercentage(context.parsed.y, 2)} of items` : `${context.parsed.y} items`
                    },
                },
            },
        },
    }
    return (
        <Container fluid>
            <Grid w="100%">
                <Group align="flex-end">
                    <TextInput
                        label={`Top ${props.datasetMetadata.dataset1.displayName} SKUs`}
                        placeholder="192"
                        value={topX}
                        onChange={(event) => setTopX(event.currentTarget.value)}
                    />
                    <SegmentedControl
                        value={yAxisFormat}
                        onChange={setYAxisFormat}
                        data={["Percent", "Frequency"]}
                    />
                    <SegmentedControl
                        value={showZeros}
                        onChange={setShowZeros}
                        data={[{ label: "Hide Zero", value: "hide0" }, { label: "Show Zero", value: "show0" }]}
                    />
                    {!_.isUndefined(props.dataset.metrics) && <SegmentedControl
                        value={displayStats}
                        onChange={setDisplayStats}
                        data={["Quartiles", "Average"]}
                    />}
                </Group>
                <Space h="md" />
                <Grid.Col span={props.dataset.metrics ? 8 : 12} h="400px">
                    <Chart type="bar" ref={chartRef} data={data} options={options} onClick={chartOnClick} />
                </Grid.Col>
                {!_.isUndefined(props.dataset.metrics) && <Grid.Col span={4}>
                    <RankingChangesMetricsDisplay
                        metrics={props.dataset.metrics}
                        displayStats={displayStats}
                        yAxisFormat={yAxisFormat}
                        total={total}
                    />
                </Grid.Col>}
            </Grid>
        </Container>

    )
}
