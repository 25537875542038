import * as rdlTypes from "../models/RDLDataTypes";
import { EventType } from "./RDLDataTypes";
import { FacetTypes } from "../utilities/facets";
import { MetricCalculationType } from "./MetricCalculationOps";
import { RuleFormValues } from "../components/forms/rule/rule-form-context";
import { UserFilterConfig } from "./userFilter";


export enum JSTypes {
    Undefined = "undefined",
    Null = "null",
    Boolean = "boolean",
    Number = "number",
    BigInt = "bigint",
    String = "string",
    Symbol = "symbol",
    Function = "function",
    Object = "object",
}

export enum ColumnTypes {
    Undefined = "undefined",
    Null = "null",
    Boolean = "boolean",
    Float = "float",
    Integer = "int",
    Category = "category",
    Date = "date",
    ListOfCategories = "listOfCategories",
}

export type ItemColumns = Record<string, ColumnTypes>;

export interface FacetData {
    columnName: string,
    facetType: FacetTypes | undefined,
    facetValues: {
        numberBucketData?: number,
        dateData: string,
    },
}

export interface EventInfo {
    displayName: string,
    attributeName: string,
}

export interface ItemCardConfig {
    attributeNames: {
        imageLink: string,
        title: string,
        subTitles: string[],
        eventInfos: EventInfo[],
    }
}

export interface PolicyConfig {
    id: string,
    name: string,
    environments: string[],
    rules: RuleFormValues[],
    timestamp: number
}

export interface Policy {
    id: number,
    name: string,
    environments: string[],
    rules_data: {
        rules: RuleFormValues[],
        timestamp: number
    }
}

export interface TestDataset {
    id?: number,
    name: string,
    environment: string,
    timestamp: number,
    tags: string[],
    item_data: {
        id?: rdlTypes.Id,
        item_id?: rdlTypes.Id,
        scores?: any,
        details?: any,
    }[],
    additional_data: null | Record<string, any>,
}

export interface DataFAQType {
    question: string,
    answer: string,
}

export interface UserDrilldownConfig {
    cardOrderRule?: RuleFormValues,
}

export interface RDLConfigType {
    loaded: boolean,
    error: boolean,
    userEventTypes: EventType[],
    metricTypes: EventType[],
    metricCalculations: MetricCalculationType[],
    itemCardConfig: ItemCardConfig,
    userDrilldownConfig: UserDrilldownConfig
    itemColumns: ItemColumns,
    policies: PolicyConfig[],
    testDatasets: TestDataset[],
    userFilters: UserFilterConfig[],
    dataFAQ: DataFAQType[],
    dateRangeWithEvents: [string | null, string | null],
}

export const DEFAULT_CONFIG: RDLConfigType = {
    loaded: false,
    error: false,
    userEventTypes: [],
    metricTypes: [],
    metricCalculations: [],
    itemCardConfig: {
        attributeNames: {
            imageLink: "",
            title: "",
            subTitles: [],
            eventInfos: [],
        }
    },
    userDrilldownConfig: {},
    itemColumns: {},
    policies: [],
    testDatasets: [],
    userFilters: [],
    dataFAQ: [],
    dateRangeWithEvents: [null, null],
}
