import * as dataOnboardingTypes from "../../models/data-onboarding-data-types";
import {
    Breadcrumbs,
    Button,
    Card,
    Group,
    Loader,
    Select,
    Stack,
    Text,
    TextInput,
    Title
} from "@mantine/core"
import { DatabaseDisplayInfoMap, DatabaseNotes, DatabaseTypeDisplayInfo } from "./Components";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PreviewableMarkdown } from "./EditableMarkdown";
import { RubberDuckyLabsApi } from "../../RDLApi";
import { forwardRef, useEffect, useState } from "react";
import { isNotEmpty, useForm } from "@mantine/form";
import { useApiContext } from "../../App";
import { useDisclosure } from "@mantine/hooks";
import _ from "underscore";


export interface EditDatabaseFormValues {
    name: string;
    type: dataOnboardingTypes.DataOnboardingDatabaseType;
    notes: string;
}

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    name: string;
    icon: React.ElementType;
    label: string;
}

// eslint-disable-next-line react/display-name
export const DatabaseTypeSelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ name, icon, ...others }: ItemProps, ref) => (
        <div ref={ref} {...others}>
            <DatabaseTypeDisplayInfo name={name} icon={icon} />
        </div>
    )
);

export function EditDatabaseForm({values, onSubmit, onCancel}: { values: EditDatabaseFormValues, onSubmit: (values: EditDatabaseFormValues) => void, onCancel: () => void }) {
    const selectData = Object.values(dataOnboardingTypes.DataOnboardingDatabaseType).map((type) => {
        const {name, icon} = DatabaseDisplayInfoMap[type];
        return { value: type, label: name, name, icon};
    });

    const form = useForm<EditDatabaseFormValues>({
        initialValues: values,
        validate: {
            name: isNotEmpty("Please enter a name"),
            type: isNotEmpty("Please select a type"),
        }
    });

    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <Stack>
                <TextInput label="Name" placeholder="Enter a name" {...form.getInputProps("name")} />
                <Select
                    label="Type"
                    withinPortal
                    itemComponent={DatabaseTypeSelectItem}
                    data={selectData}
                    placeholder="Select a database"
                    {...form.getInputProps("type")}
                />
                <Stack spacing={3}>
                    <Text size="sm" weight={500} color="gray.9">Notes</Text>
                    <Card p="md" radius="md" withBorder>
                        <PreviewableMarkdown {...form.getInputProps('notes')} />
                    </Card>
                </Stack>
                <Group position="right">
                    <Button variant="outline" color="gray" onClick={onCancel}>Cancel</Button>
                    <Button type="submit">Save</Button>
                </Group>
            </Stack>
        </form>
    )
}

function DetailedDatabaseDisplayInfo({database}: { database: dataOnboardingTypes.DataOnboardingDatabase}) {
    const displayInfo = DatabaseDisplayInfoMap[database.type];

    return (
        <Stack>
            <Group position="apart">
                <Stack spacing={3}>
                    <Text size="sm" weight={500} color="gray.9">Type</Text>
                    <DatabaseTypeDisplayInfo name={displayInfo.name} icon={displayInfo.icon} />
                </Stack>
            </Group>
            <Stack spacing={3}>
                <Text size="sm" weight={500} color="gray.9">Notes</Text>
                <Card p="md" radius="md" withBorder>
                    <DatabaseNotes database={database} />
                </Card>
            </Stack>
        </Stack>
    )
}

function PageBreadcrumbs({db}: {db: dataOnboardingTypes.DataOnboardingDatabase}) {
    const links = [
        { title: 'Data Onboarding Databases', to: '..' },
        { title: db.name, to: '.' },
    ];

    const items = links.map((link, index) => (
        <Link to={link.to} key={index}>
            {link.title}
        </Link>
    ));

    return (
        <Breadcrumbs separator={">"}>{items}</Breadcrumbs>
    );
}

export default function DataOnboardingDatabaseDetailPage() {
    const { databaseId } = useParams();
    const [loading, setLoading] = useState(true);
    const [db, setDb] = useState<dataOnboardingTypes.DataOnboardingDatabase | undefined>(undefined);
    const [editing, { open, close }] = useDisclosure(false);
    const apiContext = useApiContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (!_.isNull(apiContext) && !_.isUndefined(databaseId)) {
            setLoading
            RubberDuckyLabsApi.getInstance(apiContext).getDataOnboardingDatabase(databaseId)
                .then((db) => setDb(db))
                .finally(() => setLoading(false));
        }
    }, [apiContext, databaseId]);

    const onEditSumbit = (values: EditDatabaseFormValues) => {
        if (!_.isNull(apiContext) && !_.isUndefined(db)) {
            setLoading(true);
            RubberDuckyLabsApi.getInstance(apiContext).updateDataOnboardingDatabase(db.id, values)
                .then((db) => setDb(db))
                .finally(() => setLoading(false));
        }
        close();
    }

    const onDelete = () => {
        if (!_.isNull(apiContext) && !_.isUndefined(db)) {
            setLoading(true);
            RubberDuckyLabsApi.getInstance(apiContext).deleteDataOnboardingDatabase(db.id)
                .then(() => navigate('..'));
        }
    }

    if (_.isUndefined(db) || loading) {
        return <Loader />
    }

    return (
        <Stack>
            <PageBreadcrumbs db={db} />
            <Title order={1}>{editing ? "Database: Editing" : `Database: ${db.name}`}</Title>
            {editing
                ? <EditDatabaseForm values={db} onSubmit={onEditSumbit} onCancel={close} />
                : <DetailedDatabaseDisplayInfo database={db} />}
            {!editing && <Group>
                <Button color="red" variant="outline" onClick={onDelete} disabled={_.isUndefined(db)}>Delete</Button>
                <Button color="gray" variant="outline" onClick={open} disabled={_.isUndefined(db)}>Edit</Button>
            </Group>}
        </Stack>
    )
}
