import { Select } from "@mantine/core";

import { ACCEPTABLE_FILTER_TYPES_MAP, FilterTypes } from "../../../utilities/filters";
import { ColumnTypes } from "../../../models/RDLConfig";
import { useRuleFormContext } from "./rule-form-context";


function DisabledFilterTypeInput() {
    return (
        <Select
            withinPortal
            disabled
            label="Filter type"
            placeholder="Pick one"
            data={[]}
        />
    );
}


export function FilterTypeInput(props: {columnType: ColumnTypes | undefined}) {
    const form = useRuleFormContext();
    const columnType = props.columnType;

    if (columnType === undefined) {
        return <DisabledFilterTypeInput />;
    }

    const acceptableTypes = ACCEPTABLE_FILTER_TYPES_MAP[columnType];
    const selectData = [
        { value: FilterTypes.NumericalRange, label: "Range of numbers", disabled: false },
        { value: FilterTypes.Boolean, label: "True / False", disabled: false },
        { value: FilterTypes.ListOfCategories, label: "List of categories", disabled: false },
        { value: FilterTypes.DateRange, label: "Range of dates", disabled: false },
    ];
    selectData.forEach((datum) => datum.disabled = !(acceptableTypes.includes(datum.value)));

    return (
        <Select
            withinPortal
            label="Filter type"
            placeholder="Pick one"
            data={selectData}
            {...form.getInputProps('data.filterData.filterType')}
        />
    );
}
