import * as rdlTypes from "../../models/RDLDataTypes";
import { Alert } from "../../components/Alert";
import {
    Divider,
    Loader,
    LoadingOverlay,
    SimpleGrid,
    Stack,
    Text,
    Title
} from "@mantine/core"
import { InView } from "react-intersection-observer";
import { LAYOUT_TO_SIMPLE_GRID_PROPS } from "../../utilities/layout";
import { PipelineTracesBreadcrumbs, createPipelineTitle } from "../../components/PipelineBreadcrumbs";
import { RubberDuckyLabsApi } from "../../RDLApi";
import { TraceCard } from "../../components/TraceCard";
import { isUndefinedOrNull } from "../../utilities/types";
import { useApiContext } from "../../App";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FakeDateRangePicker from "../../components/FakeDateRangePicker";
import _ from "underscore";


const SCROLL_PAGE_SIZE = 20;


function TracesList(props: { rankingPipeline: rdlTypes.RankingPipeline }) {
    const { rankingPipeline } = props;
    const apiContext = useApiContext();
    const [traceIds, setTraceIds] = useState<string[] | undefined>(undefined);
    const [scrollLimit, setScrollLimit] = useState(0);

    useEffect(() => {
        if (!_.isNull(apiContext)) {
            RubberDuckyLabsApi.getInstance(apiContext)
                .getTraceIds(rankingPipeline.id)
                .then(setTraceIds)
                .catch(() => setTraceIds([]))
        }

        return () => {
            setTraceIds(undefined);
        }
    }, [apiContext]);

    if (_.isUndefined(traceIds)) {
        return <Loader />;
    }

    const cards = traceIds.slice(0, scrollLimit).map((traceId) => (
        <TraceCard
            key={traceId}
            traceId={traceId}
            rankingPipeline={rankingPipeline}
        />
    ));

    return (
        <>
            <SimpleGrid {...LAYOUT_TO_SIMPLE_GRID_PROPS.lg}>
                {cards}
            </SimpleGrid>
            <InView onChange={(inView) => inView && setScrollLimit(scrollLimit + SCROLL_PAGE_SIZE)} />
        </>
    );
}


export default function TracesListPage() {
    const { rankingPipelineId } = useParams();
    const apiContext = useApiContext();
    const [rankingPipeline, setRankingPipeline] = useState<rdlTypes.RankingPipeline | undefined | null>(undefined);

    useEffect(() => {
        if (_.isUndefined(rankingPipelineId)) {
            setRankingPipeline(null);
        } else if (!_.isNull(apiContext)) {
            const integerRankingPipelineId = parseInt(rankingPipelineId);
            RubberDuckyLabsApi.getInstance(apiContext)
                .getRankingPipeline(integerRankingPipelineId)
                .then(setRankingPipeline)
                .catch(() => setRankingPipeline(null))
        }

        return () => {
            setRankingPipeline(undefined);
        }
    }, [apiContext, rankingPipelineId]);


    return (
        <Stack>
            {_.isNull(rankingPipeline) && <Alert message={`Ranking pipeline ${rankingPipelineId} not found`} onClose={_.noop} />}
            <LoadingOverlay visible={_.isUndefined(rankingPipeline)} />
            <PipelineTracesBreadcrumbs rankingPipeline={rankingPipeline} />
            <Title order={1}>{createPipelineTitle(rankingPipeline)} Traces</Title>
            <Text c="dimmed">Explore individual traces from {createPipelineTitle(rankingPipeline)}</Text>
            {!isUndefinedOrNull(rankingPipeline) && <SimpleGrid {...LAYOUT_TO_SIMPLE_GRID_PROPS.lg} >
                <FakeDateRangePicker disabled={false} />
            </SimpleGrid>}
            {!isUndefinedOrNull(rankingPipeline) && <Divider />}
            {!isUndefinedOrNull(rankingPipeline) && <TracesList rankingPipeline={rankingPipeline} />}
        </Stack>
    )
}
