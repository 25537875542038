import { InView } from 'react-intersection-observer';
import { ItemCard, ItemCardProps } from "./ItemCard"
import { LAYOUT_TO_SIMPLE_GRID_PROPS, LayoutSizes } from "../utilities/layout"
import { SimpleGrid } from "@mantine/core"
import { useState } from 'react';
import _ from "underscore";


const SCROLL_PAGE_SIZE = 14;

function itemCardKey(card: ItemCardProps) {
    if (!_.isUndefined(card.event)) {
        return  `${card.event.event_type}-${card.event.event_id}`;
    } else if (!_.isUndefined(card.item)) {
        return card.item.item_id;
    }

    return card.id;
}


// TODO(Alexandra): Scroll needs to find a way to reset its scroll page length when the cards change
// significantly. This is annoying to do while the page is loading.
export const ItemCardGrid = function (props: {
    cards: ItemCardProps[],
    size: LayoutSizes,
    onClick?: (item: ItemCardProps) => void
}) {
    const [scrollLimit, setScrollLimit] = useState(SCROLL_PAGE_SIZE);

    return (
        <>
            <SimpleGrid {...LAYOUT_TO_SIMPLE_GRID_PROPS[props.size]}>
                {props.cards
                    .slice(0, scrollLimit)
                    .map((datum) => (
                        <ItemCard
                            {...datum}
                            key={itemCardKey(datum)}
                            onClick={props.onClick}
                        />
                    ))}
            </SimpleGrid>
            <InView onChange={(inView) => inView && setScrollLimit(scrollLimit + SCROLL_PAGE_SIZE)} />
        </>
    )
}
