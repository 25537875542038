import { HealthCheckTimeRange } from "../services/health-checks";
import { Select, SelectProps } from "@mantine/core";


export type EnumSelectProps = Omit<SelectProps, "data" | "onChange" | "value"> & {
    value: HealthCheckTimeRange | null,
    onChange: (value: HealthCheckTimeRange | null) => void,
};


export default function HealthCheckTimeRangeSelect(props: EnumSelectProps) {
    const data = Object.values(HealthCheckTimeRange).map((name) => ({ value: name, label: name }));

    return (
        <Select
            data={data}
            {...props}/>
    )
}
