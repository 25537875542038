import {
    Group,
    SegmentedControl,
    Stack,
    Text,
    Title,
} from "@mantine/core";
import { MaybeDateRange } from "../../utilities/date-range";
import { MetricFormValues, getMetricsFromConfig } from "../../components/forms/metric/metric-form-context";
import { useEffect, useState } from "react";
import { useListState } from "@mantine/hooks";
import { useRDLConfig } from "../../App";
import EventStatisticsPanel from "../../components/data-explore-panels/EventStatisticsPanel";
import RDLEventDateRangePicker from "../../components/custom/RDLDateRangePicker";


enum AggregationsOptions {
    CurrentPage = 'CurrentPage',
    OrderOnPage = 'OrderOnPage',
    Both = 'Both',
    None = 'None',
}


export default function PositionalAnalysisPage() {
    const config = useRDLConfig();
    // TODO(Alexandra): date range picker
    const [dateRange, setDateRange]  = useState<MaybeDateRange>([new Date(2023, 5, 1), new Date(2023, 5, 1)])
    const [metrics, metricsHandlers] = useListState<MetricFormValues>([]);
    const [aggregations, aggregationsHandlers] = useListState<string>([]);
    const [aggregationsOptions, setAggregationsOptions] = useState(AggregationsOptions.CurrentPage);

    useEffect(() => {
        if (config.loaded) {
            metricsHandlers.setState(getMetricsFromConfig(config));
        }

        return () => {
            metricsHandlers.setState([]);
        }
    }, [config]);

    const aggregationsOptionsData = [
        AggregationsOptions.CurrentPage,
        AggregationsOptions.OrderOnPage,
        AggregationsOptions.Both,
        AggregationsOptions.None,
    ];

    useEffect(() => {
        switch(aggregationsOptions) {
        case AggregationsOptions.CurrentPage:
            aggregationsHandlers.setState(['event.event_attributes.CurrentPage'])
            break;
        case AggregationsOptions.OrderOnPage:
            aggregationsHandlers.setState(['event.event_attributes.OrderOnPage'])
            break;
        case AggregationsOptions.Both:
            aggregationsHandlers.setState([
                'event.event_attributes.CurrentPage',
                'event.event_attributes.OrderOnPage'
            ])
            break;
        case AggregationsOptions.None:
            aggregationsHandlers.setState([]);
            break;
        }
    }, [aggregationsOptions])


    return (
        <Stack>
            <Title order={1}>Aggregated Performance by Position</Title>
            <Text c="dimmed">View aggregate metrics about performance by position on page or current page.</Text>
            <Group>
                <RDLEventDateRangePicker
                    value={dateRange}
                    onChange={(value) => setDateRange(value as MaybeDateRange)}
                />
                <Stack spacing={3}>
                    <Text size="sm" weight={500}>Aggregate By</Text>
                    <SegmentedControl
                        value={aggregationsOptions}
                        onChange={(value: keyof typeof AggregationsOptions) => {
                            setAggregationsOptions(AggregationsOptions[value])
                        }}
                        data={aggregationsOptionsData}
                    />
                </Stack>
            </Group>
            <EventStatisticsPanel
                metrics={metrics}
                dateRange={dateRange}
                aggregations={aggregations}
            />
        </Stack>
    );
}
