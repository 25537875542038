import { MantineTheme } from "@mantine/core";


const defaultChartOptions = {
    responsive: true,
    plugins: {
        zoom: {
            zoom: {
                wheel: {
                    enabled: true,
                },
                pinch: {
                    enabled: true
                },
                drag: {
                    enabled: true,
                },
                mode: 'x' as const,
            },
            pan: {
                enabled: true,
                modifierKey: 'shift' as const,
                mode: 'x' as const,
            }
        },
    },
};


export function mergeWithDefaultChartOptions(options: any) {
    if (options.plugins === undefined) {
        return {...defaultChartOptions, ...options}
    }

    return {...defaultChartOptions, ...options, plugins: {...defaultChartOptions.plugins, ...options.plugins}};
}


export function chartColors(theme: MantineTheme) {
    return chartColorsList(theme).map((color) => ({
        borderColor: theme.fn.rgba(color[6], 0.5),
        backgroundColor: theme.fn.rgba(color[4], 0.5),
    }));
}


export function chartColorsList(theme: MantineTheme) {
    return [
        theme.colors.red,
        theme.colors.pink,
        theme.colors.grape,
        theme.colors.violet,
        theme.colors.indigo,
        theme.colors.blue,
        theme.colors.cyan,
        theme.colors.teal,
        theme.colors.green,
        theme.colors.lime,
        theme.colors.yellow,
        theme.colors.orange,
    ];
}
