import * as dateFNS from "date-fns";
import * as rdlTypes from "../models/RDLDataTypes";
import { MaybeDateRange, isDateRange, isWithinDateRange } from "./date-range";
import { isUndefinedOrNull } from "./types";


export interface EventsData {
    eventType: rdlTypes.EventType,
    data: rdlTypes.EventCount[],
}

export const filterEventsToTimeScale = function(
    eventsData: EventsData[],
    sharedTimeScale: MaybeDateRange,
): EventsData[] {
    if (!isDateRange(sharedTimeScale)) {
        return eventsData;
    }

    const memoTrue = new Set();
    const memoFalse = new Set();

    const dateFilter = function(date: string) {
        if (!(memoTrue.has(date) || memoFalse.has(date))) {
            const result = isWithinDateRange(dateFNS.parseISO(date), sharedTimeScale);
            if (result) {
                memoTrue.add(date);
            } else {
                memoFalse.add(date);
            }
        }
        return memoTrue.has(date);
    }

    return eventsData.map(({eventType, data}) => ({
        eventType,
        data: data.filter((eventCount) => dateFilter(eventCount.date)),
    }));
}

const RECOMMENDATION_EVENT_TYPES = [
    rdlTypes.EventType.Recommendation,
];

export function isRecommendationEventType(eventType: rdlTypes.EventType) {
    return RECOMMENDATION_EVENT_TYPES.includes(eventType);
}

export function isRecommendationEvent(event?: rdlTypes.Event) {
    return !isUndefinedOrNull(event) && isRecommendationEventType(event.event_type);
}

export function isUserSignalEventType(eventType: rdlTypes.EventType) {
    if (isRecommendationEventType(eventType)) return false;
    return eventType !== rdlTypes.EventType.View
}
