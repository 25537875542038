import { FilterInclusivityType, useRuleFormContext } from "./rule-form-context";
import { Select } from "@mantine/core";

export function FilterInclusivityInput() {
    const form = useRuleFormContext();

    return (
        <Select
            withinPortal
            data={[FilterInclusivityType.Is, FilterInclusivityType.IsNot, FilterInclusivityType.IsUndefinedOrNull, FilterInclusivityType.IsNotUndefinedOrNull]}
            {...form.getInputProps('data.filterData.inclusivity')}
        />
    );
}
