import {
    Card,
    Divider,
    Flex,
    Group,
    Image,
    Modal,
    Skeleton,
    Stack,
    Text,
    ThemeIcon,
    Tooltip,
    useMantineTheme
} from "@mantine/core"
import ReactJson from 'react-json-view';
import _ from "underscore";

import * as rdlTypes from "../models/RDLDataTypes";
import { EventInfo } from "../models/RDLConfig";
import { EventName, isRateMetricName } from "../utilities/metric-calculations/metrics";
import { IconArrowBigDown, IconArrowBigUp } from "@tabler/icons-react";
import { RubberDuckyLabsApi } from "../RDLApi";
import { formatValue } from "../utilities/format-values";
import { fromUnixTime, lightFormat } from "date-fns";
import { getEventIcon, getEventNameStr } from "./EventIconMapping";
import { isInteration } from "../utilities/details";
import { useApiContext, useIsLoadingItems, useRDLConfig } from "../App";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import objectPath from "object-path";


interface PointFromDistribution {
    metricType: rdlTypes.EventType,
    value: number,
    mean: number,
    std: number,
}

export type Metrics = Record<string, PointFromDistribution>

type Scores = Record<string, number>;

type Details = Record<string, any>;

export interface ItemCardProps {
    id?: rdlTypes.Id,
    item?: rdlTypes.Item,
    event?: rdlTypes.Event,
    metrics?: Metrics,
    flagged?: boolean,
    scores?: Scores,
    details?: Details,
    displayMetrics?: EventName[],
    onClick?: (item: ItemCardProps) => void,
}

function stdIconColor(numberOfStandardDeviations: number): string {
    if (numberOfStandardDeviations >= 3) {
        return "green";
    } else if (numberOfStandardDeviations >= 1) {
        return "blue";
    } else if (numberOfStandardDeviations >= -1) {
        return "gray";
    } else if (numberOfStandardDeviations >= -3) {
        return "yellow";
    } else {
        return "red";
    }
}

function STDBadge(props: { point: PointFromDistribution, label: string, metricName: string }) {
    const isAboveMean = props.point.value > props.point.mean;
    const numberOfStandardDeviations = (props.point.value - props.point.mean) / props.point.std;

    return (
        <Flex wrap="wrap" gap={4}>
            <Tooltip
                multiline
                label={`${Math.abs(numberOfStandardDeviations).toFixed(2)} standard deviations ${isAboveMean ? 'above' : 'below'} the mean for ${props.metricName}`}
            >
                <ThemeIcon color={stdIconColor(numberOfStandardDeviations)} variant="light" size="xs">
                    {isAboveMean ? <IconArrowBigUp /> : <IconArrowBigDown />}
                </ThemeIcon>
            </Tooltip>
            <Text span size="xs" weight={700}>{props.label}: </Text>
            <Text span size="xs">
                {isRateMetricName(props.metricName)
                    ? props.point.value.toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 4 })
                    : props.point.value}
            </Text>
        </Flex>
    );
}

function MetricsCardSection(props: { metrics: Metrics, displayMetrics: EventName[] | undefined }) {
    const metrics = props.displayMetrics !== undefined
        ? Object.entries(props.metrics).filter(([eventName]) => props.displayMetrics?.includes(eventName as EventName))
        : Object.entries(props.metrics).slice(0, 2);
    return (
        <Stack spacing={0} justify="flex-start">
            {metrics.map(([key, metric]) => <STDBadge key={key} point={metric} label={key} metricName={key} />)}
        </Stack>
    )
}

function EventIcon(props: { event: rdlTypes.Event }) {
    const event = props.event;

    const getLabelAndIcon = function (event: rdlTypes.Event) {
        const EventIcon = getEventIcon(event.event_type)
        return { label: getEventNameStr(event.event_type), icon: <EventIcon /> }
    }

    const { label, icon } = getLabelAndIcon(event);

    return (
        <Tooltip
            label={label}
            withArrow
            position="top"
        >
            <ThemeIcon variant="light" color="blue" size="sm">
                {icon}
            </ThemeIcon>
        </Tooltip>
    )
}

function EventCardSection(props: { event: rdlTypes.Event, eventInfos: EventInfo[] }) {
    const event = props.event;
    return (
        <>
            <Group position="left" mb="xs">
                <EventIcon event={event} />
                <Text color="blue" size="xs">
                    {event.event_attributes.source || "Unknown source"}
                </Text>
            </Group>
            <Text color="blue" size="xs" mb="xs">
                {
                    lightFormat(
                        fromUnixTime(event.timestamp),
                        'yyyy/MM/dd h:mm:ss a'
                    )
                }
            </Text>
            {
                props.eventInfos.map((eventInfo) => (
                    <Text color="blue" size="xs" mb="xs" key={eventInfo.attributeName}>
                        {eventInfo.displayName}: {objectPath.get(event, eventInfo.attributeName)}
                    </Text>
                ))
            }
        </>
    );
}

function KeyValueCardSection(props: { keyValuePairs: Details }) {
    return (
        <Stack spacing={0} justify="flex-start">
            {Object.entries(props.keyValuePairs).map(([key, value]) => (
                <span key={key}>
                    <Text span size="xs" weight={700}>{key}: </Text>
                    <Text span size="xs" key={key}>{formatValue(value)}</Text>
                </span>
            ))}
        </Stack>
    );
}


export function SkeletonItemCard() {
    return (
        <Card shadow="sm" p="lg" radius="md" withBorder>
            <Card.Section>
                <Skeleton height={200} width={600} mb="xs" />
            </Card.Section>
            <Skeleton height={16} radius="xl" mb="xs" />
            <Skeleton height={8} radius="xl" />
        </Card>
    );
}


export function ItemCardTitle(props: { item?: rdlTypes.Item, flagged?: boolean }) {
    const { item, flagged } = props;
    const config = useRDLConfig();
    const attributeNamesConfig = config.itemCardConfig.attributeNames;
    const notFound = item === undefined || _.isEmpty(item.item_attributes);

    return (
        <Text weight={700} mt="sm" color={flagged ? "red" : undefined}>
            {
                notFound || _.isEmpty(objectPath.get(item, attributeNamesConfig.title))
                    ? `Item not found`
                    : objectPath.get(item, attributeNamesConfig.title)
            }
        </Text>
    );
}


export function ItemCardSubtitle(props: { item?: rdlTypes.Item, id?: rdlTypes.Id }) {
    const { item, id } = props;
    const config = useRDLConfig();
    const attributeNamesConfig = config.itemCardConfig.attributeNames;
    const notFound = item === undefined || _.isEmpty(item.item_attributes);

    return (
        <Text size="xs">
            {notFound ? `Item ${id}` : attributeNamesConfig.subTitles
                .map((attr) => objectPath.get(item, attr))
                .filter((v) => v !== undefined && v !== "")
                .join(" ")}
        </Text>
    );
}


export function ItemCard(props: ItemCardProps & { children?: React.ReactNode, fetchItem?: (itemId: rdlTypes.Id) => Promise<rdlTypes.Item | null> }) {
    const children = props.children;
    const [opened, { close, open }] = useDisclosure(false);
    const config = useRDLConfig();
    const attributeNamesConfig = config.itemCardConfig.attributeNames;
    const [loading, setLoading] = useState(false);
    const isLoadingItems = useIsLoadingItems();
    const theme = useMantineTheme();

    const apiContext = useApiContext();
    const [item, setItem] = useState<rdlTypes.Item | undefined>(props.item);

    useEffect(() => {
        if (!_.isNull(apiContext) && props.item === undefined && props.id !== undefined && props.fetchItem === undefined) {
            setLoading(true);
            RubberDuckyLabsApi.getInstance(apiContext)
                .getItem(props.id)
                .then(setItem)
                .catch(() => {
                    setItem(undefined);
                })
                .finally(() => setLoading(false));
        }
    }, [apiContext, props.id]);

    useEffect(() => {
        if (props.item === undefined && props.id !== undefined && props.fetchItem !== undefined) {
            setLoading(true);
            props.fetchItem(props.id)
                .then((item) => _.isNull(item) ? setItem(undefined) : setItem(item))
                .catch(() => setItem(undefined))
                .finally(() => setLoading(false));
        }
    }, [props.id, props.fetchItem, props.item]);

    const notFound = item === undefined || _.isEmpty(item.item_attributes);
    const isLoading = loading || isLoadingItems;
    if (notFound && isLoading) return <SkeletonItemCard />;

    const image = (
        notFound || _.isEmpty(objectPath.get(item, attributeNamesConfig.imageLink))
            ? <Image withPlaceholder height={200} placeholder={<Text align="center">No image link</Text>} />
            : <Image src={objectPath.get(item, attributeNamesConfig.imageLink)} fit="contain" />
    );
    const title = <ItemCardTitle {...props} item={item} />;
    const subTitle = <ItemCardSubtitle {...props} item={item} />;

    const shouldEmphasize = props.details !== undefined
        && Object.entries(props.details)
            .some(([key, value]) => isInteration(key) && value === 1);

    const styles = {
        cursor: 'pointer',
        backgroundColor: shouldEmphasize ? theme.colors.blue[0] : undefined,
        borderColor: shouldEmphasize ? theme.colors.blue[8] : undefined,
    }

    const onCardClick = () => props.onClick !== undefined ? props.onClick(props) : open();

    return (
        <>
            <Modal
                opened={opened}
                onClose={close}
                title="Item debug data"
            >
                {item != undefined && <ReactJson src={item} name={"item"} collapsed />}
                {props.event !== undefined && <ReactJson collapsed src={props.event} name={"event"} />}
                {props.metrics !== undefined && <ReactJson collapsed src={props.metrics} name={"metrics"} />}
                {props.scores !== undefined && <ReactJson collapsed src={props.scores} name={"scores"} />}
                {props.details !== undefined && <ReactJson collapsed src={props.details} name={"details"} />}
            </Modal>
            <Card shadow="sm" p="lg" radius="md" withBorder onClick={onCardClick} style={styles}>
                {props.event !== undefined && <EventCardSection event={props.event} eventInfos={attributeNamesConfig.eventInfos} />}
                <Card.Section>
                    {image}
                </Card.Section>
                {title}
                {subTitle}
                {children}
                {props.metrics !== undefined && <MetricsCardSection metrics={props.metrics} displayMetrics={props.displayMetrics} />}
                {props.scores !== undefined && <><Divider my="xs" /><KeyValueCardSection keyValuePairs={props.scores} /></>}
                {props.details !== undefined && <><Divider my="xs" /><KeyValueCardSection keyValuePairs={props.details} /></>}
            </Card>
        </>
    );
}
