import * as rdlTypes from "../models/RDLDataTypes";
import { Button, Loader, Stack } from "@mantine/core";
import { RubberDuckyLabsAdminApi } from "../RDLApi";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import _ from "underscore";

interface OrganizationPickerProps { user?: rdlTypes.RDLUser, onChange: (value: rdlTypes.Organization) => void }

export default function OrganizationPicker({user, onChange}: OrganizationPickerProps) {
    const [organizations, setOrganizations] = useState<rdlTypes.Organization[]>([]);
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        if (!_.isUndefined(user)) {
            if (user.is_site_admin) {
                RubberDuckyLabsAdminApi.getInstance(getAccessTokenSilently)
                    .getOrganizations()
                    .then(({data}) => setOrganizations(data));
            } else {
                setOrganizations(user.organizations);
            }
        }
    }, [user, getAccessTokenSilently]);

    if (_.isUndefined(user) || (user.is_site_admin && _.isEmpty(organizations))) {
        return <Loader />;
    }

    return (
        <Stack>
            {organizations.map((organization) => (
                <Button
                    key={organization.id}
                    variant="light"
                    onClick={() => onChange(organization)}
                >
                    {organization.name}
                </Button>
            ))}
        </Stack>
    );
}
