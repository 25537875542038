import * as dataOnboardingTypes from "../../models/data-onboarding-data-types";
import {
    ActionIcon,
    Badge,
    Button,
    Card,
    Center,
    Group,
    Loader,
    Menu,
    Modal,
    Select,
    SimpleGrid,
    Stack,
    Table,
    Text,
    ThemeIcon,
    Title,
    Tooltip
} from "@mantine/core"
import {
    IconAlertTriangleFilled,
    IconBrandGoogleBigQuery,
    IconBrandSnowflake,
    IconCalendarFilled,
    IconCircleCheckFilled,
    IconCircleDashed,
    IconClock,
    IconClockFilled,
    IconDotsVertical,
    IconEdit,
    IconFile,
    IconFilePlus,
    IconSql,
    IconSquareXFilled,
    IconTrash
} from "@tabler/icons-react"
import { Link, useNavigate } from "react-router-dom";
import { PreviewableCode, PreviewableMarkdown } from "./EditableMarkdown";
import { Prism } from "@mantine/prism";
import { RubberDuckyLabsApi } from "../../RDLApi";
import { UseListStateHandlers, useDisclosure, useListState } from "@mantine/hooks";
import { formatTimestamp } from "../../utilities/time";
import { forwardRef, useEffect, useState } from "react";
import { isNotEmpty, useForm } from "@mantine/form";
import { useApiContext } from "../../App";
import Markdown from 'react-markdown';
import _ from "underscore";


export function StatusBadge({ status }: { status: dataOnboardingTypes.DataOnboardingProjectTestStatus }) {
    const map: Record<dataOnboardingTypes.DataOnboardingProjectTestStatus , any> = {
        [dataOnboardingTypes.DataOnboardingProjectTestStatus.UNDEFINED]: { color: "gray", icon: IconCircleDashed, title: "Incomplete" },
        [dataOnboardingTypes.DataOnboardingProjectTestStatus.UPCOMING]: { color: "blue", icon: IconCalendarFilled, title: "Upcoming" },
        [dataOnboardingTypes.DataOnboardingProjectTestStatus.DUE_SOON]: { color: "orange", icon: IconClockFilled, title: "Due Soon" },
        [dataOnboardingTypes.DataOnboardingProjectTestStatus.OVERDUE]: { color: "red", icon: IconSquareXFilled, title: "Overdue" },
        [dataOnboardingTypes.DataOnboardingProjectTestStatus.NEEDS_REMEDIATION]: { color: "red", icon: IconAlertTriangleFilled, title: "Needs Remediation" },
        [dataOnboardingTypes.DataOnboardingProjectTestStatus.PENDING_REVIEW]: { color: "gray", icon: IconClock, title: "Pending Review" },
        [dataOnboardingTypes.DataOnboardingProjectTestStatus.OK]: { color: "green", icon: IconCircleCheckFilled, title: "OK" },
    }

    const { color, icon: Icon, title } = map[status];
    return (
        <Tooltip label={title}>
            <Badge
                size="md"
                leftSection={
                    <ThemeIcon mt={10} size="xs" radius="xl" variant="transparent" color={color}>
                        <Icon />
                    </ThemeIcon>
                }
                color={color}
            >
                <Text truncate tt="capitalize">{title}</Text>
            </Badge>
        </Tooltip>
    );
}


export const DatabaseDisplayInfoMap: Record<dataOnboardingTypes.DataOnboardingDatabaseType, { name: string, icon: any }> = {
    [dataOnboardingTypes.DataOnboardingDatabaseType.GOOGLE_BIGQUERY]: { name: "Google BigQuery", icon: IconBrandGoogleBigQuery },
    [dataOnboardingTypes.DataOnboardingDatabaseType.POSTGRES]: { name: "Postgres", icon: IconSql },
    [dataOnboardingTypes.DataOnboardingDatabaseType.SNOWFLAKE]: { name: "Snowflake", icon: IconBrandSnowflake },
}

export function DatabaseDisplayInfo({database}: {database: dataOnboardingTypes.DataOnboardingDatabase}) {
    const {name, icon: Icon} = DatabaseDisplayInfoMap[database.type];

    return (
        <Group noWrap>
            <ThemeIcon size="lg" radius="xl" variant="light" color="cyan">
                <Icon />
            </ThemeIcon>
            <div>
                <Text size="sm">{database.name}</Text>
                <Text size="xs" opacity={0.65}>
                    {name}
                </Text>
            </div>
        </Group>
    )
}

export function DatabaseNotes({database}: {database: dataOnboardingTypes.DataOnboardingDatabase}) {
    if (_.isEmpty(database.notes)) {
        return <Text c="dimmed" italic>No notes</Text>;
    }

    return <Markdown>{database.notes}</Markdown>;
}


export function DatabaseTypeDisplayInfo({name, icon}: {name: string, icon: React.ElementType}) {
    const Icon = icon;
    return (
        <Group noWrap>
            <ThemeIcon size="lg" radius="xl" variant="light" color="cyan">
                <Icon />
            </ThemeIcon>
            <div>
                <Text size="sm">{name}</Text>
            </div>
        </Group>
    )
}


interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
    database: dataOnboardingTypes.DataOnboardingDatabase;
    label: string;
}

// eslint-disable-next-line react/display-name
export const DatabaseSelectItem = forwardRef<HTMLDivElement, ItemProps>(
    ({ database, ...others }: ItemProps, ref) => (
        <div ref={ref} {...others}>
            <DatabaseDisplayInfo database={database} />
        </div>
    )
);


export interface EditDatabaseAnswerFormValues {
    databaseId: string;
    query: string;
    response: string;
}

function AddDatabaseButton() {
    return (
        <Button component={Link} to="/data-onboarding/databases">Add a database</Button>
    );
}


function EditDatabaseAnswerForm({values, onSubmit}: {values: EditDatabaseAnswerFormValues, onSubmit: (data: EditDatabaseAnswerFormValues) => void}) {
    const [loading, setLoading] = useState(true);
    const [databases, setDatabases] = useListState<dataOnboardingTypes.DataOnboardingDatabase>([]);
    const apiContext = useApiContext();

    useEffect(() => {
        if(!_.isNull(apiContext)) {
            setLoading(true);
            RubberDuckyLabsApi.getInstance(apiContext).iterateDataOnboardingDatabases()
                .then((dbs) => setDatabases.setState(dbs))
                .finally(() => setLoading(false));
        }
    }, [apiContext]);

    const selectData = databases.map((db) => {
        return {value: db.id.toString(), label: db.name, database: db};
    });
    const form = useForm<EditDatabaseAnswerFormValues>({
        initialValues: values,
        validate: {
            databaseId: isNotEmpty("Please select a database"),
            query: isNotEmpty("Tell us how to find the data"),
        }
    })

    const noDatabases = !loading && _.isEmpty(databases);
    const disabled = loading || noDatabases;

    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <Stack>
                <Select
                    disabled={disabled}
                    label="Database"
                    withinPortal
                    itemComponent={DatabaseSelectItem}
                    data={selectData}
                    placeholder={noDatabases ? "No databases available" : "Select a database"}
                    {...form.getInputProps("databaseId")}
                />
                <Stack spacing={3}>
                    <Text size="sm" weight={500} color="gray.9">Query</Text>
                    <Card p="md" radius="md" withBorder>
                        <PreviewableCode
                            placeholder="Enter a query to find the data (SQL)"
                            language="sql"
                            disabled={disabled}
                            {...form.getInputProps('query')}
                        />
                    </Card>
                </Stack>
                <Stack spacing={3}>
                    <Text size="sm" weight={500} color="gray.9">Response</Text>
                    <Card p="md" radius="md" withBorder>
                        <PreviewableMarkdown
                            placeholder="Enter the response from the most recent query execution (markdown)"
                            disabled={disabled}
                            {...form.getInputProps('response')}
                        />
                    </Card>
                </Stack>
                <Group position="right">
                    {noDatabases && <Text c="dimmed" italic>This answer requires a database</Text>}
                    {noDatabases && <AddDatabaseButton />}
                    <Button type="submit" disabled={disabled}>Save</Button>
                </Group>
            </Stack>
        </form>
    );
}

export function CreateAnswerModal({onCreate}: {onCreate: (data: EditDatabaseAnswerFormValues) => void}) {
    const [opened, { open, close }] = useDisclosure(false);
    const onSubmit = (values: EditDatabaseAnswerFormValues) => {
        onCreate(values);
        close();
    }

    return (
        <>
            <Modal
                size="lg"
                opened={opened}
                onClose={close}
                title="Add an answer"
            >
                <EditDatabaseAnswerForm
                    values={{databaseId: "", query: "", response: ""}}
                    onSubmit={onSubmit}
                />
            </Modal>
            <Group position="left">
                <Button variant="outline" onClick={open}>Add answer</Button>
            </Group>
        </>
    )
}


export function AnswerTableRow({answer, onChange, onDelete}: {answer: dataOnboardingTypes.DataOnboardingTestAnswer, onChange: (data: dataOnboardingTypes.DataOnboardingTestAnswer) => void, onDelete: () => void}) {
    const navigate = useNavigate();
    const [opened, { open, close }] = useDisclosure(false);
    const [database, setDatabase] = useState<dataOnboardingTypes.DataOnboardingDatabase | undefined>(undefined);
    const apiContext = useApiContext();

    useEffect(() => {
        if (!_.isNull(apiContext)) {
            RubberDuckyLabsApi.getInstance(apiContext).getDataOnboardingDatabase(answer.database_id)
                .then((db) => setDatabase(db));
        }
    }, [apiContext, answer.database_id]);

    const onEditSumbit = (values: EditDatabaseAnswerFormValues) => {
        if (!_.isNull(apiContext)) {
            const data: dataOnboardingTypes.DataOnboardingTestAnswerUpdateData = {
                ...values,
                database_id: Number.parseInt(values.databaseId),
            };
            RubberDuckyLabsApi.getInstance(apiContext).updateDataOnboardingTestAnswer(answer.project_id, answer.test_id, answer.id, data)
                .then((answer) => onChange(answer));
        }
        close();
    }

    const onDeleteSubmit = () => {
        if (!_.isNull(apiContext)) {
            RubberDuckyLabsApi.getInstance(apiContext).deleteDataOnboardingTestAnswer(answer.project_id, answer.test_id, answer.id)
                .then(() => onDelete());
        }
    }

    return (
        <>

            <tr>
                <td
                    onClick={() => navigate(`/data-onboarding/databases/${answer.database_id}`)}
                    style={{cursor: "pointer"}}
                >
                    {_.isUndefined(database) ? <Loader /> : <DatabaseDisplayInfo database={database} />}
                </td>
                <td>
                    <Prism language="sql">{answer.query}</Prism>
                </td>
                <td><Markdown>{answer.response}</Markdown></td>
                <td>
                    <Modal
                        size="lg"
                        opened={opened}
                        onClose={close}
                        title="Edit answer"
                    >
                        <EditDatabaseAnswerForm
                            values={{databaseId: answer.database_id.toString(), query: answer.query, response: answer.response}}
                            onSubmit={onEditSumbit}
                        />
                    </Modal>
                    <Menu shadow="md" width={200} position="bottom-start" withinPortal>
                        <Menu.Target>
                            <ActionIcon variant="transparent" color="gray" onClick={(e: React.MouseEvent) => e.preventDefault()} >
                                <IconDotsVertical size={16} />
                            </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item icon={<IconEdit size={16} />} onClick={open}>Edit</Menu.Item>
                            <Menu.Item icon={<IconTrash size={16} />} onClick={onDeleteSubmit}>Delete</Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </td>
            </tr>
        </>
    );
}

export function EmptyRow({colSpan}: {colSpan: number}) {
    return (
        <tr><td colSpan={colSpan}><Center h={100}><Text fw={700}>No data found</Text></Center></td></tr>
    )
}


export function AnswersTable({answers, answersHandlers}: {answers: dataOnboardingTypes.DataOnboardingTestAnswer[], answersHandlers: UseListStateHandlers<dataOnboardingTypes.DataOnboardingTestAnswer>}) {
    const rows = answers.map((answer, index) => <AnswerTableRow key={answer.id} answer={answer} onChange={(answer) => answersHandlers.setItem(index, answer)} onDelete={() => answersHandlers.remove(index)} />);

    return (
        <Table withBorder>
            <thead>
                <tr>
                    <th>Database</th>
                    <th>Query</th>
                    <th>Response</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {rows}
                {_.isEmpty(rows) && <EmptyRow colSpan={4} />}
            </tbody>
        </Table>
    );
}

function CreateProjectCard() {
    const linkTo = `/data-onboarding/projects?create=true`;

    return (
        <Card p="lg" radius="md" withBorder component={Link} to={linkTo}>
            <Stack spacing="xs">
                <Group>
                    <ThemeIcon color={'cyan'} variant="light" size="lg" >
                        <IconFilePlus />
                    </ThemeIcon>
                    <Text weight="bold">New Project</Text>
                </Group>
                <Text size="sm" c="dimmed">Create a new project workspace for managing recommender systems data.</Text>
            </Stack>
        </Card>
    );
}

function ProjectCard({project}: {project: dataOnboardingTypes.DataOnboardingProject}) {
    const linkTo = `/data-onboarding/projects/${project.id}/tests`;

    return (
        <Card p="lg" radius="md" withBorder component={Link} to={linkTo}>
            <Stack spacing="xs">
                <Group>
                    <ThemeIcon color={'cyan'} variant="light" size="lg" >
                        <IconFile />
                    </ThemeIcon>
                    <Text weight="bold">{project.name}</Text>
                </Group>
                <Text size="sm" c="dimmed" italic>Created {formatTimestamp(project.created)}</Text>
            </Stack>
        </Card>
    );
}

export function ProjectsQuickLinks() {
    const [loading, setLoading] = useState(true);
    const [projects, projectsHandlers] = useListState<dataOnboardingTypes.DataOnboardingProject>([]);
    const apiContext = useApiContext();

    useEffect(() => {
        if (!_.isNull(apiContext)) {
            setLoading(true);
            RubberDuckyLabsApi.getInstance(apiContext)
                .iterateDataOnboardingProjects(100)
                .then((projects) => projectsHandlers.setState(projects))
                .finally(() => setLoading(false));
        }
    }, [apiContext]);

    return (
        <Stack>
            <Title order={2}>Quick Links</Title>
            <SimpleGrid cols={2}>
                {loading && <Loader m="auto" />}
                {projects.map((project) => <ProjectCard key={project.id} project={project} />)}
                <CreateProjectCard />
            </SimpleGrid>
        </Stack>
    )
}
