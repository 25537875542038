import {
    Accordion,
    ActionIcon,
    Button,
    Code,
    Group,
    Menu,
    Modal,
    Paper,
    Stack,
    Text,
    Tooltip
} from "@mantine/core"
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import {
    IconArrowsShuffle,
    IconDotsVertical,
    IconPencil,
    IconPlus,
    IconTrash,
} from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";

import { ActiveInput } from "./ActiveInput";
import { EventType } from "../../../models/RDLDataTypes";
import { FilterInput } from "./FilterInput";
import { FilterTypeInput } from "./FilterTypeInput";
import { ItemColumns, PolicyConfig, RDLConfigType } from "../../../models/RDLConfig";
import { LimitInput } from "./LimitInput";
import { PolicyInput } from "./PolicyInput";
import {
    RuleFormProvider,
    RuleFormValues,
    RuleTypes,
    newRule,
    useRuleForm,
    validateRuleFormValues
} from "./rule-form-context"
import { RuleTitle } from "./RuleTitle";
import { SortInputList } from "./SortInput";
import { TypeInput } from "./TypeInput";
import { WeightedScoreInput } from "./WeightedScoreInput";
import { getMetricColumns } from "../../../utilities/metric-calculations/metrics";
import { useColumns } from "../../../context/ColumnContext";

type OnSubmitFunctionType = (values: RuleFormValues) => void;
type OnRemoveFunctionType = () => void;


function EditRuleForm(
    props: {
        onSubmit: OnSubmitFunctionType,
        values: RuleFormValues,
        onRemove: OnRemoveFunctionType,
        columns: ItemColumns,
        policies: PolicyConfig[],
        metricEventTypes: EventType[],
    }
) {
    const form = useRuleForm({
        initialValues: props.values,
        validate: validateRuleFormValues,
    });

    const name = form.values.data.filterData.columnName;
    const metricColumns = getMetricColumns(props.metricEventTypes);
    const columnType = name in props.columns ? props.columns[name] : metricColumns[name];

    return (
        <RuleFormProvider form={form}>
            <form onSubmit={form.onSubmit((values) => props.onSubmit(values))}>
                <Stack>
                    <TypeInput />
                    {form.values.type === RuleTypes.Limit && <LimitInput />}
                    {form.values.type === RuleTypes.Filter && <FilterInput />}
                    {form.values.type === RuleTypes.Sort && <SortInputList />}
                    {form.values.type === RuleTypes.WeightedScore && <WeightedScoreInput />}
                    {form.values.type === RuleTypes.Policy && <PolicyInput policies={props.policies} />}
                    {form.values.type !== undefined && <Accordion>
                        <Accordion.Item value="settings">
                            <Accordion.Control>
                                <Text size="sm">Advanced</Text>
                            </Accordion.Control>
                            <Accordion.Panel>
                                <Stack>
                                    {form.values.type === RuleTypes.Filter && <>
                                        <Group><Text size="sm" color="gray.9" weight={500}>Data type: </Text><Code>{columnType}</Code></Group>
                                        <FilterTypeInput columnType={columnType} />
                                    </>}
                                    <ActiveInput />
                                </Stack>
                            </Accordion.Panel>
                        </Accordion.Item>
                    </Accordion>}
                    <Group position="right" mt="auto">
                        <Button variant="light" color="gray" onClick={() => props.onRemove()}>Discard</Button>
                        <Button type="submit">Save</Button>
                    </Group>
                </Stack>
            </form>
        </RuleFormProvider>
    )
}

// Submitting this form will unfortunately trigger submitting the outside form, don't know why yet
export function CreateRuleModal(props: {
    onSubmit: OnSubmitFunctionType,
    columns: ItemColumns,
    policies: PolicyConfig[],
    metricEventTypes: EventType[],
}) {
    const [opened, { close, open }] = useDisclosure(false);
    const onSubmit = function (values: RuleFormValues) {
        props.onSubmit(values);
        close();
    }

    return (
        <>
            <Modal
                size="lg"
                opened={opened}
                onClose={close}
                title="Create a rule"
            >
                <EditRuleForm onSubmit={onSubmit} onRemove={close} values={newRule()} columns={props.columns} policies={props.policies} metricEventTypes={props.metricEventTypes} />
            </Modal>
            <Group mt="xs" position="left">
                <Tooltip
                    label={`Add a rule`}
                    withArrow
                    position="top"
                >
                    <ActionIcon onClick={open} variant="light" color="blue" ><IconPlus /></ActionIcon>
                </Tooltip>
            </Group>
        </>
    );
}


export function EditRuleModal(
    props: {
        onSubmit: OnSubmitFunctionType,
        values: RuleFormValues,
        onRemove: OnRemoveFunctionType,
        columns: ItemColumns,
        policies: PolicyConfig[],
        metricEventTypes: EventType[],
    }
) {
    const [opened, { close, open }] = useDisclosure(false);
    const onSubmit = function (values: RuleFormValues) {
        props.onSubmit(values);
        close();
    }

    return (
        <div>
            <Modal
                size="lg"
                opened={opened}
                onClose={close}
                title="Edit rule"
            >
                <EditRuleForm onSubmit={onSubmit} onRemove={close} values={props.values} columns={props.columns} policies={props.policies} metricEventTypes={props.metricEventTypes} />
            </Modal>
            <Group>
                <Paper shadow="xs" p="md">
                    <Group>
                        <RuleTitle rule={props.values} />
                        <Menu shadow="md" width={200} position="bottom-start">
                            <Menu.Target>
                                <ActionIcon variant="transparent" color="gray">
                                    <IconDotsVertical size={16} />
                                </ActionIcon>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <Menu.Item icon={<IconPencil size={16} />} onClick={open}>Edit</Menu.Item>
                                <Menu.Item icon={<IconTrash size={16} />} onClick={() => props.onRemove()}>Delete</Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                    </Group>
                </Paper >
                {
                    props.values.type === RuleTypes.Shuffle && (
                        <Tooltip
                            label={`Reshuffle items`}
                            withArrow
                            position="top"
                        >
                            <ActionIcon mt="xs" variant="light" color="blue" onClick={() => onSubmit(props.values)}>
                                <IconArrowsShuffle />
                            </ActionIcon>
                        </Tooltip>
                    )
                }
            </Group>
        </div>
    );
}

interface DraggableRulesProps {
    rules: RuleFormValues[],
    ruleHandlers: any,
    config: RDLConfigType,
}

export function DraggableRules({ rules, ruleHandlers, config }: DraggableRulesProps) {
    const handlers = ruleHandlers;

    const onEditSubmit = function (index: number, values: RuleFormValues) {
        handlers.setItem(index, values);
    }

    const onEditRemove = function (index: number) {
        handlers.remove(index);
    }

    const onCreateSubmit = function (values: RuleFormValues) {
        handlers.append(values);
    };

    const onDragEnd = function ({ destination, source }: DropResult) {
        destination && handlers.reorder({ from: source.index, to: destination.index });
    }

    const columns = useColumns();

    const fields = rules.map((rule: RuleFormValues, index: number) => (
        <Draggable key={rule.key} index={index} draggableId={rule.key}>
            {(provided) => (
                <Group ref={provided.innerRef} mt="xs" {...provided.draggableProps} {...provided.dragHandleProps}>
                    <EditRuleModal
                        key={rule.key}
                        onSubmit={(values) => onEditSubmit(index, values)}
                        values={rule}
                        onRemove={() => onEditRemove(index)}
                        columns={columns}
                        policies={config.policies}
                        metricEventTypes={config.metricTypes}
                    />
                </Group>)}
        </Draggable>));

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="dnd-list" direction="vertical">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {fields}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <CreateRuleModal onSubmit={onCreateSubmit} columns={columns} policies={config.policies} metricEventTypes={config.metricTypes} />
        </>
    );
}
