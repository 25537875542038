

import { Alert } from "../../Alert";
import { Button, Grid, Group, Skeleton } from "@mantine/core"
import { RankingDrilldownFormProvider, RankingDrilldownFormValues, useRankingDrilldownForm, validateRankingDrilldownFormValues } from "./ranking-drilldown-form-context"
import { RankingPipeline } from "../../../models/RDLDataTypes";
import { RankingPipelineInput } from "./RankingPipelineInput";
import { RubberDuckyLabsApi } from "../../../RDLApi";
import { TransactionIdInput } from "./TransactionIdInput";
import { useApiContext } from "../../../App";
import { useEffect, useState } from "react";
import NotImplementedModal from "../../NotImplementedModal";
import _ from "underscore";

export function RankingDrilldownInputForm(props: {
    values: RankingDrilldownFormValues,
    onSubmit: (values: RankingDrilldownFormValues) => void,
}) {
    const form = useRankingDrilldownForm({
        initialValues: props.values,
        validate: validateRankingDrilldownFormValues,
    });

    const apiContext = useApiContext();
    const [apiError, setApiError] = useState('');
    const [transactionIds, setTransactionIds] = useState<string[]>([]);
    const [rankingPipelines, setRankingPipelines] = useState<RankingPipeline[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!_.isNull(apiContext)) {
            setLoading(true);
            RubberDuckyLabsApi.getInstance(apiContext)
                .getTransactionIds()
                .then(setTransactionIds)
                .then(() => RubberDuckyLabsApi.getInstance(apiContext).listRankingPipelines())
                .then(({data}) => setRankingPipelines(data))
                .catch((error) => setApiError(error.message))
                .finally(() => setLoading(false));
        }

        return () => {
            setTransactionIds([]);
        }
    }, [apiContext]);

    if (loading) {
        return (
            <Group>
                <Skeleton height={21} width={100} />
                <Skeleton height={40} />
            </Group>
        );
    }

    return (
        <RankingDrilldownFormProvider form={form}>
            {!_.isEmpty(apiError) && <Alert message={apiError} onClose={() => setApiError('')} />}
            <NotImplementedModal opened={_.isEmpty(transactionIds)} />
            <form onSubmit={form.onSubmit((values) => props.onSubmit(values))}>
                <Grid align="flex-end">
                    <Grid.Col span={3}>
                        <RankingPipelineInput rankingPipelines={rankingPipelines} />
                    </Grid.Col>
                    <Grid.Col span={5}>
                        <TransactionIdInput transactionIds={transactionIds} />
                    </Grid.Col>
                    <Grid.Col span={2}>
                        <Button type="submit">Submit</Button>
                    </Grid.Col>
                </Grid>
            </form>
        </RankingDrilldownFormProvider>
    )
}
