import * as rdlTypes from "../../models/RDLDataTypes";
import { ColumnTypes, ItemColumns } from '../../models/RDLConfig';
import { MetricFormValues } from "../../components/forms/metric/metric-form-context";


export function getMetricColumns(metricEventTypes: rdlTypes.EventType[]): ItemColumns {
    return Object.fromEntries(metricEventTypes.map((eventType) => {
        const metricName = `metrics.${rdlTypes.EventType[eventType]}.value`;
        const columnType = isRateMetric(eventType) ? ColumnTypes.Float : ColumnTypes.Integer;
        return [metricName, columnType];
    }));
}

export type EventName = keyof typeof rdlTypes.EventType;
const METRIC_NAMES: string[] = Object.values(rdlTypes.EventType)
    .filter((m): m is string => typeof m === "string");

export const createMetricTitle = function (metric: MetricFormValues) {
    return metric.displayName;
}

const RATE_METRICS = [
    rdlTypes.EventType.LikeRate,
    rdlTypes.EventType.BuyOrBidClickRate,
    rdlTypes.EventType.SellOrAskClickRate,
    rdlTypes.EventType.BuyCompletedRate,
    rdlTypes.EventType.SellCompletedRate,
    rdlTypes.EventType.DirectPDPRate,
    rdlTypes.EventType.DirectATCRate,
    rdlTypes.EventType.DirectConversionRate,
    rdlTypes.EventType.DislikeRate,
    rdlTypes.EventType.AddToPackageRate,
];

export const isRateMetric = function(eventType: rdlTypes.EventType): boolean {
    return RATE_METRICS.includes(eventType);
}

export const isMetricName = function (metricName: string): metricName is EventName {
    return METRIC_NAMES.includes(metricName);
}

export const isRateMetricName = function(metricName: string): boolean {
    return isMetricName(metricName) && isRateMetric(rdlTypes.EventType[metricName]);
}
