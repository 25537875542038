import 'chartjs-adapter-date-fns';
import { Bar } from 'react-chartjs-2';
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    ChartOptions,
    Title as ChartTitle,
    Filler,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    TimeScale,
    Tooltip,
    TooltipItem,
} from 'chart.js';
import { MetricFormValues } from "../forms/metric/metric-form-context";
import { RateStatistic } from "../../utilities/statistics-api/types";
import { chartColorsList, mergeWithDefaultChartOptions } from "../../utilities/charts";
import { formatRateForChartTicks, formatRateForChartTooltip } from "../../utilities/format-values";
import { useMantineTheme } from "@mantine/core";
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    BarElement,
    PointElement,
    LineElement,
    ChartTitle,
    Tooltip,
    Legend,
    zoomPlugin,
    Filler,
);


// TODO(Alexandra) exponential by default, add option for linear at some point
export function RateStatisticsBarChart(props: {
    stats: RateStatistic[],
    aggregation: string,
    colorIndex: number,
    metric: MetricFormValues,
}) {
    const { stats, aggregation, colorIndex, metric } = props;
    const theme = useMantineTheme();
    const colors = chartColorsList(theme);
    const formattedAggregation = aggregation.replace('event.event_attributes.', '')

    const dataset = {
        data: stats,
        borderColor: theme.fn.rgba(colors[colorIndex % colors.length][6], 0.5),
        backgroundColor: theme.fn.rgba(colors[colorIndex % colors.length][5], 0.5),
        parsing: {
            yAxisKey: 'rate',
            xAxisKey: `aggregation.${aggregation.replaceAll('.', '\\.')}`,
        }
    };

    const data = {
        datasets: [dataset],
    }

    const options: ChartOptions = {
        scales: {
            y: {
                type: 'linear',
                title: { display: false, text: `Average ${metric.displayName}` },
                ticks: {
                    callback: (value: any): string => formatRateForChartTicks(value),
                },
                beginAtZero: true,
                grid: { display: false }
            },
            x: {
                title: { display: true, text: formattedAggregation },
                type: 'linear',
                grid: { display: false }
            }
        },
        plugins: {
            title: {
                display: false,
                text: `Average ${metric.displayName} by ${aggregation}`,
            },
            tooltip: {
                intersect: false,
                callbacks: {
                    title: (context: TooltipItem<any>[]) => context.map((ctx) => `${formattedAggregation} is ${ctx.parsed.x}`),
                    label: (context: any) => {
                        const value = context.parsed.y;
                        const formattedPercentage = formatRateForChartTooltip(value);
                        return `${formattedPercentage} ${metric.displayName}`;
                    }

                },
            },
            zoom: {
                zoom: {
                    wheel: { enabled: false },
                    pinch: { enabled: false },
                    drag: { enabled: false },
                },
                pan: { enabled: false },
            },
            legend: {
                display: false,
            },
        },
    };

    return (
        <>
            <Bar
                options={mergeWithDefaultChartOptions(options)}
                data={data}
            />
        </>
    );
}
