import { MouseEvent, useMemo, useRef } from "react";
import _ from "underscore";

import 'chartjs-adapter-date-fns';
import * as math from "mathjs";
import { Bar } from 'react-chartjs-2';
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    ChartOptions,
    Title as ChartTitle,
    Filler,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    TimeScale,
    Tooltip,
} from 'chart.js';
import { FacetFormValues } from "../forms/facet/facet-form-context";
import { FacetedCards, chartAxisTitle, chartTitleWithFacets } from "../../utilities/facets";
import { ItemCardProps, Metrics } from "../ItemCard";
import { MantineTheme, useMantineTheme } from "@mantine/core";
import { MetricFormValues } from "../forms/metric/metric-form-context";
import { chartColorsList, mergeWithDefaultChartOptions } from "../../utilities/charts";
import { formatPercentage } from "../../utilities/format-values";
import { isMetricName, isRateMetricName } from "../../utilities/metric-calculations/metrics";
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    BarElement,
    PointElement,
    LineElement,
    ChartTitle,
    Tooltip,
    Legend,
    zoomPlugin,
    Filler,
);


const createDataset = function(
    facetedCards: FacetedCards[],
    metric: MetricFormValues,
    metricIndex: number,
    theme: MantineTheme,
) {
    const colors = chartColorsList(theme);

    const metricName = metric.name;
    if (!isMetricName(metricName)) {
        throw Error(`Invalid metric name: ${metricName}`);
    }
    const isRateMetric = isRateMetricName(metricName);

    const data = facetedCards.map((dataset) => {
        const cardsWithMetrics = dataset.cards
            .filter((card): card is ItemCardProps & { metrics: Metrics } => card.metrics !== undefined)
        const metrics = cardsWithMetrics.map((c) => c.metrics[metricName].value);
        return {
            facetName: dataset.facetName,
            mean: cardsWithMetrics.length > 0 ?
                isRateMetric ? math.mean(metrics) : math.sum(metrics)
                : 0,
        };
    });

    return {
        data: data,
        borderColor: theme.fn.rgba(colors[metricIndex % colors.length][6], 0.5),
        backgroundColor: theme.fn.rgba(colors[metricIndex % colors.length][5], 0.5),
        parsing: {
            yAxisKey: 'facetName',
            xAxisKey: 'mean',
        }
    };
}


// TODO(Alexandra) exponential by default, add option for linear at some point
export function MetricAverageBarChart(props: {
    datasets: FacetedCards[],
    metric: MetricFormValues,
    metricIndex: number,
    facets: FacetFormValues[],
    onClickItem: (facetName: string) => void,
}) {
    const chartRef = useRef<any>(null);
    const metric = props.metric;
    const theme = useMantineTheme();

    const dataset = useMemo(
        () => createDataset(props.datasets, props.metric, props.metricIndex, theme),
        [props.datasets, props.metric, theme],
    );

    const chartOnClick = (event: MouseEvent<HTMLCanvasElement>) => {
        const elements =  chartRef.current.getElementsAtEventForMode(event, 'nearest', { intersect: false }, true);
        const first = _.first(elements);
        if (!_.isUndefined(first)) {
            const facetName = first.element.$context?.raw?.facetName;
            if (!_.isUndefined(facetName)) props.onClickItem(facetName);
        }
    }

    const isRateMetric = isRateMetricName(metric.name);

    const data = {
        datasets: [dataset],
    }

    const options: ChartOptions = {
        indexAxis: 'y',
        aspectRatio: 1,
        scales: {
            y: {
                type: 'category',
                title: { display: props.facets.length > 0, text: chartAxisTitle(props.facets) },
            },
            x: {
                title: { display: true, text: isRateMetric ? `Average ${metric.displayName}` : `Total ${metric.displayName} events` },
                type: 'linear',
                ticks: {
                    callback: (value: any): string => isRateMetric ? formatPercentage(value, 2) : value,
                },
            }
        },
        plugins: {
            title: {
                display: true,
                text: chartTitleWithFacets(isRateMetric ? `${metric.displayName}` : `${metric.displayName} events`, props.facets),
            },
            tooltip: {
                intersect: false,
                callbacks: {
                    label: (context: any) => isRateMetric ? formatPercentage(context.parsed.x, 2) : `${context.parsed.x}`,
                },
            },
            zoom: {
                zoom: {
                    wheel: { enabled: false },
                    pinch: { enabled: false },
                    drag: { enabled: false },
                },
                pan: { enabled: false },
            },
            legend: {
                display: false,
            },
        },
    };

    return (
        <>
            <Bar
                ref={chartRef}
                options={mergeWithDefaultChartOptions(options)}
                data={data}
                onClick={chartOnClick}
            />
        </>
    );
}
