import * as rdlTypes from "../../models/RDLDataTypes";
import * as statsTypes from "../../models/StatsDataTypes";
import { Alert } from "../../components/Alert";
import {
    Card,
    LoadingOverlay,
    SimpleGrid,
    Skeleton,
    Stack,
    Text,
    Title
} from "@mantine/core"
import { EventName } from "../../utilities/metric-calculations/metrics";
import { LAYOUT_TO_SIMPLE_GRID_PROPS } from "../../utilities/layout";
import { getEventNameStr } from "../../components/EventIconMapping";
import { randomId } from "@mantine/hooks";
import { useApiContext, useRDLConfig } from "../../App";
import { useEffect, useMemo, useState } from "react";
import HealthCheckService, { HealthCheckTimeRange } from "../../services/health-checks";
import HealthCheckTimeRangeSelect from "../../components/HealthCheckTimeRangeSelect";
import HealthChecksMetricsChart, { MetricsChartDatapoint } from "../../components/charts/HealthChecksMetricsChart";
import _ from "underscore";


export interface Metric {
    key: string,
    eventType: rdlTypes.EventType,
    eventName: EventName,
    displayName: string,
}


export default function HealthChecksMetricsPage() {
    const config = useRDLConfig();
    const apiContext = useApiContext();
    const [healthCheckData, setHealthCheckData] = useState<Record<rdlTypes.EventType, MetricsChartDatapoint[]>>({} as Record<rdlTypes.EventType, MetricsChartDatapoint[]>);
    const [healthCheckTimeRange, setHealthCheckTimeRange] = useState<HealthCheckTimeRange | null>(HealthCheckTimeRange.LastWeek);
    const [apiError, setApiError] = useState("");

    const metrics: Metric[] = useMemo(() => config.metricTypes.map((eventType) => ({
        key: randomId(),
        eventType: eventType,
        eventName: rdlTypes.EventType[eventType] as EventName,
        displayName: getEventNameStr(eventType),
    })), [config]);

    useEffect(() => {
        const onSuccess = (eventType: rdlTypes.EventType, data: statsTypes.Statistic[]) => {
            const newData = data.map((d) => ({ date: d.timescale_value, count: d.func_value.count }));
            setHealthCheckData((prev) => ({ ...prev, [eventType]: newData }))
        }

        if (!_.isNull(apiContext) && !_.isNull(healthCheckTimeRange)) {
            HealthCheckService
                .getInstance(apiContext)
                .getHealthCheckForAllEvents(
                    metrics.map(({ eventType }) => eventType),
                    healthCheckTimeRange,
                    onSuccess,
                )
                .catch((error) => setApiError(error.message))
        }

        return () => {
            setHealthCheckData({} as Record<rdlTypes.EventType, MetricsChartDatapoint[]>);
            setApiError("");
        }
    }, [apiContext, metrics, healthCheckTimeRange]);

    return (
        <Stack>
            {!_.isEmpty(apiError) && <Alert message={apiError} onClose={() => setApiError('')} />}
            <LoadingOverlay visible={_.isEmpty(metrics)} />
            <Title order={1}>Health Check: Metrics</Title>
            <Text c="dimmed">Check that all user interaction data is correctly configured.</Text>
            <HealthCheckTimeRangeSelect  value={healthCheckTimeRange} onChange={setHealthCheckTimeRange} />
            <SimpleGrid {...LAYOUT_TO_SIMPLE_GRID_PROPS.sm}>
                {metrics.map((metric) =>
                    <Card key={metric.key}>
                        <Text size="sm" weight="bold" my="lg">
                            {`${metric.displayName}`} events over time
                        </Text>
                        {<Skeleton visible={!(metric.eventType in healthCheckData)}>
                            <HealthChecksMetricsChart
                                data={healthCheckData[metric.eventType]}
                                metric={metric}
                                healthCheckTimeRange={healthCheckTimeRange}
                            />
                        </Skeleton>}
                    </Card>)}
            </SimpleGrid>
        </Stack>
    );
}
