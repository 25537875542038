import { EventType } from "./RDLDataTypes";


export enum MetricOperations {
    Divide = "divide",
}


// TODO: We can support recursive metric calculations by including MetricCalculation in the union
// type of the LHS and RHS
// We can support operations of singletons (like count, no-op) by allowing one of LHS or RHS to be undefined or null
export type MetricCalculationLeft = EventType;
export type MetricCalculationRight = EventType;

export interface MetricCalculation {
    left: MetricCalculationLeft;
    right: MetricCalculationRight;
    op: MetricOperations;
}

export interface MetricCalculationType {
    eventType: EventType,
    calculation: MetricCalculation
}
