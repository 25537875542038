import {
    Divider,
    SegmentedControl,
    Stack,
    Tabs,
    Text,
    Title
} from "@mantine/core"
import { SampleSizeForConversionRateABTestCalculator, SampleSizeForConversionRateABTestCalculatorValues } from "./components/SampleSizeForConversionRateABTestCalculator";
import { StatisticalSignificanceForABTestCalculator } from "./components/StatisticalSignificanceForABTestCalculator";
import { useState } from "react";
import _ from "underscore";


enum CalculatorType {
    SampleSize = "sample-size",
    PostTestAnalysis = "post-test-analysis",
}


export interface ConversionRateABTestCalculatorValues {
    baselineConversionRate: number;
    effectSize: number;
    confidenceLevel: number;
    sampleSize: number;
}


export function isConversionRateABTestCalculatorValues(value: SampleSizeForConversionRateABTestCalculatorValues): value is ConversionRateABTestCalculatorValues {
    return _.isNumber(value.sampleSize) && _.isNumber(value.effectSize);
}

export default function ABTestCalculatorPage() {
    const metricName = "conversion rate";
    const [calculatorType, setCalculatorType] = useState<string>(CalculatorType.SampleSize);
    const calculatorTypeSelectData = [
        { value: CalculatorType.SampleSize, label: "Sample Size" },
        { value: CalculatorType.PostTestAnalysis, label: "Post Test Analysis"},
    ];
    const [initialValues, setInitialValues] = useState<ConversionRateABTestCalculatorValues>({
        baselineConversionRate: 0.03,
        effectSize: 0.20,
        confidenceLevel: 0.95,
        sampleSize: 6338,
    });

    return (
        <Stack>
            <Title order={1}>A/B Test Calculator</Title>
            <Text c="dimmed">Pre and post test analysis for A/B tests</Text>
            <Tabs defaultValue="rate">
                <Tabs.List>
                    <Tabs.Tab value="rate">Rate Metrics</Tabs.Tab>
                    <Tabs.Tab value="per-user" disabled>Per-User Metrics</Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="rate" mt="xl">
                    <Stack align="flex-start" maw={600} mx="auto">
                        <Stack spacing="3" align="flex-start">
                            <Text size="sm" weight={500} color="gray.9">Calculate</Text>
                            <SegmentedControl data={calculatorTypeSelectData} value={calculatorType} onChange={setCalculatorType} />
                        </Stack>
                    </Stack>
                    <Divider my="xl" maw={600} mx="auto" />

                    { calculatorType === CalculatorType.SampleSize && <SampleSizeForConversionRateABTestCalculator metricName={metricName} initialValues={initialValues} onChange={setInitialValues} />}
                    { calculatorType === CalculatorType.PostTestAnalysis && <StatisticalSignificanceForABTestCalculator metricName={metricName} initialValues={initialValues} onChange={setInitialValues} /> }
                </Tabs.Panel>
                <Tabs.Panel value="per-user">Coming soon</Tabs.Panel>
            </Tabs>
        </Stack>

    );
}
