import { FacetData } from "../../../models/RDLConfig";
import { createFormContext } from "@mantine/form";
import { randomId } from "@mantine/hooks";


export interface FacetFormValues {
    key: string,
    active: boolean,
    data: {
        facetData: FacetData,
    }
}

export const newFacet = function(): FacetFormValues {
    return {
        key: randomId(),
        active: true,
        data: {
            facetData: {
                columnName: '',
                facetType: undefined,
                facetValues: {
                    dateData: 'year',
                },
            },
        },
    };
};

export const validateFacetFormValues = {
    data: {
        facetData: {
            columnName: (value: string) => value.length === 0 ? 'Please enter a column name' : undefined,
            facetType: (value: any) => value === undefined ? 'Please select a facet type' : undefined,
            facetValues: {
                numberData: undefined,
                dateData: undefined,
            },
        },
    },
}

export const [FacetFormProvider, useFacetFormContext, useFacetForm] =
  createFormContext<FacetFormValues>();
