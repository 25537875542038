import { Select } from "@mantine/core";


import { ACCEPTABLE_FACET_TYPES_MAP } from "../../../utilities/facets";
import { ColumnTypes } from "../../../models/RDLConfig";
import { createSelectItemsFromColumns } from "../../../utilities/select-items";
import { useColumns } from "../../../context/ColumnContext";
import { useEffect, useMemo } from "react";
import { useFacetFormContext } from "./facet-form-context";


export function SearchableNameInput() {
    const form = useFacetFormContext();
    const columns = useColumns();

    const searchableColumns = useMemo(
        () => createSelectItemsFromColumns(columns),
        [columns],
    );

    useEffect(() => {
        const facetType = form.values.data.facetData.facetType;
        const columnName = form.values.data.facetData.columnName;
        // TODO(Alexandra): This is another place where we're assuming that all non item attributes are floats
        const columnType = columnName in columns ? columns[columnName] : ColumnTypes.Float;
        const acceptableTypes = ACCEPTABLE_FACET_TYPES_MAP[columnType];
        if (facetType === undefined || !acceptableTypes.includes(facetType)) {
            form.setFieldValue('data.facetData.facetType', acceptableTypes[0]);
        }
    }, [form.values.data.facetData.columnName]);

    return (
        <Select
            withinPortal
            label="Aggregate by"
            data={searchableColumns}
            placeholder="Pick one"
            searchable
            {...form.getInputProps('data.facetData.columnName')}
        />
    );
}
