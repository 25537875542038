import 'chartjs-adapter-date-fns';
import { Bar } from 'react-chartjs-2';
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Title as ChartTitle,
    Filler,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    TimeScale,
    Tooltip,
} from 'chart.js';
import { NO_SEGMENT, UserSegment } from '../../models/user-segments';
import { formatLargePercentage } from "../../utilities/format-values";
import { mergeWithDefaultChartOptions } from "../../utilities/charts";
import { useMantineTheme } from "@mantine/core";
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    BarElement,
    PointElement,
    LineElement,
    ChartTitle,
    Tooltip,
    Legend,
    zoomPlugin,
    Filler,
);


export function SegmentsChart({ countUsersWithoutSegment, countUsers, segments }: {
    segments: UserSegment[],
    countUsersWithoutSegment: number,
    countUsers: number,
}) {
    const theme = useMantineTheme();

    const counts = segments.map(({key, countUsers}) => key === NO_SEGMENT ? countUsersWithoutSegment : countUsers)

    const data = {
        labels: segments.map(({name}) => name),
        datasets: [{
            label: 'Users',
            data: counts,
            backgroundColor: segments.map(({color}) => theme.fn.rgba(color[4], 0.5)),
            borderColor: segments.map(({color}) => theme.fn.rgba(color[6], 0.5)),
            borderWidth: 1,
        }]
    };

    const options = mergeWithDefaultChartOptions({
        aspectRatio: 5,
        indexAxis: 'y',
        plugins: {
            legend: { display: false },
            title: {
                display: true,
                text: "User Segment Breakdown Chart",
            },
            tooltip: {
                intersect: false,
                callbacks: {
                    label: (context: any) => `${context.parsed.x.toLocaleString()} ${context.dataset.label} (${formatLargePercentage(context.parsed.x / countUsers)})`,
                }
            },
            zoom: {
                zoom: {
                    wheel: { enabled: false },
                    pinch: { enabled: false },
                    drag: { enabled: false },
                },
                pan: { enabled: false },
            },
        },
    });

    return (
        <Bar options={options} data={data} />
    );
}
