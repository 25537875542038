import * as rdlTypes from "../models/RDLDataTypes";
import {
    Icon,
    IconBookmarks,
    IconCash,
    IconClick,
    IconCoin,
    IconEye,
    IconHandStop,
    IconMailFast,
    IconMailForward,
    IconPackage,
    IconQuestionMark,
    IconRobot,
    IconSearch,
    IconShoppingCart,
    IconStar,
    IconStarOff,
    IconThumbDown,
    IconThumbUp,
    IconUserPlus,
} from "@tabler/icons-react";


const EVENT_ICON_MAP = new Map<rdlTypes.EventType, Icon>([
    [rdlTypes.EventType.View, IconEye],
    [rdlTypes.EventType.Like, IconThumbUp],
    [rdlTypes.EventType.Dislike, IconThumbDown],
    [rdlTypes.EventType.AddToPackage, IconPackage],
    [rdlTypes.EventType.ProductViewed, IconEye],
    [rdlTypes.EventType.BuyOrBidButtonClicked, IconClick],
    [rdlTypes.EventType.ViewAllDataClicked, IconSearch],
    [rdlTypes.EventType.ProductFollowed, IconUserPlus],
    [rdlTypes.EventType.SellOrAskButtonClicked, IconMailForward],
    [rdlTypes.EventType.BuyCompleted, IconCoin],
    [rdlTypes.EventType.BidCreated, IconHandStop],
    [rdlTypes.EventType.SellCompleted, IconCash],
    [rdlTypes.EventType.ProductAddedToPortfolio, IconThumbUp],
    [rdlTypes.EventType.AskCreated, IconMailFast],
    [rdlTypes.EventType.Recommendation, IconRobot],
    [rdlTypes.EventType.DirectConversion, IconCoin],
    [rdlTypes.EventType.DirectATC, IconShoppingCart],
    [rdlTypes.EventType.DirectPDP, IconEye],
    [rdlTypes.EventType.AddToCollection, IconBookmarks],
    [rdlTypes.EventType.PositiveOverallRating, IconStar],
    [rdlTypes.EventType.NegativeOverallRating, IconStarOff],
]);

export function getEventIcon(eventType: rdlTypes.EventType): Icon {
    return EVENT_ICON_MAP.get(eventType) ?? IconQuestionMark;
}

export function getEventNameStr(eventType: rdlTypes.EventType): string {
    return Object.values(rdlTypes.EventType).includes(eventType)
        ? rdlTypes.EventType[eventType].replace(/([A-Z])/g, ' $1').slice(1)
        : 'Unknown';
}
