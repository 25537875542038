import 'chartjs-adapter-date-fns';
import * as rdlTypes from "../../models/RDLDataTypes";
import { Bar } from 'react-chartjs-2';
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Title as ChartTitle,
    Filler,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    TimeScale,
    Tooltip,
} from 'chart.js';
import { Metric } from "../../pages/health-checks/HealthChecksMetricsPage";
import { UserSegment } from '../../models/user-segments';
import { mergeWithDefaultChartOptions } from "../../utilities/charts";
import { useMantineTheme } from "@mantine/core";
import _ from "underscore";
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    BarElement,
    PointElement,
    LineElement,
    ChartTitle,
    Tooltip,
    Legend,
    zoomPlugin,
    Filler,
);


export function SegmentsMetricChart(props: {
    segments: UserSegment[],
    metric: Metric,
    segmentData: Record<rdlTypes.Id, number>,
}) {
    const { segments, metric, segmentData } = props;
    const theme = useMantineTheme();

    const data = {
        labels: _.pluck(segments, 'name'),
        datasets: [{
            label: `${metric.displayName} per user`,
            data: segments.map(({key}) => segmentData[key]),
            backgroundColor: segments.map(({color}) => theme.fn.rgba(color[4], 0.5)),
            borderColor: segments.map(({color}) => theme.fn.rgba(color[6], 0.5)),
            borderWidth: 1,
        }]
    };

    const options = mergeWithDefaultChartOptions({
        aspectRatio: 5,
        indexAxis: 'y',
        plugins: {
            legend: { display: false },
            title: {
                display: true,
                text: `${metric.displayName} per user`,
            },
            zoom: {
                zoom: {
                    wheel: { enabled: false },
                    pinch: { enabled: false },
                    drag: { enabled: false },
                },
                pan: { enabled: false },
            },
        },
    });

    return (
        <Bar options={options} data={data} />
    );
}
