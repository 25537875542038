import * as dataOnboardingTypes from "./models/data-onboarding-data-types";
import * as mlModelTypes from "./models/ml-model-data-types";
import * as rdlTypes from "./models/RDLDataTypes";
import * as statsTypes from "./models/StatsDataTypes";
import { AxiosInstance, AxiosResponse } from 'axios';
import { Policy, RDLConfigType, TestDataset } from './models/RDLConfig';
import { RankingChangesDataset } from './components/charts/RankingChangesComparisonDashboard';
import { UserFilter } from "./components/forms/user-filters/user-filters-form-context";
import { aggregationsToApiParams, dateToAPIParams, userFiltersToJSONPostData } from "./utilities/api-params";
import _ from "underscore";
import axios from 'axios';


const BASE_URLS: Record<string, string> = {
    "localhost": "http://localhost:5002",
    "staging.rubberduckylabs.io": "https://staging.rubberduckylabs.io:5002",
    "app.rubberduckylabs.io": "https://app.rubberduckylabs.io:5002",
};

function getBaseURL() {
    const url = BASE_URLS[window.location.hostname];
    if (!url) {
        throw new Error(`Unknown hostname: ${window.location.hostname}`);
    }
    return url;
}


export interface RDLApiContext {
    getToken: () => Promise<string>,
    organization: rdlTypes.Organization,
}

type GetAccessTokenSilentlyType = (options?: {scope: string}) => Promise<string>;

abstract class UsersHttpClient {
    protected instance: AxiosInstance;
    protected getAccessTokenSilently: GetAccessTokenSilentlyType;

    public constructor(getAccessTokenSilently: GetAccessTokenSilentlyType) {
        this.getAccessTokenSilently = getAccessTokenSilently;

        const baseURL = getBaseURL();

        this.instance = axios.create({
            baseURL: `${baseURL}/users`,
            timeout: 600000,
        });
        this._initializeInterceptors(this.instance);
    }

    private _initializeInterceptors = (instance: AxiosInstance) => {
        instance.interceptors.request.use(
            (config) => this
                .getAccessTokenSilently()
                .then((token) => {
                    if (config.headers) config.headers.Authorization = `Bearer ${token}`;
                    return config;
                })
        );
        instance.interceptors.response.use(
            ({ data }: AxiosResponse) => data,
        );
    }
}


export class RubberDuckyLabsUsersApi extends UsersHttpClient {
    private static classInstance?: RubberDuckyLabsUsersApi;

    public constructor(getAccessTokenSilently: (GetAccessTokenSilentlyType)) {
        super(getAccessTokenSilently);
    }

    public static getInstance(getAccessTokenSilently: GetAccessTokenSilentlyType) {
        if (!this.classInstance) {
            this.classInstance = new RubberDuckyLabsUsersApi(getAccessTokenSilently);
        }

        return this.classInstance;
    }

    public getSelf(): Promise<rdlTypes.RDLUser> {
        return this.instance.get(`/self`);
    }
}


abstract class AdminHttpClient {
    protected instance: AxiosInstance;
    protected getAccessTokenSilently: ({scope}: {scope: string}) => Promise<string>;

    public constructor(getAccessTokenSilently: ({scope}: {scope: string}) => Promise<string>) {
        this.getAccessTokenSilently = getAccessTokenSilently;

        const baseURL = getBaseURL();

        this.instance = axios.create({
            baseURL: `${baseURL}/admin`,
            timeout: 600000,
        });
        this._initializeInterceptors(this.instance);
    }

    private _initializeInterceptors = (instance: AxiosInstance) => {
        instance.interceptors.request.use(
            (config) => this
                .getAccessTokenSilently({scope: 'read:site_admin write:site_admin'})
                .then((token) => {
                    if (config.headers) config.headers.Authorization = `Bearer ${token}`;
                    return config;
                })
        );
        instance.interceptors.response.use(
            ({ data }: AxiosResponse) => data,
        );
    }
}


export class RubberDuckyLabsAdminApi extends AdminHttpClient {
    private static classInstance?: RubberDuckyLabsAdminApi;

    public constructor(getAccessTokenSilently: ({scope}: {scope: string}) => Promise<string>) {
        super(getAccessTokenSilently);
    }

    public static getInstance(getAccessTokenSilently: ({scope}: {scope: string}) => Promise<string>) {
        if (!this.classInstance) {
            this.classInstance = new RubberDuckyLabsAdminApi(getAccessTokenSilently);
        }

        return this.classInstance;
    }

    public getUsers(limit?: number, after?: rdlTypes.Id): Promise<rdlTypes.Pagination<rdlTypes.RDLUser>> {
        const params = { after: after, limit: limit }
        return this.instance.get(`/users`, { params });
    }

    public getUser(userId: rdlTypes.Id): Promise<rdlTypes.RDLUser> {
        return this.instance.get(`/users/${userId}`);
    }

    public updateUser(user: rdlTypes.RDLUser, data: rdlTypes.RDLUserUpdateData): Promise<rdlTypes.RDLUser> {
        return this.instance.put(`/users/${user.id}`, data);
    }

    public deleteUser(user: rdlTypes.RDLUser): Promise<rdlTypes.RDLUser> {
        return this.instance.delete(`/users/${user.id}`);
    }

    public createUser(data: rdlTypes.RDLUserUpdateData): Promise<rdlTypes.RDLUser> {
        return this.instance.post(`/users`, data);
    }

    public deleteUserOrganizationMapping(user: rdlTypes.RDLUser, organizationId: rdlTypes.Id): Promise<any> {
        return this.instance.delete(`/users/${user.id}/organizations/${organizationId}`);
    }

    public createUserOrganizationMapping(user: rdlTypes.RDLUser, organizationId: rdlTypes.Id): Promise<any> {
        return this.instance.post(`/users/${user.id}/organizations/${organizationId}`);
    }

    public getOrganizations(limit?: number, after?: rdlTypes.Id): Promise<rdlTypes.Pagination<rdlTypes.Organization>> {
        const params = { after: after, limit: limit }
        return this.instance.get(`/organizations`, { params });
    }

    public createOrganization(name: string): Promise<rdlTypes.Organization> {
        return this.instance.post(`/organizations`, { name: name });
    }
}

abstract class HttpClient {
    protected instance: AxiosInstance;
    protected instanceV2: AxiosInstance;
    protected instanceV3: AxiosInstance;
    protected context: RDLApiContext;
    protected token: string | null = null;

    public constructor(context: RDLApiContext) {
        this.context = context;

        const baseURL = getBaseURL();
        this.instance = axios.create({
            baseURL: `${baseURL}/${this.context.organization.name}`,
            timeout: 600000,
        });
        this.instanceV2 = axios.create({
            baseURL: `${baseURL}/rdl`,
            timeout: 600000,
        });
        this.instanceV3 = axios.create({
            baseURL: `${baseURL}`,
            timeout: 600000,
        });
        this._initializeInterceptors(this.instance);
        this._initializeInterceptors(this.instanceV2);
        this._initializeInterceptors(this.instanceV3);
    }

    private _initializeInterceptors = (instance: AxiosInstance) => {
        instance.interceptors.request.use(
            (config) => this.context
                .getToken()
                .then((token) => {
                    if (config.headers) config.headers.Authorization = `Bearer ${token}`;
                    return config;
                })
        );
        instance.interceptors.response.use(
            ({ data }: AxiosResponse) => data,
        );
    }
}

export class RubberDuckyLabsApi extends HttpClient {
    private static classInstance?: RubberDuckyLabsApi;

    public constructor(context: RDLApiContext) {
        super(context);
    }

    public static getInstance(context: RDLApiContext) {
        if (!this.classInstance) {
            this.classInstance = new RubberDuckyLabsApi(context);
        }

        return this.classInstance;
    }

    // Health check
    public healthCheck(): Promise<{message: string}> {
        return this.instance.get('/');
    }

    // Public functions for calling RDL API

    // Config API
    public getConfig(): Promise<RDLConfigType> {
        return this.instance.get('/config');
    }

    // Items API
    public getItems(limit?: number, after?: rdlTypes.Id): Promise<rdlTypes.Pagination<rdlTypes.Item>> {
        return this.instance.get('/items', { params: { after: after, limit: limit } });
    }

    public getItem(itemId: rdlTypes.Id): Promise<rdlTypes.Item> {
        return this.instance.get(`/items/${itemId}`);
    }

    // Events API
    public getEvents(
        clientId: rdlTypes.Id,
        eventType: rdlTypes.EventType,
        startDate: Date,
        endDate: Date,
        limit?: number,
        after?: rdlTypes.Id
    ): Promise<rdlTypes.Pagination<rdlTypes.Event>> {
        const params = {
            event_type: eventType,
            client_id: clientId,
            start_date: dateToAPIParams(startDate),
            end_date: dateToAPIParams(endDate),
            after: after,
            limit: limit
        };
        return this.instance.get('/events', { params: params });
    }

    public getEventsByItemAndDate(
        eventType: rdlTypes.EventType,
        startDate: Date,
        endDate: Date,
        userFilters: UserFilter[],
        segment: string | null,
    ): Promise<rdlTypes.EventCount[]> {
        const params = new URLSearchParams();
        params.append("event_type", eventType.toString());
        params.append("start_date", dateToAPIParams(startDate));
        params.append("end_date", dateToAPIParams(endDate));
        params.append("user_attributes", JSON.stringify(userFiltersToJSONPostData(userFilters)));
        if (!_.isNull(segment)) params.append("segment", segment);

        return this.instance.get('/events_by_item_and_date', { params });
    }

    // Users API
    public getUsers(
        userFilters: UserFilter[],
        segment: string | null,
        limit?: number,
        after?: rdlTypes.Id
    ): Promise<rdlTypes.Pagination<rdlTypes.User>> {
        const params = new URLSearchParams();
        if (!_.isUndefined(limit)) params.append("limit", limit.toString());
        if (!_.isUndefined(after)) params.append("after", after.toString());
        if (!_.isNull(segment)) params.append("segment", segment);
        params.append("user_attributes", JSON.stringify(userFiltersToJSONPostData(userFilters)));

        return this.instance.get('/users', { params });
    }

    public getUser(userId: rdlTypes.Id): Promise<rdlTypes.User> {
        return this.instance.get(`/users/${userId}`);
    }

    public getUsersBatch(userIds: rdlTypes.Id[]): Promise<rdlTypes.Pagination<rdlTypes.User>> {
        if (_.isEmpty(userIds)) return Promise.resolve({ data: [], paging: { after: null }, count: 0 });

        const params = new URLSearchParams();
        for (const userId of userIds) {
            params.append("user_id", userId.toString());
        }
        return this.instance.get('/users/batch', { params });
    }

    public getUserStyleProfile(userId: rdlTypes.Id): Promise<rdlTypes.UserStyleProfile> {
        return this.instance.get(`/users/${userId}/style-profile`);
    }

    // Test datasets API
    public getTestDatasets(allTags: string[] = [], anyTags?: string[]): Promise<TestDataset[]> {
        const params = {
            all_tags: allTags.length > 0 ? allTags.join(',') : undefined,
            any_tags: anyTags !== undefined ? anyTags.join(',') : undefined,
        };
        return this.instanceV2.get(`/organizations/${this.context.organization.id}/test-datasets`, { params: params });
    }

    public getTransactionIds(): Promise<string[]> {
        const params = {
            keys: "transactionId",
        };
        return this.instanceV2.get(`/organizations/${this.context.organization.id}/test-datasets/tags`, { params: params });
    }

    public getTestDatasetTags(keys: string[]): Promise<string[]> {
        const params = {
            keys: keys.join(','),
        };
        return this.instanceV2.get(`/organizations/${this.context.organization.id}/test-datasets/tags`, { params: params });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public getTraceIds(rankingPipelineId: number): Promise<string[]> {
        return this.getTransactionIds();
    }

    public getFinalStageTags(): Promise<string[]> {
        return new Promise((resolve) => {
            if (this.context.organization.id == 3) {
                return resolve([
                    "source:finalRankingOutput",
                ]);
            }
            return resolve([]);
        });
    }

    public getTestDataset(testDatasetId: number): Promise<TestDataset> {
        return this.instanceV2.get(`/organizations/${this.context.organization.id}/test-datasets/${testDatasetId}`);
    }

    public getTestDatasetNames(): Promise<string[]> {
        return this.instanceV2.get(`/organizations/${this.context.organization.id}/test-datasets/names`);
    }

    public getTestDatasetByName(testDatasetName: string): Promise<TestDataset> {
        return this.instanceV2.get(`/organizations/${this.context.organization.id}/test-datasets/name/${testDatasetName}`);
    }

    public createTestDataset(testDataset: TestDataset): Promise<TestDataset> {
        return this.instanceV2.put(`/organizations/${this.context.organization.id}/test-datasets`, { ...testDataset });
    }

    public updateTestDataset(testDatasetId: number, testDataset: TestDataset): Promise<TestDataset> {
        return this.instanceV2.post(
            `/organizations/${this.context.organization.id}/test-datasets/${testDatasetId}`,
            { ...testDataset },
        );
    }

    public deleteTestDataset(testDatasetId: number): Promise<TestDataset> {
        return this.instanceV2.delete(`/organizations/${this.context.organization.id}/test-datasets/${testDatasetId}`);
    }

    // Ranking Pipelines API

    public createRankingPipeline(data: rdlTypes.RankingPipelineCreateData): Promise<rdlTypes.RankingPipeline> {
        return this.instanceV2.post(`/organizations/${this.context.organization.id}/ranking-pipelines/`, data);
    }

    public listRankingPipelines(): Promise<rdlTypes.Pagination<rdlTypes.RankingPipeline>> {
        return this.instanceV2.get(`/organizations/${this.context.organization.id}/ranking-pipelines`);
    }

    public getRankingPipeline(rankingPipelineId: number): Promise<rdlTypes.RankingPipeline> {
        return this.instanceV2.get(`/organizations/${this.context.organization.id}/ranking-pipelines/${rankingPipelineId}`);
    }

    public deleteRankingPipeline(rankingPipeline: rdlTypes.RankingPipeline): Promise<null> {
        return this.instanceV2.delete(`/organizations/${this.context.organization.id}/ranking-pipelines/${rankingPipeline.id}`);
    }

    public createRankingPipelineStage(rankingPipelineId: number, data: rdlTypes.RankingPipelineStageCreateData): Promise<rdlTypes.RankingPipelineStage> {
        return this.instanceV2.post(`/organizations/${this.context.organization.id}/ranking-pipelines/${rankingPipelineId}/stages`, data);
    }

    public listRankingPipelineStages(rankingPipelineId: number): Promise<rdlTypes.Pagination<rdlTypes.RankingPipelineStage>> {
        return this.instanceV2.get(`/organizations/${this.context.organization.id}/ranking-pipelines/${rankingPipelineId}/stages`);
    }

    public deleteRankingPipelineStage(rankingPipelineId: number, rankingPipelineStage: rdlTypes.RankingPipelineStage): Promise<null> {
        return this.instanceV2.delete(`/organizations/${this.context.organization.id}/ranking-pipelines/${rankingPipelineId}/stages/${rankingPipelineStage.id}`);
    }

    // Policies API
    public getPolicies(): Promise<Policy[]> {
        return this.instanceV2.get(`/organizations/${this.context.organization.id}/policies`);
    }

    public getPolicy(policyId: number): Promise<Policy> {
        return this.instanceV2.get(`/organizations/${this.context.organization.id}/policies/${policyId}`);
    }

    public createPolicy(policy: Policy): Promise<Policy> {
        return this.instanceV2.put(`/organizations/${this.context.organization.id}/policies`, { ...policy });
    }

    public updatePolicy(policyId: number, policy: Policy): Promise<Policy> {
        return this.instanceV2.post(`/organizations/${this.context.organization.id}/policies/${policyId}`, { ...policy });
    }

    public deletePolicy(policyId: number): Promise<Policy> {
        return this.instanceV2.delete(`/organizations/${this.context.organization.id}/policies/${policyId}`);
    }

    // Statistics API

    public getRankingChanges(testDatasetId1: number, testDatasetId2: number): Promise<RankingChangesDataset> {
        const params = {
            test_dataset_id_1: testDatasetId1,
            test_dataset_id_2: testDatasetId2,
        };
        return this.instanceV2.get(`/organizations/${this.context.organization.id}/statistics/ranking-changes`, { params: params });
    }

    public getAggregateRankingChanges(tags_1: string, tags_2: string, include_zero = false): Promise<RankingChangesDataset> {
        const params = {
            tags_1: tags_1,
            tags_2: tags_2,
            include_zero: include_zero,
        };
        return this.instanceV2.get(`/organizations/${this.context.organization.id}/statistics/aggregate-ranking-changes`, { params: params });
    }

    // Traces API

    public getTraceItemMetadata(traceId: string, itemId: rdlTypes.Id): Promise<rdlTypes.TraceItemMetadata> {
        const params = {
            trace_id: traceId,
        };
        return this.instance.get(`/traces/items/${itemId}`, { params: params });
    }

    // Events API
    public getEventsStatistics(
        eventType: rdlTypes.EventType,
        startDate: Date,
        endDate: Date,
        aggregations?: string[],
    ): Promise<rdlTypes.EventStatisticsResponse> {
        const params = {
            start_date: dateToAPIParams(startDate),
            end_date: dateToAPIParams(endDate),
            aggregation: aggregationsToApiParams(aggregations),
        };
        return this.instance.get(`/statistics/events/${eventType}`, { params, });
    }

    public listUserSegmentsMetadata(): Promise<rdlTypes.Pagination<rdlTypes.UserSegmentMetadata>> {
        return this.instanceV2.get(`/organizations/${this.context.organization.id}/user-segments/`);
    }

    public createUserSegmentMetadata(data: rdlTypes.UserSegmentMetadataCreateData): Promise<rdlTypes.UserSegmentMetadata> {
        return this.instanceV2.post(`/organizations/${this.context.organization.id}/user-segments/`, data);
    }

    public listUsersInSegment(segmentId: rdlTypes.Id): Promise<rdlTypes.Pagination<rdlTypes.UserSegmentMembership>> {
        return this.instanceV2.get(`/organizations/${this.context.organization.id}/user-segments/${segmentId}/users`);
    }

    public listUsersInSegments(segmentIds: rdlTypes.Id[]): Promise<rdlTypes.Pagination<rdlTypes.UserSegmentMembership>> {
        const params = new URLSearchParams();
        for (const segmentId of segmentIds) {
            params.append("segment_id", segmentId.toString());
        }
        return this.instanceV2.get(`/organizations/${this.context.organization.id}/user-segments/batch/users`, { params });
    }


    public listUserSegmentsForUser(userId: string): Promise<rdlTypes.Pagination<rdlTypes.UserSegmentMembership>> {
        return this.instanceV2.get(`/organizations/${this.context.organization.id}/user-segments/users/${userId}`);
    }


    public addUsersToSegment(segmentId: rdlTypes.Id, userIds: string[]): Promise<rdlTypes.Pagination<rdlTypes.UserSegmentMembership>> {
        return this.instanceV2.post(
            `/organizations/${this.context.organization.id}/user-segments/${segmentId}/users`,
            userIds,
        );
    }


    public removeAllUsersFromSegment(segmentId: rdlTypes.Id): Promise<void> {
        return this.instanceV2.delete(
            `/organizations/${this.context.organization.id}/user-segments/${segmentId}/users`,
        );
    }

    public removeUserFromSegment(segmentId: rdlTypes.Id, userId: string): Promise<void> {
        return this.instanceV2.delete(
            `/organizations/${this.context.organization.id}/user-segments/${segmentId}/users/${userId}`,
        );
    }

    public deleteUserSegment(segmentId: rdlTypes.Id): Promise<void> {
        return this.instanceV2.delete(`/organizations/${this.context.organization.id}/user-segments/${segmentId}`);
    }

    // Stats API

    public getEventStatistics(
        eventType: rdlTypes.EventType,
        startDate: Date,
        endDate: Date,
        timescale: statsTypes.StatisticsTimescaleValue,
        aggregation?: string[],
        query?: string[],
        limit?: number,
        after?: statsTypes.StatisticsAfter,
    ): Promise<statsTypes.StatisticsPagination> {
        const params = new URLSearchParams();

        params.append("start_date", dateToAPIParams(startDate));
        params.append("end_date", dateToAPIParams(endDate));

        for (const agg of aggregation ?? []) {
            params.append("aggregation", agg);
        }
        params.append("timescale", timescale);
        if (!_.isUndefined(limit)) {
            params.append("limit", limit.toString());
        }
        if (!_.isUndefined(after)) {
            params.append("after", JSON.stringify(after));
        }

        for (const q of query ?? []) {
            params.append("query", q);
        }

        return this.instance.get(`/statistics/events/${eventType}`, { params });
    }

    private _iterateEventStatistics(
        eventType: rdlTypes.EventType,
        startDate: Date,
        endDate: Date,
        timescale: statsTypes.StatisticsTimescaleValue,
        aggregation?: string[],
        query?: string[],
        limit?: number,
        after?: statsTypes.StatisticsAfter | null,
    ): Promise<statsTypes.Statistic[]> {
        if (_.isNull(after)) {
            return Promise.resolve([]);
        }

        return this.getEventStatistics(eventType, startDate, endDate, timescale, aggregation, query, limit, after)
            .then(({ data, paging }) => (
                this._iterateEventStatistics(eventType, startDate, endDate, timescale, aggregation, query, limit, paging.after)
                    .then((newData) => [...data, ...newData])
            ))
    }

    public iterateEventStatistics(
        eventType: rdlTypes.EventType,
        startDate: Date,
        endDate: Date,
        timescale: statsTypes.StatisticsTimescaleValue,
        aggregation?: string[],
        query?: string[],
        limit?: number,
    ): Promise<statsTypes.Statistic[]> {
        return this._iterateEventStatistics(eventType, startDate, endDate, timescale, aggregation, query, limit);
    }

    public getMLModel(modelId: number): Promise<mlModelTypes.MLModel> {
        return this.instanceV2.get(`/organizations/${this.context.organization.id}/ml-models/${modelId}`);
    }

    public getMLModelData(modelId: number, userIds?: rdlTypes.Id[], limit?: number, after?: rdlTypes.Id): Promise<rdlTypes.Pagination<mlModelTypes.MLModelData>> {
        const params = new URLSearchParams();
        for (const userId of userIds ?? []) {
            params.append("user_id", userId.toString());
        }
        if (!_.isUndefined(limit)) {
            params.append("limit", limit.toString());
        }
        if (!_.isUndefined(after)) {
            params.append("after", JSON.stringify(after));
        }
        return this.instanceV2.get(`/organizations/${this.context.organization.id}/ml-models/${modelId}/data`, { params });
    }

    public getAllMLModelData(modelId: number, userIds?: rdlTypes.Id[], limit?: number, after?: rdlTypes.Id | null): Promise<mlModelTypes.MLModelData[]> {
        if (_.isNull(after)) {
            return Promise.resolve([]);
        }

        return this.getMLModelData(modelId, userIds, limit, after)
            .then(({ data, paging }) => (
                this.getAllMLModelData(modelId, userIds, limit, paging.after)
                    .then((newData) => [...data, ...newData])
            ))
    }

    public getMLModelDataGroupedByUserSegments(mlModelId: number): Promise<Record<string, Record<string, number>>> {
        return this.instanceV2.get(`/organizations/${this.context.organization.id}/ml-models/${mlModelId}/data/group-by-user-segment`);
    }

    public listDataOnboardingProjects(limit?: number, after?: rdlTypes.Id): Promise<rdlTypes.Pagination<dataOnboardingTypes.DataOnboardingProject>> {
        const params = { after: after, limit: limit }
        return this.instanceV2.get(`/organizations/${this.context.organization.id}/data-onboarding/projects`, { params });
    }


    private _iterateDataOnboardingProjects(limit?: number, after?: rdlTypes.Id | null): Promise<dataOnboardingTypes.DataOnboardingProject[]> {
        if (_.isNull(after)) {
            return Promise.resolve([]);
        }


        return this.listDataOnboardingProjects(limit, after)
            .then(({data, paging}) => (
                this._iterateDataOnboardingProjects(limit, paging.after)
                    .then((newData) => [...data, ...newData])
            ));
    }

    public iterateDataOnboardingProjects(limit?: number): Promise<dataOnboardingTypes.DataOnboardingProject[]> {
        return this._iterateDataOnboardingProjects(limit);
    }

    public createDataOnboardingProject(data: dataOnboardingTypes.DataOnboardingProjectCreateData): Promise<dataOnboardingTypes.DataOnboardingProject> {
        return this.instanceV2.post(`/organizations/${this.context.organization.id}/data-onboarding/projects`, data);
    }

    public getDataOnboardingProject(projectId: rdlTypes.Id): Promise<dataOnboardingTypes.DataOnboardingProject> {
        return this.instanceV2.get(`/organizations/${this.context.organization.id}/data-onboarding/projects/${projectId}`);
    }

    public updateDataOnboardingProject(projectId: rdlTypes.Id, data: dataOnboardingTypes.DataOnboardingProjectUpdateData): Promise<dataOnboardingTypes.DataOnboardingProject> {
        return this.instanceV2.put(`/organizations/${this.context.organization.id}/data-onboarding/projects/${projectId}`, data);
    }

    public deleteDataOnboardingProject(projectId: rdlTypes.Id): Promise<null> {
        return this.instanceV2.delete(`/organizations/${this.context.organization.id}/data-onboarding/projects/${projectId}`);
    }

    public listDataOnboardingTests(limit?: number, after?: rdlTypes.Id): Promise<rdlTypes.Pagination<dataOnboardingTypes.DataOnboardingTest>> {
        const params = { after: after, limit: limit }
        return this.instanceV2.get(`/organizations/${this.context.organization.id}/data-onboarding/tests`, { params });
    }

    private _iterateDataOnboardingTests(limit?: number, after?: rdlTypes.Id | null): Promise<dataOnboardingTypes.DataOnboardingTest[]> {
        if (_.isNull(after)) {
            return Promise.resolve([]);
        }

        return this.listDataOnboardingTests(limit, after)
            .then(({data, paging}) => (
                this._iterateDataOnboardingTests(limit, paging.after)
                    .then((newData) => [...data, ...newData])
            ));
    }

    public iterateDataOnboardingTests(limit?: number): Promise<dataOnboardingTypes.DataOnboardingTest[]> {
        return this._iterateDataOnboardingTests(limit);
    }

    public createDataOnboardingTest(data: dataOnboardingTypes.DataOnboardingTestCreateData): Promise<dataOnboardingTypes.DataOnboardingTest> {
        return this.instanceV2.post(`/organizations/${this.context.organization.id}/data-onboarding/tests`, data);
    }

    public getDataOnboardingTest(testId: rdlTypes.Id): Promise<dataOnboardingTypes.DataOnboardingTest> {
        return this.instanceV2.get(`/organizations/${this.context.organization.id}/data-onboarding/tests/${testId}`);
    }

    public updateDataOnboardingTest(testId: rdlTypes.Id, data: dataOnboardingTypes.DataOnboardingTestCreateData): Promise<dataOnboardingTypes.DataOnboardingTest> {
        return this.instanceV2.put(`/organizations/${this.context.organization.id}/data-onboarding/tests/${testId}`, data);
    }

    public deleteDataOnboardingTest(testId: rdlTypes.Id): Promise<null> {
        return this.instanceV2.delete(`/organizations/${this.context.organization.id}/data-onboarding/tests/${testId}`);
    }

    public listDataOnboardingDatabases(limit?: number, after?: rdlTypes.Id): Promise<rdlTypes.Pagination<dataOnboardingTypes.DataOnboardingDatabase>> {
        const params = { after: after, limit: limit }
        return this.instanceV2.get(`/organizations/${this.context.organization.id}/data-onboarding/databases`, { params });
    }

    private _iterateDataOnboardingDatabases(limit?: number, after?: rdlTypes.Id | null): Promise<dataOnboardingTypes.DataOnboardingDatabase[]> {
        if (_.isNull(after)) {
            return Promise.resolve([]);
        }

        return this.listDataOnboardingDatabases(limit, after)
            .then(({data, paging}) => (
                this._iterateDataOnboardingDatabases(limit, paging.after)
                    .then((newData) => [...data, ...newData])
            ));
    }

    public iterateDataOnboardingDatabases(limit?: number): Promise<dataOnboardingTypes.DataOnboardingDatabase[]> {
        return this._iterateDataOnboardingDatabases(limit);
    }

    public createDataOnboardingDatabase(data: dataOnboardingTypes.DataOnboardingDatabaseCreateData): Promise<dataOnboardingTypes.DataOnboardingDatabase> {
        return this.instanceV2.post(`/organizations/${this.context.organization.id}/data-onboarding/databases`, data);
    }

    public getDataOnboardingDatabase(databaseId: rdlTypes.Id): Promise<dataOnboardingTypes.DataOnboardingDatabase> {
        return this.instanceV2.get(`/organizations/${this.context.organization.id}/data-onboarding/databases/${databaseId}`);
    }

    public updateDataOnboardingDatabase(databaseId: rdlTypes.Id, data: dataOnboardingTypes.DataOnboardingDatabaseCreateData): Promise<dataOnboardingTypes.DataOnboardingDatabase> {
        return this.instanceV2.put(`/organizations/${this.context.organization.id}/data-onboarding/databases/${databaseId}`, data);
    }

    public deleteDataOnboardingDatabase(databaseId: rdlTypes.Id): Promise<null> {
        return this.instanceV2.delete(`/organizations/${this.context.organization.id}/data-onboarding/databases/${databaseId}`);
    }

    public listDataOnboardingTestAnswers(projectId: rdlTypes.Id, testId: rdlTypes.Id, limit?: number, after?: rdlTypes.Id): Promise<rdlTypes.Pagination<dataOnboardingTypes.DataOnboardingTestAnswer>> {
        const params = { after: after, limit: limit }
        return this.instanceV2.get(`/organizations/${this.context.organization.id}/data-onboarding/projects/${projectId}/tests/${testId}/answers`, { params });
    }

    private _iterateDataOnboardingTestAnswers(projectId: rdlTypes.Id, testId: rdlTypes.Id, limit?: number, after?: rdlTypes.Id | null): Promise<dataOnboardingTypes.DataOnboardingTestAnswer[]> {
        if (_.isNull(after)) {
            return Promise.resolve([]);
        }

        return this.listDataOnboardingTestAnswers(projectId, testId, limit, after)
            .then(({data, paging}) => (
                this._iterateDataOnboardingTestAnswers(projectId, testId, limit, paging.after)
                    .then((newData) => [...data, ...newData])
            ));
    }

    public iterateDataOnboardingTestAnswers(projectId: rdlTypes.Id, testId: rdlTypes.Id, limit?: number): Promise<dataOnboardingTypes.DataOnboardingTestAnswer[]> {
        return this._iterateDataOnboardingTestAnswers(projectId, testId, limit);
    }

    public createDataOnboardingTestAnswer(projectId: rdlTypes.Id, testId: rdlTypes.Id, data: dataOnboardingTypes.DataOnboardingTestAnswerCreateData): Promise<dataOnboardingTypes.DataOnboardingTestAnswer> {
        return this.instanceV2.post(`/organizations/${this.context.organization.id}/data-onboarding/projects/${projectId}/tests/${testId}/answers`, data);
    }

    public getDataOnboardingTestAnswer(projectId: rdlTypes.Id, testId: rdlTypes.Id, answerId: rdlTypes.Id): Promise<dataOnboardingTypes.DataOnboardingTestAnswer> {
        return this.instanceV2.get(`/organizations/${this.context.organization.id}/data-onboarding/projects/${projectId}/tests/${testId}/answers/${answerId}`);
    }

    public updateDataOnboardingTestAnswer(projectId: rdlTypes.Id, testId: rdlTypes.Id, answerId: rdlTypes.Id, data: dataOnboardingTypes.DataOnboardingTestAnswerUpdateData): Promise<dataOnboardingTypes.DataOnboardingTestAnswer> {
        return this.instanceV2.put(`/organizations/${this.context.organization.id}/data-onboarding/projects/${projectId}/tests/${testId}/answers/${answerId}`, data);
    }

    public deleteDataOnboardingTestAnswer(projectId: rdlTypes.Id, testId: rdlTypes.Id, answerId: rdlTypes.Id): Promise<null> {
        return this.instanceV2.delete(`/organizations/${this.context.organization.id}/data-onboarding/projects/${projectId}/tests/${testId}/answers/${answerId}`);
    }

    public listDataOnboardingTestMetadata(projectId: rdlTypes.Id, limit?: number, after?: rdlTypes.Id): Promise<rdlTypes.Pagination<dataOnboardingTypes.DataOnboardingTestMetadata>> {
        const params = { after: after, limit: limit }
        return this.instanceV2.get(`/organizations/${this.context.organization.id}/data-onboarding/projects/${projectId}/tests`, { params });
    }

    private _iterateDataOnboardingTestMetadata(projectId: rdlTypes.Id, limit?: number, after?: rdlTypes.Id | null): Promise<dataOnboardingTypes.DataOnboardingTestMetadata[]> {
        if (_.isNull(after)) {
            return Promise.resolve([]);
        }

        return this.listDataOnboardingTestMetadata(projectId, limit, after)
            .then(({data, paging}) => (
                this._iterateDataOnboardingTestMetadata(projectId, limit, paging.after)
                    .then((newData) => [...data, ...newData])
            ));
    }

    public iterateDataOnboardingTestMetadata(projectId: rdlTypes.Id, limit?: number): Promise<dataOnboardingTypes.DataOnboardingTestMetadata[]> {
        return this._iterateDataOnboardingTestMetadata(projectId, limit);
    }

    public getDataOnboardingTestMetadata(projectId: rdlTypes.Id, testId: rdlTypes.Id): Promise<dataOnboardingTypes.DataOnboardingTestMetadata> {
        return this.instanceV2.get(`/organizations/${this.context.organization.id}/data-onboarding/projects/${projectId}/tests/${testId}`);
    }

    public updateDataOnboardingTestMetadata(projectId: rdlTypes.Id, testId: rdlTypes.Id, data: dataOnboardingTypes.DataOnboardingTestMetadataUpdateData): Promise<dataOnboardingTypes.DataOnboardingTestMetadata> {
        return this.instanceV2.put(`/organizations/${this.context.organization.id}/data-onboarding/projects/${projectId}/tests/${testId}`, data);
    }
}
