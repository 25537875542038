import * as rdlTypes from "../models/RDLDataTypes";
import * as statsTypes from "../models/StatsDataTypes";
import { Alert } from "../components/Alert";
import {
    Box,
    Group,
    Loader,
    ScrollArea,
    Stack,
    Text,
    ThemeIcon,
    Title,
    Tooltip
} from "@mantine/core"
import { EventName } from "../utilities/metric-calculations/metrics";
import { InView } from "react-intersection-observer";
import { ItemCard } from "../components/ItemCard";
import { MaybeDateRange } from "../utilities/date-range";
import { Metric } from "./health-checks/HealthChecksMetricsPage";
import { RDLApiContext, RubberDuckyLabsApi } from "../RDLApi";
import { getEventIcon, getEventNameStr } from "../components/EventIconMapping";
import { randomId, useListState } from "@mantine/hooks";
import { subDays } from "date-fns";
import { useApiContext, useRDLConfig } from "../App";
import { useCallback, useEffect, useMemo, useState } from "react";
import RDLEventDateRangePicker from "../components/custom/RDLDateRangePicker";
import SegmentPicker from "../components/users/SegmentPicker";
import _ from "underscore";


function ItemsByMetricRowTitle({ metric }: { metric: Metric }) {
    return (
        <Text size="sm" weight="bold" my="lg">
            Top items by count of {`${metric.displayName}`} events
        </Text>
    );
}

const SCROLL_PAGE_SIZE = 10;



function ItemsByMetricRow({ metric, dateRange, segment, fetchItem }: { metric: Metric, dateRange: MaybeDateRange, segment: string | null, fetchItem: (itemId: rdlTypes.Id) => Promise<rdlTypes.Item | null> }) {
    const apiContext = useApiContext();
    const [itemStats, setItemStats] = useListState<[rdlTypes.Id, number]>([]);
    const [startDate, endDate] = dateRange;
    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState("");
    const [scrollLimit, setScrollLimit] = useState(SCROLL_PAGE_SIZE);


    useEffect(() => {
        const aggregation = "events.style_color_id";
        const func = statsTypes.StatisticsFunctionType.COUNT;

        if (!_.isNull(apiContext) && !_.isNull(startDate) && !_.isNull(endDate)) {
            setLoading(true);
            RubberDuckyLabsApi.getInstance(apiContext)
                .iterateEventStatistics(
                    metric.eventType,
                    startDate,
                    endDate,
                    statsTypes.StatisticsTimescaleValue.ALL,
                    [aggregation],
                    _.isNull(segment) ? undefined : [`users.segment:"${segment}"`],
                    30000,
                )
                .then((data) => {
                    setItemStats.append(...(data.map((d): [rdlTypes.Id, number] => [d.aggregation_value[aggregation], d.func_value[func]])));
                })
                .catch((error) => setApiError(error.message))
                .finally(() => setLoading(false))
        }

        return () => {
            setLoading(false);
            setItemStats.setState([]);
        }
    }, [apiContext, metric, startDate, endDate, segment]);

    const EventIcon = getEventIcon(metric.eventType);

    if (apiError.length === 0 && !loading && itemStats.length === 0) {
        return (
            <Stack>
                <ItemsByMetricRowTitle metric={metric} />
                <Text size="sm" italic c="dimmed">No events for this time frame</Text>
            </Stack>
        );
    }

    return (
        <Stack>
            <ItemsByMetricRowTitle metric={metric} />
            {apiError.length > 0 && <Alert message={apiError} />}
            {loading && <Loader />}
            <ScrollArea>
                <Group noWrap>
                    {itemStats
                        .slice(0, scrollLimit)
                        .map(([itemId, count]) => (
                            <Box key={itemId} w={200}>
                                <ItemCard key={itemId} id={itemId} fetchItem={fetchItem}>
                                    <Group>
                                        <Tooltip label={getEventNameStr(metric.eventType)} withArrow >
                                            <ThemeIcon variant="light" size="sm">
                                                <EventIcon />
                                            </ThemeIcon>
                                        </Tooltip>
                                        <Text size="sm" weight="bold">{count}</Text>
                                    </Group>
                                </ItemCard>
                            </Box>
                        ))}
                    <InView onChange={(inView) => inView && setScrollLimit((prev) => prev + SCROLL_PAGE_SIZE)}>
                        {(scrollLimit < _.size(itemStats)) && <Loader />}
                    </InView>
                </Group>
            </ScrollArea>
        </Stack>
    );
}

type FetchItemType = (itemId: rdlTypes.Id) => Promise<rdlTypes.Item | null>;

function fetchItemFactory(apiContext: RDLApiContext | null): FetchItemType {
    if (_.isNull(apiContext)) {
        return () => Promise.resolve(null);
    }

    const itemsMap: Record<rdlTypes.Id, rdlTypes.Item> = {};

    return (itemId: rdlTypes.Id): Promise<rdlTypes.Item> => {
        if (itemId in itemsMap) {
            return Promise.resolve(itemsMap[itemId]);
        } else {
            return RubberDuckyLabsApi.getInstance(apiContext)
                .getItem(itemId)
                .then((item) => {
                    itemsMap[itemId] = item;
                    return item;
                })
        }
    }
}


export default function ItemsByMetricPage() {
    const config = useRDLConfig();
    const apiContext = useApiContext();
    const [dateRange, setDateRange] = useState<MaybeDateRange>([subDays(new Date(), 7), new Date()]);
    const [segment, setSegment] = useState<string | null>(null);

    const metrics: Metric[] = useMemo(() => config.metricTypes
        .map((eventType) => ({
            key: randomId(),
            eventType: eventType,
            eventName: rdlTypes.EventType[eventType] as EventName,
            displayName: getEventNameStr(eventType),
        })), [config]);

    const fetchItem: FetchItemType = useCallback((itemId) => {
        const _fetchItem = fetchItemFactory(apiContext);
        return _fetchItem(itemId);
    }, [apiContext]);

    return (
        <Stack>
            <Title order={1}>Items by Metric</Title>
            <Text c="dimmed">View items broken down by each metric</Text>
            <Group>
                <RDLEventDateRangePicker value={dateRange} onChange={setDateRange} />
                <SegmentPicker value={segment} onChange={setSegment} />
            </Group>
            {metrics.map((metric) => (
                <ItemsByMetricRow key={metric.key} metric={metric} dateRange={dateRange} segment={segment} fetchItem={fetchItem} />
            ))}
        </Stack>
    )
}
