import { useRuleFormContext } from "./rule-form-context";
import RDLEventDateRangePicker from "../../custom/RDLDateRangePicker";

export function DateRangeInput() {
    const form = useRuleFormContext();

    return (
        <RDLEventDateRangePicker
            {...form.getInputProps('data.filterData.filterValues.dateData')}
        />
    );
}
