import { ACCEPTABLE_FILTER_TYPES_MAP } from "../../../utilities/filters";
import { ColumnTypes } from "../../../models/RDLConfig";
import { Select } from "@mantine/core";
import { createSelectItemsFromColumns } from "../../../utilities/select-items";
import { useColumns } from "../../../context/ColumnContext";
import { useEffect, useMemo } from "react";
import { useRuleFormContext } from "./rule-form-context";

export function SearchableNameInput() {
    const form = useRuleFormContext();
    const columns = useColumns();

    const searchableColumns = useMemo(
        () => createSelectItemsFromColumns(columns),
        [columns],
    );


    useEffect(() => {
        const filterType = form.values.data.filterData.filterType;
        const columnsName = form.values.data.filterData.columnName
        const columnType = columnsName in columns ? columns[columnsName] : ColumnTypes.Float;
        const acceptableTypes = ACCEPTABLE_FILTER_TYPES_MAP[columnType];
        if (filterType === undefined || !acceptableTypes.includes(filterType)) {
            form.setFieldValue('data.filterData.filterType', acceptableTypes[0]);
        }
    }, [form.values.data.filterData.columnName]);

    return (
        <Select
            withinPortal
            label="Field name"
            data={searchableColumns}
            placeholder="Type a name"
            searchable
            {...form.getInputProps('data.filterData.columnName')}
        />
    );
}
