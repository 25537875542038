import * as rdlTypes from "../../models/RDLDataTypes";
import { EventCount, EventType } from '../../models/RDLDataTypes';
import { EventsData } from "../events";
import { MetricCalculationType, MetricOperations } from "../../models/MetricCalculationOps";


type ItemIdDateToCount = Map<string, [rdlTypes.Id, string, number]>;
type EventsMap = Map<EventType, ItemIdDateToCount>;


export function calculateOperation(leftCount: number, rightCount: number | undefined, op: MetricOperations): number {
    switch (op) {
    // TODO(Alexandra): if rightCount is 0 or undefined, this should be positive infinity
    case MetricOperations.Divide:
        return rightCount === undefined || rightCount === 0 ? 0 : leftCount / rightCount;
    default:
        return 0;
    }
}

function calculateMetric(
    metricCalculation: MetricCalculationType,
    eventsMap: EventsMap,
): EventCount[] {
    const calculation = metricCalculation.calculation;

    const rightMap: ItemIdDateToCount = eventsMap.get(calculation.right) ?? new Map();
    const leftMap: ItemIdDateToCount = eventsMap.get(calculation.left) ?? new Map();

    const rightMapEntries = Array.from(rightMap.entries());

    return rightMapEntries.map(([idDatePair, [item_id, date, rightCount]]) => {
        const [,,leftCount] = leftMap.get(idDatePair) ?? [null, null, 0];
        const rate = calculateOperation(leftCount, rightCount, calculation.op);

        return {
            item_id,
            date,
            rate,
            count: rightCount,
            event_type: metricCalculation.eventType,
        };
    });
}

export const calculateMetrics = function (
    metricCalculations: MetricCalculationType[],
    eventsData: EventsData[]
): EventsData[] {
    const eventsMap: EventsMap = new Map(eventsData.map((eventData) => [
        eventData.eventType,
        new Map(eventData.data.map((datum) => [
            [datum.item_id, datum.date].toString(),
            [datum.item_id, datum.date, datum.count],
        ]))
    ]));

    const calculationResults: EventsData[] = metricCalculations.map((metricCalculation) => ({
        eventType: metricCalculation.eventType,
        data: calculateMetric(
            metricCalculation,
            eventsMap
        ),
    }));

    return calculationResults;
}
