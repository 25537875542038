import {
    Avatar,
    Box,
    Group,
    Menu,
    UnstyledButton
} from '@mantine/core'
import { ComponentPropsWithoutRef } from "react";
import { IconLogout, IconUser } from '@tabler/icons-react';
import { Link } from "react-router-dom";
import { LogoutOptions, User } from "@auth0/auth0-react";
import { forwardRef, useState } from 'react';


/**
 * Most of this code is borrowed from https://mantine.dev/core/menu/#custom-control-with-component
 */

interface UserButtonProps extends ComponentPropsWithoutRef<'button'> {
  picture: string | undefined;
  name: string | undefined;
  email: string | undefined;
}

const UserButton = forwardRef<HTMLButtonElement, UserButtonProps>(
    ({ picture, name, email, ...others }: UserButtonProps, ref) => (
        <Box
            sx={(theme) => ({
                paddingTop: theme.spacing.sm,
                borderTop: `1px solid ${
                    theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
                }`,
            })}
        >
            <UnstyledButton
                ref={ref}
                sx={(theme) => ({
                    display: 'block',
                    width: '100%',
                    padding: theme.spacing.md,
                    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

                    '&:hover': {
                        backgroundColor:
                        theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
                    },
                })}
                {...others}
            >
                <Group>
                    <Avatar src={picture} radius="xl" alt={`${name} (${email})`} />
                </Group>

            </UnstyledButton>
        </Box>
    )
);
UserButton.displayName = 'UserButton';

export default function UserMenu(props: {user: User, onLogout: (options?: LogoutOptions | undefined) => void}) {
    const [opened, setOpened] = useState(false);

    return (
        <Group position="center">
            <Menu
                opened={opened}
                onChange={setOpened}
                position="top-start"
                width={200}
            >
                <Menu.Target>
                    <UserButton name={props.user.name} email={props.user.email} picture={props.user.picture} />
                </Menu.Target>
                <Menu.Dropdown>
                    <Menu.Label>{props.user.name}</Menu.Label>
                    <Menu.Item
                        icon={<IconUser size={14} />}
                        component={Link}
                        to="/settings/profile"
                    >
                        My Profile
                    </Menu.Item>
                    <Menu.Item
                        color="red"
                        icon={<IconLogout size={14} />}
                        onClick={() => props.onLogout({ returnTo: window.location.origin })}
                    >
                        Log out
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
        </Group>
    );
}
