import { ColumnTypes, ItemColumns } from '../models/RDLConfig';
import { ItemCardProps } from '../components/ItemCard';
import { ReactNode, createContext, useContext, useMemo } from 'react';
import { construcstEventColumnTypes } from '../utilities/armoireData';
import { getMetricColumns } from '../utilities/metric-calculations/metrics';
import { useRDLConfig } from '../App';
import _ from 'underscore';

type ColumnContextType = [ItemColumns, ItemCardProps[]];

const ColumnContext = createContext<ColumnContextType>([{}, []]);

export const useColumns = function (): ItemColumns {
    const [columns] = useContext(ColumnContext);
    return columns;
}

export const useCards = function (): ItemCardProps[] {
    const [, cards] = useContext(ColumnContext);
    return cards;
}

export const ColumnProvider = function (props: { children: ReactNode, cards: ItemCardProps[] }) {
    const config = useRDLConfig();

    const useMetricColumns = useMemo(() => {
        return props.cards.every(({ metrics }) => metrics !== undefined);
    }, [props.cards]);


    const metricColumns: ItemColumns = useMemo(() => {
        const metricEventTypes = config.metricTypes.concat(config.metricCalculations.map(calculation => calculation.eventType));
        return getMetricColumns(metricEventTypes);
    }, [config]);

    const scoreColumns: ItemColumns = useMemo(() => (
        _.chain(props.cards)
            .map(({ scores }) => scores === undefined ? [] : Object.keys(scores))
            .flatten()
            .unique()
            .map((name): [string, ColumnTypes] => [`scores.${name}`, ColumnTypes.Float])
            .object()
            .value()
    ), [props.cards]);

    const detailsColumns: ItemColumns = useMemo(() => (
        _.chain(props.cards)
            .map(({ details }) => details === undefined ? [] : Object.entries(details))
            .flatten(1)
            .map(([name, value]): [string, ColumnTypes] => {
                if (typeof value === 'number') {
                    return [`details.${name}`, ColumnTypes.Float]
                }
                return [`details.${name}`, ColumnTypes.Category]
            })
            .unique()
            .object()
            .value()
    ), [props.cards]);

    const useEventColumns = useMemo(() => {
        return props.cards.every(({ event }) => event !== undefined);
    }, [props.cards]);

    const eventColumns: ItemColumns = useMemo(() => (
        props.cards.length === 0 ? {} : construcstEventColumnTypes(props.cards[0].event)
    ), [props.cards]);

    const columns = {
        ...(props.cards.some((card) => card.item !== undefined) ? config.itemColumns : {}),
        ...scoreColumns,
        ...detailsColumns,
        ...(useMetricColumns ? metricColumns : {}),
        ...(useEventColumns ? eventColumns : {}),
    }

    return (
        <ColumnContext.Provider value={[columns, props.cards]}>
            {props.children}
        </ColumnContext.Provider>
    );
}
