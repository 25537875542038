import { Select } from "@mantine/core";
import { useRankingDrilldownFormContext } from "./ranking-drilldown-form-context";
import _ from "underscore";


export const TransactionIdInput = function (props: {
    transactionIds: string[],
}) {
    const form = useRankingDrilldownFormContext();
    return (
        <Select
            withinPortal
            disabled={_.isEmpty(props.transactionIds)}
            placeholder="Select a transaction ID"
            label="Transaction ID"
            data={props.transactionIds}
            {...form.getInputProps('transactionId')}
        />
    )
}
