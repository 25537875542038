import * as rdlTypes from "../../models/RDLDataTypes";
import { Alert } from "../Alert";
import { Group, Loader, Text } from "@mantine/core";
import { camelCaseToTitleCase } from "../../utilities/text";
import { formatDistanceToNow, lightFormat } from "date-fns";
import _ from "underscore";

function DisplayData(props: Record<string, string>) {
    return (
        <Group>
            {Object.entries(props).map(([key, value]) => (
                <Group key={key} spacing={3}>
                    <Text size="sm" tt="capitalize" weight={700}>{camelCaseToTitleCase(key)}: </Text>
                    <Text size="sm">{value}</Text>
                </Group>
            ))}
        </Group>
    );
}


export function UserInfoCard({ user }: { user?: rdlTypes.User | null }) {
    if (_.isUndefined(user)) {
        return <Loader />;
    } else if (_.isNull(user)) {
        return <Alert message="User not found" onClose={_.noop} />;
    }

    const userAttributes = user.user_attributes;
    return (
        <DisplayData
            created={lightFormat(new Date(userAttributes.created), 'MM/dd/yyyy')}
            subscriber={userAttributes.subscriber.toString()}
            lastActive={formatDistanceToNow(new Date(userAttributes.last_activity), { addSuffix: true })}
            height={userAttributes.height ?? '-'}
            bra={userAttributes.bra ?? '-'}
            age={_.isNull(userAttributes.age) ? '-' : userAttributes.age.toString()}
            maternity={_.isNull(userAttributes.maternity) ? '-' : userAttributes.maternity.toString()}
        />
    );

}
