import { NO_SEGMENT } from "../../models/user-segments";
import { RubberDuckyLabsApi } from "../../RDLApi";
import { Select, SelectItem } from "@mantine/core";
import { useApiContext } from "../../App";
import { useEffect, useState } from "react";
import { useListState } from "@mantine/hooks";
import _ from "underscore";

const DEFAULT_SELECT_DATA = [{value: NO_SEGMENT, label: "No Segment"}];


export default function SegmentPicker({ disabled, value, onChange }: { disabled?: boolean, value: string | null, onChange: (value: string | null) => void }) {
    const apiContext = useApiContext();
    const [selectData, setSelectData] = useListState<SelectItem>(DEFAULT_SELECT_DATA);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!_.isNull(apiContext)) {
            RubberDuckyLabsApi.getInstance(apiContext)
                .listUserSegmentsMetadata()
                .then(({data}) => data.map(({id, name}) => ({value: id.toString(), label: name})))
                .then((selectItems) => setSelectData.append(...selectItems))
                .finally(() => setLoading(false));
        }

        return () => {
            setLoading(false);
            setSelectData.setState(DEFAULT_SELECT_DATA);
        }
    }, [apiContext]);

    return (
        <Select
            withinPortal
            disabled={loading || disabled}
            label="User Segments"
            placeholder={loading ? "loading..." : "Select user segments"}
            nothingFound="No user segments found"
            data={selectData}
            searchable
            value={value}
            onChange={onChange}
        />
    );
}
