import { RDLConfigType } from '../models/RDLConfig';
import {
    addDays,
    differenceInDays,
    eachDayOfInterval,
    format,
    isBefore,
    isEqual as isDateEqual,
    isDate as isDateReturnsBoolean,
    isValid,
    lightFormat,
    parseISO,
    parseJSON,
    subDays
} from 'date-fns'
import _ from "underscore";

export type MaybeDateRange = [Date | null, Date | null];
export type DateRange = [Date, Date];

export function isStringJSONDate(str: string) {
    const parsedDate = parseJSON(str);
    return isValid(parsedDate);
}

function isBeforeOrEqual(date: Date, dateToCompare: Date) {
    return isDateEqual(date, dateToCompare) || isBefore(date, dateToCompare);
}

export function isWithinDateRange(date: Date, dateRange: DateRange) {
    const start = dateRange[0];
    const end = dateRange[1];
    return isBeforeOrEqual(start, date) && isBeforeOrEqual(date, end);
}

export function isWithinMaybeDateRange(date: Date, dateRange: MaybeDateRange) {
    if (!isDateRange(dateRange)) {
        return true;
    }

    return isWithinDateRange(date, dateRange);
}

export function isDate(date: Date | null): date is Date {
    if (date === null) {
        return false;
    }

    return isDateReturnsBoolean(date);
}

export function isDateRange(dateRange: MaybeDateRange): dateRange is DateRange {
    return dateRange.every((date) => isDate(date));
}

export function isDateRangeEqual(a: DateRange, b: DateRange) {
    return a.every((e, i) => isDateEqual(e, b[i]));
}

export function safeIsDateRangeEqual(a: MaybeDateRange, b: MaybeDateRange) {
    if (!isDateRange(a) && !isDateRange(b)) {
        return true;
    }

    if (isDateRange(a) && isDateRange(b) && isDateRangeEqual(a, b)) {
        return true;
    }

    return false;
}

export const safeFormatDateRange = function(dateRange: MaybeDateRange) {
    if (!isDateRange(dateRange)) return '';

    const formatString = 'MM/dd/yyyy';
    return `${format(dateRange[0], formatString)} - ${format(dateRange[1], formatString)}`
}

export const parseDatesInObject = function(value: any): any {
    if (_.isString(value)) {
        if (isStringJSONDate(value)) {
            return parseJSON(value);
        }
        const date = parseISO(value);
        if (!isNaN(date.getTime())) {
            return date;
        }
        return value;
    } else if (_.isArray(value)) {
        return value.map((v) => parseDatesInObject(v));
    } else if (_.isObject(value)) {
        return Object.fromEntries(Object.entries(value).map(([k, v]: [string, any]) => ([k, parseDatesInObject(v)])));
    }

    return value;
}

export const parseDateRangeFromConfig = function(config: RDLConfigType): MaybeDateRange {
    const [start, end] = config.dateRangeWithEvents;

    return [
        start ? parseISO(start) : null,
        end ? parseISO(end) : null,
    ];
}

export function dateRangeOr30Days(config: RDLConfigType): DateRange {
    const [start, end] = parseDateRangeFromConfig(config);

    if (isDate(start) && isDate(end)) {
        return [start, end];
    }

    return [subDays(new Date(), 30), new Date()];
}

const DEFAULT_CHUNK_SIZE_IN_DAYS = 30;

export const getDateRangesWithoutData = function (datesWithData: Set<string>, requestedDateRange: DateRange): DateRange[] {
    const [start, end] = requestedDateRange;
    const dateRangesWithoutData: DateRange[] = [];
    let intervalStart: Date | undefined = undefined;

    for (const date of eachDayOfInterval({ start, end })) {
        const isDateContained = datesWithData.has(lightFormat(date, 'yyyy-MM-dd'));
        if (isDateContained && intervalStart !== undefined) {
            dateRangesWithoutData.push([intervalStart, date]);
            intervalStart = undefined;
        } else if (!isDateContained && intervalStart === undefined) {
            intervalStart = date;
        } else if (intervalStart !== undefined && differenceInDays(date, intervalStart) >= DEFAULT_CHUNK_SIZE_IN_DAYS) {
            dateRangesWithoutData.push([intervalStart, date]);
            intervalStart = date;
        }
    }

    if (intervalStart !== undefined) {
        dateRangesWithoutData.push([intervalStart, addDays(end, 1)]);
    }

    return dateRangesWithoutData;
}
