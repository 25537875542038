import * as rdlTypes from "../../../models/RDLDataTypes";
import { Alert } from "../../Alert";
import { Button, Group, TextInput } from "@mantine/core"
import { PipelineFormProvider, usePipelineForm } from "./pipeline-form-context";
import { RubberDuckyLabsApi } from "../../../RDLApi";
import { isNotEmpty } from "@mantine/form";
import { useApiContext } from "../../../App";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import _ from "underscore";


export default function CreatePipelineForm() {
    const apiContext = useApiContext();
    const [apiErrorMessage, setApiErrorMessage] = useState<string|undefined>(undefined)
    const navigate = useNavigate();

    const form = usePipelineForm({
        initialValues: {
            name: '',
        },
        validate: {
            name: isNotEmpty('Please enter a name'),
        },
    });

    const onSubmit = (values: rdlTypes.RankingPipelineCreateData) => {
        setApiErrorMessage(undefined);
        if (!_.isNull(apiContext)) {
            RubberDuckyLabsApi.getInstance(apiContext)
                .createRankingPipeline(values)
                .then((rankingPipeline) => navigate(`/pipelines/${rankingPipeline.id}`))
                .catch((error) => {
                    setApiErrorMessage(error.message);
                });
        }
    }

    const disabled = _.isNull(apiContext);

    return (
        <PipelineFormProvider form={form}>
            <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
                {apiErrorMessage && <Alert message={apiErrorMessage} onClose={() => setApiErrorMessage(undefined)} />}
                <TextInput
                    placeholder="Pipeline Name"
                    label="Name"
                    withAsterisk
                    mt="md"
                    {...form.getInputProps('name')}
                />
                <Group position="right" mt="md">
                    <Button variant="light" type="submit" disabled={disabled}>Create</Button>
                </Group>

            </form>
        </PipelineFormProvider>
    );
}
