import {
    Accordion,
    Button,
    Code,
    Group,
    Modal,
    Stack,
    Text
} from "@mantine/core"
import { IconX } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";

import { ActiveInput } from "../rule/ActiveInput";
import { FilterInput } from "../rule/FilterInput";
import { FilterTypeInput } from "../rule/FilterTypeInput";
import {
    RuleFilter,
    RuleFormProvider,
    RuleTypes,
    isRuleFilter,
    newRule,
    useRuleForm,
    validateRuleFormValues
} from "../rule/rule-form-context"
import { createFilterTitle } from "../../../utilities/filters";
import { useColumns } from "../../../context/ColumnContext";


type OnSubmitFunctionType = (values: RuleFilter) => void;
type OnRemoveFunctionType = () => void;


export function EditFilterForm(
    props: {
        values: RuleFilter,
        onSubmit: OnSubmitFunctionType,
        onRemove: OnRemoveFunctionType,
    }
) {
    const form = useRuleForm({
        initialValues: props.values,
        validate: validateRuleFormValues,
    });

    const columns = useColumns();
    const name = form.values.data.filterData.columnName;
    const columnType = columns[name];

    return (
        <RuleFormProvider form={form}>
            <form onSubmit={form.onSubmit((values) => isRuleFilter(values) && props.onSubmit(values))}>
                <Stack>
                    <FilterInput />
                    {!(name === '') && <Accordion>
                        <Accordion.Item value="settings">
                            <Accordion.Control>
                                <Text size="sm">Advanced</Text>
                            </Accordion.Control>
                            <Accordion.Panel>
                                <Stack>
                                    <Stack spacing={4}>
                                        <Group><Text size="sm" color="gray.9" weight={500}>Data type: </Text><Code>{columnType}</Code></Group>
                                    </Stack>
                                    <FilterTypeInput columnType={columnType} />
                                    <ActiveInput />
                                </Stack>
                            </Accordion.Panel>
                        </Accordion.Item>
                    </Accordion>}
                    <Group position="right">
                        <Button variant="light" color="gray" onClick={() => props.onRemove()}>Discard</Button>
                        <Button type="submit">Save</Button>
                    </Group>
                </Stack>
            </form>
        </RuleFormProvider>
    )
}

// Submitting this form will unfortunately trigger submitting the outside form, don't know why yet
function CreateFilterModal(props: {
    onSubmit: OnSubmitFunctionType,
}) {
    const [opened, { close, open }] = useDisclosure(false);
    const onSubmit = function (values: RuleFilter) {
        props.onSubmit(values);
        close();
    }

    return (
        <>
            <Modal
                opened={opened}
                onClose={close}
                title="Create a filter"
            >
                <EditFilterForm
                    onSubmit={onSubmit}
                    onRemove={close}
                    values={({ ...newRule(), type: RuleTypes.Filter })}
                />
            </Modal>
            <Group position="center">
                <Button onClick={open}>Add a filter</Button>
            </Group>
        </>
    );
}


export function EditFilterModal(props: {
    onSubmit: OnSubmitFunctionType,
    values: RuleFilter,
    onRemove: OnRemoveFunctionType,
}) {
    const [opened, { close, open }] = useDisclosure(false);
    const onSubmit = function (values: RuleFilter) {
        props.onSubmit(values);
        close();
    }

    const color = props.values.active ? "blue" : "gray";

    return (
        <>
            <Modal
                opened={opened}
                onClose={close}
                title="Edit filter"
            >
                <EditFilterForm
                    onSubmit={onSubmit}
                    onRemove={close}
                    values={props.values}
                />
            </Modal>
            <Button.Group>
                <Button
                    radius="xl"
                    color={color}
                    variant="light"
                    compact={true}
                    onClick={open}
                    pl={15}
                >
                    {createFilterTitle(props.values.data.filterData)}
                </Button>
                <Button
                    radius="xl"
                    color={color}
                    variant="light"
                    compact={true}
                    onClick={() => props.onRemove()}
                >
                    <IconX size={10} />
                </Button>
            </Button.Group>
        </>
    );
}


export function FiltersInput(props: {
    filters: RuleFilter[],
    handlers: any,
}) {
    const filters = props.filters;
    const handlers = props.handlers;

    return (
        <Group position="left" mt="md">
            <CreateFilterModal
                onSubmit={(values: RuleFilter) => handlers.append(values)}
            />
            {filters.map((filter, index: number) => <EditFilterModal
                key={filter.key}
                onSubmit={(values: RuleFilter) => handlers.setItem(index, values)}
                values={filter}
                onRemove={() => handlers.remove(index)}
            />
            )}
        </Group>
    );
}
