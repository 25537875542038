import * as rdlTypes from "../../models/RDLDataTypes";
import {
    ActionIcon,
    Button,
    Checkbox,
    Code,
    Group,
    LoadingOverlay,
    Alert as MantineAlert,
    Menu,
    Modal,
    MultiSelect,
    Stack,
    Table,
    Text,
    TextInput,
    Title
} from "@mantine/core"
import { Alert } from "../../components/Alert";
import { IconDotsVertical, IconEdit, IconTrash } from "@tabler/icons-react";
import { RubberDuckyLabsAdminApi } from "../../RDLApi";
import { isNotEmpty, useForm } from "@mantine/form";
import { useAuth0 } from "@auth0/auth0-react";
import { useDisclosure, useListState } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useRDLUser } from "../../App";
import _ from "underscore";

function SiteAdminPageGuard(props: { children: React.ReactNode }) {
    const rdlUser = useRDLUser();

    if (!rdlUser?.is_site_admin) {
        return (
            <Alert
                message="You must be a site admin to view this page"
                onClose={_.noop}
            />
        );
    }

    return (
        <Stack>
            <MantineAlert color="blue" title="FYI">Site admin page</MantineAlert>
            {props.children}
        </Stack>
    );
}


function OrganizationsTable(props: { organizations: rdlTypes.Organization[] }) {
    const { organizations } = props;
    const rows = organizations.map(({id, name}) => (
        <tr key={id}>
            <td>{id}</td>
            <td>{name}</td>
        </tr>
    ));

    return (
        <Table>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Name</th>
                </tr>
            </thead>
            <tbody>{rows}</tbody>
        </Table>
    );
}


interface EditUsersFormValues {
    auth0_subclaim: string;
    is_site_admin: boolean;
    organizations: string[];
}


function EditUsersForm({values, onSubmit}: { values: EditUsersFormValues, onSubmit: (values: EditUsersFormValues) => void }) {
    const { getAccessTokenSilently } = useAuth0();
    const [organizations, organizationHandlers] = useListState<rdlTypes.Organization>([]);
    const organizationsSelectData = organizations.map(({id, name}) => ({ value: id.toString(), label: name }));

    useEffect(() => {
        RubberDuckyLabsAdminApi.getInstance(getAccessTokenSilently)
            .getOrganizations()
            .then(({data}) => organizationHandlers.setState(data));
    }, [getAccessTokenSilently]);

    const form = useForm<EditUsersFormValues>({
        initialValues: values,
        validate: {
            auth0_subclaim: isNotEmpty("Auth0 Subclaim is required"),
            organizations: (value, values) => {
                if (_.isEmpty(value) && !values.is_site_admin) {
                    return "Select an organization";
                }
            },
            is_site_admin: (value, values) => {
                if (value && !_.isEmpty(values.organizations)) {
                    return "Cannot be a site admin and belong to an organization";
                }
            }
        }
    });

    return (
        <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
            <Stack>
                <TextInput
                    label="Auth0 Subclaim"
                    placeholder="Find via the auth0 console"
                    {...form.getInputProps("auth0_subclaim")}
                />
                <Checkbox
                    label="Site Admin"
                    {...form.getInputProps("is_site_admin", { type: "checkbox" }) }
                />
                <MultiSelect
                    withinPortal
                    mt="md"
                    label="Organization"
                    placeholder="Select organizations"
                    data={organizationsSelectData}
                    {...form.getInputProps('organizations')}
                />
                <Group position="right">
                    <Button type="submit">Submit</Button>
                </Group>
            </Stack>
        </form>
    );
}


function UsersTableRow({ user, onDeleteSubmit, onEditSubmit }: { user: rdlTypes.RDLUser, onDeleteSubmit: () => void, onEditSubmit: (values: EditUsersFormValues) => void }) {
    const [opened, { open, close }] = useDisclosure(false);

    return (
        <tr key={user.id}>
            <td>{user.id}</td>
            <td><Code>{user.auth0_subclaim}</Code></td>
            <td>{user.is_site_admin ? "Yes" : "No"}</td>
            <td>
                {_.isEmpty(user.organizations) && <Text c="dimmed" italic>None</Text>}
                {user.organizations.map(({name}) => name).join(", ")}
            </td>
            <td>
                <Modal
                    size="lg"
                    opened={opened}
                    onClose={close}
                    title="Edit User"
                    onClick={(e) => e.stopPropagation()}
                >
                    <EditUsersForm
                        values={{
                            auth0_subclaim: user.auth0_subclaim,
                            is_site_admin: user.is_site_admin,
                            organizations: user.organizations.map(({id}) => id.toString())
                        }}
                        onSubmit={(v) => {
                            onEditSubmit(v);
                            close();
                        }}
                    />
                </Modal>
                <Menu shadow="md" width={200} position="bottom-start" withinPortal>
                    <Menu.Target>
                        <ActionIcon variant="transparent" color="gray" onClick={(e: React.MouseEvent) => e.stopPropagation()} >
                            <IconDotsVertical size={16} />
                        </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown onClick={(e) => e.stopPropagation()}>
                        <Menu.Item icon={<IconEdit size={16} />} onClick={open}>Edit</Menu.Item>
                        <Menu.Item icon={<IconTrash size={16} />} onClick={() => onDeleteSubmit()}>Delete</Menu.Item>
                    </Menu.Dropdown>
                </Menu>
            </td>
        </tr>
    )
}

function UsersTable({users, onRemove, onEdit}: { users: rdlTypes.RDLUser[], onRemove: (index: number) => void, onEdit: (index: number, user: rdlTypes.RDLUser) => void }) {
    const {getAccessTokenSilently} = useAuth0();

    const deleteUserOrganizationMappings = (user: rdlTypes.RDLUser, organizations: rdlTypes.Organization[]): Promise<rdlTypes.RDLUser> => {
        if (_.isEmpty(organizations)) {
            return Promise.resolve(user);
        }
        return RubberDuckyLabsAdminApi.getInstance(getAccessTokenSilently)
            .deleteUserOrganizationMapping(user, organizations[0].id)
            .then(() => deleteUserOrganizationMappings(user, organizations.slice(1)));
    }

    const addUserOrganizationMappings = (user: rdlTypes.RDLUser, organizationIds: string[]): Promise<rdlTypes.RDLUser> => {
        if (_.isEmpty(organizationIds)) {
            return Promise.resolve(user);
        }

        return RubberDuckyLabsAdminApi.getInstance(getAccessTokenSilently)
            .createUserOrganizationMapping(user, organizationIds[0])
            .then(() => addUserOrganizationMappings(user, organizationIds.slice(1)));
    }

    const onDeleteUser = (user: rdlTypes.RDLUser, index: number) => {
        const deleteUser = (user: rdlTypes.RDLUser) => (
            RubberDuckyLabsAdminApi.getInstance(getAccessTokenSilently)
                .deleteUser(user)
        );

        return deleteUserOrganizationMappings(user, user.organizations).then((user) => deleteUser(user)).then(() => onRemove(index));
    }

    const onEditUser = (user: rdlTypes.RDLUser, index: number, values: EditUsersFormValues) => {
        const uoMappingsToAdd = values.organizations.filter((orgId) => !user.organizations.some(({id}) => id.toString() === orgId));
        const uoMappingsToRemove = user.organizations.filter(({id}) => !values.organizations.includes(id.toString()));

        return deleteUserOrganizationMappings(user, uoMappingsToRemove)
            .then((user) => addUserOrganizationMappings(user, uoMappingsToAdd))
            .then((user) => RubberDuckyLabsAdminApi.getInstance(getAccessTokenSilently)
                .updateUser(user, { is_site_admin: values.is_site_admin, auth0_subclaim: values.auth0_subclaim }))
            .then((user) => onEdit(index, user));
    }

    const rows = users.map((user, index) => <UsersTableRow
        key={user.id}
        user={user}
        onDeleteSubmit={() => onDeleteUser(user, index)}
        onEditSubmit={(values) => onEditUser(user, index, values)}
    />);

    return (
        <Table>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Auth0 Subclaim</th>
                    <th>Site Admin?</th>
                    <th>Organizations</th>
                    <th/>
                </tr>
            </thead>
            <tbody>{rows}</tbody>
        </Table>
    );
}

interface CreateOrganizationFormValues {
    name: string;
}

function CreateOrganization(props: { onCreate: (organization: rdlTypes.Organization) => void }) {
    const { onCreate } = props;
    const { getAccessTokenSilently } = useAuth0();
    const [opened, { open, close }] = useDisclosure(false);
    const [loading, { open: startLoading, close: endLoading }] = useDisclosure(false);

    const form = useForm<CreateOrganizationFormValues>({
        initialValues: {
            name: "",
        },
        validate: {
            name: isNotEmpty("Name is required"),
        }
    });

    const onSubmit = (values: CreateOrganizationFormValues) => {
        startLoading();
        RubberDuckyLabsAdminApi.getInstance(getAccessTokenSilently)
            .createOrganization(values.name)
            .then((organization) => onCreate(organization))
            .then(endLoading)
            .then(close);
    }

    return (
        <>
            <Modal opened={opened} onClose={close} title="Create an Organization">
                <form onSubmit={form.onSubmit(onSubmit)}>
                    <TextInput
                        withAsterisk
                        label="Name"
                        placeholder="Organization name"
                        {...form.getInputProps('name')}
                    />
                    <Group position="right" mt="md">
                        <Button type="submit" loading={loading}>Submit</Button>
                    </Group>
                </form>
            </Modal>

            <Group position="left">
                <Button onClick={open}>Create an organization</Button>
            </Group>
        </>
    );
}


function CreateUser({ onCreate }: { onCreate: (user: rdlTypes.RDLUser) => void }) {
    const { getAccessTokenSilently } = useAuth0();
    const [opened, { open, close }] = useDisclosure(false);
    const [loading, setLoading] = useState(false);

    const addUserOrganizationMappings = (user: rdlTypes.RDLUser, organizationIds: string[]): Promise<rdlTypes.RDLUser> => {
        if (_.isEmpty(organizationIds)) {
            return RubberDuckyLabsAdminApi.getInstance(getAccessTokenSilently).getUser(user.id);
        }

        return RubberDuckyLabsAdminApi.getInstance(getAccessTokenSilently)
            .createUserOrganizationMapping(user, organizationIds[0])
            .then(() => addUserOrganizationMappings(user, organizationIds.slice(1)));
    }

    const onSubmit = (values: EditUsersFormValues) => {
        setLoading(true);
        RubberDuckyLabsAdminApi.getInstance(getAccessTokenSilently)
            .createUser({ auth0_subclaim: values.auth0_subclaim, is_site_admin: values.is_site_admin })
            .then((user) => addUserOrganizationMappings(user, values.organizations))
            .then((user) => onCreate(user))
            .then(() => setLoading(false))
            .then(close);
    }

    return (
        <>
            <Modal opened={opened} onClose={close} title="Create a User">
                <LoadingOverlay visible={loading} />
                <EditUsersForm
                    values={{ auth0_subclaim: '', is_site_admin: false, organizations: [] }}
                    onSubmit={onSubmit}
                />
            </Modal>

            <Group position="left">
                <Button onClick={open}>Create a user</Button>
            </Group>
        </>
    );

}


export default function SiteAdminUsersAndOrganizationsPage() {
    const { getAccessTokenSilently } = useAuth0();
    const [organizations, organizationHandlers] = useListState<rdlTypes.Organization>([]);
    const [users, userHandlers] = useListState<rdlTypes.RDLUser>([]);

    useEffect(() => {
        RubberDuckyLabsAdminApi.getInstance(getAccessTokenSilently)
            .getOrganizations()
            .then(({data}) => organizationHandlers.setState(data));

        RubberDuckyLabsAdminApi.getInstance(getAccessTokenSilently)
            .getUsers()
            .then(({data}) => userHandlers.setState(data));

        return () => {
            organizationHandlers.setState([]);
            userHandlers.setState([]);
        }
    }, [getAccessTokenSilently]);


    return (
        <SiteAdminPageGuard>
            <Stack>
                <Title order={1}>Users and Organizations</Title>
                <Text c="dimmed">Manage RDL users and organizations</Text>
                <Title order={2}>Organizations</Title>
                <OrganizationsTable organizations={organizations} />
                <CreateOrganization
                    onCreate={(organization) => organizationHandlers.prepend(organization)}
                />
                <Title order={2}>Users</Title>
                <UsersTable users={users} onRemove={(i) => userHandlers.remove(i)} onEdit={(i, u) => userHandlers.setItem(i, u)} />
                <CreateUser onCreate={(user) => userHandlers.prepend(user)}
                />
            </Stack>
        </SiteAdminPageGuard>
    );
}
