import * as rdlTypes from "../models/RDLDataTypes";
import { Card, Stack, Text } from "@mantine/core";
import { Link } from "react-router-dom";
import { RubberDuckyLabsApi } from "../RDLApi";
import { TestDataset } from "../models/RDLConfig";
import { isRDLId } from "../utilities/types";
import { useApiContext } from "../App";
import { useEffect, useState } from "react";
import _ from "underscore";


interface TraceMetadata {
    currentPage?: number,
    directPDP: number,
    directATC: number,
    directConversion: number,
}

export function TraceCard(props: { traceId: string, rankingPipeline: rdlTypes.RankingPipeline, selectedItems?: rdlTypes.Id[] }) {
    const { traceId, rankingPipeline } = props;
    const apiContext = useApiContext();
    const [traceMetadata, setTraceMetadata] = useState<TraceMetadata | null | undefined>(undefined);

    useEffect(() => {
        if (!_.isNull(apiContext)) {
            const tags = [
                traceId,
                "source:finalRankingOutput"
            ];
            RubberDuckyLabsApi.getInstance(apiContext)
                .getTestDatasets(tags)
                .then((testDatasets) => testDatasets.filter((testDataset): testDataset is TestDataset => isRDLId(testDataset.id)))
                .then((testDatasets) => {
                    const currentPage = testDatasets[0]?.item_data[0]?.details.CurrentPage;
                    const directPDP = testDatasets[0]?.item_data.filter(({ details }) => details.DirectPDP > 0).length;
                    const directATC = testDatasets[0]?.item_data.filter(({ details }) => details.DirectATC > 0).length;
                    const directConversion = testDatasets[0]?.item_data.filter(({ details }) => details.DirectConversion > 0).length;
                    if ([currentPage, directATC, directConversion, directPDP].every(_.isUndefined)) {
                        setTraceMetadata(null);
                        return;
                    }
                    setTraceMetadata({
                        currentPage,
                        directPDP,
                        directATC,
                        directConversion,
                    })
                })
                .catch(() => setTraceMetadata(null))
        }

        return () => {
            setTraceMetadata(undefined);
        }
    }, [traceId, rankingPipeline]);

    const params = new URLSearchParams();
    if (!_.isUndefined(props.selectedItems)) {
        params.append("selectedItems", props.selectedItems.map((item) => item.toString()).join(","));
    }
    const to = `/pipelines/${rankingPipeline.id}/traces/${encodeURIComponent(traceId)}?${params.toString()}`;

    if (_.isNull(traceMetadata) || _.isUndefined(traceMetadata)) {
        const pdp = _.random(0, 10);
        const atc = _.random(0, pdp);
        const conversion = _.random(0, atc);
        return (
            <Card shadow="sm" p="lg" radius="md" withBorder component={Link} to={to} >
                <Stack spacing="xs">
                    <Text size="sm" weight={700} truncate>{traceId}</Text>
                    <Text size="sm">Current Page: 1</Text>
                    <Text size="sm">Direct PDP: {pdp}</Text>
                    <Text size="sm">Direct ATC: {atc}</Text>
                    <Text size="sm">Direct Conversion: {conversion}</Text>
                </Stack>
            </Card>
        )
    }

    return (
        <Card shadow="sm" p="lg" radius="md" withBorder component={Link} to={to} >
            <Stack spacing="xs">
                <Text size="sm" weight={700} truncate>{traceId}</Text>
                <Text size="sm">Current Page: {traceMetadata.currentPage}</Text>
                <Text size="sm">Direct PDP: {traceMetadata.directPDP}</Text>
                <Text size="sm">Direct ATC: {traceMetadata.directATC}</Text>
                <Text size="sm">Direct Conversion: {traceMetadata.directConversion}</Text>
            </Stack>
        </Card>
    )
}
