import { ItemColumns } from "../models/RDLConfig"
import { SelectItem } from "@mantine/core"
import _ from 'underscore';

const createSelectItemsFromColumnGroup = function(columns: ItemColumns, prefix: string, group: string): SelectItem[] {
    return _.chain(columns)
        .keys()
        .filter((name) => name.startsWith(prefix))
        .map((name) => ({
            value: name,
            label: name.replace(prefix, ''),
            group: group,
        }))
        .value();
}

export const createSelectItemsFromColumns = function(columns: ItemColumns): SelectItem[] {
    return [
        ...createSelectItemsFromColumnGroup(columns, 'scores.', 'Scores'),
        ...createSelectItemsFromColumnGroup(columns, 'metrics.', 'Metrics'),
        ...createSelectItemsFromColumnGroup(columns, 'item.item_attributes.', 'Item Attributes'),
        ...createSelectItemsFromColumnGroup(columns, 'event.', 'Event Attributes'),
        ...createSelectItemsFromColumnGroup(columns, 'details.', 'Details'),
    ]
}
