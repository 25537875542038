import { ItemCardProps } from "../../components/ItemCard";
import { TestDataset } from "../../models/RDLConfig";
import { isRDLId, isUndefinedOrNull } from "../../utilities/types";


export function parseTestDatasetItems(dataset: TestDataset | null | undefined): ItemCardProps[] {
    if (isUndefinedOrNull(dataset)) {
        return [];
    }
    return (
        dataset.item_data.map((itemDatum) => {
            if (isRDLId(itemDatum)) {
                return { id: itemDatum };
            }
            const id = itemDatum.id ?? itemDatum.item_id;
            return {
                id: id,
                scores: itemDatum.scores,
                details: itemDatum.details,
            };
        })
    );
}
