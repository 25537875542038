import {
    Box,
    Button,
    Divider,
    Flex,
    Group,
    MultiSelect,
    NumberInput,
    SegmentedControl,
    SimpleGrid,
    Skeleton,
    Stack,
    Text,
    Title
} from "@mantine/core"
import { DataExploreApiRequestFormValues, DataExploreFormProvider, useDataExploreForm, useDataExploreFormContext } from "./data-explore-request-form-context";
import { DatePickerInput } from "@mantine/dates";
import { IconCalendar } from "@tabler/icons-react";
import { RDLConfigType } from "../../../models/RDLConfig";
import { UserFilterConfig, UserFilterTypes } from "../../../models/userFilter";
import { isDateRange } from "../../../utilities/date-range";
import RDLEventDateRangePicker from "../../custom/RDLDateRangePicker";
import SegmentPicker from "../../users/SegmentPicker";
import _ from 'underscore';

function toDisplayName(userFilterName: string) {
    return userFilterName
        .split("_")
        .filter(x => x.length > 0)
        .map((x) => (x.charAt(0).toUpperCase() + x.slice(1)))
        .join(" ");
}

function MultiSelectUserAttribute(props: { userFilterConfig: UserFilterConfig | undefined, disabled: boolean, index: number }) {
    const form = useDataExploreFormContext();
    const multiSelectOptions = props.userFilterConfig !== undefined ? props.userFilterConfig.data.multiSelectOptions : [];
    const data = multiSelectOptions.map(
        (option) => ({ value: option.name, label: option.name, group: option.group })
    );

    return (
        <MultiSelect
            disabled={props.disabled}
            label={toDisplayName(form.values.userFilters[props.index].name)}
            placeholder="All users"
            data={data}
            {...form.getInputProps(`userFilters.${props.index}.data.listOfCategories`)}
        />
    );
}

function DateRangeUserAttribute(props: { disabled: boolean, index: number }) {
    const form = useDataExploreFormContext();

    return (
        <DatePickerInput
            type="range"
            icon={<IconCalendar size="1.1rem" stroke={1.5} />}
            disabled={props.disabled}
            label={toDisplayName(form.values.userFilters[props.index].name)}
            placeholder="All users"
            allowSingleDateInRange
            {...form.getInputProps(`userFilters.${props.index}.data.dateRange`)}
            maxDate={new Date()}
            clearable
        />
    );
}


function BooleanUserFilter(props: { disabled: boolean, index: number }) {
    const { index, disabled } = props;
    const form = useDataExploreFormContext();
    const value = (() => {
        switch(form.values.userFilters[index].data.boolean) {
        case true:
            return "True";
        case false:
            return "False";
        default:
            return "Any";
        }
    })();
    const onChange = (value: string) => {
        switch(value) {
        case "True":
            form.setFieldValue(`userFilters.${index}.data.boolean`, true);
            break;
        case "False":
            form.setFieldValue(`userFilters.${index}.data.boolean`, false);
            break;
        default:
            form.setFieldValue(`userFilters.${index}.data.boolean`, undefined);
            break;
        }
    }

    return (
        <Stack spacing={3}>
            <Text size="sm" weight={500}>{toDisplayName(form.values.userFilters[props.index].name)}</Text>
            <SegmentedControl
                disabled={disabled}
                value={value}
                onChange={onChange}
                data={["True", "False", "Any"]}
            />
        </Stack>
    );
}

function NumericalRangeUserAttribute(props: { disabled: boolean, index: number }) {
    const form = useDataExploreFormContext();

    return (
        <Box sx={{ width: '12em' }}>
            <Stack spacing={3}>
                <Text size="sm" weight={500}>{toDisplayName(form.values.userFilters[props.index].name)}</Text>
                <SimpleGrid cols={2}>
                    <NumberInput
                        disabled={props.disabled}
                        hideControls
                        // styles={{ input: { width: '6em' } }}
                        placeholder="min"
                        step={1}
                        precision={2}
                        max={form.values.userFilters[props.index].data.numericalRange.max}
                        {...form.getInputProps(`userFilters.${props.index}.data.numericalRange.min`)}
                    />
                    <NumberInput
                        disabled={props.disabled}
                        hideControls
                        // styles={{ input: { width: '6em' } }}
                        placeholder="max"
                        step={1}
                        precision={2}
                        min={form.values.userFilters[props.index].data.numericalRange.min}
                        {...form.getInputProps(`userFilters.${props.index}.data.numericalRange.max`)}
                    />
                </SimpleGrid>
            </Stack>
        </Box>
    )
}


function UserFilterListItem(props: { disabled: boolean, index: number, userFilterConfig: UserFilterConfig | undefined }) {
    const form = useDataExploreFormContext();
    const userFilter = form.values.userFilters[props.index];

    switch (userFilter.type) {
    case UserFilterTypes.DateRange:
        return (
            <DateRangeUserAttribute
                index={props.index}
                disabled={props.disabled}
            />
        );
    case UserFilterTypes.MultiSelect:
        return (
            <MultiSelectUserAttribute
                userFilterConfig={props.userFilterConfig}
                index={props.index}
                disabled={props.disabled}
            />
        );
    case UserFilterTypes.NumericalRange:
        return (
            <NumericalRangeUserAttribute
                index={props.index}
                disabled={props.disabled}
            />
        );
    case UserFilterTypes.Boolean:
        return (
            <BooleanUserFilter
                index={props.index}
                disabled={props.disabled}
            />
        );
    }
}


function UserFilterList(props: { config: RDLConfigType, disabled: boolean }) {
    const form = useDataExploreFormContext();

    return (
        <Group>
            {form.values.userFilters.map((userFilter, index) => (
                <UserFilterListItem
                    key={userFilter.name}
                    userFilterConfig={props.config.userFilters.find((filterConfig) => filterConfig.filterName === userFilter.name)}
                    index={index}
                    disabled={props.disabled}
                />
            ))}
        </Group>
    );
}


export function DataExploreApiRequestForm(props: {
    loading: boolean,
    disabled: boolean,
    config: RDLConfigType,
    values: DataExploreApiRequestFormValues,
    onSubmit: (values: DataExploreApiRequestFormValues) => Promise<void>,
}) {
    const form = useDataExploreForm({
        initialValues: props.values,
        validate: {
            dateRange: (value) => !isDateRange(value) ? 'Please enter a date range' : null,
        },
    });

    const disabled = props.disabled || props.loading;

    return (
        <DataExploreFormProvider form={form}>
            <Flex>
                <form onSubmit={form.onSubmit(props.onSubmit)}>
                    <Group align="end">
                        <Stack spacing={4} >
                            <Title order={6}>Events</Title>
                            <RDLEventDateRangePicker
                                {...form.getInputProps('dateRange')}
                            />
                        </Stack>
                        {!_.isEmpty(props.config.userFilters) && <>
                            <Divider orientation="vertical" />
                            <Stack spacing={4} >
                                <Title order={6}>Users</Title>
                                <UserFilterList config={props.config} disabled={disabled} />
                            </Stack>
                        </>}
                        <SegmentPicker disabled={disabled} {...form.getInputProps('segment')} />
                        <Button type="submit" loading={props.loading} disabled={disabled}>Submit</Button>
                    </Group>
                </form>
            </Flex>
        </DataExploreFormProvider>
    );
}

export function SkeletonDataExploreForm() {
    return (
        <Flex>
            <Group align="end">
                <Stack spacing={4}>
                    <Title order={6}>Events</Title>
                    <Skeleton width={85} height={14} />
                    <Skeleton width={180} height={36} />
                </Stack>
                <Divider orientation="vertical" />
                <Stack spacing={4} justify="flex-end">
                    <Title order={6}>Users</Title>
                    <Skeleton width={85} height={14} />
                    <Skeleton width={180} height={36} />
                </Stack>
                <Stack spacing={4} justify="flex-end">
                    <Skeleton width={85} height={14} />
                    <Skeleton width={180} height={36} />
                </Stack>
                <Button type="submit" disabled>Submit</Button>
            </Group>
        </Flex>
    );
}
