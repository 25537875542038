import { NumberInput, Slider, Stack, Text } from "@mantine/core";
import { formatPercentage } from "../../../utilities/format-values";

export function PercentageInput(props: any) {
    const precision = 2;

    return (
        <NumberInput
            min={0}
            precision={precision + 2}
            step={Math.pow(10, -1 * (precision + 2))}
            parser={(value) => {
                const number = parseFloat(value.replace(/\s?%/g, ''));
                return !Number.isNaN(number) ? (number / 100) : '';
            }}
            formatter={(value) => {
                const number = parseFloat(value);
                return !Number.isNaN(number) ? formatPercentage(number, precision) : "%";
            }}
            {...props}
        />
    )
}


export function ConfidenceLevelSlider(props: any) {
    return (
        <Stack spacing={3}>
            <Text size="sm" fw={500} c="gray.9">Confidence level</Text>
            <Slider
                defaultValue={0.95}
                min={0.5}
                max={0.99}
                step={0.01}
                labelAlwaysOn
                label={(value) => formatPercentage(value)}
                marks={[{value: 0.5, label: "50%"}, {value: 0.8, label: "80%"}, {value: 0.9, label: "90%"}, {value: 0.95, label: "95%"}, {value: 0.99, label: "99%"}]}
                {...props}
            />
        </Stack>
    )
}
