
export const formatLargePercentage = function(n: number): string {
    if (n < 0.01) {
        return '< 1%';
    }

    return formatPercentage(n);
}

export const formatPercentage = function(n: number, digits?: number): string {
    return n.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: digits ?? 0 });
}

export const formatRateForChartTicks = function(n: number): string {
    return (0 < n && n < 1)
        ? formatPercentage(n, Math.max(Math.abs(Math.floor(Math.log10(n))) - 2, 0))
        : formatPercentage(n);
}

export const formatRateForChartTooltip = function(n: number): string {
    return (0 < n && n < 1)
        ? formatPercentage(n, Math.abs(Math.floor(Math.log10(n))))
        : formatPercentage(n);
}

export function formatValue(value: string | number): string {
    if (typeof value === 'string') {
        return value;
    }
    const numString = value.toString();
    if (Number.isInteger(value)) {
        return numString;
    }

    const intNum = Math.trunc(value * 10000);
    if (intNum % 10000 === 0) {
        return value.toExponential(4);
    }
    const greaterThanSixDecimals = /\.\d{7,}/.test(numString)
    if (greaterThanSixDecimals) {
        return value.toFixed(6);
    }
    return numString;
}
