import { MaybeDateRange } from '../../../utilities/date-range';
import { RDLConfigType } from '../../../models/RDLConfig';
import { UserFilterTypes } from '../../../models/userFilter';
import { createFormContext } from '@mantine/form';


export interface UserFilter {
    name: string,
    type: UserFilterTypes,
    data: {
        listOfCategories: string[],
        dateRange: MaybeDateRange,
        numericalRange: {
            min: number | undefined,
            max: number | undefined,
        },
        boolean?: boolean,
    },
}

export interface UserFiltersFormValues {
    userFilters: UserFilter[],
    segment: null | string,
}

export const newUserFilters = function(config: RDLConfigType): UserFilter[] {
    return config.userFilters.map((userFilter) => ({
        name: userFilter.filterName,
        type: userFilter.filterType,
        data: {
            listOfCategories: [],
            dateRange: [null, null],
            numericalRange: { min: undefined, max: undefined },
        }
    }));
};

export const [UserFiltersFormProvider, useUserFiltersFormContext, useUserFiltersForm] =
    createFormContext<UserFiltersFormValues>();
