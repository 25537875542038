import { NumberInput } from "@mantine/core";


import { useFacetFormContext } from "./facet-form-context";


export function NumberBucketsUniformInput() {
    const form = useFacetFormContext();

    return (
        <NumberInput
            label="Bucket size (leave blank for auto sizing)"
            placeholder="(Auto)"
            min={0}
            {...form.getInputProps('data.facetData.facetValues.numberBucketData')}
        />
    );
}
