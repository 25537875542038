import { MouseEvent, useEffect, useRef, useState } from "react";
import _ from "underscore";

import 'chartjs-adapter-date-fns';
import { ActionIcon, Group, useMantineTheme } from "@mantine/core";
import { Bar } from 'react-chartjs-2';
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    ChartOptions,
    Title as ChartTitle,
    Filler,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    TimeScale,
    Tooltip,
} from 'chart.js';
import { FacetFormValues } from "../forms/facet/facet-form-context";
import { FacetedCards, chartAxisTitle, chartTitleWithFacets } from "../../utilities/facets";
import { IconArrowLeft, IconArrowRight, IconZoomIn, IconZoomOut } from "@tabler/icons-react";
import { chartColorsList, mergeWithDefaultChartOptions } from "../../utilities/charts";
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    BarElement,
    PointElement,
    LineElement,
    ChartTitle,
    Tooltip,
    Legend,
    zoomPlugin,
    Filler,
);

export function DistributionOfItemsChart(props: {
    datasets: FacetedCards[],
    facets: FacetFormValues[],
    colorIndex?: number,
    onClickItem?: (facetName: string) => void,
}) {
    const chartRef = useRef<any>(null);
    const theme = useMantineTheme();
    const colors = chartColorsList(theme);

    const facetedDatasets = props.datasets;

    const maxZoom = facetedDatasets.length;
    const [zoom, setZoom] = useState([0, maxZoom]);
    const resetZoom = function() {
        setZoom([0, maxZoom]);
    }
    useEffect(resetZoom, [maxZoom]);

    const chartOnClick = (event: MouseEvent<HTMLCanvasElement>) => {
        const elements =  chartRef.current.getElementsAtEventForMode(event, 'nearest', { intersect: false }, true);
        const first = _.first(elements);
        if (!_.isUndefined(first)) {
            const facetName = first.element.$context?.raw?.facetName;
            if (!_.isUndefined(facetName) && !_.isUndefined(props.onClickItem)) props.onClickItem(facetName);
        }
    }

    const xyData = facetedDatasets.map(({facetName, cards}) => ({ facetName, count: cards.length }));

    const dataset = {
        label: 'Items',
        data: xyData.slice(...zoom),
        borderColor: theme.fn.rgba(colors[props.colorIndex ?? 0 % colors.length][6], 0.5),
        backgroundColor: theme.fn.rgba(colors[props.colorIndex ?? 0 % colors.length][5], 0.5),
        categoryPercentage: 0.8,
        barPercentage: 0.8,
        parsing: {
            xAxisKey: 'count',
            yAxisKey: 'facetName',
        }
    };

    const data = {
        datasets: [dataset]
    }

    const options: ChartOptions = {
        indexAxis: 'y',
        aspectRatio: 1,
        scales: {
            y: {
                // ticks: {
                //     callback: function (value: any, index: number): string {
                //         if (data.datasets[0].data.length > index) {
                //             const dataPoint = data.datasets[0].data[index];
                //             return getLabel(Number.parseFloat(dataPoint.x));
                //         }
                //         return '';
                //         // TODO(Alexandra): this method will be available with a chartjs upgrade
                //         // return this.getLabelForValue(value);
                //     },
                // },
                title: { display: true, text: chartAxisTitle(props.facets) },
            },
            x: {
                title: { display: true, text: 'Count of Items' },
            }
        },
        plugins: {
            title: {
                display: true,
                text: chartTitleWithFacets(`Count of Items`, props.facets),
            },
            tooltip: {
                intersect: false,
                // callbacks: {
                //     title: (context: any) => `${getLabel(Number.parseInt(context[0].raw.x))} ${metric.name}`,
                //     afterTitle: () => metric.name === 'LikeRate' ? '(Right side not inclusive)' : '',
                //     label: (context: any) => `${context.dataset.label}`,
                //     afterLabel: (context: any) => `${context.parsed.y} items`,
                // },
            },
            zoom: {
                zoom: {
                    wheel: { enabled: false },
                    pinch: { enabled: false },
                    drag: { enabled: false },
                },
                pan: { enabled: false },
            },
        },
    };

    return (
        <>
            <Bar
                ref={chartRef}
                options={mergeWithDefaultChartOptions(options)}
                data={data}
                onClick={chartOnClick}
            />
            <Group>
                <ActionIcon onClick={() => { setZoom([0, Math.min(10, maxZoom)]) }} disabled={zoom[1] - zoom[0] <= 10}>
                    <IconZoomIn />
                </ActionIcon>
                <ActionIcon onClick={() => { resetZoom()}} disabled={zoom[0] === 0 && zoom[1] === maxZoom}>
                    <IconZoomOut />
                </ActionIcon>
                <ActionIcon disabled={(zoom[0] === 0 && zoom[1] === maxZoom)} onClick={() => setZoom(([prevMin,]) => {
                    const min = Math.max(prevMin - 10, 0);
                    const max = Math.min(min + 10, maxZoom);
                    return [min, max];
                })}>
                    <IconArrowLeft />
                </ActionIcon>
                <ActionIcon disabled={(zoom[0] === 0 && zoom[1] === maxZoom)} onClick={() => setZoom(([,prevMax]) => {
                    const max = Math.min(prevMax + 10, maxZoom);
                    const min = Math.max(max - 10, 0);
                    return [min, max];
                })}>
                    <IconArrowRight />
                </ActionIcon>
            </Group>
        </>
    );
}
