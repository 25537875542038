import {
    Container,
    Spoiler,
    Stack,
    Table,
    Text,
    Title
} from "@mantine/core"
import _ from "underscore";
import objectPath from "object-path";

import { ItemCardProps } from "../../components/ItemCard";
import { ItemColumns } from "../../models/RDLConfig";
import { calculateBreakdown } from "../../utilities/product-catalog";
import { formatPercentage } from "../../utilities/format-values";
import { isUndefinedOrNull } from "../../utilities/types";
import { useApiContext, useItems, useLoadAndCacheItems, useRDLConfig } from "../../App";
import { useEffect } from "react";


function ColumnsTable(props: { columns: ItemColumns, cards: ItemCardProps[] }) {
    const rows = Object.entries(props.columns).map(([columnName, columnType]) => {
        const values = props.cards.map((card) => objectPath.get(card, columnName));
        const [nullOrUndefinedValues, nonNullOrUndefinedValues] = _.partition(values, (v) => isUndefinedOrNull(v));
        const percentNullOrUndefined = nullOrUndefinedValues.length / values.length;
        const breakdown = nonNullOrUndefinedValues.length === 0 ? '-' : calculateBreakdown(nonNullOrUndefinedValues, columnType);
        const name = columnName.includes("item.item_attributes.") ? columnName.substring("item.item_attributes.".length) : columnName;

        return {
            name: name,
            type: columnType,
            breakdown: breakdown,
            percentNull: percentNullOrUndefined,
        }
    })
        .map((data) => (
            <tr key={data.name}>
                <td><Text>{data.name}</Text></td>
                <td><Text>{data.type}</Text></td>
                <td><Text>{formatPercentage(data.percentNull)}</Text></td>
                <td>
                    <Spoiler maxHeight={66} showLabel="Show more" hideLabel="Hide">
                        <Container fluid px={0}>
                            <Text>{data.breakdown}</Text>
                        </Container>
                    </Spoiler>
                </td>
            </tr>
        ));

    return (
        <Table highlightOnHover>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Percent undefined or null</th>
                    <th>Breakdown</th>
                </tr>
            </thead>
            <tbody>{rows}</tbody>
        </Table>
    );
}

export default function ProductCatalogFieldsPage() {
    const apiContext = useApiContext();
    const items = useItems();
    const loadAndCacheItems = useLoadAndCacheItems();
    const config = useRDLConfig();

    useEffect(() => {
        loadAndCacheItems(apiContext);
    }, [apiContext]);

    return (
        <Stack>
            <Title order={1}>Product Catalog Fields Page</Title>
            <Text c="dimmed">Explore all of the item attributes in your product catalog.</Text>
            <Container fluid>
                <ColumnsTable columns={config.itemColumns} cards={items.map((item) => ({ item }))} />
            </Container>
        </Stack>
    )
}
