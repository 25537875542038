import * as dataOnboardingTypes from "../../models/data-onboarding-data-types";
import {
    ActionIcon,
    Center,
    Divider,
    Group,
    LoadingOverlay,
    Modal,
    Stack,
    Table,
    Tabs,
    Text,
    Title
} from "@mantine/core"
import { IconEdit } from "@tabler/icons-react";
import { ProjectsEditForm } from "./DataOnboardingProjectsPage";
import { RubberDuckyLabsApi } from "../../RDLApi";
import { StatusBadge } from "./Components";
import { useApiContext } from "../../App";
import { useDisclosure, useListState } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "underscore";


function EmptyRow() {
    return (
        <tr><td colSpan={4}><Center h={100}><Text fw={700}>No data found</Text></Center></td></tr>
    )
}


function DataOnboardingTestsTable({tests, project} : {tests: dataOnboardingTypes.DataOnboardingTestMetadata[], project: dataOnboardingTypes.DataOnboardingProject}) {
    const navigate = useNavigate();

    const rows = tests.map(({status, test}) => {
        return (
            <tr key={test.id} onClick={() => navigate(`/data-onboarding/projects/${project.id}/tests/${test.id}`)} style={{cursor: "pointer"}}>
                <td>{test.title}</td>
                <td><Text tt="capitalize">{test.category_l1}</Text></td>
                <td><StatusBadge status={status} /></td>
            </tr>
        )
    });

    return (
        <Table withBorder highlightOnHover>
            <thead>
                <tr>
                    <th>Question</th>
                    <th>Category</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>{_.isEmpty(rows) ? <EmptyRow /> : rows}</tbody>
        </Table>
    );
}


function PageTitle({ project, activeTab } : { project?: dataOnboardingTypes.DataOnboardingProject, activeTab: string | null }) {
    if (_.isUndefined(project)) {
        return <Title order={1}>Loading...</Title>
    }

    return (
        <Title order={1} tt="capitalize">{_.isNull(activeTab) ? `${project.name}: All` : `${project.name}: ${activeTab}`}</Title>
    )
}


function EditProjectButton({project, disabled, onEditSumbit} : {project: dataOnboardingTypes.DataOnboardingProject, disabled: boolean, onEditSumbit: (values: dataOnboardingTypes.DataOnboardingProjectCreateData) => void}) {
    const [opened, {open, close}] = useDisclosure();

    return (
        <>
            <Modal
                size="lg"
                opened={opened}
                onClose={close}
                title="Edit Project"
                onClick={(e) => e.stopPropagation()}
            >
                <ProjectsEditForm
                    project={_.pick(project, 'name', 'description')}
                    onSubmit={(values) => {
                        onEditSumbit(values);
                        close();
                    }}
                />
            </Modal>
            <ActionIcon variant="transparent" color="gray" onClick={open} disabled={disabled}>
                <IconEdit />
            </ActionIcon>
        </>
    )
}


export default function DataOnboardingTestsPage() {
    const [activeTab, setActiveTab] = useState<string | null>('all');
    const { projectId } = useParams();
    const [project, setProject] = useState<dataOnboardingTypes.DataOnboardingProject | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(true);
    const [tests, testsHandlers] = useListState<dataOnboardingTypes.DataOnboardingTestMetadata>([]);

    const apiContext = useApiContext();

    useEffect(() => {
        if (!_.isNull(apiContext) && !_.isUndefined(projectId)) {
            setLoading(true);
            RubberDuckyLabsApi.getInstance(apiContext)
                .getDataOnboardingProject(projectId)
                .then((project) => {
                    setProject(project);
                    return RubberDuckyLabsApi.getInstance(apiContext)
                        .iterateDataOnboardingTestMetadata(project.id);
                })
                .then((tests) => testsHandlers.setState(tests))
                .finally(() => setLoading(false));
        }
    }, [apiContext, projectId]);

    const onEdit = (values: dataOnboardingTypes.DataOnboardingProjectCreateData) => {
        if (!_.isNull(apiContext) && !_.isUndefined(project)) {
            RubberDuckyLabsApi.getInstance(apiContext)
                .updateDataOnboardingProject(project.id, values)
                .then((project) => setProject(project));
        }
    }

    return (
        <Stack>
            <Group position="apart">
                <PageTitle project={project} activeTab={activeTab} />
                {!_.isUndefined(project) && <EditProjectButton disabled={loading} project={project} onEditSumbit={onEdit} />}
            </Group>
            {!_.isUndefined(project) && project.description && <Text c="dimmed">{project.description}</Text>}
            <Divider />
            <LoadingOverlay visible={loading} />
            {!_.isUndefined(project) && <Tabs value={activeTab} onTabChange={setActiveTab} orientation="vertical">
                <Tabs.List mr="xs">
                    <Tabs.Tab value="all">All</Tabs.Tab>
                    <Tabs.Tab value="metrics">Metrics</Tabs.Tab>
                    <Tabs.Tab value="products">Products</Tabs.Tab>
                    <Tabs.Tab value="users">Users</Tabs.Tab>
                    <Tabs.Tab value="events">Events</Tabs.Tab>
                </Tabs.List>
                <Tabs.Panel value="all"><DataOnboardingTestsTable project={project} tests={tests} /></Tabs.Panel>
                <Tabs.Panel value="metrics"><DataOnboardingTestsTable project={project} tests={tests.filter(({test}) => test.category_l1 === dataOnboardingTypes.DataOnboardingTestCategoryL1.METRICS)} /></Tabs.Panel>
                <Tabs.Panel value="products"><DataOnboardingTestsTable project={project} tests={tests.filter(({test}) => test.category_l1 === dataOnboardingTypes.DataOnboardingTestCategoryL1.PRODUCTS)} /></Tabs.Panel>
                <Tabs.Panel value="users"><DataOnboardingTestsTable project={project} tests={tests.filter(({test}) => test.category_l1 === dataOnboardingTypes.DataOnboardingTestCategoryL1.USERS)} /></Tabs.Panel>
                <Tabs.Panel value="events"><DataOnboardingTestsTable project={project} tests={tests.filter(({test}) => test.category_l1 === dataOnboardingTypes.DataOnboardingTestCategoryL1.EVENTS)} /></Tabs.Panel>
            </Tabs>}
        </Stack>
    );
}
