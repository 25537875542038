import * as rdlTypes from "../../models/RDLDataTypes";
import { Alert } from "../../components/Alert";
import {
    Avatar,
    Card,
    Flex,
    Group,
    Loader,
    SegmentedControl,
    Select,
    Stack,
    Text,
    Title,
    useMantineTheme,
} from "@mantine/core";
import { ColumnProvider } from "../../context/ColumnContext";
import { DateRange, MaybeDateRange, isDate, isDateRange } from "../../utilities/date-range";
import { DraggableRules } from "../../components/forms/rule/RuleModals";
import { EventToggle, EventToggleButtons } from "../../components/ToggleEventButton";
import { FilterInclusivityType } from "../../components/forms/rule/rule-form-context";
import { FilterTypes } from "../../utilities/filters";
import { ItemCardGrid } from "../../components/ItemCardGrid";
import { ItemCardProps } from "../../components/ItemCard";
import { LayoutSizes } from "../../utilities/layout";
import { NO_SEGMENT, UserSegment } from "../../models/user-segments";
import { RDLApiContext, RubberDuckyLabsApi } from "../../RDLApi";
import { RDLConfigType } from "../../models/RDLConfig";
import { RuleFormValues, RuleTypes, newRule } from "../../components/forms/rule/rule-form-context";
import { SegmentDisplay, UserSegmentMembershipActionsMenu } from "../../components/users/SegmentDisplay";
import { UseListStateHandlers, useListState } from "@mantine/hooks";
import { UserInfoCard } from "../../components/users/UserInfoCard";
import { UsersBreadcrumbs, createUsersTitle } from "../../components/UsersBreadcrumbs";
import { addDays } from "date-fns";
import { applyRule } from "../../utilities/rules";
import { createNewSegments } from "../../utilities/user-segments";
import { isRecommendationEventType, isUserSignalEventType } from "../../utilities/events";
import { isUndefinedOrNull } from "../../utilities/types";
import { paginate } from "../../utilities/paginate";
import { useApiContext, useRDLConfig } from "../../App";
import { useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import MultiColumnLayout from "../../components/MultiColumnLayout";
import RDLEventDateRangePicker from "../../components/custom/RDLDateRangePicker";
import _ from "underscore";

const DEFAULT_EVENT_LIMIT = 1000;


function paginateAllUserEvents(
    userId: rdlTypes.Id,
    dateRange: DateRange,
    callback: (events: rdlTypes.Event[]) => void,
    apiContext: RDLApiContext,
    config: RDLConfigType,
): Promise<PromiseSettledResult<void>[]> {
    const requests = config.userEventTypes.map((eventType) => (
        paginate(
            (after) => RubberDuckyLabsApi
                .getInstance(apiContext)
                .getEvents(
                    userId,
                    eventType,
                    dateRange[0],
                    addDays(dateRange[1], 1),
                    DEFAULT_EVENT_LIMIT,
                    after
                ),
            (events) => callback(events)
        )
    ));

    return Promise.allSettled(requests);
}


function createDisplayCardsAndToggleCount(
    rules: RuleFormValues[],
    eventToggles: EventToggle[],
    cards: ItemCardProps[],
    config: RDLConfigType,
): [ItemCardProps[], Map<rdlTypes.EventType, number>] {
    const cardsFilteredByRules = rules
        .filter((rule) => rule.active)
        .reduce((newCards, rule) => applyRule(rule, newCards, config), cards);

    const toggleCounts = new Map<rdlTypes.EventType, number>(
        config.userEventTypes.map((eventType) => [
            eventType,
            cardsFilteredByRules.filter((card) => card.event?.event_type === eventType).length,
        ])
    );

    const cardsFilteredByRulesAndEventToggles = eventToggles
        .filter(({ toggleValue }) => !toggleValue)
        .reduce(
            (newCards, { eventType }) => newCards.filter(({ event }) => event?.event_type !== eventType),
            cardsFilteredByRules,
        );

    return [cardsFilteredByRulesAndEventToggles, toggleCounts];
}


export function EventsCardsColumn(props: {
    cards: ItemCardProps[],
    rules: RuleFormValues[],
    rulesHandlers: UseListStateHandlers<RuleFormValues>,
    eventToggles: EventToggle[],
    eventTogglesHandlers: UseListStateHandlers<EventToggle>,
    toggleCounts: Map<rdlTypes.EventType, number>,
    size: LayoutSizes,
}) {
    const config = useRDLConfig();

    return (
        <>
            <Flex justify="center" mb="lg" direction="column">
                <DraggableRules rules={props.rules} ruleHandlers={props.rulesHandlers} config={config} />
            </Flex>
            <EventToggleButtons
                eventToggles={props.eventToggles}
                eventToggleHandlers={props.eventTogglesHandlers}
                eventToggleCounts={props.toggleCounts} />
            <ItemCardGrid cards={props.cards} size={props.size} />
        </>
    );
}


function SegmentsForUserDisplay({userId}: {userId: rdlTypes.Id | undefined}) {
    const apiContext = useApiContext();
    const theme = useMantineTheme();
    const [userSegmentMemberships, setUserSegmentMemberships] = useListState<rdlTypes.UserSegmentMembership>([]);
    const [userSegments, setUserSegments] = useListState<UserSegment>([]);
    const [loadingSegments, setLoadingSegments] = useState(false);

    useEffect(() => {
        if (!_.isNull(apiContext) && !_.isUndefined(userId)) {
            setLoadingSegments(true);
            RubberDuckyLabsApi
                .getInstance(apiContext)
                .listUserSegmentsMetadata()
                .then(({data}) => setUserSegments.setState(createNewSegments(data, userSegments, theme)))
                .then(() => RubberDuckyLabsApi.getInstance(apiContext).listUserSegmentsForUser(userId.toString()))
                .then(({data}) => (
                    _.isEmpty(data)
                        ? setUserSegmentMemberships.setState([{user_id: userId, segment_id: NO_SEGMENT}])
                        : setUserSegmentMemberships.setState(data)
                ))
                .finally(() => setLoadingSegments(false));
        }
    }, [apiContext, userId]);

    const removeUserFromSegment = (segmentKey: rdlTypes.Id) => {
        if (!_.isNull(apiContext) && !_.isUndefined(userId)) {
            RubberDuckyLabsApi.getInstance(apiContext)
                .removeUserFromSegment(segmentKey, userId.toString())
                .then(() => userSegmentMemberships.filter(({segment_id}) => segment_id !== segmentKey));
        }
    }

    const segmentKeyToSegmentMap = _.indexBy(userSegments, 'key');

    const segments = userSegmentMemberships
        .map(({segment_id}) => segmentKeyToSegmentMap[segment_id])
        .filter((segment): segment is UserSegment => !_.isUndefined(segment));

    if (_.isUndefined(userId) || loadingSegments) {
        return <Loader />;
    }

    return (
        <Group>
            {segments.map((segment) => (
                <Card shadow="sm" p="sm" radius="md" withBorder key={segment.key}>
                    <Group>
                        <UserSegmentMembershipActionsMenu userId={userId} segments={_.pluck(userSegmentMemberships, 'segment_id')} segmentKeyToSegmentMap={segmentKeyToSegmentMap} removeUserFromSegment={removeUserFromSegment} />
                        <SegmentDisplay key={segment.key} segment={segment} />
                    </Group>
                </Card>
            ))}
        </Group>
    )
}


export function UsersDetailPage() {
    const { userId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const maybeDateRange = useMemo(() => {
        const startDate = searchParams.get("startDate");
        const endDate = searchParams.get("endDate");
        return [
            !_.isNull(startDate) ? new Date(startDate) : undefined,
            !_.isNull(endDate) ? new Date(endDate) : undefined,
        ] as MaybeDateRange;
    }, [searchParams]);
    const setMaybeDateRange = (newMaybeDateRange: MaybeDateRange) => {
        const [start, end] = newMaybeDateRange;
        setSearchParams((prev) => {
            if (isDate(start)) prev.set("startDate", start.toISOString());
            else prev.delete("startDate");

            if (isDate(end)) prev.set("endDate", end.toISOString());
            else prev.delete("endDate");

            return prev;
        });
    };
    const apiContext = useApiContext();
    const config = useRDLConfig();
    const [apiError, setApiError] = useState<string | undefined>(undefined);
    const [user, setUser] = useState<rdlTypes.User | undefined | null>(undefined);
    const [originalCards, originalCardsHandlers] = useListState<ItemCardProps>([]);
    const [unifiedCards, setUnifiedCards] = useState<ItemCardProps[]>([]);
    const [splitCards, setSplitCards] = useState<ItemCardProps[]>([]);
    const [layout, setLayout] = useState<string>("split");
    const [unifiedEventToggles, unifiedEventTogglesHandlers] = useListState<EventToggle>([]);
    const [splitEventToggles, splitEventTogglesHandlers] = useListState<EventToggle>([]);
    const [unifiedRules, unifiedRulesHandlers] = useListState<RuleFormValues>([]);
    const [splitRules, splitRulesHandlers] = useListState<RuleFormValues>([]);
    const [unifiedToggleCounts, setUnifiedToggleCounts] = useState<Map<rdlTypes.EventType, number>>(new Map());
    const [splitToggleCounts, setSplitToggleCounts] = useState<Map<rdlTypes.EventType, number>>(new Map());
    const [transactionId, setTransactionId] = useState<string | undefined | null>(undefined);

    useEffect(() => {
        if (!_.isNull(apiContext) && !_.isUndefined(userId)) {
            RubberDuckyLabsApi.getInstance(apiContext)
                .getUser(userId)
                .then((user) => {
                    setUser(user);
                    if (!isDateRange(maybeDateRange)) {
                        setMaybeDateRange([new Date(user.user_attributes.created), new Date()]);
                    }
                })
                .catch((error) => {
                    setUser(null);
                    setApiError(error.message);
                });
        }

        return () => {
            setUser(undefined);
        }
    }, [apiContext, userId]);

    useEffect(() => {
        if (
            !isUndefinedOrNull(userId)
            && isDateRange(maybeDateRange)
            && !isUndefinedOrNull(apiContext)
            && !isUndefinedOrNull(config)
            && config.loaded
        ) {
            originalCardsHandlers.setState([]);
            paginateAllUserEvents(
                userId,
                maybeDateRange,
                (events) => originalCardsHandlers.append(
                    ...events.map((event) => ({
                        id: event.item_id,
                        event,
                    }))
                ),
                apiContext,
                config,
            )
        }
    }, [maybeDateRange, apiContext, userId, config]);

    useEffect(() => {
        setUnifiedCards(originalCards);
        setSplitCards(originalCards);

        if (!_.isEmpty(originalCards) && !_.isUndefined(config.userDrilldownConfig.cardOrderRule)) {
            if (unifiedRules.every((rule) => rule.type !== RuleTypes.Sort)) {
                unifiedRulesHandlers.prepend(config.userDrilldownConfig.cardOrderRule);
            }
            if (splitRules.every((rule) => rule.type !== RuleTypes.Sort)) {
                splitRulesHandlers.prepend(config.userDrilldownConfig.cardOrderRule);
            }
        }
    }, [originalCards]);

    const onChangeTransactionId = (value: string | null) => {
        const matchesTransactionIdRule = (rule: RuleFormValues) => (
            !isUndefinedOrNull(transactionId)
            && rule.type === RuleTypes.Filter
            && rule.data.filterData.filterType === FilterTypes.ListOfCategories
            && rule.data.filterData.columnName === "event.event_attributes.RecommendationsTransactionID"
            && rule.data.filterData.inclusivity === FilterInclusivityType.Is
            && rule.data.filterData.filterValues.categoryData.length === 1
            && rule.data.filterData.filterValues.categoryData.includes(transactionId)
        );
        unifiedRulesHandlers.filter(
            (rule) => !matchesTransactionIdRule(rule),
        );
        splitRulesHandlers.filter(
            (rule) => !matchesTransactionIdRule(rule),
        );

        if (!_.isNull(value)) {
            const transactionIdRule = newRule();
            transactionIdRule.type = RuleTypes.Filter;
            transactionIdRule.data.filterData.filterType = FilterTypes.ListOfCategories;
            transactionIdRule.data.filterData.columnName = "event.event_attributes.RecommendationsTransactionID";
            transactionIdRule.data.filterData.inclusivity = FilterInclusivityType.Is;
            transactionIdRule.data.filterData.filterValues.categoryData = [value];
            unifiedRulesHandlers.append(transactionIdRule);
            splitRulesHandlers.append(transactionIdRule);

        }

        setTransactionId(value);
    }

    useEffect(() => {
        if (layout === 'unified') {
            unifiedEventTogglesHandlers.setState(() => config.userEventTypes.map((eventType) => ({
                eventType: eventType,
                toggleValue: isRecommendationEventType(eventType) || isUserSignalEventType(eventType),
            })));
            splitEventTogglesHandlers.setState(() => config.userEventTypes.map((eventType) => ({
                eventType: eventType,
                toggleValue: false,
            })));
            splitRulesHandlers.setState([]);
        } else {
            unifiedEventTogglesHandlers.setState(() => config.userEventTypes.map((eventType) => ({
                eventType: eventType,
                toggleValue: isRecommendationEventType(eventType),
            })));
            splitEventTogglesHandlers.setState(() => config.userEventTypes.map((eventType) => ({
                eventType: eventType,
                toggleValue: isUserSignalEventType(eventType),
            })));
            splitRulesHandlers.setState(unifiedRules);
        }
    }, [config, layout]);

    useEffect(() => {
        const [newCards, newToggleCounts] = createDisplayCardsAndToggleCount(
            unifiedRules,
            unifiedEventToggles,
            originalCards,
            config,
        );
        setUnifiedCards(newCards);
        setUnifiedToggleCounts(newToggleCounts);
    }, [unifiedRules, unifiedEventToggles, originalCards, config]);

    useEffect(() => {
        const [newCards, newToggleCounts] = createDisplayCardsAndToggleCount(
            splitRules,
            splitEventToggles,
            originalCards,
            config,
        );
        setSplitCards(newCards);
        setSplitToggleCounts(newToggleCounts);
    }, [splitRules, splitEventToggles, originalCards, config]);

    const apiErrorAlert = (
        !_.isUndefined(apiError) && <Alert message={apiError} onClose={() => setApiError(undefined)} />
    );

    const transactionIdsData = useMemo(() => (
        _.uniq(originalCards
            .map(({ event }) => event)
            .filter((event): event is rdlTypes.Event => event !== undefined)
            .map(({ event_attributes }) => event_attributes.RecommendationsTransactionID)
            .filter((transactionId) => !_.isUndefined(transactionId)))
    ), [originalCards]);

    return (
        <Stack>
            <UsersBreadcrumbs user={user} />
            {apiErrorAlert}
            <Group>
                <Avatar color="yellow" />
                <Title order={1}>{createUsersTitle(user)}</Title>
            </Group>
            <Text c="dimmed">Drilldown into user behavior</Text>
            <UserInfoCard user={user} />
            <SegmentsForUserDisplay userId={user?.user_id} />
            <Group align="start">
                <RDLEventDateRangePicker
                    value={maybeDateRange}
                    onChange={setMaybeDateRange}
                />
                {!_.isEmpty(transactionIdsData) && <Select
                    clearable
                    label="Transaction ID"
                    placeholder="Pick one (optional)"
                    data={transactionIdsData}
                    value={transactionId}
                    onChange={onChangeTransactionId}
                />}
                <Stack spacing={3}>
                    <Text size="sm" weight={500}>Layout</Text>
                    <SegmentedControl data={["unified", "split"]} value={layout} onChange={setLayout} />
                </Stack>
            </Group>
            <ColumnProvider cards={originalCards}>
                <MultiColumnLayout columns={[
                    <EventsCardsColumn
                        key="unified"
                        cards={unifiedCards}
                        eventToggles={unifiedEventToggles}
                        eventTogglesHandlers={unifiedEventTogglesHandlers}
                        rules={unifiedRules}
                        rulesHandlers={unifiedRulesHandlers}
                        toggleCounts={unifiedToggleCounts}
                        size={layout === "unified" ? "xl" : "md"}
                    />,
                    layout !== "unified" && <EventsCardsColumn
                        key="split"
                        cards={splitCards}
                        eventToggles={splitEventToggles}
                        eventTogglesHandlers={splitEventTogglesHandlers}
                        rules={splitRules}
                        rulesHandlers={splitRulesHandlers}
                        toggleCounts={splitToggleCounts}
                        size="md"
                    />,
                ]}
                />
            </ColumnProvider>
        </Stack>
    );
}
