import * as rdlTypes from "../../../models/RDLDataTypes";
import { EventName } from "../../../utilities/metric-calculations/metrics";
import { Select } from "@mantine/core";
import { getEventNameStr } from "../../EventIconMapping";
import { useMetricFormContext } from "./metric-form-context";

export function SearchableNameInput(props: {names: string[]}) {
    const form = useMetricFormContext();
    const inputProps = form.getInputProps('name');
    const onChange = function(value: EventName) {
        inputProps.onChange(value);
        form.setFieldValue('displayName', getEventNameStr(rdlTypes.EventType[value]));
    };

    return (
        <Select
            withinPortal
            label="Event name"
            data={props.names}
            placeholder="Type a name"
            searchable
            {...{...form.getInputProps('name'), onChange: onChange}}
        />
    );
}
