import { Select } from "@mantine/core";

import { ACCEPTABLE_FACET_TYPES_MAP, FacetTypes } from "../../../utilities/facets";
import { ColumnTypes } from "../../../models/RDLConfig";
import { useColumns } from "../../../context/ColumnContext";
import { useFacetFormContext } from "./facet-form-context";


export function FacetTypeInput() {
    const form = useFacetFormContext();
    const columns = useColumns();

    const columnName = form.values.data.facetData.columnName;
    const columnType = columnName in columns ? columns[columnName] : ColumnTypes.Float
    const acceptableTypes = ACCEPTABLE_FACET_TYPES_MAP[columnType];
    const selectData = [
        {value: FacetTypes.NumberBucketsUniform, label: "Uniformly bucketed", disabled: false},
        {value: FacetTypes.NumberBucketsUniformIntegers, label: "Uniformly bucketed", disabled: false},
        {value: FacetTypes.NumberBucketsExponentialBase10, label: "Exponentially bucketed (base 10)", disabled: false},
        {value: FacetTypes.BooleanValue, label: "True / False", disabled: false},
        {value: FacetTypes.ListOfCategories, label: "By category (mutually exclusive)", disabled: false},
        {value: FacetTypes.Date, label: "Dates", disabled: false},
        {value: FacetTypes.NonMECEListOfCategories, label: "By category (non-MECE)", disabled: false},
    ];
    selectData.forEach((datum) => datum.disabled = !(acceptableTypes.includes(datum.value)));

    return (
        <Select
            withinPortal
            label="Aggregation type"
            placeholder="Pick one"
            data={selectData}
            {...form.getInputProps('data.facetData.facetType')}
        />
    );
}
