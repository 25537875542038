import { ColumnTypes } from "../models/RDLConfig";
import { ItemCardProps } from "../components/ItemCard";
import { RuleDataSort } from "../components/forms/rule/rule-form-context";
import { compareAsc, parseJSON } from "date-fns";
import objectPath from "object-path";

function compareKeysAsc(a: any, b: any, columnType: ColumnTypes | undefined): number {
    switch (columnType) {
    case ColumnTypes.Boolean:
        return a === b ? 0 : (a === true ? -1 : 1);
    case ColumnTypes.Float:
    case ColumnTypes.Integer:
        return a - b;
    case ColumnTypes.Category:
        return a < b ? -1 : (b < a ? 1 : 0);
    case ColumnTypes.Date:
        return compareAsc(a, b);
    case ColumnTypes.ListOfCategories:
    case ColumnTypes.Undefined:
    case ColumnTypes.Null:
    case undefined:
        return 0;
    }
}

function compareKeys(a: any[], b: any[], sortData: RuleDataSort[]): number {
    return sortData.reduce((acc, sort, index) => {
        if (acc !== 0) {
            return acc;
        }
        return sort.direction === 'Ascending'
            ? compareKeysAsc(a[index], b[index], sort.columnType)
            : compareKeysAsc(b[index], a[index], sort.columnType);
    }, 0);
}

export function applySort(sortData: RuleDataSort[], cards: ItemCardProps[]): ItemCardProps[] {
    const cardsForSorting = cards
        .map((card) => {
            return sortData.map((sort) => objectPath.get(card, sort.columnName));
        })
        .map((values) => sortData.map((sort, index) => sort.columnType === ColumnTypes.Date ? parseJSON(values[index]) : values[index]))
        .map((values, index) => ({ card: cards[index], sortKeys: values }));
    cardsForSorting.sort((a, b) => compareKeys(a.sortKeys, b.sortKeys, sortData));

    return cardsForSorting.map(({ card }) => card);
}
