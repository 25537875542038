import { MaybeDateRange, dateRangeOr30Days } from "../utilities/date-range";
import { useEffect, useState } from "react";
import { useRDLConfig } from "../App";
import RDLEventDatePicker from "./custom/RDLDateRangePicker";

export default function FakeDateRangePicker(props: { disabled: boolean }) {
    const { disabled } = props;
    const config = useRDLConfig();
    const [dateRange, setDateRange] = useState<MaybeDateRange>(dateRangeOr30Days(config));

    useEffect(() => {
        setDateRange(dateRangeOr30Days(config));
    }, [config]);

    return (
        <RDLEventDatePicker
            value={dateRange}
            onChange={(value) => setDateRange(value as MaybeDateRange)}
            disabled={disabled}
        />
    )
}
