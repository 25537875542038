import * as dataOnboardingTypes from "../../models/data-onboarding-data-types";
import { Breadcrumbs, Stack, Title } from "@mantine/core";
import { EditDatabaseForm, EditDatabaseFormValues } from "./DataOnboardingDatabasesDetailPage";
import { Link, useNavigate } from "react-router-dom";
import { RubberDuckyLabsApi } from "../../RDLApi";
import { useApiContext } from "../../App";
import _ from "underscore";

function PageBreadcrumbs() {
    const links = [
        { title: 'Data Onboarding Databases', to: '..' },
        { title: 'Create New', to: '.' },
    ];

    const items = links.map((link, index) => (
        <Link to={link.to} key={index}>
            {link.title}
        </Link>
    ));

    return (
        <Breadcrumbs separator={">"}>{items}</Breadcrumbs>
    );
}

export default function DataOnboardingDatabaseCreatePage() {
    const apiContext = useApiContext();
    const navigate = useNavigate();

    const onEditSumbit = (values: EditDatabaseFormValues) => {
        if (!_.isNull(apiContext)) {
            RubberDuckyLabsApi.getInstance(apiContext).createDataOnboardingDatabase(values)
                .then((database) => navigate(`/data-onboarding/databases/${database.id}`));
        }
    }

    return (
        <Stack>
            <PageBreadcrumbs />
            <Title order={1}>Create New Database</Title>
            <EditDatabaseForm
                values={{name: "", type: dataOnboardingTypes.DataOnboardingDatabaseType.POSTGRES, notes: ""}}
                onSubmit={onEditSumbit}
                onCancel={() => navigate('/data-onboarding/databases')}
            />
        </Stack>
    )
}
