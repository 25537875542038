import {
    Accordion,
    Alert,
    Box,
    LoadingOverlay,
    Stack,
    Text,
    Title
} from "@mantine/core"
import { DataFAQType } from "../models/RDLConfig";
import { IconHelpCircle } from "@tabler/icons-react";
import { useRDLConfig } from "../App";


function DataFAQList(props: { dataFAQs: DataFAQType[] }) {
    return (
        <Accordion variant="separated" styles={{control: {backgroundColor: 'white'}}}>
            {props.dataFAQs.map((dataFAQ) => (
                <Accordion.Item value={dataFAQ.question} key={dataFAQ.question}>
                    <Accordion.Control><Text size="sm" weight="bold">{dataFAQ.question}</Text></Accordion.Control>
                    <Accordion.Panel><Text size="sm">{dataFAQ.answer}</Text></Accordion.Panel>
                </Accordion.Item>
            ))}
        </Accordion>
    );
}


export default function DataFAQPage () {
    const config = useRDLConfig();

    return (
        <Box pos="relative">
            <Stack spacing="xs">
                <LoadingOverlay visible={!config.loaded} />
                <Title order={1}>Data FAQ</Title>
                <Alert
                    title="Can't find the answer?"
                    icon={<IconHelpCircle size={16} />}
                    color="blue"
                >
                Post additional questions and comments in your Rubber Ducky Labs Slack Connect channel,
                or email support@rubberduckylabs.io
                </Alert>
                <DataFAQList dataFAQs={config.dataFAQ} />
            </Stack>
        </Box>
    )
}
