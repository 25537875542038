import * as rdlTypes from "../../models/RDLDataTypes";
import {
    ActionIcon,
    ColorSwatch,
    Group,
    Loader,
    Menu,
    Text
} from "@mantine/core"
import { IconDotsVertical, IconTrash } from "@tabler/icons-react";
import { NO_SEGMENT, UserSegment } from "../../models/user-segments";
import _ from "underscore";


export function UserSegmentMembershipActionsMenu({ userId, segments, segmentKeyToSegmentMap, removeUserFromSegment } : { userId: rdlTypes.Id, segments: rdlTypes.Id[], segmentKeyToSegmentMap: Record<rdlTypes.Id, UserSegment>, removeUserFromSegment: (userId: string, segmentKey: rdlTypes.Id) => void }) {
    return (
        <Menu shadow="md" width={200} position="bottom-start" withinPortal>
            <Menu.Target>
                <ActionIcon variant="transparent" color="gray" mx={-8}>
                    <IconDotsVertical size={16} />
                </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Label>Segments</Menu.Label>
                {segments.every((segmentKey) => segmentKey === NO_SEGMENT) && <Menu.Item disabled>No actions</Menu.Item>}
                {
                    segments.filter((segmentKey) => segmentKey !== NO_SEGMENT && segmentKey in segmentKeyToSegmentMap).map((segmentKey) => (
                        <Menu.Item key={segmentKey} icon={<IconTrash size={16} />} onClick={() => removeUserFromSegment(userId.toString(), segmentKey)} >Remove user from {segmentKeyToSegmentMap[segmentKey].name}</Menu.Item>
                    ))
                }
            </Menu.Dropdown>
        </Menu>
    )
}

export function SegmentDisplay({ segment } : { segment?: UserSegment }) {
    if (_.isUndefined(segment)) {
        return (
            <Group spacing="xs" noWrap>
                <Loader size="1rem" />
                -
            </Group>
        )
    }

    return (
        <Group spacing="xs" noWrap align="center">
            <ColorSwatch size="1rem" color={segment.color[2]} />
            <Text size="sm" truncate>{segment.name}</Text>
        </Group>
    )
}
