import {
    Box,
    Group,
    SegmentedControl,
    SimpleGrid,
    Skeleton,
    Stack,
    Text
} from "@mantine/core"
import { EventName, isMetricName } from "../../utilities/metric-calculations/metrics";
import { EventsData } from '../../utilities/events';
import { FacetFormValues } from '../forms/facet/facet-form-context';
import { FacetedCards, chartTitleWithFacets } from "../../utilities/facets";
import { HelpAlert, HelpIcon } from '../Help';
import { ItemCardGrid } from '../ItemCardGrid';
import { ItemCardProps, SkeletonItemCard } from '../ItemCard';
import { LayoutSizes } from '../../utilities/layout';
import { MaybeDateRange } from '../../utilities/date-range';
import { MetricFormValues } from "../forms/metric/metric-form-context";
import { useDisclosure } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import { useRDLConfig } from '../../App';
import { useState } from "react";
import CountsOverTimeCompareChart from "../charts/CountsOverTimeCompareChart";
import MultiColumnLayout from "../MultiColumnLayout";
import _ from 'underscore';


export type FacetHideStatus = Record<string, boolean>;

function EmptyOverviewPanel() {
    const [opened, {close, toggle} ] = useDisclosure(true);

    return (
        <Stack spacing="xl">
            <HelpAlert
                title="How do I use this page?"
                message={ "Select a date range for events, and press submit!"}
                opened={opened}
                close={close}
            />
            {!opened && <HelpIcon toggle={toggle} />}
        </Stack>
    )
}


function SkeletonGrid() {
    return (
        <SimpleGrid
            breakpoints={[
                { minWidth: 'sm', cols: 2 },
                { minWidth: 'md', cols: 3 },
            ]}>
            {_.range(6).map((i) => <SkeletonItemCard key={i} />)}
        </SimpleGrid>
    );
}


function SkeletonOverviewPanel() {
    return (
        <Stack spacing="xl">
            <Skeleton height={300} />
            <Group grow>
                <MultiColumnLayout
                    columns={[
                        <SkeletonGrid key={1} />,
                        <SkeletonGrid key={2} />,
                    ]}
                />
            </Group>
        </Stack >
    );
}


function SortedCardGrid(props: {
    metric: MetricFormValues,
    facetedCards: FacetedCards[],
    facetHideStatus: FacetHideStatus,
    size?: LayoutSizes,
}) {
    const sortConfigForm = useForm({
        initialValues: {
            sort: "descending",
        },
    });

    const metricName: EventName = props.metric.name;

    if (!isMetricName(metricName)) {
        throw Error(`Invalid metric name: ${metricName}`);
    }

    const cards = _.chain(props.facetedCards)
        .reject((dataset) => props.facetHideStatus[dataset.facetName])
        .pluck('cards')
        .flatten()
        .value();

    const sortDesc = function(a: ItemCardProps, b: ItemCardProps) {
        if (a.metrics === undefined || !(metricName in a.metrics)) {
            return 1;
        }
        if (b.metrics === undefined || !(metricName in b.metrics)) {
            return 0;
        }
        return b.metrics[metricName].value - a.metrics[metricName].value;
    }
    cards.sort(sortConfigForm.values.sort === "descending" ? sortDesc : (a, b) => -1 * sortDesc(a, b));

    return (
        <Box display="block">
            <Group position="apart">
                <Text size="sm" weight="bold" my="lg">{sortConfigForm.values.sort === "descending" ? "Top" : "Bottom"} {props.metric.displayName}</Text>
                <SegmentedControl
                    data={["descending", "ascending"]}
                    {...sortConfigForm.getInputProps('sort')}
                />
            </Group>
            <ItemCardGrid cards={cards} size={props.size ?? 'lg'} />
        </Box>
    );
}


export default function OverviewPanel(props: {
    loading: boolean,
    facetedCards: FacetedCards[],
    metrics: MetricFormValues[],
    facets: FacetFormValues[],
    sharedTimeScale: MaybeDateRange,
    setSharedTimeScale: (value: MaybeDateRange) => void,
    eventsData: EventsData[],
}) {
    const config = useRDLConfig();

    // TODO(Alexandra): would love to get rid of facethide status, but it has some nice properties of
    // preserving the hide status when filtering has changed but faceting hasn't.
    // This might be eaiser if filtering and faceting were more cleanly broken apart.
    const [facetHideStatus, setFacetHideStatus] = useState<FacetHideStatus>({});
    const onClickDataset = function (datasetLabel: string) {
        setFacetHideStatus((oldFacetHideStatus) => ({ ...oldFacetHideStatus, [datasetLabel]: !oldFacetHideStatus[datasetLabel] }))
    };

    const [opened, {close, toggle} ] = useDisclosure(true);

    const layoutConfigForm = useForm<{columns: LayoutSizes}>({
        initialValues: {
            columns: "md",
        },
    });

    // TODO(Alexandra): upgrade mantine and this is a built-in form function
    // https://mantine.dev/form/values/
    const transformValues = function(values: typeof layoutConfigForm.values): { columns: number } {
        switch (values.columns) {
        case "lg":
            return { columns: 1};
        case "md":
            return { columns: 2 };
        case "sm":
        default:
            return {columns: 3 };
        }
    }


    const empty = props.eventsData.map((ed) => ed.data).flat().length === 0;
    if (empty) {
        if (props.loading) return <SkeletonOverviewPanel />;
        return <EmptyOverviewPanel />;
    }

    return (
        <>
            <HelpAlert
                title="How do I use this panel?"
                message={ "View up to three metrics side-by-side to start forming hypotheses about the best and worst performing items."
                + " Add filters and aggregate items using the controls above as you start to recognize patterns."
                + " Drag and drop metrics in the controls above to re-arrange the ordering on this page."
                }
                opened={opened}
                close={close}
            />
            <Group mt="xs" position="apart" align='start'>
                <Stack align="flex-start" spacing="xs">
                    <Text size="sm" weight="bold">Number of metrics to compare</Text>
                    <SegmentedControl
                        data={[
                            { label: "one", value: "lg" },
                            { label: "two", value: "md" },
                            { label: "three", value: "sm" },
                        ]}
                        {...layoutConfigForm.getInputProps('columns')}
                    />
                </Stack>
                <HelpIcon toggle={toggle} />
            </Group>
            <MultiColumnLayout
                columns={
                    props.metrics.slice(0, transformValues(layoutConfigForm.values).columns).map((metric) => <>
                        <Text size="sm" weight="bold" my="lg">
                            {chartTitleWithFacets(metric.displayName, props.facets)} over time
                        </Text>
                        <CountsOverTimeCompareChart
                            datasets={props.facetedCards}
                            onClickDataset={onClickDataset}
                            onXScaleChange={props.setSharedTimeScale}
                            xScale={props.sharedTimeScale}
                            metrics={[metric]}
                            facetHideStatus={facetHideStatus}
                            config={config}
                            eventsData={props.eventsData}
                        />
                    </>)
                }
            />
            <MultiColumnLayout
                columns={
                    props.metrics.slice(0, transformValues(layoutConfigForm.values).columns).map((metric) => (
                        <SortedCardGrid
                            key={metric.name}
                            metric={metric}
                            facetedCards={props.facetedCards}
                            facetHideStatus={facetHideStatus}
                            size={layoutConfigForm.values.columns}
                        />
                    ))
                }
            />
        </>
    )
}
