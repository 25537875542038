import 'chartjs-adapter-date-fns';
import * as dateFNS from "date-fns";
import * as rdlTypes from "../../models/RDLDataTypes";
import {
    BarElement,
    CategoryScale,
    ChartEvent,
    Chart as ChartJS,
    Filler,
    Legend,
    LegendItem,
    LineElement,
    LinearScale,
    PointElement,
    TimeScale,
    Title,
    Tooltip,
} from 'chart.js';
import { EventsData } from '../../utilities/events';
import { FacetHideStatus } from '../data-explore-panels/OverviewPanel';
import { FacetedCards } from "../../utilities/facets";
import { Line, getElementAtEvent } from 'react-chartjs-2';
import { MaybeDateRange } from "../../utilities/date-range";
import { MetricFormValues } from "../forms/metric/metric-form-context";
import { MouseEvent, useMemo, useRef } from "react";
import { RDLConfigType } from '../../models/RDLConfig';
import { calculateMetrics } from '../../utilities/metric-calculations/apply-calculations';
import { chartColorsList, mergeWithDefaultChartOptions } from "../../utilities/charts";
import { isRateMetric } from '../../utilities/metric-calculations/metrics';
import { useMantineTheme } from "@mantine/core";
import _ from 'underscore';
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin,
    Filler,
);

interface DateAndMetricsMap { date: Date, metricsMap: Record<string, number> }

export interface CountsOverTimeCompareChartDataset {
    facetName: string,
    data: DateAndMetricsMap[],
}

export const generateChartMetrics = function (
    facetedCards: FacetedCards[],
    eventsData: EventsData[],
    config: RDLConfigType,
): CountsOverTimeCompareChartDataset[] {
    const calculationsData = calculateMetrics(config.metricCalculations, eventsData);
    const eventsAndCalculationsData = eventsData.concat(calculationsData);
    const allMetrics: rdlTypes.EventCount[] = eventsAndCalculationsData.map(({data}) => data).flat();

    return facetedCards.map(({facetName, cards}) => {
        const itemIds = new Set(cards.map((card) => card.item?.item_id).filter((id) => id !== undefined));

        /**
         * Ok so this grouping has to be done by every facet because we're filtering out ids
         * to only the ids that match the cards.
         *
         * If we have more data, like our client_tenure model, then what we want to do is:
         *   1) We want to group on client_tenure, then group on date
         *   2) We want to pick the exact client_tenure value that matches up with our current
         * facet, and use that client_tenure data. We will have the client_tenure values readily
         * available.
         *
         * We don't currently have a way to determine
         *   a) if we are even faceting on client_tenure and
         *   b) what the value is (though we could do really janky string parse)
         */

        const data: DateAndMetricsMap[] = _.chain(allMetrics)
            .filter(({item_id}) => itemIds.has(item_id))
            .groupBy('date')
            .mapObject((facetMetrics) => {
                return _.chain(facetMetrics)
                    .groupBy('event_type')
                    .mapObject((eventCounts) => {
                        if (_.isEmpty(eventCounts)) return 0;
                        const eventType = eventCounts[0].event_type;
                        if (isRateMetric(eventType)) {
                            const totalDenom = eventCounts.reduce((prev, curr) => prev + curr.count, 0);
                            const totalNom = eventCounts.reduce((prev, curr) => prev + (curr.rate ? curr.rate * curr.count : 0), 0);
                            return totalNom / totalDenom;
                        } else {
                            return eventCounts.reduce((prev, curr) => prev + curr.count, 0);
                        }
                    })
                    .value();
            })
            .pairs()
            .map(([dateStr, metricsMap]) => ({date: dateFNS.parseISO(dateStr), metricsMap }))
            .value();
        data.sort((a, b) => dateFNS.compareAsc(a.date, b.date));

        return {
            facetName,
            data: data,
        };
    });
};


export default function CountsOverTimeCompareChart(props: {
    datasets: FacetedCards[],
    eventsData: EventsData[],
    facetHideStatus: FacetHideStatus,
    xScale: MaybeDateRange,
    metrics: MetricFormValues[],
    onClickDataset: (datasetLabel: string) => void,
    onXScaleChange: (xScale: MaybeDateRange) => void,
    config: RDLConfigType,
}) {
    const metric1 = props.metrics.at(0);
    const metric2 = props.metrics.at(1);

    const chartMetrics: CountsOverTimeCompareChartDataset[] = useMemo(
        () => generateChartMetrics(
            props.datasets,
            props.eventsData,
            props.config,
        ),
        [props.datasets, props.eventsData, props.config],
    );

    const chartRef = useRef<any>(null);

    const legendOnClick = (e: ChartEvent, legendItem: LegendItem) => {
        const index = legendItem.datasetIndex;
        if (index !== undefined) {
            const dataset = chartMetrics[index % chartMetrics.length];
            props.onClickDataset(dataset.facetName);
        }
    }

    const chartOnClick = (event: MouseEvent<HTMLCanvasElement>) => {
        const elements = getElementAtEvent(chartRef.current, event);
        if (elements.length > 0) {
            const element = elements[0];
            const index = element.datasetIndex;
            const dataset = chartMetrics[index % chartMetrics.length];
            props.onClickDataset(dataset.facetName);
        }
    }

    const onPanOrZoom = function (chart: any) {
        const { min, max } = chart.chart.scales.x;
        props.onXScaleChange([new Date(min), new Date(max)]);
    }

    const theme = useMantineTheme();
    const colors = chartColorsList(theme);

    const scales: any = {
        x: {
            type: 'time',
            time: {
                unit: 'day',
            },
            min: props.xScale[0],
            max: props.xScale[1],
        },
        y: { display: false },
        y1: { display: false },
    };

    const datasets = [];

    if (metric1 !== undefined) {
        scales.y = {
            type: 'linear',
            display: true,
            position: 'left',
            title: { display: true, text: metric1.displayName },
        };

        datasets.push(...chartMetrics.map((dataset, index) => ({
            label: `${metric1.displayName} ${dataset.facetName}`,
            data: dataset.data,
            parsing: {
                xAxisKey: 'date',
                yAxisKey: `metricsMap.${rdlTypes.EventType[metric1.name]}`, // TODO(Alexandra): this is brittle
            },
            borderColor: theme.fn.rgba(colors[index % colors.length][9], 0.5),
            backgroundColor: theme.fn.rgba(colors[index % colors.length][8], 0.5),
            hidden: props.facetHideStatus[dataset.facetName],
            yAxisID: 'y',
        })));
    }

    if (metric2 !== undefined) {
        scales.y1 = {
            type: 'linear',
            display: true,
            position: 'right',
            title: { display: true, text: metric2.displayName },

            // grid line settings
            grid: {
                drawOnChartArea: false, // only want the grid lines for one axis to show up
            },
        };

        datasets.push(...chartMetrics.map((dataset, index) => ({
            label: `${metric2.displayName} ${dataset.facetName}`,
            data: dataset.data,
            parsing: {
                xAxisKey: 'date',
                yAxisKey: `metricsMap.${rdlTypes.EventType[metric2.name]}` // TODO(Alexandra): this is brittle
            },
            borderColor: theme.fn.rgba(colors[index % colors.length][2], 0.5),
            backgroundColor: theme.fn.rgba(colors[index % colors.length][1], 0.5),
            hidden: props.facetHideStatus[dataset.facetName],
            yAxisID: 'y1',
        })));
    }

    const options = mergeWithDefaultChartOptions({
        aspectRatio: datasets.length > 5 ? 3 : 5,
        scales: scales,
        plugins: {
            title: {
                display: false,
            },
            tooltip: {
                intersect: false,
                callbacks: {
                    //     beforeBody: (context: any) => props.data[context[0].dataIndex].id,
                    //     label: (context: any) => `${context.parsed.y} ${context.dataset.label}`,
                },
            },
            legend: {
                display: true,
                onClick: legendOnClick,
            },
            zoom: {
                zoom: {
                    pinch: {
                        enabled: true
                    },
                    drag: {
                        enabled: true,
                    },
                    mode: 'x' as const,
                    onZoom: onPanOrZoom,
                },
                pan: {
                    enabled: true,
                    modifierKey: 'shift' as const,
                    mode: 'x' as const,
                    onPan: onPanOrZoom,
                },
                limits: {
                    x: {
                        max: new Date()
                    },
                },
            },
        },
    });

    const data = {
        datasets: datasets,
    };

    return (
        <Line
            ref={chartRef}
            options={options}
            data={data}
            onClick={chartOnClick}
        />
    );
}
