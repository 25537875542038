import * as dataOnboardingTypes from "../../models/data-onboarding-data-types";
import { formatTimestamp, formatUpdatedTimestamp } from "../../utilities/time";
import _ from "underscore";

import {
    ActionIcon,
    Button,
    Group,
    LoadingOverlay,
    Menu,
    Modal,
    Select,
    Stack,
    Table,
    Text,
    TextInput,
    Textarea,
    Title
} from "@mantine/core"
import { AlertWithTimeout } from "../../components/Alert";
import { EmptyRow } from "./Components";
import { IconDotsVertical, IconEdit, IconTrash } from "@tabler/icons-react";
import { RubberDuckyLabsApi } from "../../RDLApi";
import { hasLength, isNotEmpty, useForm } from "@mantine/form";
import { useApiContext } from "../../App";
import { useDisclosure, useListState } from "@mantine/hooks";
import { useEffect, useState } from "react";
import axios from "axios";


function TestsEditForm({onSubmit, values}: {onSubmit: (values: dataOnboardingTypes.DataOnboardingTestCreateData) => void, values: dataOnboardingTypes.DataOnboardingTestCreateData}) {
    const form = useForm<dataOnboardingTypes.DataOnboardingTestCreateData>({
        initialValues: values,
        validate: {
            title: hasLength({min: 1}, 'Please provide a title'),
            description: hasLength({min: 1}, 'Please provide a description'),
            category_l1: isNotEmpty('Please select a category'),
            answer_type: isNotEmpty('Please select an answer type'),
        },
    });

    const answerTypeSelectData = [
        dataOnboardingTypes.DataOnboardingTestAnswerType.DATABASE,
    ];

    const categoryL1SelectData = [
        dataOnboardingTypes.DataOnboardingTestCategoryL1.EVENTS,
        dataOnboardingTypes.DataOnboardingTestCategoryL1.METRICS,
        dataOnboardingTypes.DataOnboardingTestCategoryL1.PRODUCTS,
        dataOnboardingTypes.DataOnboardingTestCategoryL1.USERS,
    ];

    return (
        <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
            <Stack>
                <TextInput
                    label="Title"
                    placeholder="Enter the title of the test"
                    required
                    {...form.getInputProps('title')}
                />
                <Select
                    withinPortal
                    label="Category"
                    placeholder="Select a category"
                    required
                    data={categoryL1SelectData}
                    {...form.getInputProps('category_l1')}
                />
                <Textarea
                    label="Description"
                    placeholder="Enter a description of the test"
                    required
                    {...form.getInputProps('description')}
                />
                <Select
                    withinPortal
                    disabled
                    label="Answer Type"
                    placeholder="Select an answer type"
                    required
                    data={answerTypeSelectData}
                    {...form.getInputProps('answer_type')}
                />
                <Group position="right">
                    <Button type="submit">Submit</Button>
                </Group>
            </Stack>
        </form>
    )
}


function DataOnboardingTestsRow({test, onEditSubmit, onDeleteSubmit} : {test: dataOnboardingTypes.DataOnboardingTest, onEditSubmit: OnEditSubmitType, onDeleteSubmit: OnDeleteSubmitType}) {
    const [opened, {open, close}] = useDisclosure();

    return (
        <tr key={test.id} style={{cursor: 'pointer'}} onClick={open}>
            <td>{test.id}</td>
            <td>{test.title}</td>
            <td><Text tt="capitalize">{test.category_l1}</Text></td>
            <td>{formatTimestamp(test.created)}</td>
            <td>{formatUpdatedTimestamp(test.updated)}</td>
            <td>{test.deleted ? "True" : "False"}</td>
            <td>{test.answer_type}</td>
            <td>
                <Modal
                    size="lg"
                    opened={opened}
                    onClose={close}
                    title="Edit Test"
                    onClick={(e) => e.stopPropagation()}
                >
                    <TestsEditForm
                        values={{
                            title: test.title,
                            description: test.description,
                            category_l1: test.category_l1,
                            answer_type: test.answer_type,
                        }}
                        onSubmit={(values) => {
                            onEditSubmit(values);
                            close();
                        }}
                    />
                </Modal>
                <Menu shadow="md" width={200} position="bottom-start" withinPortal>
                    <Menu.Target>
                        <ActionIcon variant="transparent" color="gray" onClick={(e: React.MouseEvent) => e.stopPropagation()} >
                            <IconDotsVertical size={16} />
                        </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown onClick={(e) => e.stopPropagation()}>
                        <Menu.Item icon={<IconEdit size={16} />} onClick={open}>Edit</Menu.Item>
                        <Menu.Item icon={<IconTrash size={16} />} onClick={onDeleteSubmit}>Delete</Menu.Item>
                    </Menu.Dropdown>
                </Menu>
            </td>
        </tr>
    )
}

type OnEditSubmitType = (values: dataOnboardingTypes.DataOnboardingTestCreateData) => void;
type OnDeleteSubmitType = () => void;
type OnCreateSubmitType = (values: dataOnboardingTypes.DataOnboardingTestCreateData) => void;
type OnEditTestType = (test: dataOnboardingTypes.DataOnboardingTest, index: number) => OnEditSubmitType;
type OnDeleteTestType = (test: dataOnboardingTypes.DataOnboardingTest, index: number) => OnDeleteSubmitType;

function DataOnboardingTestsTable({tests, onEditTest, onDeleteTest} : {tests: dataOnboardingTypes.DataOnboardingTest[], onEditTest: OnEditTestType, onDeleteTest: OnDeleteTestType }) {
    const rows = tests.map((test, index) => <DataOnboardingTestsRow
        key={test.id}
        test={test}
        onEditSubmit={onEditTest(test, index)}
        onDeleteSubmit={onDeleteTest(test, index)}
    />);

    return (
        <Table withBorder highlightOnHover>
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Question</th>
                    <th>Category</th>
                    <th>Created</th>
                    <th>Updated</th>
                    <th>Deleted</th>
                    <th>Answer Type</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>{_.isEmpty(rows) ? <EmptyRow colSpan={8} /> : rows}</tbody>
        </Table>
    );
}

function TestsCreateModal({onCreateSubmit}: {onCreateSubmit: OnCreateSubmitType}) {
    const [opened, {open, close}] = useDisclosure();

    const onSubmit = (values: dataOnboardingTypes.DataOnboardingTestCreateData) => {
        onCreateSubmit(values);
        close();
    }

    return (
        <>
            <Modal opened={opened} onClose={close} title="Create Test">
                <TestsEditForm onSubmit={onSubmit} values={{
                    title: "",
                    description: "",
                    category_l1: dataOnboardingTypes.DataOnboardingTestCategoryL1.EVENTS,
                    answer_type: dataOnboardingTypes.DataOnboardingTestAnswerType.DATABASE,
                }} />
            </Modal>
            <Group>
                <Button onClick={open} variant="outline">Create Test</Button>
            </Group>
        </>
    )
}



export function DataOnboardingTestsManagementPage() {
    const [apiError, setApiError] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [tests, testsHandlers] = useListState<dataOnboardingTypes.DataOnboardingTest>([]);
    const apiContext = useApiContext();

    const onEditTest = (test: dataOnboardingTypes.DataOnboardingTest, index: number) => {
        const onEditSubmit = (values: dataOnboardingTypes.DataOnboardingTestCreateData) => {
            if (!_.isNull(apiContext)) {
                RubberDuckyLabsApi.getInstance(apiContext)
                    .updateDataOnboardingTest(test.id, values)
                    .then((updatedTest) => testsHandlers.setItem(index, updatedTest));
            }
        }
        return onEditSubmit;
    }

    const onCreateSubmit = (values: dataOnboardingTypes.DataOnboardingTestCreateData) => {
        if (!_.isNull(apiContext)) {
            RubberDuckyLabsApi.getInstance(apiContext)
                .createDataOnboardingTest(values)
                .then((test) => testsHandlers.append(test));
        }
    }

    const onDeleteTest = (test: dataOnboardingTypes.DataOnboardingTest, index: number) => {
        const onDeleteSubmit = () => {
            if (!_.isNull(apiContext)) {
                RubberDuckyLabsApi.getInstance(apiContext)
                    .deleteDataOnboardingTest(test.id)
                    .then(() => testsHandlers.remove(index))
                    .catch((error) => {
                        if (axios.isAxiosError(error) && error.response && _.isObject(error.response.data) && _.isString(error.response.data.detail)) {
                            setApiError(error.response.data.detail);
                        } else {
                            setApiError('An error occurred while deleting the test.');
                        }
                    });
            }
        }
        return onDeleteSubmit;
    }

    useEffect(() => {
        if (!_.isNull(apiContext)) {
            setLoading(true);
            RubberDuckyLabsApi.getInstance(apiContext)
                .iterateDataOnboardingTests()
                .then((tests) => testsHandlers.setState(tests))
                .finally(() => setLoading(false));
        }
    }, [apiContext]);

    return(
        <Stack>
            <AlertWithTimeout message={apiError} setMessage={setApiError} />
            <Title order={1}>Admin Tests Management</Title>
            <Text c="dimmed">Admins can create and manage data onboarding tests.</Text>
            <LoadingOverlay visible={loading} />
            <DataOnboardingTestsTable tests={tests} onEditTest={onEditTest} onDeleteTest={onDeleteTest} />
            <TestsCreateModal onCreateSubmit={onCreateSubmit} />
        </Stack>
    );
}
