import * as rdlTypes from "../models/RDLDataTypes";
import * as statsTypes from "../models/StatsDataTypes";
import { RDLApiContext, RubberDuckyLabsApi } from "../RDLApi";
import { addDays, subDays, subMonths } from "date-fns";


export enum HealthCheckTimeRange {
    LastWeek = "Last week",
    LastMonth = "Last month",
    LastYear = "Last year",
}


export default class HealthCheckService {
    private static classInstance?: HealthCheckService;
    protected api: RubberDuckyLabsApi;

    public constructor(context: RDLApiContext) {
        this.api = RubberDuckyLabsApi.getInstance(context);
    }

    public static getInstance(context: RDLApiContext) {
        if (!this.classInstance) {
            this.classInstance = new HealthCheckService(context);
        }

        return this.classInstance;
    }



    public static getTimescaleAndLimitForTimeRange(timeRange: HealthCheckTimeRange): [Date, Date, statsTypes.StatisticsTimescaleValue] {
        const today = new Date();
        switch (timeRange) {
        case HealthCheckTimeRange.LastWeek:
            return [subDays(today, 7), today, statsTypes.StatisticsTimescaleValue.DAY];
        case HealthCheckTimeRange.LastMonth:
            return [subMonths(today, 1), today, statsTypes.StatisticsTimescaleValue.DAY];
        case HealthCheckTimeRange.LastYear:
            return [subMonths(today, 12), today, statsTypes.StatisticsTimescaleValue.MONTH];
        }
    }


    // We don't paginate these events because we only care about the first page
    private getHealthCheckForEventsRecursive(
        eventTypes: rdlTypes.EventType[],
        startDate: Date,
        endDate: Date,
        timescale: statsTypes.StatisticsTimescaleValue,
        onSuccess: (eventType: rdlTypes.EventType, data: statsTypes.Statistic[]) => void,
    ): Promise<void> {
        if (eventTypes.length === 0) {
            return Promise.resolve();
        }

        const firstEventType = eventTypes[0];
        const restEventTypes = eventTypes.slice(1);

        return this.api.getEventStatistics(firstEventType, startDate, addDays(endDate, 1), timescale)
            .then(({data}) => onSuccess(firstEventType, data))
            .then(() => this.getHealthCheckForEventsRecursive(restEventTypes, startDate, endDate, timescale, onSuccess));
    }

    public getHealthCheckForAllEvents(
        eventTypes: rdlTypes.EventType[],
        timeRange: HealthCheckTimeRange,
        onSuccess: (eventType: rdlTypes.EventType, data: statsTypes.Statistic[]) => void,
    ): Promise<void> {
        const [startDate, endDate, timescale] = HealthCheckService.getTimescaleAndLimitForTimeRange(timeRange);
        return this.getHealthCheckForEventsRecursive(eventTypes, startDate, endDate, timescale, onSuccess);
    }
}
