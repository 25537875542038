import { Center, Stack, Text } from "@mantine/core";
import { ConfidenceLevelSlider, PercentageInput } from "./Inputs";
import { ConversionRateABTestCalculatorValues, isConversionRateABTestCalculatorValues } from "../ABTestCalculatorPage";
import { calculateMinimumSampleSize } from "../../../utilities/statistical-significance";
import { formatPercentage } from "../../../utilities/format-values";
import { isInRange, useForm } from "@mantine/form";
import { useEffect, useMemo } from "react";
import _ from "underscore";

export interface SampleSizeForConversionRateABTestCalculatorValues {
    baselineConversionRate: number;
    effectSize: number;
    confidenceLevel: number;
    sampleSize: number | null;
}

interface SampleSizeForConversionRateABTestCalculatorProps {
    metricName: string;
    initialValues: ConversionRateABTestCalculatorValues;
    onChange: (values: ConversionRateABTestCalculatorValues) => void;
}

export function SampleSizeForConversionRateABTestCalculator({ metricName, initialValues, onChange } : SampleSizeForConversionRateABTestCalculatorProps) {
    const form = useForm<SampleSizeForConversionRateABTestCalculatorValues>({
        initialValues: initialValues,
        validate: {
            baselineConversionRate: isInRange({min: 0, max: 1}, "Enter a number between 0% and 100%"),
            effectSize: isInRange({min: 0}, "Enter a number greater than 0%"),
        },
        transformValues: (values) => {
            const transformedValues = {
                baselineConversionRate: values.baselineConversionRate,
                effectSize: values.effectSize,
                confidenceLevel: Math.min(0.99, values.confidenceLevel),
            }
            const { baselineConversionRate, effectSize, confidenceLevel } = transformedValues;
            if (!(_.every([baselineConversionRate, effectSize, confidenceLevel], _.isNumber))) {
                return {...transformedValues, sampleSize: null};
            }

            const sampleSize = calculateMinimumSampleSize(baselineConversionRate, effectSize, confidenceLevel);
            return {...transformedValues, sampleSize };
        }
    });

    useEffect(() => {
        form.validate();
    }, [form.values]);

    useEffect(() => {
        const transformedValues = form.getTransformedValues()
        if (isConversionRateABTestCalculatorValues(transformedValues)) {
            onChange(transformedValues);
        }
    }, [form.values]);

    const sampleSize = useMemo(() => form.getTransformedValues().sampleSize, [form.values]);

    return (
        <form onSubmit={form.onSubmit((values) => console.log(values))}>
            <Stack maw={600} mx="auto" mt="xs">
                <PercentageInput
                    required
                    label={`Baseline ${metricName}`}
                    {...form.getInputProps("baselineConversionRate")}
                />
                <PercentageInput
                    required
                    label={`Expected effect size (lift or drop), relative to baseline`}
                    {...form.getInputProps("effectSize")}
                />
                <ConfidenceLevelSlider mb="md" {...form.getInputProps("confidenceLevel")} />
                <Center>
                    <Stack spacing="3" align="center">
                        <Text size="sm" weight={500} color="gray.9">Sample Size per Variation</Text>
                        {_.isNull(sampleSize)
                            ? <Text color="red" fw="bold" size="xl">{'error'}</Text>
                            : <Text color="blue" fw="bold" size="xl">{sampleSize}</Text>
                        }
                    </Stack>
                </Center>
                {!_.isNull(sampleSize) && <Text>
                    If <Text span color="blue" fw="bold">{sampleSize}</Text> users are expected to receive the sample treatment over the course of the test,
                    then with <Text span c="blue" fw="bold">{formatPercentage(form.getTransformedValues().confidenceLevel)}</Text> confidence
                    any {metricName} above
                    <Text span c="blue" fw="bold"> {formatPercentage(form.values.baselineConversionRate * (1 + form.values.effectSize), 2)} </Text>
                    or below
                    <Text span c="blue" fw="bold"> {formatPercentage(form.values.baselineConversionRate * (1 - form.values.effectSize), 2)} </Text>
                    will be statistically significant.
                </Text>}
            </Stack>
        </form>
    )
}
