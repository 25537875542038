import { ColumnTypes, ItemColumns, JSTypes } from '../models/RDLConfig';
import { isStringJSONDate } from './date-range';
import _ from 'underscore';

function getJSType(value: any): JSTypes {
    if (value === null) return JSTypes.Null;

    switch(typeof value) {
    case "undefined":
        return JSTypes.Undefined;
    case "boolean":
        return JSTypes.Boolean;
    case "number":
        return JSTypes.Number;
    case "bigint":
        return JSTypes.BigInt;
    case "string":
        return JSTypes.String;
    case "symbol":
        return JSTypes.Symbol;
    case "function":
        return JSTypes.Function;
    case "object":
        return JSTypes.Object;
    }
}

function getArmoireTypeFromValueAndJSType(value: any, jsType: JSTypes): ColumnTypes {
    switch(jsType) {
    case JSTypes.Undefined:
        return ColumnTypes.Undefined
    case JSTypes.Null:
        return ColumnTypes.Null;
    case JSTypes.Boolean:
        return ColumnTypes.Boolean;
    case JSTypes.Number:
        return ColumnTypes.Float;
    case JSTypes.BigInt:
        return ColumnTypes.Float;
    case JSTypes.String:
        return isStringJSONDate(value) ? ColumnTypes.Date : ColumnTypes.Category;
    }

    throw Error(`Unsupported JSType: ${jsType}`);
}

export const isColumnTypes = function(value: ColumnTypes | undefined): value is ColumnTypes {
    return value !== undefined;
}

function isJSTypeScalar(jsType: JSTypes) {
    const nonScalarJSTypes = [JSTypes.Symbol, JSTypes.Function, JSTypes.Object]
    if (nonScalarJSTypes.includes(jsType)) {
        return false;
    }

    return true;
}

function constructColumnTypesRecursive(value: any, fragment: string[]): ItemColumns {
    const jsType = getJSType(value);
    if (isJSTypeScalar(jsType)) {
        const columnName = fragment.join('.');
        const columnType = getArmoireTypeFromValueAndJSType(value, jsType)
        return { [columnName]:  columnType };
    } else if (jsType === JSTypes.Object) {
        return _.extend({}, ...Object.entries(value).map(([k, v]) => constructColumnTypesRecursive(v, fragment.concat(k))));
    }

    throw Error(`No support for data type ${jsType}`);
}

// TODO(georgia): Use this function in onboarding flow
export function constructColumnTypes(item: any): ItemColumns {
    return constructColumnTypesRecursive(item, ['item']);
}

export function construcstEventColumnTypes(event: any): ItemColumns {
    return constructColumnTypesRecursive(event, ['event']);
}
