import { RankingPipeline } from "../../../models/RDLDataTypes";
import { Select } from "@mantine/core";
import { useRankingDrilldownFormContext } from "./ranking-drilldown-form-context";
import _ from "underscore";


export const RankingPipelineInput = function (props: {
    rankingPipelines: RankingPipeline[],
}) {
    const form = useRankingDrilldownFormContext();
    const data = props.rankingPipelines.map(pipeline => ({
        value: pipeline.id.toString(),
        label: pipeline.name,
    }));
    return (
        <Select
            withinPortal
            disabled={_.isEmpty(props.rankingPipelines)}
            placeholder="Select a ranking pipeline"
            label="Ranking Pipeline"
            data={data}
            {...form.getInputProps('rankingPipelineId')}
        />
    )
}
