import * as rdlTypes from "../models/RDLDataTypes";
import { ColumnTypes, ItemColumns } from "../models/RDLConfig";
import { ItemCardProps } from "../components/ItemCard";
import { SelectItem } from "@mantine/core";
import { isUndefinedOrNull } from "./types";
import _ from "underscore";
import objectPath from "object-path";


export type SearchableCategories = Record<string, (string | SelectItem)[]>

export const categoryLabel = function(category: string): string {
    return category === "" ? "(Empty string)" : category;
}


export const DISABLED_EMPTY_STRING_SELECT_ITEM: SelectItem = {
    value: "",
    label: `${categoryLabel("")} - No items`,
    disabled: true,
};

export const EMPTY_STRING_SELECT_ITEM: SelectItem = {
    value: "",
    label: categoryLabel(""),
    disabled: false,
};


function getSearchableCategoriesFromCards(columns: ItemColumns, cards: ItemCardProps[]): SearchableCategories {
    const categoryColumns: string[] = Object.entries(columns)
        .filter(([, columnType]) => columnType === ColumnTypes.Category || columnType === ColumnTypes.ListOfCategories)
        .map(([columnName, ]) => columnName);

    return Object.fromEntries(categoryColumns.map((columnName) => {
        const categoriesSortedByFrequency = _.chain(cards)
            .map((card) => objectPath.get(card, columnName))
            .flatten()
            .reject(isUndefinedOrNull)
            .countBy()
            .pairs()
            .sortBy(([, frequency]) => -frequency)
            .map(([value]) => value)
            .value();

        const emptyStringIndex = categoriesSortedByFrequency.indexOf("");
        if (emptyStringIndex > -1) {
            categoriesSortedByFrequency.splice(emptyStringIndex, 1);
        }
        const selectItems: SelectItem[] = categoriesSortedByFrequency
            .slice(0, 1000)
            .map((value) => ({
                value: value,
                label: categoryLabel(value),
                disabled: false,
            }));
        if (emptyStringIndex > -1) {
            selectItems.unshift(EMPTY_STRING_SELECT_ITEM);
        } else {
            selectItems.unshift(DISABLED_EMPTY_STRING_SELECT_ITEM);
        }
        return [columnName, selectItems];
    }));
}


export function getSearchableCategoriesFromItemsAndCards(
    columns: ItemColumns,
    items: rdlTypes.Item[],
    cards: ItemCardProps[],
): SearchableCategories {
    const uniqueCards: ItemCardProps[] = _.chain(cards)
        .concat(items.map((item): ItemCardProps => ({item})))
        .sortBy(({item}) => item?.item_id)
        .uniq(true, ({item}) => item?.item_id)
        .value();
    return getSearchableCategoriesFromCards(columns, uniqueCards);
}
