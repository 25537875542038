import { Box, Grid } from "@mantine/core";
import { isUndefinedOrNull } from "../utilities/types";


export default function MultiColumnLayout(props: { columns: React.ReactNode[], noLines?: boolean }) {
    const columns = props.columns.filter((column) => !(isUndefinedOrNull(column) || column === false));
    const columnSpan = Math.floor(12 / columns.length);

    const lineFormat = props.noLines === undefined || !props.noLines
        ? { borderLeft: "1px solid #CED4DA" }
        : {};

    return (
        <Grid justify="center" align="flex-start" grow>
            {columns.map((column, index) => (
                <Grid.Col span={columnSpan} key={index}>
                    {index > 0
                        ? <Box sx={lineFormat} pl="md">{column}</Box>
                        : <Box>{column}</Box>}
                </Grid.Col>
            ))}
        </Grid>
    );
}
