import { createFormContext } from "@mantine/form";

export interface TestDatasetsFormValues {
    name: string,
    environment: string,
    tags: string[],
    timestamp: number,
    jsonData: string,
}

export const newTestDataset = function (): TestDatasetsFormValues {
    return {
        name: "",
        environment: "dev",
        tags: [],
        timestamp: Math.floor(Date.now() / 1000),
        jsonData: "",
    };
};

export const validateTestDatasetsFormValues = {
    name: (value: string) => {
        if (value.length === 0) {
            return 'Name is required';
        }

        return undefined;
    },
    environment: (value: string) => {
        if (value.length === 0) {
            return 'Environment is required';
        }

        return undefined;
    },
    jsonData: (value: string) => {
        if (value.length === 0) {
            return 'JSON Data is required';
        }

        return undefined;
    },
};

export const [TestDatasetsFormProvider, useTestDatasetsFormContext, useTestDatasetsForm] =
    createFormContext<TestDatasetsFormValues>();
