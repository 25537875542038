import { Alert } from "../../../components/Alert";
import { RubberDuckyLabsApi } from "../../../RDLApi";
import { Select, Skeleton } from "@mantine/core";
import { useApiContext } from "../../../App";
import { useEffect, useState } from "react";
import { useTestDataFormContext } from "./test-data-form-context";
import _ from "underscore";

export const TestDatasetInput = function() {
    const form = useTestDataFormContext();
    const apiContext = useApiContext();
    const [apiError, setApiError] = useState('');
    const [testDatasetNames, setTestDatasetNames] = useState<string[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!_.isNull(apiContext)) {
            setLoading(true);
            RubberDuckyLabsApi.getInstance(apiContext)
                .getTestDatasetNames()
                .then(setTestDatasetNames)
                .catch((error) => setApiError(error.message))
                .finally(() => setLoading(false));
        }

        return () => {
            setTestDatasetNames([]);
        }
    }, [apiContext]);

    if (loading) {
        return (
            <>
                <Skeleton height={21} width={100} />
                <Skeleton height={40} />
            </>
        );
    }

    return (
        <>
            {!_.isEmpty(apiError) && <Alert message={apiError} onClose={() => setApiError('')} />}
            <Select
                withinPortal
                searchable
                placeholder="Dataset ID"
                label="Test Dataset ID"
                data={testDatasetNames}
                {...form.getInputProps('testDatasetName')}
            />
        </>
    )
}
