import { Alert } from "../../components/Alert";
import {
    Code,
    Group,
    Loader,
    Stack,
    Text,
    ThemeIcon,
    Title
} from "@mantine/core"
import { IconCircleCheck } from "@tabler/icons-react";
import { RubberDuckyLabsApi } from "../../RDLApi";
import { useApiContext } from "../../App";
import { useEffect, useState } from "react";
import _ from "underscore";


function ApiHealthCheck() {
    const apiContext = useApiContext();
    const [healthCheck, setHealthCheck] = useState<{message: string} | undefined | null>(undefined);

    useEffect(() => {
        if (!_.isNull(apiContext)) {
            RubberDuckyLabsApi
                .getInstance(apiContext)
                .healthCheck()
                .then((response) => setHealthCheck(response))
                .catch(() => setHealthCheck(null));
        }
        return () => {
            setHealthCheck(undefined);
        }
    }, [apiContext]);

    if (_.isUndefined(healthCheck)) return (<Loader size="sm" />);
    if (_.isNull(healthCheck)) return (<Alert message="API Health Check Failed" onClose={_.noop} />);

    return (
        <Group>
            <ThemeIcon color="green" variant="light"><IconCircleCheck /></ThemeIcon>
            <Text>Successfully authenticated with API</Text>
        </Group>
    );
}


function ConfigurationDisplay() {
    const build = process.env.NODE_ENV;
    const environment = process.env.REACT_APP_RDL_ENV_NAME
    const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

    return (
        <Stack>
            <Group><Text>Create react app build:</Text><Code>{build}</Code></Group>
            <Group><Text>Environment:</Text><Code>{environment}</Code></Group>
            <Group><Text>API Base URL:</Text><Code>{apiBaseURL}</Code></Group>
            <Group><Text>Web Container Version: </Text><Code>Not implemented</Code></Group>
        </Stack>
    )
}


export default function HealthChecksConfigPage() {
    return (
        <Stack>
            <Title order={1}>Health Check: Configuration</Title>
            <Text c="dimmed">View how the current Rubber Ducky Labs deployment is configured</Text>
            <Title order={2}>Configuration</Title>
            <ConfigurationDisplay />
            <Title order={2}>API Health Check</Title>
            <ApiHealthCheck />
        </Stack>
    );
}
