import {
    Accordion,
    Button,
    Group,
    Paper,
    Select,
    Stack,
    Text,
    TextInput,
    Title
} from '@mantine/core'
import { Alert } from '../../components/Alert';
import { EnvTag, envOptions } from '../../components/EnvTag';
import { Link } from 'react-router-dom';
import { Policy } from '../../models/RDLConfig';
import { RubberDuckyLabsApi } from '../../RDLApi';
import { RuleFormValues } from '../../components/forms/rule/rule-form-context';
import { RuleTitle } from '../../components/forms/rule/RuleTitle';
import { useApiContext } from "../../App";
import { useEffect, useMemo, useState } from 'react';
import { useForm } from '@mantine/form';
import _ from 'underscore';

function AccordionLabel(props: { name: string, environments: string[] }) {
    return (
        <Group noWrap>
            <Text>{props.name}</Text>
            {props.environments.map((env) => <EnvTag key={env} env={env} />)}
        </Group>
    );
}

function AccordionContent(props: { values: RuleFormValues }) {
    return (
        <Paper shadow="xs" p="md">
            <RuleTitle rule={props.values} />
        </Paper>
    );
}

export default function PoliciesListPage() {
    const form = useForm({
        initialValues: {
            policyNameSearch: '',
            envFilter: '',
        }
    });

    const apiContext = useApiContext();
    const [policies, setPolicies] = useState<Policy[]>([]);
    const [filteredPolicies, setFilteredPolicies] = useState<Policy[]>([]);
    const [apiError, setApiError] = useState<string>('');
    useEffect(() => {
        if (!_.isNull(apiContext)) {
            RubberDuckyLabsApi.getInstance(apiContext).getPolicies()
                .then((response) => {
                    setPolicies(response);
                })
                .catch((error) => setApiError(error.message));
        }

        return () => {
            setPolicies([]);
        };
    }, [apiContext]);

    useMemo(() => {
        setFilteredPolicies(
            policies.filter((policy) => {
                const nameMatch = form.values.policyNameSearch === '' || policy.name.includes(form.values.policyNameSearch);
                const envMatch = form.values.envFilter === ''
                    || form.values.envFilter === 'all environments'
                    || policy.environments.some(env => env === form.values.envFilter);
                return nameMatch && envMatch;
            })
        );

        return () => {
            setFilteredPolicies([]);
        };
    }, [policies, form.values.policyNameSearch, form.values.envFilter]);

    const shownPolicies = filteredPolicies.map((item) => {
        return (
            <Accordion.Item value={item.name} key={item.id}>
                <Accordion.Control>
                    <AccordionLabel name={item.name} environments={item.environments} />
                </Accordion.Control>
                <Accordion.Panel>
                    <Stack>
                        {item.rules_data.rules.map(rule => <AccordionContent key={rule.key} values={rule} />)}
                        <Link to={item.id.toString()} style={{ textDecoration: 'none' }}>
                            <Button>Show Details</Button>
                        </Link>
                    </Stack>
                </Accordion.Panel>
            </Accordion.Item>
        );
    });

    return (
        <Stack>
            <Title order={1}>Policies Library</Title>
            <Group>
                <TextInput label="Policy Name" {...form.getInputProps('policyNameSearch')} />
                <Select
                    label="Environment"
                    data={envOptions}
                    placeholder="all environments"
                    searchable
                    {...form.getInputProps('envFilter')}
                />
            </Group>
            {!_.isEmpty(apiError) && <Alert onClose={() => setApiError('')} message={apiError} />}
            <Accordion chevronPosition="right" variant="contained">{shownPolicies}</Accordion>
        </Stack>
    );
}
