import * as dataOnboardingTypes from "../../models/data-onboarding-data-types";
import {
    ActionIcon,
    Breadcrumbs,
    Button,
    Card,
    Group,
    LoadingOverlay,
    Skeleton,
    Stack,
    Text,
    Title
} from "@mantine/core"
import { AnswersTable, CreateAnswerModal, EditDatabaseAnswerFormValues, StatusBadge } from "./Components"
import { EditableMarkdown } from "./EditableMarkdown";
import { IconEdit } from "@tabler/icons-react";
import { Link, useParams } from "react-router-dom";
import { RubberDuckyLabsApi } from "../../RDLApi";
import { useApiContext } from "../../App";
import { useDisclosure, useListState } from "@mantine/hooks";
import { useEffect, useState } from "react";
import _ from "underscore";

function PageBreadcrumbs({test, project}: {test?: dataOnboardingTypes.DataOnboardingTestMetadata, project?: dataOnboardingTypes.DataOnboardingProject}) {
    const links = [
        { title: _.isUndefined(project) ? "Loading..." : project.name, to: '..' },
        { title: _.isUndefined(test) ? "Loading..." : test.test.title, to: '.' },
    ];

    const items = links.map((link, index) => (
        <Link to={link.to} key={index}>
            {link.title}
        </Link>
    ));

    return (
        <Breadcrumbs separator={">"}>{items}</Breadcrumbs>
    );
}

const answerTypeToInstructionsMap: Record<dataOnboardingTypes.DataOnboardingTestAnswerType, string> = {
    [dataOnboardingTypes.DataOnboardingTestAnswerType.DATABASE]: "Please add an answer below to show us where to find the data, how to properly query it, and an example response.",
};

function TestDescription({test}: {test?: dataOnboardingTypes.DataOnboardingTestMetadata}) {
    return (
        <Card p="md" radius="md" withBorder>
            <Stack>
                <Title order={2}>Details</Title>
                {_.isUndefined(test) ? <Skeleton h={8} /> : <Text size="sm">{test.test.description}</Text>}
            </Stack>
        </Card>
    );
}

function TestInstructions({test}: {test?: dataOnboardingTypes.DataOnboardingTestMetadata}) {
    return (
        <Card p="md" radius="md" withBorder>
            <Stack>
                <Title order={2}>How to Complete</Title>
                {_.isUndefined(test) ? <Skeleton h={8} /> : <Text size="sm">{answerTypeToInstructionsMap[test.test.answer_type]}</Text>}
            </Stack>
        </Card>
    );
}


export default function DataOnboardingTestsDetailPage() {
    const { testId, projectId } = useParams();
    const [loading, setLoading] = useState<boolean>(true);
    const [project, setProject] = useState<dataOnboardingTypes.DataOnboardingProject | undefined>(undefined);
    const [test, setTest] = useState<dataOnboardingTypes.DataOnboardingTestMetadata | undefined>(undefined);
    const [answers, answersHandlers] = useListState<dataOnboardingTypes.DataOnboardingTestAnswer>([]);
    const [editing, {open, close}] = useDisclosure(false);

    const apiContext = useApiContext();

    useEffect(() => {
        if (!_.isNull(apiContext) && !_.isUndefined(projectId) && !_.isUndefined(testId)) {
            setLoading(true);
            RubberDuckyLabsApi.getInstance(apiContext)
                .getDataOnboardingProject(projectId)
                .then((project) => {
                    setProject(project);
                    return RubberDuckyLabsApi.getInstance(apiContext).getDataOnboardingTestMetadata(project.id, testId)
                })
                .then((test) => {
                    setTest(test);
                    answersHandlers.setState(test.answers);
                })
                .finally(() => setLoading(false));
        }
    }, [apiContext, projectId, testId]);

    const onCreateAnswer = (values: EditDatabaseAnswerFormValues): void => {
        if (_.isUndefined(project) || _.isUndefined(test)) {
            throw new Error("Project or test is not defined");
        }

        if (_.isNull(apiContext)) return;

        RubberDuckyLabsApi.getInstance(apiContext).createDataOnboardingTestAnswer(project.id, test.test.id, {
            type: dataOnboardingTypes.DataOnboardingTestAnswerType.DATABASE,
            ...values,
            database_id: Number.parseInt(values.databaseId),
        }).then(
            () => RubberDuckyLabsApi.getInstance(apiContext).getDataOnboardingTestMetadata(project.id, test.test.id)
        ).then((test) => {
            setTest(test);
            answersHandlers.setState(test.answers);
        });
    }

    const onUpdateComments = (comments: string): void => {
        if (_.isUndefined(project) || _.isUndefined(test)) {
            throw new Error("Project or test is not defined");
        }

        if (_.isNull(apiContext)) return;
        const data: dataOnboardingTypes.DataOnboardingTestMetadataUpdateData = { comments };
        RubberDuckyLabsApi.getInstance(apiContext).updateDataOnboardingTestMetadata(project.id, test.test.id, data).then((test) => setTest(test));
    }

    return (
        <Stack>
            <PageBreadcrumbs test={test} project={project} />
            <LoadingOverlay visible={loading} />
            <Group position="apart">
                <Title order={1}>{_.isUndefined(test) ? <Skeleton h={44} w={300} /> : `${test.test.title}`}</Title>
                <StatusBadge status={test?.status ?? dataOnboardingTypes.DataOnboardingProjectTestStatus.UNDEFINED} />
            </Group>
            <TestDescription test={test} />
            <TestInstructions test={test} />
            <Card p="md" radius="md" withBorder>
                <Stack>
                    <Title order={2}>Answers</Title>
                    <AnswersTable answers={answers} answersHandlers={answersHandlers} />
                    <CreateAnswerModal onCreate={onCreateAnswer} />
                </Stack>
            </Card>
            <Card p="md" radius="md" withBorder>
                <Stack>
                    <Group position="apart">
                        <Title order={2}>Comments</Title>
                        <ActionIcon onClick={open} disabled={editing}>
                            <IconEdit />
                        </ActionIcon>
                    </Group>
                    {_.isUndefined(test)
                        ? <Skeleton h={100} mt={6} radius="xl" />
                        : <EditableMarkdown
                            value={test.comments}
                            onClose={close}
                            onSave={onUpdateComments}
                            editing={editing} />}
                    {(!editing && _.isEmpty(test?.comments)) && <Group><Button onClick={open} variant="outline">Edit comments</Button></Group>}
                </Stack>
            </Card>
        </Stack>
    )
}
