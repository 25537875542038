import { ColumnTypes } from '../../../models/RDLConfig';
import { FilterTypes } from '../../../utilities/filters';
import { MaybeDateRange, isDateRange } from '../../../utilities/date-range'
import { createFormContext } from '@mantine/form';
import { randomId } from '@mantine/hooks';

export enum RuleTypes {
    Shuffle = "shuffle",
    Limit = "limit",
    Filter = "filter",
    Sort = "sort",
    WeightedScore = "weightedscore",
    Policy = "policy",
}

export enum FilterInclusivityType {
    Is = "is",
    IsNot = "is not",
    IsUndefinedOrNull = "is undefined or null",
    IsNotUndefinedOrNull = "is not undefined or null",
}

// TODO(Alexandra): namespace this to RuleFilterData
export interface FilterData {
    columnName: string,
    filterType: FilterTypes | undefined,
    inclusivity: FilterInclusivityType,
    filterValues: {
        booleanData: "true" | "false",
        categoryData: string[],
        numberData: {
            min: number | undefined,
            max: number | undefined,
        },
        dateData: MaybeDateRange
    },
}
interface RuleDataLimit {
    limit: number | undefined,
}
type RuleDataFilter = FilterData;
export interface RuleDataSort {
    columnName: string,
    columnType: ColumnTypes | undefined,
    direction: "Ascending" | "Descending",
}

export interface WeightedScoreColumnWeight {
    key: string,
    columnName: string,
    coefficient: number | undefined,
}

export interface RuleDataWeightedScore {
    weightedscoreName: string,
    columnWeights: WeightedScoreColumnWeight[],
}

export interface RuleDataPolicy {
    policyId: string,
    policyName: string,
    rules: RuleFormValues[],
}

export interface RuleFormValues {
    key: string,
    active: boolean,
    type: RuleTypes | undefined,
    data: {
        limitData: RuleDataLimit,
        filterData: RuleDataFilter,
        sortData: RuleDataSort[],
        weightedscoreData: RuleDataWeightedScore,
        policyData: RuleDataPolicy,
    },
}

type RuleShuffle = RuleFormValues & { type: RuleTypes.Shuffle };
type RuleLimit = RuleFormValues & { type: RuleTypes.Limit };
export type RuleFilter = RuleFormValues & { type: RuleTypes.Filter };
type RuleSort = RuleFormValues & { type: RuleTypes.Sort };
type RuleWeightedScore = RuleFormValues & { type: RuleTypes.WeightedScore };
type RulePolicy = RuleFormValues & { type: RuleTypes.Policy };

export const isRuleFilter = function (rule: RuleFormValues): rule is RuleFilter {
    return rule.type === RuleTypes.Filter;
}

export const isRuleShuffle = function (rule: RuleFormValues): rule is RuleShuffle {
    return rule.type === RuleTypes.Shuffle;
}

export const isRuleLimit = function (rule: RuleFormValues): rule is RuleLimit {
    return rule.type === RuleTypes.Limit;
}

export const isRuleSort = function (rule: RuleFormValues): rule is RuleSort {
    return rule.type === RuleTypes.Sort;
}

export const isRuleWeightedScore = function (rule: RuleFormValues): rule is RuleWeightedScore {
    return rule.type === RuleTypes.WeightedScore;
}
export const isRulePolicy = function (rule: RuleFormValues): rule is RulePolicy {
    return rule.type === RuleTypes.Policy;
}

export const newWeightedScoreColumnWeight = function (): WeightedScoreColumnWeight {
    return ({ columnName: '', coefficient: 1, key: randomId() });
}

export const shouldHideAdditionalFilterInputs = function (filterData: FilterData): boolean {
    return [
        FilterInclusivityType.IsNotUndefinedOrNull,
        FilterInclusivityType.IsUndefinedOrNull
    ].includes(filterData.inclusivity);
}

export const newRule = function (): RuleFormValues {
    // TODO(Alexandra): better handling of undefined
    return {
        key: randomId(),
        active: true,
        type: undefined,
        data: {
            limitData: { limit: undefined },
            filterData: {
                columnName: '',
                filterType: undefined,
                inclusivity: FilterInclusivityType.Is,
                filterValues: {
                    booleanData: "true",
                    categoryData: [],
                    numberData: { min: undefined, max: undefined },
                    dateData: [null, null]
                }
            },
            sortData: [
                {
                    columnName: '',
                    columnType: undefined,
                    direction: 'Ascending',
                }
            ],
            weightedscoreData: {
                weightedscoreName: '',
                columnWeights: [newWeightedScoreColumnWeight(), newWeightedScoreColumnWeight()],
            },
            policyData: {
                policyId: '',
                policyName: '',
                rules: [],
            }
        },
    };
};


export const newRangeFilter = function(range: [number, number], columnName: string): RuleFilter {
    const [min, max] = range;
    const newFilter: RuleFilter = {
        ...newRule(),
        type: RuleTypes.Filter,
    };
    newFilter.data.filterData = {
        ...newFilter.data.filterData,
        inclusivity: FilterInclusivityType.Is,
        columnName: columnName,
        filterType: FilterTypes.NumericalRange,
        filterValues: {
            ...newFilter.data.filterData.filterValues,
            numberData: { min, max }
        },
    }
    return newFilter;
}


export const validateFilterData = {
    columnName: (value: string, values: any) => {
        return values.type === RuleTypes.Filter && value.length === 0 ? 'Please enter a column name' : undefined;
    },
    filterType: (value: any, values: any) => {
        return values.type === RuleTypes.Filter && value === undefined ? 'Please select a filter type' : undefined;
    },
    filterValues: {
        booleanData: (value: any, values: any) => {
            if (shouldHideAdditionalFilterInputs(values.data.filterData)) return undefined;
            if (values.type !== RuleTypes.Filter) return undefined;
            const filterData = values.data.filterData;
            if (filterData.filterType === FilterTypes.Boolean) {
                return undefined;
            }
            return undefined;
        },
        categoryData: (value: any, values: any) => {
            if (shouldHideAdditionalFilterInputs(values.data.filterData)) return undefined;
            if (values.type !== RuleTypes.Filter) return undefined;
            const filterData = values.data.filterData;
            if (filterData.filterType === FilterTypes.ListOfCategories) {
                if (value.length === 0) {
                    return 'Please enter categories';
                }
            }
            return undefined;
        },
        numberData: {
            min: (value: any, values: any) => {
                if (shouldHideAdditionalFilterInputs(values.data.filterData)) return undefined;
                if (values.type !== RuleTypes.Filter) return undefined;
                const filterData = values.data.filterData;
                if (filterData.filterType === FilterTypes.NumericalRange) {
                    if (value === undefined && filterData.filterValues.numberData.max === undefined) {
                        return 'Please enter either a min or a max value';
                    }
                }
                return undefined;
            },
            max: (value: any, values: any) => {
                if (shouldHideAdditionalFilterInputs(values.data.filterData)) return undefined;
                if (values.type !== RuleTypes.Filter) return undefined;
                const filterData = values.data.filterData;
                if (filterData.filterType === FilterTypes.NumericalRange) {
                    if (filterData.filterValues.numberData.min === undefined && value === undefined) {
                        return 'Please enter either a min or a max value';
                    }
                }
                return undefined;
            },
        },
        dateData: (value: any, values: any) => {
            if (shouldHideAdditionalFilterInputs(values.data.filterData)) return undefined;
            if (values.type !== RuleTypes.Filter) return undefined;
            const filterData = values.data.filterData;
            if (filterData.filterType === FilterTypes.DateRange) {
                if (!isDateRange(value)) {
                    return 'Please enter a date range';
                }
            }
        },
    },
};

const validateRuleWeightedScoreData = {
    weightedscoreName: (value: string, values: any) => {
        if(values.type !== RuleTypes.WeightedScore) return undefined;
        if (value.length === 0 || value === 'scores.')
            return 'Please enter a name for the new weighted score';
    },
    columnWeights: {
        columnName: (value: string, values: any) => {
            return values.type === RuleTypes.WeightedScore && value.length === 0 ? 'Please select a column' : undefined;
        },
        coefficient: (value: number | undefined, values: any) => {
            return values.type === RuleTypes.WeightedScore && value === undefined ? 'Please enter a coefficient' : undefined;
        },
    }
}

const validatePolicyData = {
    policyId: (value: string, values: any) => {
        return values.type === RuleTypes.Policy && value.length === 0 ? 'Please enter a policy id' : undefined;
    }
}


export const validateRuleFormValues = {
    type: (value: any) => value === undefined ? 'Please select a rule type' : undefined,
    data: {
        shuffleData: (value: any, values: any) => values.type === RuleTypes.Shuffle ? undefined : undefined,
        limitData: {
            limit: (value: any, values: any) => (
                values.type === RuleTypes.Limit && value === undefined
                    ? 'Please enter a limit'
                    : undefined
            ),
        },
        filterData: validateFilterData,
        sortData: {
            columnName: (value: string, values: any) => {
                return values.type === RuleTypes.Sort && value.length === 0 ? 'Please enter a column name' : undefined;
            }
        },
        weightedscoreData: validateRuleWeightedScoreData,
        policyData: validatePolicyData,
    },
};


export const [RuleFormProvider, useRuleFormContext, useRuleForm] =
    createFormContext<RuleFormValues>();
