import {
    Icon,
    IconAdjustmentsUp,
    IconBook,
    IconCalculator,
    IconChartDots,
    IconDatabase,
    IconFile,
    IconFileDatabase,
    IconHeartRateMonitor,
    IconInfoCircle,
    IconList,
    IconSchema,
    IconSettings,
    IconSettingsCode,
    IconShoppingBag,
    IconSkull,
    IconSparkles,
    IconTestPipe,
    IconUser,
    IconUserCog
} from '@tabler/icons-react'
import { RDLConfigType } from './models/RDLConfig';


export interface MainLink {
    label: string,
    icon: Icon,
    linkTo: string,
    color: string,
    description?: string,
    children?: MainLink[],
    siteAdminOnly?: boolean,
    requiresConfig?: boolean,
}


const MAIN_LINKS: MainLink[] = [
    {
        "label": "Data Onboarding",
        "description": "Upload and manage your RecSys data.",
        icon: IconFileDatabase,
        linkTo: "/data-onboarding",
        color: "cyan",
        children: [
            {
                label: "Projects",
                description: "Manage your data onboarding projects.",
                icon: IconFile,
                linkTo: "/data-onboarding/projects",
                color: "cyan",
            },
            {
                label: "Databases",
                description: "Connect your databases.",
                icon: IconDatabase,
                linkTo: "/data-onboarding/databases",
                color: "cyan",
            },
            {
                label: "Tests",
                description: "Admins can create and manage data onboarding tests.",
                icon: IconSparkles,
                linkTo: "/data-onboarding/tests",
                color: "cyan",
                siteAdminOnly: true,
            }
        ]
    },
    {
        label: "Calculators",
        description: "Calculator utilities for common RecSys tasks",
        icon: IconCalculator,
        linkTo: "/calculators",
        color: "orange",
        children: [
            {
                label: "A/B Test Calculator",
                description: "Pre and post test analysis for A/B tests",
                icon: IconTestPipe,
                linkTo: "/calculators/ab-test",
                color: "orange",
            },
        ],
    },
    {
        label: "Data Explore",
        description: "Visually explore your RecSys events.",
        icon: IconChartDots,
        linkTo: "/data-explore",
        color: "green",
        requiresConfig: true,
        children: [
            {
                label: "Data Explore",
                description: "Visually explore your RecSys events.",
                icon: IconChartDots,
                linkTo: "/data-explore/freeform",
                color: "green",
            },
            {
                label: "Items by Metric",
                description: "View the top items by a specific metric.",
                icon: IconChartDots,
                linkTo: "/data-explore/items-by-metric",
                color: "green",
            }
        ]
    },
    {
        label: "Health Checks",
        description: "Monitor the health of your data connections.",
        icon: IconHeartRateMonitor,
        linkTo: "/health-checks",
        color: "lime",
        requiresConfig: true,
        children: [
            {
                label: "Metrics",
                description: "Check that all user interaction data is correctly configured.",
                icon: IconHeartRateMonitor,
                linkTo: "metrics",
                color: "lime",
            },
            {
                label: "Configuration",
                description: "View how the current Rubber Ducky Labs deployment is configured.",
                icon: IconSettingsCode,
                linkTo: "config",
                color: "lime",
            },
        ],
    },
    {
        label: "Users",
        description: "Drilldown into user behavior.",
        icon: IconUser,
        linkTo: "/users",
        color: "yellow",
        requiresConfig: true,
    },
    {
        label: "Pipelines",
        description: "View your ranking pipelines.",
        icon: IconSchema,
        linkTo: "/pipelines",
        color: "pink",
        requiresConfig: true,
    },
    {
        label: "Product Catalog",
        description: "Explore your product catalog.",
        icon: IconBook,
        linkTo: "/product-catalog",
        color: "grape",
        requiresConfig: true,
        children: [
            {
                label: "Product Catalog Items",
                description: "Browse all of the items in your product catalog.",
                icon: IconShoppingBag,
                linkTo: "items",
                color: "grape",
            },
            {
                label: "Product Catalog Fields",
                description: "Explore all of the item attributes in your product catalog.",
                icon: IconList,
                linkTo: "fields",
                color: "grape",
            },
        ]
    },
    {
        label: "Data FAQ",
        description: "Knowledge hub for your data in Rubber Ducky Labs.",
        icon: IconInfoCircle,
        linkTo: "/data-faq",
        requiresConfig: true,
        color: "gray",
    },
    {
        label: "Dead Pages",
        siteAdminOnly: true,
        description: "Unused pages that might be useful in the future.",
        icon: IconSkull,
        linkTo: "/dead-pages",
        color: "gray",
        requiresConfig: true,
        children: [
            {
                label: "Positional Analysis",
                description: "View aggregate metrics about performance by position on page or current page.",
                icon: IconAdjustmentsUp,
                linkTo: "positional-analysis",
                color: "gray",
            }
        ]
    },
    {
        label: "Site Admin",
        siteAdminOnly: true,
        description: "Manage your Rubber Ducky Labs deployment.",
        icon: IconSettings,
        linkTo: "/site-admin",
        color: "blue",
        children: [
            {
                label: "Users and Organizations",
                description: "Manage RDL users and organizations",
                icon: IconUserCog,
                linkTo: "users-and-organizations",
                color: "blue",
            }
        ]
    }
];


export function mainLinks(isSiteAdmin: boolean, config: RDLConfigType): MainLink[] {
    const isValidConfig = config.loaded && !config.error;
    const filterLink = (link: MainLink) => (!link.siteAdminOnly || isSiteAdmin) && (!link.requiresConfig || isValidConfig);

    return (
        MAIN_LINKS
            .filter(filterLink)
            .map(link => ({
                ...link,
                children: link.children?.filter(filterLink)
            }))
    );
}
