import * as mlModelTypes from "../../models/ml-model-data-types";
import * as rdlTypes from "../../models/RDLDataTypes";
import * as statsTypes from "../../models/StatsDataTypes";
import { Alert } from "../../components/Alert";
import {
    Button,
    Group,
    Loader,
    LoadingOverlay,
    Modal,
    Stack,
    Tabs,
    Text,
    Title,
    useMantineTheme
} from "@mantine/core"
import { DraggableSegments } from "../../components/DraggableSegments";
import { EmbeddingsChart } from "../../components/charts/EmbeddingsChart"
import { EventName } from "../../utilities/metric-calculations/metrics";
import { IconChartBar } from "@tabler/icons-react"
import { InView } from "react-intersection-observer";
import { Metric } from "../health-checks/HealthChecksMetricsPage";
import { NO_SEGMENT, UserSegment } from "../../models/user-segments";
import { OnAddUsersToSegmentType, OnCreateSegmentType } from "../../components/forms/user-segment/user-segment-form-context";
import { RubberDuckyLabsApi } from "../../RDLApi";
import { SegmentsChart } from "../../components/charts/SegmentsChart";
import { SegmentsMetricChart } from "../../components/charts/SegmentsMetricChart";
import { getEventNameStr } from "../../components/EventIconMapping";
import { isUndefinedOrNull } from "../../utilities/types";
import { isUserSignalEventType } from "../../utilities/events";
import { randomId, useDisclosure, useListState } from "@mantine/hooks";
import { useApiContext, useRDLConfig } from "../../App";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CreateUserSegmentForm from "../../components/forms/user-segment/CreateUserSegmentForm";
import _ from "underscore";

import { UsersTableFromIds } from "../../components/UsersTable";
import { createNewSegments } from "../../utilities/user-segments";


function CreateUserSegmentButton({ userIds, onCreateSegment, onAddUsersToSegment }: { userIds: string[], onCreateSegment: OnCreateSegmentType, onAddUsersToSegment: OnAddUsersToSegmentType }) {
    const [opened, { open, close }] = useDisclosure(false);

    return (
        <>
            <Modal opened={opened} onClose={close} title={`Create a new segment from ${userIds.length} users`}>
                <CreateUserSegmentForm userIds={userIds} onCreateSegment={onCreateSegment} onAddUsersToSegment={(userSegmentId, userIds) => {
                    close();
                    onAddUsersToSegment(userSegmentId, userIds);
                }} />
            </Modal>
            <Button color="blue" variant="light" onClick={open} >
                    Add users to segment
            </Button>
        </>
    )
}


interface SegmentsChartsForEventsProps {
    segments: UserSegment[],
    countUsers: number,
    countUsersWithoutSegment: number,
}

function SegmentsEventsTab({ segments, metric }: { metric: Metric, segments: UserSegment[] }) {
    const apiContext = useApiContext();
    const [segmentData, setSegmentData] = useState<Record<rdlTypes.Id, number> | undefined | null>();
    const [loadData, setLoadData] = useState(false);

    useEffect(() => {
        if (loadData && !_.isNull(apiContext)) {
            RubberDuckyLabsApi
                .getInstance(apiContext)
                // TODO (Alexandra): get rid of hardocded dates
                .getEventStatistics(metric.eventType, new Date(2021, 1, 1), new Date(), statsTypes.StatisticsTimescaleValue.ALL, ['users.segment'], undefined, segments.length)
                .then(({data}) => {
                    const eventsBySegment = _.chain(data)
                        .indexBy((d) => d.aggregation_value["users.segment"] ?? NO_SEGMENT)
                        .mapObject((v) => v.func_value.count)
                        .value();
                    const newSegmentData = _.chain(segments)
                        .map(({key, countUsers}): [string, number] => [key, (key in eventsBySegment ? eventsBySegment[key] : 0) / countUsers])
                        .object()
                        .value();
                    setSegmentData(newSegmentData);
                })
                .catch(() => setSegmentData(null));
        }
    }, [apiContext, metric, loadData, segments]);

    if (_.isNull(segmentData)) {
        return <Alert message="Failed to load data" onClose={_.noop} />;
    } else if (_.isUndefined(segmentData)) {
        return (
            <>
                <Loader />
                <InView onChange={(inView) => inView && setLoadData(true)} />
            </>
        );
    }

    return (
        <SegmentsMetricChart
            metric={metric}
            segments={segments.filter(({key}) => key !== NO_SEGMENT)}
            segmentData={segmentData}/>
    )
}

function SegmentsChartsForEvents({segments, countUsersWithoutSegment, countUsers}: SegmentsChartsForEventsProps) {
    const config = useRDLConfig();

    const metrics: Metric[] = useMemo(() => config.metricTypes
        .filter((eventType) => isUserSignalEventType(eventType))
        .map((eventType) => ({
            key: randomId(),
            eventType: eventType,
            eventName: rdlTypes.EventType[eventType] as EventName,
            displayName: getEventNameStr(eventType),
        })), [config]);

    return (
        <Tabs defaultValue="count" keepMounted={false}>
            <Tabs.List>
                {<Tabs.Tab
                    value="count"
                    icon={<IconChartBar size="0.8rem" />}>
                    <Text tt="capitalize">Count</Text>
                </Tabs.Tab>}
                {metrics.map(({key, displayName}) => (
                    <Tabs.Tab
                        key={key}
                        value={key}
                        icon={<IconChartBar size="0.8rem" />}><Text tt="capitalize">{displayName}</Text>
                    </Tabs.Tab>
                ))}
            </Tabs.List>
            <Tabs.Panel value="count" pt="xs">
                {<SegmentsChart countUsers={countUsers} countUsersWithoutSegment={countUsersWithoutSegment} segments={segments} />}
            </Tabs.Panel>
            {metrics.map((metric) => (
                <Tabs.Panel key={metric.key} value={metric.key} pt="xs">
                    <SegmentsEventsTab
                        metric={metric}
                        segments={segments}
                    />
                </Tabs.Panel>
            ))}
        </Tabs>
    )
}


function ModelInfo({ model }: { model: mlModelTypes.MLModel | null | undefined }) {
    if (_.isNull(model)) {
        return <Alert message={"Failed to load model"} />;
    } else if (_.isUndefined(model)) {
        return <Loader />;
    }

    return (
        <Group>
            <Text weight={500} span>{model.name}</Text>
            <Text c="dimmed" span>{model.name} {model.description}</Text>
        </Group>
    )
}


export default function UserSegmentsPage() {
    const apiContext = useApiContext();
    const theme = useMantineTheme();

    const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
    const [userSegmentMembership, setUserSegmentMembership] = useListState<rdlTypes.UserSegmentMembership>([]);
    const [visualization, setVisualization] = useState<{user_id: string, x: number, y: number}[] | null | undefined>();
    const [userSegments, setUserSegments] = useListState<UserSegment>([]);
    const [loadingSegments, setLoadingSegments] = useState(false);
    const [visualizationModel, setVisualizationModel] = useState<mlModelTypes.MLModel | null | undefined>();
    const [searchParams] = useSearchParams();
    const visualizationModelId = parseInt(searchParams.get("visualizationModelId") ?? "1");

    useEffect(() => {
        if (!_.isNull(apiContext) && !_.isUndefined(visualizationModelId)) {
            RubberDuckyLabsApi
                .getInstance(apiContext)
                .getMLModel(visualizationModelId)
                .then((model) => setVisualizationModel(model))
                .catch(() => setVisualizationModel(null));

            RubberDuckyLabsApi
                .getInstance(apiContext)
                .getAllMLModelData(visualizationModelId, undefined, 20000)
                .then((result) => {
                    const newVisualization = result.map(({data}) => ({user_id: data.user_id, x: data.x, y: data.y }));
                    setVisualization(newVisualization);
                })
                .catch(() => setVisualization(null));
        } else if (_.isNaN(visualizationModelId)) {
            setVisualizationModel(undefined);
            setVisualization(undefined);
        }
    }, [apiContext, visualizationModelId]);

    useEffect(() => {
        if (!_.isNull(apiContext)) {
            setLoadingSegments(true);
            RubberDuckyLabsApi
                .getInstance(apiContext)
                .listUserSegmentsMetadata()
                .then(({data}) => {
                    const newSegments = createNewSegments(data, userSegments, theme);
                    setUserSegments.append(...newSegments);
                    return _.pluck(newSegments, 'key').filter((key) => key !== NO_SEGMENT);
                })
                .then((segmentKeys) => RubberDuckyLabsApi.getInstance(apiContext).listUsersInSegments(segmentKeys))
                .then(({data}) => setUserSegmentMembership.append(...data))
                .finally(() => setLoadingSegments(false));
        }
    }, [apiContext]);

    const onDeleteSegment = (index: number, segment: UserSegment) => {
        setUserSegments.remove(index);
        setUserSegmentMembership.filter(({segment_id}) => segment_id !== segment.key);
        if (!_.isNull(apiContext)) {
            RubberDuckyLabsApi
                .getInstance(apiContext)
                .removeAllUsersFromSegment(segment.key)
                .then(() => RubberDuckyLabsApi.getInstance(apiContext).deleteUserSegment(segment.key));
        }

    }

    const onAddUsersToSegment: OnAddUsersToSegmentType = (userSegmentId, userIds) => {
        if (_.isNull(apiContext)) {
            return;
        }
        setLoadingSegments(true);
        RubberDuckyLabsApi
            .getInstance(apiContext)
            .addUsersToSegment(userSegmentId, userIds)
            .then(() => setUserSegmentMembership.append(...userIds.map((userId) => ({user_id: userId, segment_id: userSegmentId}))))
            .then(() => {
                // TODO(Alexandra): this isn't exactly correct because we don't know if the user was already in the segment
                setUserSegments.applyWhere(
                    (segment) => (segment.key === userSegmentId),
                    (segment) => ({...segment, countUsers: segment.countUsers + userIds.length})
                );
            })
            .then(() => setLoadingSegments(false));
    }

    const userIdToSegmentMap: Record<string, rdlTypes.Id> = useMemo(() => {
        const hiddenSegmentsMap = _.chain(userSegments)
            .indexBy('key')
            .mapObject(({hidden}) => hidden)
            .value();

        return _.chain(userSegmentMembership)
            .groupBy(({user_id}) => user_id)
            .mapObject((memberships) => {
                const visibleMemberships = memberships.filter(({segment_id}) => !hiddenSegmentsMap[segment_id]);
                if (_.isEmpty(visibleMemberships)) {
                    return NO_SEGMENT;
                }
                return visibleMemberships[0].segment_id;
            })
            .value();
    }, [userSegmentMembership, userSegments]);


    const embeddingsChart = useMemo(() => {
        if (_.isUndefined(visualization) || _.isUndefined(userSegments)) {
            return <Loader />;
        }

        if (_.isNull(visualization) || _.isNull(userSegments)) {
            return <Alert message={"Failed to load embeddings or segments"} onClose={_.noop} />;
        }

        return <EmbeddingsChart
            data={visualization}
            segments={userSegments.filter(({hidden}) => !hidden)}
            userIdToSegmentMap={userIdToSegmentMap}
            onSelect={(event: MouseEvent, chartElements: any[]) => {
                const newSelectedUserIds = chartElements.map(({element}) => element.$context.raw.user_id);
                setSelectedUserIds(newSelectedUserIds);
            }}
        />
    }, [visualization, userSegments, userIdToSegmentMap, setSelectedUserIds]);

    const hideAllSegments = () => {
        setUserSegments.applyWhere(
            ({key}) => key !== NO_SEGMENT,
            (segment) => ({...segment, hidden: true})
        );
    }

    const countUsersWithoutSegment = useMemo(() => {
        const usersInVisualizations = new Set(visualization?.map(({user_id}) => user_id));
        const usersInSegments = new Set(userSegmentMembership.map(({user_id}) => user_id));
        const usersInVisualizationWithoutSegment = new Set();
        usersInVisualizations.forEach((userId) => {
            if (!usersInSegments.has(userId)) {
                usersInVisualizationWithoutSegment.add(userId);
            }
        });
        return usersInVisualizationWithoutSegment.size;
    }, [userSegmentMembership, visualization]);

    const removeUserFromSegment = (userId: string, segmentKey: rdlTypes.Id) => {
        if (!_.isNull(apiContext)) {
            RubberDuckyLabsApi
                .getInstance(apiContext)
                .removeUserFromSegment(segmentKey, userId)
                .then(() => {
                    setUserSegmentMembership.filter(({user_id, segment_id}) => !(user_id === userId && segment_id === segmentKey));
                    setUserSegments.applyWhere(
                        (segment) => (segment.key === segmentKey),
                        (segment) => ({...segment, countUsers: segment.countUsers - 1})
                    );
                });
        }
    }

    return (
        <Stack>
            <LoadingOverlay visible={loadingSegments} />
            <Title order={1}>User Segments Explorer</Title>
            <ModelInfo model={visualizationModel} />
            <Group>
                <Button onClick={hideAllSegments}>Hide all segments</Button>
            </Group>
            <DraggableSegments segments={userSegments} segmentsHandlers={setUserSegments} onDeleteSegment={onDeleteSegment} />
            <Title order={2}>Stats about Segments</Title>
            {(isUndefinedOrNull(visualization) || isUndefinedOrNull(userSegments))
                ? (_.isUndefined(visualization) || _.isUndefined(userSegments))
                    ? <Loader />
                    : <Alert message={"Failed to load visualization or segments"} />
                : <SegmentsChartsForEvents segments={userSegments.filter(({hidden}) => !hidden)} countUsers={visualization.length} countUsersWithoutSegment={countUsersWithoutSegment} />}
            <Title order={2}>Embeddings by Segment</Title>
            {embeddingsChart}
            <Group>
                <Title order={2}>Selected Users ({selectedUserIds.length})</Title>
                <CreateUserSegmentButton
                    userIds={selectedUserIds}
                    onAddUsersToSegment={onAddUsersToSegment}
                    onCreateSegment={(userSegmentMetadata) => {
                        const newSegments = createNewSegments([userSegmentMetadata], userSegments, theme);
                        setUserSegments.append(...newSegments);
                    }}
                />
            </Group>
            <UsersTableFromIds
                userIds={selectedUserIds}
                userSegmentMemberships={userSegmentMembership}
                userSegments={userSegments}
                removeUserFromSegment={removeUserFromSegment}
                loadingSegments={loadingSegments}
            />
        </Stack>
    );
}
