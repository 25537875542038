import * as rdlTypes from "../models/RDLDataTypes";
import {
    Box,
    JsonInput,
    Stack,
    Text,
    Title
} from "@mantine/core"
import { PipelineStage } from "../utilities/ranked-cards";
import { useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import PipelineChart from "../components/charts/PipelineChart";

const DEFAULT_PIPELINE: PipelineStage[] = [
    {
        "label": "Stage 1",
        "type": rdlTypes.RankingPipelineStageType.CandidateGeneration,
        "cards": [
            { id: 1 },
            { id: 2 },
            { id: 3 },
            { id: 4 },
            { id: 5 },
        ]
    },
    {
        "label": "Stage 2",
        "type": rdlTypes.RankingPipelineStageType.CandidateGeneration,
        "cards": [
            { id: 1 },
            { id: 3 },
            { id: 6 },
            { id: 7 },
            { id: 8 },
        ]
    },
    {
        "label": "Stage 3",
        "type": rdlTypes.RankingPipelineStageType.RankedList,
        "cards": [
            { id: 6 },
            { id: 7 },
            { id: 8 },
            { id: 1 },
            { id: 3 },
            { id: 4 },
            { id: 5 },
            { id: 2 },
        ]
    }
];

export default function ChartsTestPage() {
    const [selectedItems, setSelectedItems] = useState<rdlTypes.Id[]>([]);

    const [pipeline, setPipeline] = useState<PipelineStage[]>([]);

    const form = useForm({
        initialValues: {
            json: '',
        },
    });

    useEffect(() => {
        form.setValues({ json: window.localStorage.getItem('pipelineJSON') ?? JSON.stringify({ pipeline: DEFAULT_PIPELINE }, null, 2) });
    }, []);

    useEffect(() => {
        try {
            setPipeline(JSON.parse(form.values.json).pipeline);
            window.localStorage.setItem('pipelineJSON', form.values.json);
        } catch (e) {
            console.log(e);
        }
    }, [form.values.json]);

    return (
        <Stack>
            <Title order={1}>Charts test page</Title>
            <Text c="dimmed">Test out new charts</Text>
            <Box h="400px" w="100%">
                <PipelineChart
                    pipeline={pipeline}
                    selectedItems={selectedItems}
                    onClickItem={(id) => setSelectedItems([id])}
                    pipelineStageForColors={pipeline.at(-1)}
                />
            </Box>
            <form onSubmit={form.onSubmit((values) => console.log(values))}>
                <JsonInput
                    label="JSON"
                    withAsterisk={form.isDirty('json')}
                    {...form.getInputProps('json')}
                    formatOnBlur
                    autosize
                />
            </form>
        </Stack>
    )
}
