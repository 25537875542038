import {
    Button,
    Group,
    JsonInput,
    Modal,
    MultiSelect,
    Select,
    Stack,
    Text,
    TextInput
} from "@mantine/core"
import { HelpAlert, HelpIcon } from "../../components/Help";
import {
    TestDatasetsFormProvider,
    TestDatasetsFormValues,
    newTestDataset,
    useTestDatasetsForm,
    validateTestDatasetsFormValues
} from "./test-dataset-form-context"
import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import ReactJson from "react-json-view";

function CreateTestDatasetsForm(props: {
    onSubmit: (values: TestDatasetsFormValues) => void,
    onRemove: () => void
}) {
    const form = useTestDatasetsForm({
        initialValues: newTestDataset(),
        validate: validateTestDatasetsFormValues,
    });

    const [tags, setTags] = useState([
        { value: "ranking", label: "ranking" },
    ]);
    const [opened, { close, toggle }] = useDisclosure(true);
    const exampleJsonData = {
        "item_data": [
            {

                "item_id": "ABC1234",
                "scores": {
                    "ranking_score": 0.5,
                    "other_score": 0.4,
                }
            }
        ],
        "additional_data_field0": "",
        "additional_data_field1": "",
    }

    return (
        <TestDatasetsFormProvider form={form}>
            <form onSubmit={form.onSubmit(props.onSubmit)}>
                <Stack>
                    <TextInput
                        withAsterisk
                        label="Name"
                        placeholder="Name"
                        {...form.getInputProps("name")}
                    />
                    <Group>
                        <Select
                            withinPortal
                            withAsterisk
                            label="Environment"
                            data={['dev', 'staging', 'prod']}
                            {...form.getInputProps("environment")}
                        />
                        <MultiSelect
                            withinPortal
                            creatable
                            searchable
                            label="Tags"
                            data={tags}
                            {...form.getInputProps("tags")}
                            getCreateLabel={(query) => `+ Create ${query}`}
                            onCreate={(query) => {
                                const tag = { value: query, label: query };
                                setTags((previousTags) => [...previousTags, tag]);
                                return tag;
                            }}
                        />
                    </Group>
                    <HelpAlert
                        title="What format should my data be in?"
                        message={
                            <Stack>
                                <Text>
                                    Your data should be in JSON format. Make sure to include `item_data` in the following format:
                                </Text>
                                <ReactJson
                                    src={exampleJsonData}
                                    name={false}
                                    collapsed={false}
                                    displayDataTypes={false}
                                    displayObjectSize={false}
                                />
                            </Stack>
                        }
                        opened={opened}
                        close={close}
                    />
                    <JsonInput
                        label="JSON Data"
                        placeholder="Insert your JSON data here"
                        validationError="Invalid JSON"
                        withAsterisk
                        autosize
                        minRows={5}
                        formatOnBlur
                        {...form.getInputProps("jsonData")}
                    />
                    <HelpIcon toggle={toggle} />
                    <Group position="right">
                        <Button variant="light" color="gray" onClick={props.onRemove}>Discard</Button>
                        <Button type="submit">Create</Button>
                    </Group>
                </Stack>
            </form>
        </TestDatasetsFormProvider>
    )
}


export default function CreateTestDatasetModal(props: {
    onSubmit: (values: TestDatasetsFormValues) => void,
}) {
    const [opened, { close, open }] = useDisclosure(false);
    const onSubmit = function (values: TestDatasetsFormValues) {
        props.onSubmit(values);
        close();
    }

    return (<>
        <Modal
            opened={opened}
            onClose={close}
            title="Create a Test Dataset"
            size="75%"
        >
            <CreateTestDatasetsForm
                onSubmit={onSubmit}
                onRemove={close}
            />
        </Modal>
        <Group>
            <Button onClick={open}>Add a Test Dataset</Button>
        </Group>
    </>)

}
