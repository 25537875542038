import { MultiSelect, SelectItem } from "@mantine/core";
import { SearchableCategories, getSearchableCategoriesFromItemsAndCards } from "../../../utilities/categories";
import { useApiContext, useItems, useLoadAndCacheItems } from "../../../App";
import { useCards, useColumns } from "../../../context/ColumnContext";
import { useEffect, useMemo, useState } from "react";
import { useRuleFormContext } from "./rule-form-context";

export function ListOfCategoriesInput() {
    const form = useRuleFormContext();

    const apiContext = useApiContext();
    const items = useItems();
    const loadAndCacheItems = useLoadAndCacheItems();
    useEffect(() => {
        loadAndCacheItems(apiContext);
    }, [apiContext]);

    const columns = useColumns();
    const cards = useCards();

    const searchableCategories: SearchableCategories = useMemo(
        () => getSearchableCategoriesFromItemsAndCards(columns, items, cards),
        [columns, items, cards],
    );

    const [multiSelectData, setMultiSelectData] = useState<(string | SelectItem)[]>([]);

    useEffect(() => {
        const columnName = form.values.data.filterData.columnName;
        const categoryOptions = columnName in searchableCategories ? searchableCategories[columnName] : [];
        const selectedCategories = form.values.data.filterData.filterValues.categoryData
            .filter((category) => !categoryOptions.includes(category));
        const newMultiSelectData = categoryOptions.concat(selectedCategories);
        setMultiSelectData(newMultiSelectData);
    }, [form.values.data.filterData.columnName, searchableCategories]);

    return (
        <MultiSelect
            withinPortal
            label="Values"
            data={multiSelectData}
            placeholder="values"
            searchable
            creatable
            getCreateLabel={(query) => `+ Create ${query}`}
            onCreate={(query) => {
                setMultiSelectData((current) => [...current, query]);
                return query;
            }}
            shouldCreate={(query, data) => !data.some((selected) => selected.value === query)}
            {...form.getInputProps('data.filterData.filterValues.categoryData')}
        />
    );
}
