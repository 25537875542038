import * as dateFNS from "date-fns";
import { UserFilter } from "../components/forms/user-filters/user-filters-form-context";
import { UserFilterTypes } from "../models/userFilter";
import { isDate } from "./date-range";
import _ from "underscore";


export function dateToAPIParams(date: Date): string {
    return dateFNS.lightFormat(date, 'yyyy-MM-dd');
}


export function aggregationsToApiParams(aggregations: string[] | undefined): string {
    return _.isUndefined(aggregations) ? '' : aggregations.join(' ');
}


function dateRangeToAPIParams(userFilter: UserFilter) {
    const [start, end] = userFilter.data.dateRange;
    return {
        start_date: isDate(start) ? dateToAPIParams(start) : undefined,
        end_date: isDate(end) ? dateToAPIParams(dateFNS.addDays(end, 1)) : undefined,
    };
}


function multiSelectToAPIParams(userFilter: UserFilter) {
    return {
        selected_categories: userFilter.data.listOfCategories,
    };
}


function numericalRangeToAPIParams(userFilter: UserFilter) {
    return userFilter.data.numericalRange;
}


function booleanToApiParams(userFilter: UserFilter) {
    return userFilter.data.boolean;
}


function userFilterToAPIParams(userFilter: UserFilter) {
    switch (userFilter.type) {
    case UserFilterTypes.DateRange:
        return dateRangeToAPIParams(userFilter);
    case UserFilterTypes.MultiSelect:
        return multiSelectToAPIParams(userFilter);
    case UserFilterTypes.NumericalRange:
        return numericalRangeToAPIParams(userFilter);
    case UserFilterTypes.Boolean:
        return booleanToApiParams(userFilter);
    }
}

export function userFiltersToJSONPostData(userFilters: UserFilter[]): Record<string, any> {
    return Object.fromEntries(
        userFilters
            .map((userFilter): [string, any] => (
                [userFilter.name, userFilterToAPIParams(userFilter)]
            ))
            .filter(([, value]) => !_.isUndefined(value))
    );
}
