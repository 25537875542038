import {
    Box,
    Button,
    Group,
    MultiSelect,
    NumberInput,
    SegmentedControl,
    SimpleGrid,
    Stack,
    Text,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { IconCalendar } from "@tabler/icons-react";
import { UserFilterConfig, UserFilterTypes } from "../../../models/userFilter";
import { UserFiltersFormProvider, UserFiltersFormValues, useUserFiltersForm, useUserFiltersFormContext } from './user-filters-form-context'
import { useEffect } from "react";
import { useRDLConfig } from '../../../App';
import SegmentPicker from "../../users/SegmentPicker";

function toDisplayName(userFilterName: string) {
    return userFilterName
        .split("_")
        .filter(x => x.length > 0)
        .map((x) => (x.charAt(0).toUpperCase() + x.slice(1)))
        .join(" ");
}

interface UserFilterPropsType {
    userFilterConfig: UserFilterConfig,
    disabled?: boolean,
    index: number,
}

function MultiSelectUserFilter(props: UserFilterPropsType) {
    const form = useUserFiltersFormContext();
    const data = props.userFilterConfig.data.multiSelectOptions.map(
        ({name, group}) => ({ value: name, label: name, group })
    );

    return (
        <MultiSelect
            withAsterisk={form.isDirty(`userFilters.${props.index}.data.listOfCategories`)}
            disabled={props.disabled ?? false}
            label={toDisplayName(props.userFilterConfig.filterName)}
            placeholder="All users"
            data={data}
            {...form.getInputProps(`userFilters.${props.index}.data.listOfCategories`)}
        />
    );
}

function DateRangeUserFilter(props: UserFilterPropsType) {
    const form = useUserFiltersFormContext();

    return (
        <DatePickerInput
            type="range"
            icon={<IconCalendar size="1.1rem" stroke={1.5} />}
            withAsterisk={form.isDirty(`userFilters.${props.index}.data.dateRange`)}
            disabled={props.disabled ?? false}
            label={toDisplayName(props.userFilterConfig.filterName)}
            placeholder="All users"
            allowSingleDateInRange
            {...form.getInputProps(`userFilters.${props.index}.data.dateRange`)}
            maxDate={new Date()}
            clearable
        />
    );
}

function NumericalRangeUserFilter(props: UserFilterPropsType) {
    const form = useUserFiltersFormContext();
    const isDirty = (
        form.isDirty(`userFilters.${props.index}.data.numericalRange.min`)
        || form.isDirty(`userFilters.${props.index}.data.numericalRange.max`)
    )

    return (
        <Box sx={{ width: '12em' }}>
            <Stack spacing={3}>
                <Group spacing={3}>
                    <Text size="sm" weight={500}>{toDisplayName(props.userFilterConfig.filterName)}</Text>
                    {isDirty && <Text size="sm" color="red">*</Text>}
                </Group>
                <SimpleGrid cols={2}>
                    <NumberInput
                        disabled={props.disabled ?? false}
                        hideControls
                        // styles={{ input: { width: '6em' } }}
                        placeholder="min"
                        step={1}
                        precision={2}
                        max={form.values.userFilters[props.index].data.numericalRange.max}
                        {...form.getInputProps(`userFilters.${props.index}.data.numericalRange.min`)}
                    />
                    <NumberInput
                        disabled={props.disabled ?? false}
                        hideControls
                        // styles={{ input: { width: '6em' } }}
                        placeholder="max"
                        step={1}
                        precision={2}
                        min={form.values.userFilters[props.index].data.numericalRange.min}
                        {...form.getInputProps(`userFilters.${props.index}.data.numericalRange.max`)}
                    />
                </SimpleGrid>
            </Stack>
        </Box>
    )
}


function BooleanUserFilter(props: UserFilterPropsType) {
    const { index, userFilterConfig } = props;
    const form = useUserFiltersFormContext();
    const isDirty = form.isDirty(`userFilters.${index}.data.boolean`);
    const value = (() => {
        switch(form.values.userFilters[index].data.boolean) {
        case true:
            return "True";
        case false:
            return "False";
        default:
            return "Any";
        }
    })();
    const onChange = (value: string) => {
        switch(value) {
        case "True":
            form.setFieldValue(`userFilters.${index}.data.boolean`, true);
            break;
        case "False":
            form.setFieldValue(`userFilters.${index}.data.boolean`, false);
            break;
        default:
            form.setFieldValue(`userFilters.${index}.data.boolean`, undefined);
            break;
        }
    }

    return (
        <Stack spacing={3}>
            <Group spacing={3}>
                <Text size="sm" weight={500}>{toDisplayName(userFilterConfig.filterName)}</Text>
                {isDirty && <Text size="sm" color="red">*</Text>}
            </Group>
            <SegmentedControl
                disabled={props.disabled ?? false}
                value={value}
                onChange={onChange}
                data={["True", "False", "Any"]}
            />
        </Stack>
    );
}


function UserFilterListItem({userFilterConfig, index}: { userFilterConfig: UserFilterConfig, index: number }) {
    switch (userFilterConfig.filterType) {
    case UserFilterTypes.DateRange:
        return (
            <DateRangeUserFilter
                userFilterConfig={userFilterConfig}
                index={index}
            />
        );
    case UserFilterTypes.MultiSelect:
        return (
            <MultiSelectUserFilter
                userFilterConfig={userFilterConfig}
                index={index}
            />
        );
    case UserFilterTypes.NumericalRange:
        return (
            <NumericalRangeUserFilter
                userFilterConfig={userFilterConfig}
                index={index}
            />
        );
    case UserFilterTypes.Boolean:
        return (
            <BooleanUserFilter
                userFilterConfig={userFilterConfig}
                index={index}
            />
        );
    }
}


export function UserFiltersForm(props: {
    values: UserFiltersFormValues,
    onSubmit: (values: UserFiltersFormValues) => void,
}) {
    const form = useUserFiltersForm({ initialValues: { segment: null, userFilters: [] } });
    const config = useRDLConfig();

    useEffect(() => {
        form.setValues(props.values);
        form.resetDirty();
        form.resetTouched();
    }, [props.values]);

    const userFilters = config.userFilters.map((userFilterConfig) => {
        const filterName = userFilterConfig.filterName;
        const index = form.values.userFilters.findIndex(({name}) => name === filterName);
        if (index === -1) return undefined;
        return <UserFilterListItem
            key={userFilterConfig.filterName}
            userFilterConfig={userFilterConfig}
            index={index}
        />
    });
    return (
        <UserFiltersFormProvider form={form}>
            <form onSubmit={form.onSubmit((values) => {
                form.resetDirty(values);
                props.onSubmit(values);
            })}>
                <Group align="end">
                    {userFilters}
                    <SegmentPicker {...form.getInputProps('segment')} />
                    <Button type="submit" disabled={!form.isDirty()}>Submit</Button>
                    <Button variant="light" disabled={!form.isDirty()} onClick={() => form.reset()}>Reset</Button>
                </Group>
            </form>
        </UserFiltersFormProvider>
    );
}
