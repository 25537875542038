import {
    Button,
    Group,
    Modal,
    Stack,
    Text,
} from "@mantine/core";
import { Link } from "react-router-dom";
import ContactSupportButton from "./ContactSupportButton";
import _ from "underscore";

export default function NotImplementedModal(props: { opened: boolean }) {
    return (
        <Modal
            opened={props.opened}
            withCloseButton={false}
            onClose={_.noop}
            title="Feature Not Available"
        >
            <Stack align="flex-start">
                <Text size="sm">Looks like we are developing this feature for someone else.</Text>
                <Text size="sm">Please contact us via slack or support@rubberduckylabs.io to request using this feature, too!</Text>
                <Group>
                    <Button component={Link} to={"/home"}>Return home</Button>
                    <ContactSupportButton />
                </Group>
            </Stack>
        </Modal>
    )
}
