import { Location } from "react-router-dom";
import { parseDatesInObject } from "./date-range";
import _ from "underscore";

export const getJSONParsedURLSearchParam = function (searchParams: URLSearchParams, key: string, defaultValue?: any): any {
    try {
        const searchParam = searchParams.get(key);
        if (searchParam === null || searchParam === '') return defaultValue;
        const parsed: any = JSON.parse(searchParam);
        return parseDatesInObject(parsed);
    } catch (error) {
        return undefined;
    }
}

export const createURLWithSearchParams = function (location: Location | string, params: Record<string, any>): URL {
    const url = new URL(_.isString(location) ? location : location.pathname, window.location.origin);

    createJSONStringifiedURLSearchParams(params).forEach((value, key) => {
        url.searchParams.set(key, value);
    });

    return url;
}

export function createJSONStringifiedURLSearchParams(params: Record<string, any>): URLSearchParams {
    const searchParams = new URLSearchParams();

    for (const key in params) {
        const value = params[key];
        if (value !== undefined && value !== null && !((_.isArray(value) || _.isObject(value) || _.isString(value)) && _.size(value) === 0)) {
            searchParams.append(key, JSON.stringify(value));
        }
    }

    return searchParams;
}
