import { useEffect, useState } from "react";
import _ from 'underscore';

import {
    BrowserRouter,
    Link,
    Navigate,
    Outlet,
    Route,
    Routes,
    useNavigate,
    useOutletContext,
} from "react-router-dom";

import * as rdlTypes from "./models/RDLDataTypes";
import { Alert } from "./components/Alert";
import {
    Anchor,
    AppShell,
    Box,
    Container,
    Grid,
    Group,
    Header,
    Loader,
    Modal,
    Navbar,
    Progress,
    Stack,
    Text,
    Title
} from '@mantine/core'
import { Auth0Provider, LogoutOptions, useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { AxiosError } from "axios";
import { DEFAULT_CONFIG, RDLConfigType } from "./models/RDLConfig";
import { DataOnboardingTestsManagementPage } from "./pages/data-onboarding/DataOnboardingTestsManagementPage";
import { HelpAlert, HelpIcon } from './components/Help';
import { LargeLogo, Logo, LogoForNav } from "./_logo";
import { RDLApiContext, RubberDuckyLabsApi, RubberDuckyLabsUsersApi } from './RDLApi';
import { SettingsProfilePage } from './pages/settings/ProfilePage';
import { UsersDetailPage } from './pages/users/UsersDetailPage';
import { UsersIndexPage } from './pages/users/UsersIndexPage';
import { mainLinks } from './mainLink';
import { useDisclosure, useLocalStorage } from '@mantine/hooks';
import ABTestCalculatorPage from "./pages/calculators/ABTestCalculatorPage";
import CatchAllErrorBoundary, { ErrorPage } from './pages/errors/CatchAllErrorBoundary';
import ChartsTestPage from "./pages/ChartsTestPage";
import ContactSupportButton from './components/ContactSupportButton';
import DataExplorePage from "./pages/DataExplorePage";
import DataFAQPage from './pages/DataFAQPage';
import DataOnboardingDatabasesCreatePage from "./pages/data-onboarding/DataOnboardingDatabasesCreatePage";
import DataOnboardingDatabasesDetailPage from "./pages/data-onboarding/DataOnboardingDatabasesDetailPage";
import DataOnboardingDatabasesPage from "./pages/data-onboarding/DataOnboardingDatabasesPage";
import DataOnboardingIndexPage from "./pages/DataOnboardingIndexPage";
import DataOnboardingProjectsPage from "./pages/data-onboarding/DataOnboardingProjectsPage";
import DataOnboardingTestsDetailPage from "./pages/data-onboarding/DataOnboardingTestsDetailPage";
import DataOnboardingTestsPage from "./pages/data-onboarding/DataOnboardingTestsPage";
import HealthChecksConfigPage from "./pages/health-checks/HealthChecksConfigPage";
import HealthChecksMetricsPage from "./pages/health-checks/HealthChecksMetricsPage";
import HomePage from "./pages/HomePage";
import ItemsByMetricPage from "./pages/ItemsByMetricPage";
import LoginButton from './components/LoginButton';
import LogoutButton from "./components/LogoutButton";
import MainLinksIndexPage from "./components/MainLinksIndexPage";
import OrganizationPicker from './components/OrganizationPicker';
import PipelinesDetailPage from "./pages/pipelines/PipelinesDetailPage";
import PipelinesListPage from "./pages/pipelines/PipelinesListPage";
import PipelinesMetricsPage from "./pages/pipelines/metrics/PipelinesMetricsPage";
import PoliciesDetailsPage from "./pages/policies/PoliciesDetailsPage";
import PoliciesListPage from "./pages/policies/PoliciesListPage";
import PositionalAnalysisPage from "./pages/dead-pages/PositionalAnalysisPage";
import ProductCatalogFieldsPage from "./pages/product-catalog/ProductCatalogFieldsPage";
import ProductCatalogItemsPage from "./pages/product-catalog/ProductCatalogItemsPage";
import RDLNavLink from './RDLNavLink';
import RankingDrilldownPage from './pages/RankingDrilldownPage';
import SiteAdminUsersAndOrganizationsPage from "./pages/site-admin/SiteAdminUsersAndOrganizationsPage";
import TestDatasetsDetailPage from "./pages/test-datasets/TestDatasetsDetailPage";
import TestDatasetsListPage from "./pages/test-datasets/TestDatasetsListPage";
import TracesListPage from "./pages/traces/TracesListPage";
import UserDrilldownPage from "./pages/UserDrilldownPage";
import UserMenu from './components/UserMenu';
import UserSegmentsPage from "./pages/users/UserSegmentsPage";


const loadItems = (
    apiContext: RDLApiContext,
    onSuccess: (items: rdlTypes.Pagination<rdlTypes.Item>) => void,
    after?: rdlTypes.Id | null
): Promise<void> => {
    if (after === null) return Promise.resolve();

    return RubberDuckyLabsApi.getInstance(apiContext).getItems(1000, after)
        .then((response: rdlTypes.Pagination<rdlTypes.Item>) => {
            onSuccess(response);
            return loadItems(apiContext, onSuccess, response.paging.after);
        });
}

type LoadAndCacheItems = (apiContext: RDLApiContext | null) => void;
type RDLOutletContextType = [rdlTypes.Item[], boolean, RDLConfigType, RDLApiContext | null, LoadAndCacheItems, rdlTypes.RDLUser | null | undefined];

export function useItems() {
    const [items]  = useOutletContext<RDLOutletContextType>();
    return items;
}

export function useLoadAndCacheItems() {
    const [, , , , loadAndCacheItems] = useOutletContext<RDLOutletContextType>();
    return loadAndCacheItems;
}

export function useIsLoadingItems() {
    const [, isLoadingItems] = useOutletContext<RDLOutletContextType>();
    return isLoadingItems;
}

export function useRDLUser(): rdlTypes.RDLUser | null | undefined {
    const [, , , , , rdlUser] = useOutletContext<RDLOutletContextType>();
    return rdlUser;
}

export function useRDLConfig(): RDLConfigType {
    const [, , config] = useOutletContext<RDLOutletContextType>();
    return config;
}


export function useApiContext(): RDLApiContext | null {
    const [, , , apiContext] = useOutletContext<RDLOutletContextType>();
    return apiContext;
}

function RDLOutlet(props: { context: RDLOutletContextType }) {
    return <Outlet context={props.context} />;
}

function RDLAppShell() {
    const { user, isAuthenticated, isLoading, logout, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
    const [rdlUser, setRdlUser] = useState<rdlTypes.RDLUser | null | undefined>(undefined);
    const [items, setItems] = useState<rdlTypes.Item[]>([]);
    const [itemsCount, setItemsCount] = useState<number>();
    const [isLoadingItems, setIsLoadingItems] = useState(false);
    const [itemsError, setItemsError] = useState('');
    const [config, setConfig] = useState<RDLConfigType>(DEFAULT_CONFIG);
    const [organization, setOrganization] = useLocalStorage<rdlTypes.Organization | undefined>({key: 'organization', defaultValue: undefined});
    const [apiContext, setApiContext] = useState<RDLApiContext | null>(null);
    const [opened, { close, toggle }] = useDisclosure(true);

    useEffect(() => {
        if (user && isAuthenticated) {
            if (_.isUndefined(user.sub)) {
                setRdlUser(null);
            } else {
                RubberDuckyLabsUsersApi.getInstance(getAccessTokenSilently)
                    .getSelf()
                    .then((user) => {
                        setRdlUser(user);
                        if (!user.is_site_admin && user.organizations.length === 1) {
                            setOrganization(user.organizations[0]);
                        }
                    })
                    .catch(() => setRdlUser(null));
            }
        }

        return () => {
            setRdlUser(undefined);
        }
    }, [user, isAuthenticated, getAccessTokenSilently]);

    useEffect(() => {
        if (!_.isUndefined(organization)) {
            const scopes = [`read:${organization.name}`, `write:${organization.name}`];

            setApiContext({
                getToken: () => {
                    return getAccessTokenSilently({ scope: scopes.join(' ') })
                        .catch(() => {
                            return getAccessTokenWithPopup({ scope: scopes.join(' ') })
                        });
                },
                organization,
            });
        }

        return () => {
            setApiContext(null);
        }
    }, [organization, getAccessTokenSilently, getAccessTokenWithPopup]);

    useEffect(() => {
        if (!_.isNull(apiContext)) {
            RubberDuckyLabsApi.getInstance(apiContext)
                .getConfig()
                .then((config: RDLConfigType) => {
                    setConfig({ ...config, loaded: true });
                })
                .catch(() => setConfig((prev) => ({ ...prev, error: true })));
        }
    }, [apiContext]);

    const loadAndCacheItems = (apiContext: RDLApiContext | null) => {
        if (!_.isNull(apiContext) && !isLoadingItems && _.isEmpty(items)) {
            setIsLoadingItems(true);
            loadItems(
                apiContext,
                (itemsPage) => {
                    if (_.isUndefined(itemsCount)) setItemsCount(itemsPage.count);
                    setItems((previousItems) => previousItems.concat(itemsPage.data));
                }
            )
                .catch((error: AxiosError) => setItemsError(error.message))
                .finally(() => setIsLoadingItems(false));
        }
    }

    if (!isAuthenticated || user === undefined) {
        throw new Error('Acccess to this page should be protected by Auth0, this is a bug');
    }

    const onLogout = (options?: LogoutOptions | undefined) => {
        logout(options);
    }

    const isSiteAdmin = (!_.isNull(rdlUser) && rdlUser?.is_site_admin) || false;
    const links = mainLinks(isSiteAdmin, config).map((mainLink) => <RDLNavLink key={mainLink.linkTo} {...mainLink} />);

    return (
        <AppShell
            padding="md"
            navbar={
                <Navbar width={{ base: 70 }} p="xs">
                    <Navbar.Section>
                        <Anchor component={Link} to={"/home"}>
                            <LogoForNav />
                        </Anchor>
                    </Navbar.Section>
                    <Navbar.Section grow mt="md">
                        {links}
                    </Navbar.Section>
                    <Navbar.Section>
                        {isLoading ? <Loader mx={20} /> : <UserMenu user={user} onLogout={onLogout} />}
                    </Navbar.Section>
                </Navbar>
            }
        >
            <>
                {!_.isNull(rdlUser) && _.isUndefined(organization) && <Modal withCloseButton={false} opened={true} onClose={_.noop} >
                    <OrganizationPicker user={rdlUser} onChange={setOrganization} />
                </Modal>}
                {_.isNull(rdlUser) && <Modal withCloseButton={false} opened={true} onClose={_.noop} >
                    <Text>Your account needs further configuration, please contact support</Text>
                    <Group>
                        <ContactSupportButton />
                        <LogoutButton />
                    </Group>
                </Modal>}
                {!_.isEmpty(itemsError) && <Alert message={itemsError} onClose={() => setItemsError('')} />}
                {isLoadingItems &&
                    <Grid align='center' gutter={0}>
                        <Grid.Col span={1}>
                            <HelpIcon toggle={toggle} />
                        </Grid.Col>
                        <Grid.Col span={11}>
                            <Progress value={_.isUndefined(itemsCount) ? 0 : items.length / itemsCount * 100} />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <HelpAlert
                                title="What is this progress bar for?"
                                message={"We are loading your product catalog into memory when Rubber Ducky Labs starts up."
                                    + "You can continue to use Rubber Ducky Labs while we load your data,"
                                    + " but the app will be noticably less responsive before loading is complete."}
                                opened={opened}
                                close={close}
                            />
                        </Grid.Col>
                    </Grid>
                }
                <Box sx={{ position: "relative" }}>
                    <RDLOutlet context={[items, isLoadingItems, config, apiContext, loadAndCacheItems, rdlUser]} />
                </Box>
            </>
        </AppShell>
    );
}

function RequireAuth() {
    const Component = withAuthenticationRequired(Outlet);
    return <Component />;
}


function Auth0ProviderWithRedirectCallBack(props: any) {
    const navigate = useNavigate();
    const onRedirectCallback = (appState: any) => {
        navigate((appState === undefined || appState === null) ? window.location.pathname : appState.returnTo);
    };
    return (
        <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
            {props.children}
        </Auth0Provider>
    );
}

export default function App() {
    return (
        <BrowserRouter>
            <Auth0ProviderWithRedirectCallBack
                domain={process.env.REACT_APP_AUTH0_DOMAIN}
                clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
                redirectUri={window.location.origin}
                audience={process.env.REACT_APP_AUTH0_AUDIENCE}
            >
                <CatchAllErrorBoundary>
                    <Routes>
                        <Route index element={<Index />} />
                        <Route path="about/" element={<About />} />
                        <Route path="error/" element={<ErrorPage onReset={_.noop} />} />
                        <Route element={<RequireAuth />}>
                            <Route element={<RDLAppShell />}>
                                <Route path="/home" element={<HomePage />} />
                                <Route path="/data-onboarding" >
                                    <Route path="" element={<DataOnboardingIndexPage />} />
                                    <Route path="projects">
                                        <Route path="" element={<DataOnboardingProjectsPage />} />
                                        <Route path=":projectId/tests">
                                            <Route path="" element={<DataOnboardingTestsPage />} />
                                            <Route path=":testId" element={<DataOnboardingTestsDetailPage />} />
                                        </Route>
                                    </Route>
                                    <Route path ="tests">
                                        <Route path="" element={<DataOnboardingTestsManagementPage />} />
                                    </Route>
                                    <Route path="databases">
                                        <Route path="" element={<DataOnboardingDatabasesPage />} />
                                        <Route path="create" element={<DataOnboardingDatabasesCreatePage />} />
                                        <Route path=":databaseId" element={<DataOnboardingDatabasesDetailPage />} />
                                    </Route>
                                </Route>
                                <Route path="calculators/">
                                    <Route path="" element={<MainLinksIndexPage />} />
                                    <Route path="ab-test" element={<ABTestCalculatorPage />} />
                                </Route>
                                <Route path="/settings/profile/" element={<SettingsProfilePage />} />
                                <Route path="user-drilldown" element={<UserDrilldownPage />} />
                                <Route path="users">
                                    <Route path="" element={<UsersIndexPage />} />
                                    <Route path="segments" element={<UserSegmentsPage />} />
                                    <Route path=":userId" element={<UsersDetailPage />} />
                                </Route>
                                <Route path="health-checks">
                                    <Route path="" element={<MainLinksIndexPage />} />
                                    <Route path="metrics" element={<HealthChecksMetricsPage />} />
                                    <Route path="config" element={<HealthChecksConfigPage />} />
                                </Route>
                                <Route path="data-explore">
                                    <Route path="" element={<MainLinksIndexPage />} />
                                    <Route path="freeform" element={<DataExplorePage />} />
                                    <Route path="items-by-metric" element={<ItemsByMetricPage />} />
                                </Route>
                                <Route path="product-catalog">
                                    <Route path="" element={<MainLinksIndexPage />} />
                                    <Route path="fields" element={<ProductCatalogFieldsPage />} />
                                    <Route path="items" element={<ProductCatalogItemsPage />} />
                                </Route>
                                <Route path="test-datasets">
                                    <Route path="" element={<TestDatasetsListPage />} />
                                    <Route path=":testDatasetId" element={<TestDatasetsDetailPage />} />
                                </Route>
                                <Route path="policies">
                                    <Route path="" element={<PoliciesListPage />} />
                                    <Route path=":policyId" element={<PoliciesDetailsPage />} />
                                </Route>
                                <Route path="data-faq" element={<DataFAQPage />} />
                                <Route path="test">
                                    <Route path="charts" element={<ChartsTestPage />} />
                                </Route>
                                <Route path="pipelines">
                                    <Route path="" element={<PipelinesListPage />} />
                                    <Route path=":rankingPipelineId">
                                        <Route path="" element={<PipelinesDetailPage />} />
                                        <Route path="traces">
                                            <Route path="" element={<TracesListPage />} />
                                            <Route path=":traceId" element={<RankingDrilldownPage />} />
                                        </Route>
                                        <Route path="metrics">
                                            <Route path="" element={<PipelinesMetricsPage />} />
                                        </Route>
                                    </Route>
                                </Route>
                                <Route path="dead-pages">
                                    <Route path="" element={<MainLinksIndexPage />} />
                                    <Route path="positional-analysis" element={<PositionalAnalysisPage />} />
                                </Route>
                                <Route path="/site-admin">
                                    <Route path="" element={<MainLinksIndexPage />} />
                                    <Route path="users-and-organizations" element={<SiteAdminUsersAndOrganizationsPage />} />
                                </Route>
                            </Route>
                        </Route>
                    </Routes>
                </CatchAllErrorBoundary>
            </Auth0ProviderWithRedirectCallBack>
        </BrowserRouter>
    );
}

// You can think of these components as "pages"
// in your app.


function Index() {
    const { isAuthenticated, isLoading } = useAuth0();

    if (isAuthenticated) {
        return <Navigate to="/home" replace />
    }

    return (
        <AppShell
            padding="md"
            header={
                <Header height="60" p="xs">
                    <Group position="apart">
                        <Logo />
                        {isLoading
                            ? <Loader mx={20} />
                            : <LoginButton />
                        }
                    </Group>
                </Header>
            }
        >
            <Container>
                <Stack>
                    <LargeLogo />
                    <Title>Welcome to Rubber Ducky Labs!</Title>
                    <Text>
                        {"We're excited to let you start debugging, exploring, and analyzing your recommender system."}
                    </Text>
                    <Group>
                        {isLoading
                            ? <Loader mx={20} />
                            : <LoginButton />
                        }
                        <ContactSupportButton />
                    </Group>
                </Stack>
            </Container>
        </AppShell>
    );
}

function About() {
    return (
        <Stack>
            <Title order={1}>About</Title>
            <Text>This prototype was created by Rubber Ducky Labs, Inc.</Text>
        </Stack>
    );
}
