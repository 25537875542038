import {
    ActionIcon,
    Button,
    Modal,
    Stack,
    Textarea,
    Tooltip
} from "@mantine/core"
import { IconCheck, IconCopy, IconShare3 } from "@tabler/icons-react";
import { useClipboard, useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const ShareButton = function () {
    const [opened, { close, open }] = useDisclosure(false);
    const [sharingURL, setSharingURL] = useState('');
    const clipboard = useClipboard({ timeout: 500 });
    const location = useLocation();

    useEffect(() => {
        const url = new URL(location.pathname + location.search, window.location.origin);
        setSharingURL(url.href);
    }, [opened]);

    return (
        <>
            <Modal
                size="lg"
                opened={opened}
                onClose={close}
                title="Copy sharing URL"
            >
                <Stack>
                    <Textarea
                        minRows={4}
                        maxRows={10}
                        value={sharingURL}
                    />
                    <ActionIcon
                        color={clipboard.copied ? 'teal' : 'blue'}
                        onClick={() => clipboard.copy(sharingURL)}
                    >
                        {clipboard.copied ? <IconCheck /> : <IconCopy />}
                    </ActionIcon>
                </Stack>
            </Modal>
            <Tooltip label="Sharing URL">
                <Button
                    onClick={open}
                    variant="light"
                    color="blue"
                    rightIcon={<IconShare3 />}
                >
                    Share URL
                </Button>
            </Tooltip>
        </>
    );

}
