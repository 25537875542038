import * as dataOnboardingTypes from "../../models/data-onboarding-data-types";
import {
    ActionIcon,
    Button,
    Center,
    Group,
    LoadingOverlay,
    Menu,
    Stack,
    Table,
    Text,
    Title
} from "@mantine/core"
import { DatabaseDisplayInfo, DatabaseNotes } from "./Components";
import { IconDotsVertical, IconEdit, IconTrash } from "@tabler/icons-react";
import { RubberDuckyLabsApi } from "../../RDLApi";
import { formatTimestamp, formatUpdatedTimestamp } from "../../utilities/time";
import { useApiContext } from "../../App";
import { useEffect, useState } from "react";
import { useListState } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import _ from "underscore";


function EmptyRow() {
    return (
        <tr><td colSpan={4}><Center h={100}><Text fw={700}>No data found</Text></Center></td></tr>
    )
}

type OnDeleteType = (db: dataOnboardingTypes.DataOnboardingDatabase, index: number) => void;


function DatabasesTable({databases, onDelete}: {databases: dataOnboardingTypes.DataOnboardingDatabase[], onDelete: OnDeleteType}) {
    const navigate = useNavigate();

    const rows = databases.map((db, index) => {
        return (
            <tr key={db.id} onClick={() => navigate(`/data-onboarding/databases/${db.id}`)} style={{cursor: "pointer"}}>
                <td>
                    <DatabaseDisplayInfo database={db} />
                </td>
                <td>{formatTimestamp(db.created)}</td>
                <td>{formatUpdatedTimestamp(db.updated)}</td>
                <td><DatabaseNotes database={db} /></td>
                <td>
                    <Menu shadow="md" width={200} position="bottom-start" withinPortal>
                        <Menu.Target>
                            <ActionIcon variant="transparent" color="gray" onClick={(e: React.MouseEvent) => e.stopPropagation()} >
                                <IconDotsVertical size={16} />
                            </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown onClick={(e) => e.stopPropagation()}>
                            <Menu.Item icon={<IconEdit size={16} />} onClick={() => navigate(`/data-onboarding/databases/${db.id}`)}>Edit</Menu.Item>
                            <Menu.Item icon={<IconTrash size={16} />} onClick={() => onDelete(db, index)}>Delete</Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </td>
            </tr>
        )
    });

    return (
        <Table withBorder highlightOnHover>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Created</th>
                    <th>Updated</th>
                    <th>Notes</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>{_.isEmpty(rows) ? <EmptyRow /> : rows}</tbody>
        </Table>
    );
}


export default function DataOnboardingDatabasesPage() {
    const [loading, setLoading] = useState(true);
    const [databases, databasesHandlers] = useListState<dataOnboardingTypes.DataOnboardingDatabase>([]);
    const apiContext = useApiContext();
    const navigate = useNavigate();

    useEffect(() => {
        if(!_.isNull(apiContext)) {
            RubberDuckyLabsApi.getInstance(apiContext).iterateDataOnboardingDatabases()
                .then((dbs) => databasesHandlers.setState(dbs))
                .finally(() => setLoading(false));
        }
    }, [apiContext]);

    const onDelete = (db: dataOnboardingTypes.DataOnboardingDatabase, index: number) => {
        if (!_.isNull(apiContext)) {
            RubberDuckyLabsApi.getInstance(apiContext).deleteDataOnboardingDatabase(db.id)
                .then(() => databasesHandlers.remove(index));
        }
    }


    return (
        <Stack>
            <Title order={1} tt="capitalize">Databases</Title>
            <Text c="dimmed">Connect your databases.</Text>
            <LoadingOverlay visible={loading} />
            <DatabasesTable databases={databases} onDelete={onDelete} />
            <Group>
                <Button
                    variant="outline"
                    onClick={() => navigate('/data-onboarding/databases/create')}
                >
                    Create New Database
                </Button>
            </Group>
        </Stack>
    )
}
