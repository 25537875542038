import { IconAlertCircle } from "@tabler/icons-react";
import { Alert as MantineAlert, Transition } from '@mantine/core';
import { useEffect } from "react";
import { useTimeout } from "@mantine/hooks";
import _ from "underscore"


export function Alert({message, onClose}: { message: string | null , onClose?: () => void }) {
    if (_.isNull(message)) {
        return null;
    }

    return (
        <MantineAlert
            withCloseButton={!_.isUndefined(onClose)}
            onClose={onClose ?? _.noop}
            icon={<IconAlertCircle size={16} />}
            title="Error"
            color="red"
        >
            {message}
        </MantineAlert>
    );
}


export function AlertWithTimeout({message, setMessage}: { message: string | null, setMessage: (message: string | null) => void }) {
    const { start } = useTimeout(() => {
        setMessage(null);
    }, 3000);
    useEffect(() => {
        if (!_.isNull(message)) {
            start()
        }
    }, [message]);

    return (
        <Transition mounted={!_.isNull(message)} transition="fade" duration={400} timingFunction="ease">
            {(styles) => <MantineAlert
                style={styles}
                icon={<IconAlertCircle size={16} />}
                title="Error"
                color="red"
            >
                {message}
            </MantineAlert>
            }
        </Transition>
    );
}
