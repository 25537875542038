import * as rdlTypes from "../models/RDLDataTypes";
import { MantineTheme } from "@mantine/core";
import { NO_SEGMENT, UserSegment } from "../models/user-segments";
import { RDLApiContext, RubberDuckyLabsApi } from "../RDLApi";
import { chartColorsList } from "./charts";
import _ from "underscore";

export function createNewSegments(userSegmentsMetadata: rdlTypes.UserSegmentMetadata[] | null, userSegments: UserSegment[], theme: MantineTheme) {
    const colors = chartColorsList(theme);

    const newSegments: UserSegment[] = (userSegmentsMetadata ?? [])
        .filter(({id}) => !_.find(userSegments, ({key}) => key === id))
        .map(({name, id, count_users}, index) => ({
            name,
            key: id.toString(),
            color: colors[(index + userSegments.length) % colors.length],
            countUsers: count_users,
            hidden: false,
        }));

    if (!_.find(userSegments, ({name}) => name === NO_SEGMENT)) {
        newSegments.push({
            name: NO_SEGMENT,
            key: NO_SEGMENT,
            color: theme.colors.gray,
            countUsers: 0,
            hidden: false,
        });
    }

    return newSegments;
}

export function getUserSegmentsMetadata(apiContext: RDLApiContext): Promise<rdlTypes.UserSegmentMetadata[] | null> {
    return RubberDuckyLabsApi
        .getInstance(apiContext)
        .listUserSegmentsMetadata()
        .then(({data}) => data)
        .catch(() => null);
}

export function getUserSegmentMembership(apiContext: RDLApiContext, userSegments: UserSegment[]): Promise<rdlTypes.UserSegmentMembership[]> {
    if (_.isNull(userSegments) || _.isEmpty(userSegments)) {
        return Promise.resolve([]);
    }
    return RubberDuckyLabsApi
        .getInstance(apiContext)
        .listUsersInSegments(_.pluck(userSegments, 'key').filter((key) => key !== NO_SEGMENT))
        .then(({data}) => data)
        .catch(() => []);
}
