import * as dataOnboardingTypes from "../../models/data-onboarding-data-types";
import {
    ActionIcon,
    Button,
    Center,
    Group,
    LoadingOverlay,
    Menu,
    Modal,
    Stack,
    Table,
    Text,
    TextInput,
    Textarea,
    Title
} from "@mantine/core"
import { Alert } from "../../components/Alert";
import { IconDotsVertical, IconEdit, IconTrash } from "@tabler/icons-react";
import { RubberDuckyLabsApi } from "../../RDLApi";
import { formatTimestamp, formatUpdatedTimestamp } from "../../utilities/time";
import { hasLength, useForm } from "@mantine/form";
import { useApiContext } from "../../App";
import { useDisclosure, useListState } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import _ from "underscore";


function EmptyRow() {
    return (
        <tr>
            <td colSpan={4}>
                <Center h={100}>
                    <Text fw={700}>No data found</Text>
                </Center>
            </td>
        </tr>
    );
}

function ProjectDescription({project}: {project: dataOnboardingTypes.DataOnboardingProject}) {
    const description = project.description;

    if (_.isEmpty(description)) {
        return <Text c="dimmed" italic>No description</Text>
    }

    return <Text lineClamp={2}>{description}</Text>
}


function DataOnboardingProjectsTableRow({project, onChange, onDelete}: {project: dataOnboardingTypes.DataOnboardingProject, onChange: (project: dataOnboardingTypes.DataOnboardingProjectCreateData) => void, onDelete: () => void}) {
    const navigate = useNavigate();
    const [opened, {open, close}] = useDisclosure();

    const onEditSumbit = (values: dataOnboardingTypes.DataOnboardingProjectCreateData) => {
        onChange(values);
        close();
    }


    return (
        <tr style={{cursor: "pointer"}} onClick={() => navigate(`/data-onboarding/projects/${project.id}/tests`)}>
            <td>{project.name}</td>
            <td><ProjectDescription project={project} /></td>
            <td>{formatTimestamp(project.created)}</td>
            <td>{formatUpdatedTimestamp(project.updated)}</td>
            <td>
                <Modal
                    size="lg"
                    opened={opened}
                    onClose={close}
                    title="Edit Project"
                    onClick={(e) => e.stopPropagation()}
                >
                    <ProjectsEditForm
                        project={_.pick(project, 'name', 'description')}
                        onSubmit={onEditSumbit}
                    />
                </Modal>
                <Menu shadow="md" width={200} position="bottom-start" withinPortal>
                    <Menu.Target>
                        <ActionIcon variant="transparent" color="gray" onClick={(e: React.MouseEvent) => e.stopPropagation()} >
                            <IconDotsVertical size={16} />
                        </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown onClick={(e) => e.stopPropagation()}>
                        <Menu.Item icon={<IconEdit size={16} />} onClick={open}>Edit</Menu.Item>
                        <Menu.Item icon={<IconTrash size={16} />} onClick={() => onDelete()}>Delete</Menu.Item>
                    </Menu.Dropdown>
                </Menu>
            </td>
        </tr>

    )
}


function DataOnboardingProjectsTable({projects, onEdit, onDelete}: {projects: dataOnboardingTypes.DataOnboardingProject[], onEdit: (project: dataOnboardingTypes.DataOnboardingProject, index: number, values: dataOnboardingTypes.DataOnboardingProjectCreateData) => void, onDelete: (project: dataOnboardingTypes.DataOnboardingProject, index: number) => void}) {
    const rows = projects.map((project, index) => <DataOnboardingProjectsTableRow
        key={project.id}
        project={project}
        onChange={(values) => onEdit(project, index, values)}
        onDelete={() => onDelete(project, index)}
    />);

    return (
        <Table withBorder highlightOnHover>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Created</th>
                    <th>Updated</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>{_.isEmpty(rows) ? <EmptyRow /> : rows}</tbody>
        </Table>
    )
}

export function ProjectsEditForm({onSubmit, project}: {onSubmit: (values: dataOnboardingTypes.DataOnboardingProjectCreateData) => void, project: dataOnboardingTypes.DataOnboardingProjectCreateData}) {
    const form = useForm<dataOnboardingTypes.DataOnboardingProjectCreateData>({
        initialValues: project,
        validate: {
            name: hasLength({ min: 1, max: 100 }, 'Name should be between 1 and 100 characters'),
        },
    });

    return (
        <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
            <Stack>
                <TextInput
                    label="Name"
                    placeholder="Project name"
                    required
                    {...form.getInputProps('name')}
                />
                <Textarea
                    label="Description"
                    placeholder="Project description"
                    {...form.getInputProps('description')}
                />
                <Group position="right">
                    <Button type="submit">Submit</Button>
                </Group>
            </Stack>
        </form>
    );
}

function ProjectsCreateModal({onCreate, initialOpened}: {onCreate: (values: dataOnboardingTypes.DataOnboardingProjectCreateData) => void, initialOpened?: boolean}) {
    const [opened, {open, close}] = useDisclosure(initialOpened);

    const onSubmit = (values: dataOnboardingTypes.DataOnboardingProjectCreateData) => {
        onCreate(values);
        close();
    }

    return (
        <>
            <Modal opened={opened} onClose={close} title="Create Project">
                <ProjectsEditForm onSubmit={onSubmit} project={{name: '', description: ''}} />
            </Modal>
            <Group>
                <Button onClick={open} variant="outline">Create Project</Button>
            </Group>
        </>
    )
}

export default function DataOnboardingProjectsPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const create = searchParams.get('create') === 'true';
    const [apiError, setApiError] = useState('');
    const [loading, setLoading] = useState(true);
    const [projects, projectsHandlers] = useListState<dataOnboardingTypes.DataOnboardingProject>([]);
    const navigate = useNavigate();
    const apiContext = useApiContext();

    useEffect(() => {
        if (!_.isNull(apiContext)) {
            setLoading(true);
            setApiError('');
            RubberDuckyLabsApi.getInstance(apiContext)
                .iterateDataOnboardingProjects(100)
                .then((projects) => projectsHandlers.setState(projects))
                .catch((error) => {
                    setApiError(error.message);
                })
                .finally(() => setLoading(false));
        }
    }, [apiContext]);

    const apiErrorAlert = (
        !_.isEmpty(apiError) && <Alert message={apiError} onClose={() => setApiError('')} />
    );

    const onEdit = (project: dataOnboardingTypes.DataOnboardingProject, index: number, values: dataOnboardingTypes.DataOnboardingProjectCreateData) => {
        projectsHandlers.setItem(index, {...project, ...values});
        if (!_.isNull(apiContext)) {
            RubberDuckyLabsApi.getInstance(apiContext)
                .updateDataOnboardingProject(project.id, values)
                .then((project) => projectsHandlers.setItem(index, project))
        }
    }

    const onDelete = (project: dataOnboardingTypes.DataOnboardingProject, index: number) => {
        projectsHandlers.remove(index);
        if (!_.isNull(apiContext)) {
            RubberDuckyLabsApi.getInstance(apiContext)
                .deleteDataOnboardingProject(project.id)
        }
    }

    const onCreate = (values: dataOnboardingTypes.DataOnboardingProjectCreateData) => {
        setSearchParams({});
        if (!_.isNull(apiContext)) {
            RubberDuckyLabsApi.getInstance(apiContext)
                .createDataOnboardingProject(values)
                .then((project) => navigate(`/data-onboarding/projects/${project.id}/tests`))
        }
    }


    return (
        <Stack>
            {apiErrorAlert}
            <Title order={1}>Data Onboarding Projects</Title>
            <LoadingOverlay visible={loading} />
            <DataOnboardingProjectsTable
                projects={projects}
                onEdit={onEdit}
                onDelete={onDelete}
            />
            {!_.isNull(apiContext) && <ProjectsCreateModal onCreate={onCreate} initialOpened={create} />}
        </Stack>
    );
}
