import { Switch } from "@mantine/core";
import { useFacetFormContext } from "./facet-form-context";


export function ActiveInput() {
    const form = useFacetFormContext();

    return (
        <Switch
            label="Active"
            {...form.getInputProps('active', {type: 'checkbox'})}
        />
    );
}
