import * as rdlTypes from "../../../models/RDLDataTypes";
import {
    ActionIcon,
    Avatar,
    Button,
    Group,
    JsonInput,
    Menu,
    Modal,
    NumberInput,
    Paper,
    Stack,
    Text,
    Tooltip
} from "@mantine/core"
import {
    IconApiApp,
    IconBook2,
    IconCodeDots,
    IconDatabase,
    IconDotsVertical,
    IconPencil,
    IconTestPipe,
    IconTrash,
} from "@tabler/icons-react";
import { TEST_DATA_TITLE_MAP, TestDataTypes } from "./testData";
import {
    TestDataFormProvider,
    TestDataFormValues,
    isValidJSONData,
    newTestData,
    useTestDataForm,
    validateTestFormValues
} from "./test-data-form-context"
import { TestDatasetInput } from "./TestDatasetInput";
import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import RDLEventDateRangePicker from "../../custom/RDLDateRangePicker";
import ReactJson from "react-json-view";


export type OnSubmitFunctionType = (values: TestDataFormValues) => Promise<void>;
type OnRemoveFunctionType = () => void;

export const TestDataIcon = function(props: { type: TestDataTypes }) {

    switch(props.type) {
    case TestDataTypes.JSON:
        return <IconCodeDots size={18} />;
    case TestDataTypes.ModelAPI:
        return <IconApiApp size={18} />;
    case TestDataTypes.HistoricalUserData:
    case TestDataTypes.HistoricalUserDataRecommendationsOnly:
        return <IconDatabase size={18} />;
    case TestDataTypes.TestDataset:
        return <IconTestPipe size={18} />;
    case TestDataTypes.ProductCatalog:
        return <IconBook2 size={18} />;
    }
}


export function TestDataTitle(props: { testData: TestDataFormValues }) {
    return (
        <>
            <TestDataIcon type={props.testData.type} />
            <Text size="xs">{TEST_DATA_TITLE_MAP[props.testData.type]}</Text>
        </>
    );
}


function EditTestDataForm(props: {
    onSubmit: OnSubmitFunctionType,
    values: TestDataFormValues,
    onRemove: OnRemoveFunctionType,
}) {
    const [loading, setLoading] = useState(false);

    const form = useTestDataForm({
        initialValues: props.values,
        validate: validateTestFormValues,
        validateInputOnBlur: true,
    });

    const onJSONStrChange = function (value: string) {
        form.getInputProps('jsonStr').onChange(value);
        try {
            const jsonData = JSON.parse(value);
            if (isValidJSONData(jsonData)) {
                form.setFieldValue('jsonData', jsonData);
            } else {
                form.setFieldError('jsonStr', 'JSON Data must be an array of ids, or an array of objects with an id or item_id field');
            }
        } catch (error) {
            form.setFieldError('jsonStr', 'Invalid JSON');
        }
    }

    const onSubmit = function (values: TestDataFormValues) {
        setLoading(true);
        props.onSubmit(values).then(() => setLoading(false));
    }

    return (
        <TestDataFormProvider form={form}>
            <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
                <Stack spacing="sm">
                    <Stack spacing={3}>
                        <Text size="sm" color="gray.9" weight={500}>Type</Text>
                        <Text size="sm" color="gray.9">{TEST_DATA_TITLE_MAP[form.values.type]}</Text>
                    </Stack>
                    {form.values.type === TestDataTypes.JSON && <JsonInput
                        label="JSON Data"
                        placeholder="Array of ids"
                        validationError={form.errors.jsonStr === undefined ? 'Invalid JSON' : form.errors.jsonStr}
                        formatOnBlur
                        autosize
                        minRows={4}
                        withAsterisk
                        {...{ ...form.getInputProps('jsonStr'), onChange: onJSONStrChange }}
                    />}
                    {form.values.type === TestDataTypes.HistoricalUserData && <NumberInput
                        placeholder="User ID"
                        label="User ID"
                        withAsterisk
                        {...form.getInputProps('historicalUserClientId')}
                    />}
                    {form.values.type === TestDataTypes.HistoricalUserData && <RDLEventDateRangePicker
                        withAsterisk
                        {...form.getInputProps('historicalUserDateRange')}
                    />}
                    {form.values.type === TestDataTypes.TestDataset && <TestDatasetInput />}
                    {form.values.type === TestDataTypes.ModelAPI && <Text size="sm" color="gray.9">
                        Not yet implemented! Email support@rubberduckylabs.io to request this feature.
                    </Text>}
                    <Group position="right">
                        <Button variant="light" color="gray" onClick={() => props.onRemove()}>Discard</Button>
                        {form.values.type !== TestDataTypes.ModelAPI && <Button type="submit" loading={loading}>Save</Button>}
                    </Group>
                </Stack>
            </form>
        </TestDataFormProvider>
    )
}


export function ChooseTestDatasetActionIcon(props: {
    onClick: () => void,
    disabled: boolean,
    type: TestDataTypes,
}) {
    return (
        <Tooltip label={`Select ${TEST_DATA_TITLE_MAP[props.type]}`}>
            <ActionIcon
                color="blue"
                disabled={props.disabled}
                onClick={props.onClick}
                variant="outline"
            >
                <TestDataIcon type={props.type} />
            </ActionIcon>
        </Tooltip>
    )
}


export function ChooseDatasetModal(props: {
    onSubmit: OnSubmitFunctionType,
    disabled: boolean,
    type: TestDataTypes,
}) {
    const [opened, { close, open }] = useDisclosure(false);
    const onSubmit = function (values: TestDataFormValues) {
        return props.onSubmit(values).then(() => close());
    }

    if (props.type === TestDataTypes.ProductCatalog) {
        return (
            <ChooseTestDatasetActionIcon
                onClick={() => onSubmit(newTestData(TestDataTypes.ProductCatalog))}
                disabled={props.disabled}
                type={props.type}
            />
        )
    }

    return (
        <>
            <Modal
                opened={opened}
                onClose={close}
                title="Choose your dataset"
            >
                <EditTestDataForm
                    onSubmit={onSubmit}
                    onRemove={close}
                    values={newTestData(props.type)}
                />
            </Modal>
            <ChooseTestDatasetActionIcon
                onClick={open}
                disabled={props.disabled}
                type={props.type} />
        </>
    );
}

function HistoricalUserDataSection(props: {
    values: TestDataFormValues,
    user: rdlTypes.User | undefined,
}) {
    const [opened, { close, open }] = useDisclosure(false);

    return (
        <>
            <Modal
                opened={opened}
                onClose={close}
                title={`User Attributes (${props.values.historicalUserClientId})`}
            >
                <ReactJson src={props.user === undefined ? ({ "loaded": false }) : props.user.user_attributes} name={"user_attributes"} />
            </Modal>
            <Paper shadow="xs" p="md" onClick={open} sx={{cursor: "pointer"}}>
                <Group>
                    <Avatar radius="xl" color="blue" size="sm" />
                    <Text size="xs">User {props.values.historicalUserClientId}</Text>
                </Group>
            </Paper>
        </>
    )
}

export function EditTestDataModal(props: {
    onSubmit: OnSubmitFunctionType,
    values: TestDataFormValues,
    onRemove: OnRemoveFunctionType,
    user: rdlTypes.User | undefined,
    disabled: boolean,
}) {
    const [opened, { close, open }] = useDisclosure(false);
    const onSubmit = function (values: TestDataFormValues) {
        return props.onSubmit(values).then(() => close());
    }

    return (
        <>
            <Modal
                opened={opened}
                onClose={close}
                title="Edit test data"
            >
                <EditTestDataForm
                    onSubmit={onSubmit}
                    onRemove={close}
                    values={props.values}
                />
            </Modal>
            <Paper shadow="xs" p="md">
                <Group>
                    <TestDataTitle testData={props.values} />
                    <Menu shadow="md" width={200} position="bottom-start">
                        <Menu.Target>
                            <ActionIcon variant="transparent" color="gray">
                                <IconDotsVertical size={16} />
                            </ActionIcon>
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item disabled={props.disabled} icon={<IconPencil size={16} />} onClick={open}>Edit</Menu.Item>
                            <Menu.Item disabled={props.disabled} icon={<IconTrash size={16} />} onClick={() => props.onRemove()}>Delete</Menu.Item>
                        </Menu.Dropdown>
                    </Menu>
                </Group>
            </Paper >
            {props.values.type === TestDataTypes.HistoricalUserData && <HistoricalUserDataSection values={props.values} user={props.user} />}
        </>
    );
}

export function TestDataInput(props: {
    testData: TestDataFormValues | undefined,
    onSubmit: OnSubmitFunctionType,
    onRemove: OnRemoveFunctionType,
    user: rdlTypes.User | undefined,
    disabled: boolean,
}) {
    const testData = props.testData;

    if (testData === undefined) {
        return (
            <Group>
                {[
                    TestDataTypes.HistoricalUserData,
                    TestDataTypes.JSON,
                    TestDataTypes.ModelAPI,
                    TestDataTypes.ProductCatalog,
                    TestDataTypes.TestDataset,
                ].map((type, index) => (
                    <ChooseDatasetModal
                        key={index}
                        disabled={props.disabled}
                        onSubmit={(values: TestDataFormValues) => props.onSubmit(values)}
                        type={type as TestDataTypes}
                    />
                ))}
            </Group>
        );
    }

    return (
        <EditTestDataModal
            disabled={props.disabled}
            key={testData.key}
            onSubmit={(values: TestDataFormValues) => props.onSubmit(values)}
            values={testData}
            onRemove={() => props.onRemove()}
            user={props.user}
        />
    );
}
