import _ from "underscore";

import * as rdlTypes from "../../models/RDLDataTypes";
import { Alert } from "../Alert";
import {
    Box,
    Flex,
    ScrollArea,
    SimpleGrid,
    Stack,
    Text,
    Title,
} from "@mantine/core";
import { EventStatisticsBarChart } from "../charts/EventStatisticsBarChart";
import { EventStatsMap, RateStatistic, RateStatsMap } from "../../utilities/statistics-api/types";
import { LAYOUT_TO_SIMPLE_GRID_PROPS } from "../../utilities/layout"
import { MaybeDateRange, isDateRange } from "../../utilities/date-range";
import { MetricFormValues } from "../forms/metric/metric-form-context";
import { RateStatisticsBarChart } from "../charts/RateStatisticsBarChart";
import { ReactNode, useEffect, useState } from "react";
import { RubberDuckyLabsApi } from "../../RDLApi";
import { StatisticDisplay } from "../StatisticDisplay";
import { calculateOperation } from "../../utilities/metric-calculations/apply-calculations";
import { formatPercentage } from "../../utilities/format-values";
import { isRateMetric } from "../../utilities/metric-calculations/metrics";
import { useApiContext, useRDLConfig } from "../../App";
import NotImplementedModal from "../NotImplementedModal";

function ChartTitleText(props: { children: ReactNode }) {
    return (
        <Text size="xs" weight="bold" color="#666" mt="xs">
            {props.children}
        </Text>
    )
}


function EventStatsNoAggregation(props: {
    metrics: MetricFormValues[],
    eventStatsMap: EventStatsMap,
    rateStatsMap: RateStatsMap,
}) {
    const columns = props.metrics.map((metric) => {
        const eventType: rdlTypes.EventType = rdlTypes.EventType[metric.name];
        const stat = isRateMetric(eventType)
            ? (props.rateStatsMap.get(eventType)?.at(0)?.rate)
            : (props.eventStatsMap.get(eventType)?.at(0)?.count);
        const formattedStat = _.isUndefined(stat) ? 'No data'
            : isRateMetric(eventType)
                ? formatPercentage(stat, 2)
                : stat.toLocaleString();
        const title = isRateMetric(eventType)
            ? `Average ${metric.displayName}`
            : `Count of ${metric.displayName}`
        return (
            <StatisticDisplay key={metric.key} title={title} value={formattedStat} isPercentage={false} />
        );
    });

    return (
        <SimpleGrid {...LAYOUT_TO_SIMPLE_GRID_PROPS.md}>
            {columns}
        </SimpleGrid>
    );
}


function EventStatsOneAggregation(props: {
    metrics: MetricFormValues[],
    aggregation: string,
    eventStatsMap: EventStatsMap,
    rateStatsMap: RateStatsMap,
}) {
    const columns = props.metrics.map((metric, index) => {
        const eventType: rdlTypes.EventType = rdlTypes.EventType[metric.name];
        if (isRateMetric(eventType)) {
            const stats = props.rateStatsMap.get(eventType);
            return (
                <Stack key={metric.key}>
                    <ChartTitleText>
                        Average {metric.displayName} by {props.aggregation.replace('event.event_attributes.', '')}
                    </ChartTitleText>
                    {_.isUndefined(stats)
                        ? <Text>No data</Text> :
                        <RateStatisticsBarChart
                            stats={stats}
                            aggregation={props.aggregation}
                            colorIndex={index}
                            metric={metric}
                        />}
                </Stack>
            )
        } else {
            const stats = props.eventStatsMap.get(eventType)
            return (
                <Stack key={metric.key}>
                    <ChartTitleText>
                        Count of {metric.displayName} by {props.aggregation.replace('event.event_attributes.', '')}
                    </ChartTitleText>
                    {_.isUndefined(stats)
                        ? <Text>No data</Text> :
                        <EventStatisticsBarChart
                            stats={stats}
                            aggregation={props.aggregation}
                            colorIndex={index}
                            metric={metric}
                        />}
                </Stack>
            )
        }
    });

    return (
        <SimpleGrid {...LAYOUT_TO_SIMPLE_GRID_PROPS.md}>
            {columns}
        </SimpleGrid>
    );
}

function EventStatsAggregationRow(props: {
    metric: MetricFormValues,
    colorIndex: number,
    stats: rdlTypes.EventStatistic[],
    aggregations: string[],

}) {
    const firstAggregation = props.aggregations.at(0) ?? '';
    const secondAggregation = props.aggregations.at(1) ?? '';
    const groups = _.groupBy(props.stats, ({ aggregation }) => (
        !_.isUndefined(aggregation) && firstAggregation in aggregation
            ? aggregation[firstAggregation]
            : 'Undefined'
    ));

    return (
        <Stack key={props.metric.key}>
            <Title order={2}>
                Count of {props.metric.displayName} by {secondAggregation.replace('event.event_attributes.', '')}
            </Title>
            <ScrollArea h={200} type="always">
                <Flex>
                    {Object.entries(groups).map(([firstAggregationValue, aggregationStats]) => (
                        <Box key={firstAggregationValue} h={200}>
                            <ChartTitleText>
                                {firstAggregation.replace('event.event_attributes.', '')} is {firstAggregationValue}
                            </ChartTitleText>
                            <EventStatisticsBarChart
                                stats={aggregationStats}
                                aggregation={secondAggregation}
                                colorIndex={props.colorIndex}
                                metric={props.metric}
                            />
                        </Box>
                    ))}
                </Flex>
            </ScrollArea>
        </Stack>
    );
}

function RateStatsAggregationRow(props: {
    metric: MetricFormValues,
    colorIndex: number,
    stats: RateStatistic[],
    aggregations: string[],
}) {
    const firstAggregation = props.aggregations.at(0) ?? '';
    const secondAggregation = props.aggregations.at(1) ?? '';
    const groups = _.groupBy(props.stats, ({ aggregation }) => (
        !_.isUndefined(aggregation) && firstAggregation in aggregation
            ? aggregation[firstAggregation]
            : 'Undefined'
    ));

    return (
        <Stack key={props.metric.key}>
            <Title order={2}>
                Average {props.metric.displayName} by {secondAggregation.replace('event.event_attributes.', '')}
            </Title>
            <ScrollArea h={200} type="always">
                <Flex>
                    {Object.entries(groups).map(([firstAggregationValue, aggregationStats]) => (
                        <Box key={firstAggregationValue} h={200}>
                            <ChartTitleText>
                                {firstAggregation.replace('event.event_attributes.', '')} is {firstAggregationValue}
                            </ChartTitleText>
                            <RateStatisticsBarChart
                                stats={aggregationStats}
                                aggregation={secondAggregation}
                                colorIndex={props.colorIndex}
                                metric={props.metric}
                            />
                        </Box>
                    ))}
                </Flex>
            </ScrollArea>
        </Stack>
    );
}


function EventStatsTwoAggregations(props: {
    metrics: MetricFormValues[],
    aggregations: string[],
    eventStatsMap: EventStatsMap,
    rateStatsMap: RateStatsMap,
}) {
    const rows = props.metrics.map((metric, index) => {
        const eventType: rdlTypes.EventType = rdlTypes.EventType[metric.name];
        if (isRateMetric(eventType)) {
            const stats = props.rateStatsMap.get(eventType);
            if (!_.isUndefined(stats)) {
                return <RateStatsAggregationRow
                    key={metric.key}
                    stats={stats}
                    metric={metric}
                    aggregations={props.aggregations}
                    colorIndex={index}
                />
            }
        } else {
            const stats = props.eventStatsMap.get(eventType);
            if (!_.isUndefined(stats)) {
                return <EventStatsAggregationRow
                    key={metric.key}
                    stats={stats}
                    metric={metric}
                    aggregations={props.aggregations}
                    colorIndex={index}
                />
            }
        }
        return (
            <Stack key={metric.key}>
                <Title order={2}>{metric.displayName}</Title>
                <Text size="xs">No Data</Text>
            </Stack>
        )
    });

    return (
        <Stack>
            {rows}
        </Stack>
    );
}


export default function EventStatisticsPanel(props: {
    metrics: MetricFormValues[],
    dateRange: MaybeDateRange,
    aggregations: string[],
}) {
    const { metrics, dateRange, aggregations } = props;
    const apiContext = useApiContext();
    const config = useRDLConfig();
    const [errorMessage, setErrorMessage] = useState('');
    const [eventStatsMap, setEventStatsMap] = useState<EventStatsMap>(new Map());
    const [rateStatsMap, setRateStatsMap] = useState<RateStatsMap>(new Map());
    const [notImplemented, setNotImplemented] = useState(false);

    useEffect(() => {
        if (!_.isNull(apiContext) && isDateRange(dateRange)) {
            const [startDate, endDate] = dateRange;
            const requests = config.metricTypes.map((eventType) => (
                RubberDuckyLabsApi
                    .getInstance(apiContext)
                    .getEventsStatistics(eventType, startDate, endDate, aggregations)
                    .then((eventStatsResponse) => setEventStatsMap((prevMap) => {
                        const newMap = new Map(prevMap);
                        newMap.set(eventType, eventStatsResponse.data);
                        return newMap;
                    }))
            ));

            Promise.allSettled(requests)
                .then((results) => {
                    const rejected = results.filter((result): result is PromiseRejectedResult => result.status === 'rejected');
                    if (!_.isEmpty(rejected)) {
                        if (rejected.some(({ reason }) => reason.response?.status === 404)) {
                            setNotImplemented(true);
                        } else {
                            setErrorMessage(rejected.map(({ reason }) => reason).join('. '));
                        }
                    }
                });
        }

        return () => {
            setEventStatsMap(new Map());
        }
    }, [config.metricTypes, dateRange, apiContext, aggregations]);

    useEffect(() => {
        config.metricCalculations.forEach(({ eventType, calculation }) => {
            const { left, right, op } = calculation;
            const leftStats = eventStatsMap.get(left);
            const rightStats = eventStatsMap.get(right);

            if (leftStats === undefined || rightStats === undefined) {
                return;
            }

            const newEventStats = leftStats.map(({ aggregation: leftAgg, count: leftCount }) => {
                const rightCount = rightStats.find(({ aggregation: rightAgg }) => _.isEqual(leftAgg, rightAgg))?.count;
                const rate = calculateOperation(leftCount, rightCount, op);

                return {
                    aggregation: leftAgg,
                    rate,
                }
            });

            setRateStatsMap((prevMap) => {
                const newMap = new Map(prevMap);
                newMap.set(eventType, newEventStats);
                return newMap;
            });
        });

        return () => {
            setRateStatsMap(new Map());
        }
    }, [config.metricCalculations, eventStatsMap])

    const eventStatsDisplay = (() => {
        switch (aggregations.length) {
        case 0:
            return (
                <EventStatsNoAggregation
                    metrics={metrics}
                    eventStatsMap={eventStatsMap}
                    rateStatsMap={rateStatsMap}
                />
            );
        case 1:
            return (
                <EventStatsOneAggregation
                    metrics={metrics}
                    aggregation={aggregations[0]}
                    eventStatsMap={eventStatsMap}
                    rateStatsMap={rateStatsMap}
                />
            );
        case 2:
        default:
            return (
                <EventStatsTwoAggregations
                    metrics={metrics}
                    aggregations={aggregations}
                    eventStatsMap={eventStatsMap}
                    rateStatsMap={rateStatsMap}
                />
            );
        }
    })();

    return (
        <>
            {!_.isEmpty(errorMessage) && <Alert message={errorMessage} onClose={() => setErrorMessage('')} />}
            <NotImplementedModal opened={notImplemented} />
            {eventStatsDisplay}
        </>
    );
}
