import {
    Alert,
    Box,
    Button,
    Center,
    NumberInput,
    Overlay,
    Stack,
    Text
} from "@mantine/core"
import { ConfidenceLevelSlider, PercentageInput } from "./Inputs";
import { ConversionRateABTestCalculatorValues } from "../ABTestCalculatorPage";
import { IconAlertCircle } from "@tabler/icons-react";
import { isStatisticallySignificant as calculateIsStatisticallySignificant, calculateMinimumSampleSize } from "../../../utilities/statistical-significance";
import { formatPercentage } from "../../../utilities/format-values";
import { isInRange, useForm } from "@mantine/form";
import { useEffect, useMemo, useState } from "react";


interface StatisticalSignificanceABTestCalculatorValues {
    baselineConversionRate: number;
    effectSize: number;
    variantConversionRate: number;
    sampleSize: number;
    confidenceLevel: number;
}


interface StatisticalSignificanceABTestCalculatorProps {
    metricName: string;
    initialValues: ConversionRateABTestCalculatorValues;
    onChange: (values: ConversionRateABTestCalculatorValues) => void;
}

function isEffectSizeSmaller(baseline: number, effectSize: number, variant: number): boolean {
    return Math.abs(variant / baseline - 1) < effectSize - 0.01;
}

export function StatisticalSignificanceForABTestCalculator({ metricName, initialValues, onChange } : StatisticalSignificanceABTestCalculatorProps) {
    const [resultsVisible, setResultsVisible] = useState(true);

    const form = useForm<StatisticalSignificanceABTestCalculatorValues>({
        initialValues: {
            ...initialValues,
            variantConversionRate: initialValues.baselineConversionRate * (1 + initialValues.effectSize),
        },
        validate: {
            baselineConversionRate: isInRange({min: 0, max: 1}, "Enter a number between 0% and 100%"),
            variantConversionRate: isInRange({min: 0, max: 1}, "Enter a number between 0% and 100%"),
            sampleSize: isInRange({min: 1}, "Enter a number greater than 1"),
        },
        transformValues: (values) => ({
            ...values,
            confidenceLevel: Math.min(0.99, values.confidenceLevel),
        }),
    });

    useEffect(() => {
        form.validate();
    }, [form.values]);

    const values = useMemo(() => form.getTransformedValues(), [form.values]);

    useEffect(() => {
        onChange({
            baselineConversionRate: values.baselineConversionRate,
            effectSize: values.effectSize,
            confidenceLevel: values.confidenceLevel,
            sampleSize: initialValues.sampleSize,
        });
    }, [values]);

    const isStatisticallySignificant = useMemo(() => {
        const { baselineConversionRate, sampleSize, confidenceLevel, variantConversionRate } = values;
        return calculateIsStatisticallySignificant(baselineConversionRate, variantConversionRate, sampleSize, confidenceLevel);
    }, [values]);

    const minimumSampleSize = useMemo(() => {
        const { baselineConversionRate, effectSize, confidenceLevel } = values;
        return calculateMinimumSampleSize(baselineConversionRate, effectSize, confidenceLevel);
    }, [values]);

    useEffect(() => {
        if(values.sampleSize < minimumSampleSize || isEffectSizeSmaller(values.baselineConversionRate, values.effectSize, values.variantConversionRate)) {
            setResultsVisible(false);
        } else {
            setResultsVisible(true);
        }
    }, [values]);

    return (
        <form onSubmit={form.onSubmit((values) => console.log(values))}>
            <Stack maw={600} mx="auto" mt="xs">
                <PercentageInput
                    required
                    label={`Variant A ${metricName}`}
                    {...form.getInputProps("baselineConversionRate")}
                />
                <PercentageInput
                    required
                    label={`Pre-test analysis expected effect size (lift or drop), relative to baseline`}
                    {...form.getInputProps("effectSize")}
                />
                <PercentageInput
                    required
                    label={`Variant B ${metricName}`}
                    {...form.getInputProps("variantConversionRate")}
                />
                <NumberInput
                    required
                    label={`Sample Size per variation`}
                    min={1}
                    {...form.getInputProps("sampleSize")}
                />
                <ConfidenceLevelSlider mb="md" {...form.getInputProps("confidenceLevel")} />

                {(values.sampleSize < minimumSampleSize) && <Alert icon={<IconAlertCircle />} color="red" title="Sample Size Warning">
                    Given your expected effect size (lift or drop) of <Text span fw="bold">{formatPercentage(values.effectSize)}</Text> and
                    confidence level of <Text span fw="bold">{formatPercentage(values.confidenceLevel)}</Text>,
                    we recommend reaching a minimum sample size of <Text span fw="bold">{minimumSampleSize}</Text> per
                    variation before checking statistical significance.
                    Your results may be unreliable.
                </Alert>}
                {isEffectSizeSmaller(values.baselineConversionRate, values.effectSize, values.variantConversionRate) && <Alert icon={<IconAlertCircle />} color="red" title="Effect Size Warning">
                    Your experiment was designed to detect changes greater than <Text span fw="bold">{formatPercentage(values.baselineConversionRate * (1 + values.effectSize), 2)}</Text> and
                    less than <Text span fw="bold">{formatPercentage(values.baselineConversionRate * (1 - values.effectSize), 2)}</Text>.
                    Your results may be unreliable.
                </Alert>}

                <Box sx={{position: "relative"}}>
                    <Center mb="sm">
                        {!resultsVisible && <Overlay blur={15} center>
                            <Button color="red" onClick={() => setResultsVisible(true)}>
                            click to reveal
                            </Button>
                        </Overlay>}
                        <Stack spacing="3" align="center">
                            <Text size="sm" weight={500} color="gray.9">Analysis</Text>
                            {isStatisticallySignificant
                                ? <Text color="blue" fw="bold" size="xl">Statistically Significant</Text>
                                : <Text color="red" fw="bold" size="xl">Not Statistically Significant</Text>
                            }
                        </Stack>
                    </Center>
                </Box>
            </Stack>
        </form>
    )
}
