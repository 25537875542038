import * as rdlTypes from "../models/RDLDataTypes";
import _ from "underscore";


export const isUndefinedOrNull = function(value: any): value is (undefined | null) {
    return _.isUndefined(value) || _.isNull(value);
}

export const isRDLId = function(value: any): value is rdlTypes.Id {
    return _.isNumber(value) || _.isString(value);
}
