import { NumberInput, SimpleGrid } from "@mantine/core";


import { useRuleFormContext } from "./rule-form-context";


export function NumericalRangeInput() {
    const form = useRuleFormContext();

    return (
        <SimpleGrid cols={2}>
            <NumberInput
                placeholder="min"
                label="min"
                step={0.0001}
                precision={4}
                max={form.values.data.filterData.filterValues.numberData.max}
                {...form.getInputProps('data.filterData.filterValues.numberData.min')}
            />
            <NumberInput
                placeholder="max"
                label="max"
                step={0.0001}
                precision={4}
                min={form.values.data.filterData.filterValues.numberData.min}
                {...form.getInputProps('data.filterData.filterValues.numberData.max')}
            />
        </SimpleGrid>
    );
}
