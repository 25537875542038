import _ from "underscore";

import { ItemCardProps } from "../components/ItemCard";
import { RDLConfigType } from "../models/RDLConfig";
import {
    RuleFormValues,
    isRuleFilter,
    isRuleLimit,
    isRulePolicy,
    isRuleShuffle,
    isRuleSort,
    isRuleWeightedScore
} from "../components/forms/rule/rule-form-context"
import { applyFilter } from "./filters";
import { applySort } from "./sort";
import { applyWeightedScore } from "./weighted-score";

export const applyRule = function(rule: RuleFormValues, cards: ItemCardProps[], config: RDLConfigType): ItemCardProps[] {
    if (isRuleShuffle(rule)) {
        return _.shuffle(cards);
    } else if (isRuleLimit(rule)) {
        return cards.slice(0, rule.data.limitData.limit);
    } else if (isRuleFilter(rule)) {
        return applyFilter(rule.data.filterData, cards);
    } else if (isRuleSort(rule)) {
        return applySort(rule.data.sortData, cards);
    } else if (isRuleWeightedScore(rule)) {
        return applyWeightedScore(rule.data.weightedscoreData, cards);
    } else if (isRulePolicy(rule)) {
        return rule.data.policyData.rules.reduce((card, rule) => applyRule(rule, card, config), cards);
    }

    throw Error(`Invalid rule type ${rule.type}`);
}
