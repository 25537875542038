import * as rdlTypes from "../models/RDLDataTypes";


export function paginate<T> (
    apiCall: (after?: rdlTypes.Id) => Promise<rdlTypes.Pagination<T>>,
    callback: (data: T[]) => void,
    after?: rdlTypes.Id | null
): Promise<void> {
    if (after === null) {
        return Promise.resolve();
    }

    return apiCall(after)
        .then((response) => {
            callback(response.data);
            return response.paging.after;
        })
        .then((after) => paginate(apiCall, callback, after));
}
