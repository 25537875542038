type StatisticsAggregation = Record<string, any>;

export enum StatisticsTimescaleValue {
    ALL = "all",
    DAY = "day",
    WEEK = "week",
    MONTH = "month",
    YEAR = "year",
}

export enum StatisticsFunctionType {
    COUNT = "count",
}

export interface Statistic {
    timescale_value: StatisticsTimescaleValue;
    aggregation_value: StatisticsAggregation;
    func_value: Record<StatisticsFunctionType, number>;
}

export interface StatisticsAfter {
    aggregation_value: StatisticsAggregation;
    timescale_value?: StatisticsTimescaleValue;
}


export interface StatisticsPaging {
    after?: StatisticsAfter | null;
}


export interface StatisticsPagination {
    data: Statistic[];
    count: number;
    paging: StatisticsPaging;
}
