import {
    Anchor,
    Badge,
    Breadcrumbs,
    Flex,
    Group,
    SimpleGrid,
    Space,
    Stack,
    Text,
    Title
} from '@mantine/core'
import { InView } from 'react-intersection-observer';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from "react";
import { useListState } from "@mantine/hooks";
import ReactJson from 'react-json-view';

import * as dateFNS from "date-fns";

import { Alert } from "../../components/Alert";
import { ColumnProvider } from '../../context/ColumnContext';
import { DraggableRules } from '../../components/forms/rule/RuleModals';
import { ItemCard, ItemCardProps } from '../../components/ItemCard';
import { ItemNotFoundPage } from '../errors/ItemNotFoundPage';
import { RubberDuckyLabsApi } from '../../RDLApi';
import { RuleFormValues } from '../../components/forms/rule/rule-form-context';
import { TestDataset } from '../../models/RDLConfig';
import { applyRule } from '../../utilities/rules';
import { parseTestDatasetItems } from './utils';
import { useApiContext, useRDLConfig } from '../../App';
import LoadingDetailsPage from '../LoadingDetailsPage';
import _ from 'underscore';


const SCROLL_PAGE_SIZE = 10;

function TestDatasetDetailBreadcrumbs(props: { dataset: any }) {
    const items = [
        { title: 'Test Datasets', route: '..' },
        { title: props.dataset.name, route: '#' },
    ].map((item, index) => (
        <Anchor key={index} component={Link} to={item.route}>
            {item.title}
        </Anchor>
    ));

    return (
        <Breadcrumbs mt="md" separator=">">{items}</Breadcrumbs>
    )
}

export default function TestDatasetsDetailPage() {
    const { testDatasetId } = useParams();
    const apiContext = useApiContext();
    const config = useRDLConfig();

    const [dataset, setDataset] = useState<TestDataset | undefined | null>(undefined);
    const [apiError, setApiError] = useState<string>('');
    useEffect(() => {
        if (testDatasetId !== undefined && !_.isNull(apiContext)) {
            RubberDuckyLabsApi.getInstance(apiContext)
                .getTestDataset(parseInt(testDatasetId))
                .then((response) => {
                    setDataset(response);
                })
                .catch((error) => {
                    setApiError(error.message);
                    setDataset(null);
                });
        }

        return () => {
            setDataset(undefined);
        }
    }, [apiContext]);

    const [scrollLimit, setScrollLimit] = useState(SCROLL_PAGE_SIZE);
    const [rules, rulesHandlers] = useListState<RuleFormValues>([]);

    const datasetCards: ItemCardProps[] = useMemo(
        () => parseTestDatasetItems(dataset),
        [dataset],
    );

    const displayCards = useMemo(() => {
        let newDisplayCards = datasetCards;
        const activeRules = rules.filter((rule) => rule.active);
        for (const rule of activeRules) {
            newDisplayCards = applyRule(rule, newDisplayCards, config);
        }
        return newDisplayCards;
    }, [rules, datasetCards]);

    if (dataset === undefined) {
        return <LoadingDetailsPage />;
    }

    if (testDatasetId === undefined || dataset === null) {
        return <ItemNotFoundPage itemName="Test Dataset" itemId={testDatasetId} />;
    }

    const tags = dataset.tags.map((tag) => (
        <Badge key={tag} color="pink">{tag}<Space w="sm" /></Badge>
    ))

    return (
        <ColumnProvider cards={datasetCards}>
            <Stack>
                {!_.isEmpty(apiError) && <Alert onClose={() => setApiError('')} message={apiError} />}
                <TestDatasetDetailBreadcrumbs dataset={dataset} />
                <Group>
                    <Title order={1}>Test Dataset Details</Title>
                    <Badge color="green">{dataset.environment}</Badge>
                </Group>
                <Group>
                    <Text c="dimmed">Created {dateFNS.formatDistanceToNow(dateFNS.fromUnixTime(dataset.timestamp), { addSuffix: true })}</Text>
                    {tags}
                </Group>
                <Text>Test Dataset Name: {dataset.name}</Text>
                <ReactJson src={dataset} name={"dataset"} collapsed={true} />
                <Flex justify="left" mb="lg" direction="column">
                    <DraggableRules rules={rules} ruleHandlers={rulesHandlers} config={config} />
                </Flex>
                {displayCards.length > 0 && <SimpleGrid cols={5} pt="md"
                    breakpoints={[
                        { maxWidth: 'xl', cols: 3, spacing: 'xl' },
                        { maxWidth: 'lg', cols: 2, spacing: 'lg' },
                        { maxWidth: 'md', cols: 2, spacing: 'md' },
                        { maxWidth: 'sm', cols: 1, spacing: 'sm' },
                        { maxWidth: 'xs', cols: 1, spacing: 'sm' },
                    ]}
                >
                    {displayCards.slice(0, scrollLimit).map((card, i) => <ItemCard {...card} key={card.item === undefined ? i : card.item.item_id} />)}
                    <InView onChange={(inView) => inView && setScrollLimit(Math.min(scrollLimit + SCROLL_PAGE_SIZE))} />
                </SimpleGrid>}
            </Stack>
        </ColumnProvider>
    );
}
