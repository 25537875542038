import { MainLink, mainLinks } from '../mainLink';
import { SimpleGrid, Stack, Text, Title } from '@mantine/core';
import { useLocation } from "react-router-dom";
import { useRDLConfig, useRDLUser } from '../App';
import PageIntroCard from './PageIntroCard';
import _ from "underscore";


export default function MainLinksIndexPage() {
    const config = useRDLConfig();
    const location = useLocation();
    const rdlUser = useRDLUser();
    const links = mainLinks(rdlUser?.is_site_admin ?? false, config);
    const mainLink: MainLink | undefined = links.find((mainLink) => mainLink.linkTo === location.pathname);

    if (_.isUndefined(mainLink)) {
        throw new Error("Page not found");
    }

    const childLinks = mainLink.children ?? [];
    const cards = childLinks.map((childLink) => <PageIntroCard key={childLink.linkTo} {...childLink} />)

    return (
        <Stack>
            <Title order={1}>{mainLink.label}</Title>
            <Text c="dimmed">{mainLink.description}</Text>
            <SimpleGrid cols={2}>
                {cards}
            </SimpleGrid>
        </Stack>
    );
}
