import { RuleTypes, useRuleFormContext } from "./rule-form-context";
import { Select } from "@mantine/core";

export function TypeInput() {
    const form = useRuleFormContext();

    const data = [
        { value: RuleTypes.Filter, label: "Filter" },
        { value: RuleTypes.Shuffle, label: "Shuffle" },
        { value: RuleTypes.Limit, label: "Limit" },
        { value: RuleTypes.Sort, label: "Sort" },
        { value: RuleTypes.WeightedScore, label: "Weighted Score" },
        { value: RuleTypes.Policy, label: "Policy" },
    ];

    return (
        <Select
            withinPortal
            label="Rule type"
            placeholder="Pick one"
            data={data}
            {...form.getInputProps('type')}
        />
    );
}
