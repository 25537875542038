import * as rdlTypes from "../../models/RDLDataTypes";
import {
    ActionIcon,
    Box,
    Button,
    Flex,
    Group,
    LoadingOverlay,
    Modal,
    Stack,
    Table,
    Text,
    Title,
    Tooltip
} from "@mantine/core"
import { Link, useNavigate, useParams } from "react-router-dom";
import _ from "underscore";


import { Alert } from "../../components/Alert";
import { IconTrash } from "@tabler/icons-react";
import { PipelineBreadcrumbs, createPipelineTitle } from "../../components/PipelineBreadcrumbs";
import { RubberDuckyLabsApi } from "../../RDLApi";
import { isUndefinedOrNull } from "../../utilities/types";
import { useApiContext } from "../../App";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import CreateRankingPipelineStageForm from "../../components/forms/pipelines/CreateRankingPipelineStageForm";
import ExamplePipelineChart from "../../components/ExamplePipelineChart";


function PipelineStagesTable(props: {
    rankingPipeline: rdlTypes.RankingPipeline,
    reloadRankingPipeline: (rankingPipelineId: number) => Promise<void>,
}) {
    const { rankingPipeline, reloadRankingPipeline } = props;
    const apiContext = useApiContext();

    const deleteRankingPipelineStage = (rankingPipelineStage: rdlTypes.RankingPipelineStage) => {
        if (!_.isNull(apiContext) && !isUndefinedOrNull(rankingPipeline)) {
            return RubberDuckyLabsApi.getInstance(apiContext)
                .deleteRankingPipelineStage(rankingPipeline.id, rankingPipelineStage)
                .then(() => reloadRankingPipeline(rankingPipeline.id));
        }

        return Promise.resolve();
    }

    const textSize = "sm"
    const stagesById: Record<number, rdlTypes.RankingPipelineStage> = _.indexBy(rankingPipeline.stages, 'id');
    const rankingPipelineStages = _.sortBy(rankingPipeline.stages, 'stage_order');
    const rows = rankingPipelineStages.map((stage) => {
        const {name, id, stage_type, input_config } = stage;
        const inputs = (() => {
            switch(stage_type) {
            case rdlTypes.RankingPipelineStageType.CandidateGeneration:
                return <Text size={textSize} c="dimmed" tt="capitalize" fs="italic">{`(${stage_type})`}</Text>;
            default:
                return <Text size={textSize}>
                    {_.chain(input_config.stage_ids)
                        .map((id) => stagesById[id])
                        .reject(_.isUndefined)
                        .pluck('name')
                        .join(', ')
                        .value()}
                </Text>;
            }
        })();

        const deleteRankingPipelineStageButton = (
            <Tooltip label="Delete stage" withArrow>
                <ActionIcon
                    variant="light"
                    color="red"
                    size="sm"
                    onClick={() => deleteRankingPipelineStage(stage)}>
                    <IconTrash />
                </ActionIcon>
            </Tooltip>
        )

        return (
            <tr key={id}>
                <td><Text size={textSize}>{name}</Text></td>
                <td>{inputs}</td>
                <td>{deleteRankingPipelineStageButton}</td>
            </tr>
        );
    });

    return (
        <Table>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Inputs</th>
                    <th/>
                </tr>
            </thead>
            <tbody>{rows}</tbody>
        </Table>
    );
}


function PipelineDetails(props: {
    rankingPipeline: rdlTypes.RankingPipeline,
    reloadRankingPipeline: (rankingPipelineId: number) => Promise<void>,
}) {
    const { rankingPipeline, reloadRankingPipeline } = props;
    const rankingPipelineStages = _.sortBy(rankingPipeline.stages, 'stage_order');
    const [opened, { open, close }] = useDisclosure(false);

    return (
        <>
            <Title order={2}>Example Pipeline Chart</Title>
            <Box h={200}>
                <ExamplePipelineChart rankingPipeline={rankingPipeline} />
            </Box>
            <Group align="flex-start" position="apart" noWrap>
                {rankingPipelineStages.map(({name, id}) => <Text key={id} size="xs" weight={700} truncate>{name}</Text>)}
            </Group>
            <Group position="apart">
                <Title order={2}>Pipeline Stages</Title>
                <Modal opened={opened} onClose={close} title="Create a New Ranking Pipeline Stage">
                    <CreateRankingPipelineStageForm
                        rankingPipeline={rankingPipeline}
                        onSubmit={() => reloadRankingPipeline(rankingPipeline.id).then(close)}
                    />
                </Modal>
                <Button
                    variant="light"
                    onClick={open}
                >
                    Add Stage
                </Button>
            </Group>
            <PipelineStagesTable reloadRankingPipeline={reloadRankingPipeline} rankingPipeline={rankingPipeline} />
        </>
    )
}


export default function PipelinesDetailPage() {
    const { rankingPipelineId } = useParams();
    const apiContext = useApiContext();
    const [rankingPipeline, setRankingPipeline] = useState<rdlTypes.RankingPipeline | undefined | null>(undefined);
    const navigate = useNavigate();

    const reloadRankingPipeline = (rankingPipelineId: number): Promise<void> => {
        if (!_.isNull(apiContext)) {
            return RubberDuckyLabsApi.getInstance(apiContext)
                .getRankingPipeline(rankingPipelineId)
                .then(setRankingPipeline)
                .catch(() => setRankingPipeline(null));
        }
        return Promise.resolve();
    }

    useEffect(() => {
        if (_.isUndefined(rankingPipelineId)) {
            setRankingPipeline(null);
        } else if (!_.isNull(apiContext)) {
            const integerRankingPipelineId = parseInt(rankingPipelineId);
            reloadRankingPipeline(integerRankingPipelineId);
        }

        return () => {
            setRankingPipeline(undefined);
        }
    }, [apiContext, rankingPipelineId]);

    const deletePipeline = () => {
        if (!_.isNull(apiContext) && !isUndefinedOrNull(rankingPipeline)) {
            RubberDuckyLabsApi.getInstance(apiContext)
                .deleteRankingPipeline(rankingPipeline)
                .then(() => navigate('..'))
        }
    }

    return (
        <Stack>
            {_.isNull(rankingPipeline) && <Alert message={`Ranking pipeline ${rankingPipelineId} not found`} onClose={_.noop} />}
            <LoadingOverlay visible={_.isUndefined(rankingPipeline)} />
            <PipelineBreadcrumbs rankingPipeline={rankingPipeline} />
            <Group position="apart">
                <Title order={1}>{createPipelineTitle(rankingPipeline)}</Title>
                <Tooltip label="Delete pipeline" withArrow>
                    <ActionIcon variant="light" color="red" onClick={deletePipeline}>
                        <IconTrash />
                    </ActionIcon>
                </Tooltip>
            </Group>

            {!isUndefinedOrNull(rankingPipeline) && <Text c="dimmed">View details of the {createPipelineTitle(rankingPipeline)} pipeline.</Text>}
            {!isUndefinedOrNull(rankingPipeline) && <Group>
                <Flex><Button variant="light" component={Link} to="traces">Explore traces</Button></Flex>
                <Flex><Button variant="light" component={Link} to="metrics">Explore metrics</Button></Flex>
            </Group>}
            {!isUndefinedOrNull(rankingPipeline) && <PipelineDetails
                rankingPipeline={rankingPipeline}
                reloadRankingPipeline={reloadRankingPipeline}
            />}
        </Stack>
    )
}
