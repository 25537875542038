import { Link, useLocation } from 'react-router-dom';
import { MainLink } from './mainLink';
import { NavLink, ThemeIcon, Tooltip } from '@mantine/core';


export default function RDLNavLink(props: MainLink) {
    const location = useLocation();
    const Icon = props.icon;

    return (
        <Tooltip label={props.label} position='right'>
            <NavLink
                component={Link}
                to={props.linkTo}
                color={props.color}
                icon={<ThemeIcon color={props.color} variant="light">{<Icon size={16} />}</ThemeIcon>}
                key={props.label}
                active={location.pathname.startsWith(props.linkTo)}
                childrenOffset={0}
            />
        </Tooltip>
    );
}
