import _ from "underscore";

export const batchRequests = function (requests: (() => Promise<any>)[], onSuccess: (value: any) => void, onError: (reason: string) => void): Promise<void> {
    if (requests.length === 0) {
        return Promise.resolve();
    }

    const BATCH_SIZE = 6;
    const firstRequests = _.first(requests, BATCH_SIZE);
    const restRequests = _.rest(requests, BATCH_SIZE);

    return Promise
        .allSettled(firstRequests.map((request) => request()))
        .then((results) => results.map((result) => result.status === "fulfilled" ? onSuccess(result.value) : onError(result.reason)))
        .then(() => batchRequests(restRequests, onSuccess, onError));
}
