import {
    Accordion,
    Button,
    Code,
    Group,
    Modal,
    Stack,
    Text
} from "@mantine/core"
import { IconX } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";

import { ActiveInput } from "./ActiveInput";
import { DateDataInput } from "./DateDataInput";
import {
    FacetFormProvider,
    FacetFormValues,
    newFacet,
    useFacetForm,
    validateFacetFormValues
} from "./facet-form-context"
import { FacetTypeInput } from "./FacetTypeInput";
import { FacetTypes, createFacetTitle } from "../../../utilities/facets";
import { NumberBucketsUniformInput } from "./NumberBucketsUniformInput";
import { SearchableNameInput } from "./SearchableNameInput";
import { useColumns } from "../../../context/ColumnContext";


type OnSubmitFunctionType = (values: FacetFormValues) => void;
type OnRemoveFunctionType = () => void;


function EditFacetForm(props: {
    values: FacetFormValues,
    onSubmit: OnSubmitFunctionType,
    onRemove: OnRemoveFunctionType,
}) {
    const form = useFacetForm({
        initialValues: props.values,
        validate: validateFacetFormValues,
    });

    const columns = useColumns();
    const columnName = form.values.data.facetData.columnName;
    const columnType = columns[columnName];
    const facetType = form.values.data.facetData.facetType;

    return (
        <FacetFormProvider form={form}>
            <form onSubmit={form.onSubmit((values) => props.onSubmit(values))}>
                <Stack>
                    <SearchableNameInput />
                    {facetType === FacetTypes.Date && <DateDataInput />}
                    {!(columnName === '') && <Accordion>
                        <Accordion.Item value="settings">
                            <Accordion.Control>
                                <Text size="sm">Advanced</Text>
                            </Accordion.Control>
                            <Accordion.Panel>
                                <Stack>
                                    <Stack spacing={4}>
                                        <Group>
                                            <Text size="sm" color="gray.9" weight={500}>Data type: </Text>
                                            <Code>{columnType}</Code>
                                        </Group>
                                    </Stack>
                                    <FacetTypeInput />
                                    {facetType !== undefined
                                        && [FacetTypes.NumberBucketsUniform, FacetTypes.NumberBucketsUniformIntegers].includes(facetType)
                                        && <NumberBucketsUniformInput />}
                                    <ActiveInput />
                                </Stack>
                            </Accordion.Panel>
                        </Accordion.Item>
                    </Accordion>}
                    <Group position="right">
                        <Button variant="light" color="gray" onClick={() => props.onRemove()}>Discard</Button>
                        <Button type="submit">Save</Button>
                    </Group>
                </Stack>
            </form>
        </FacetFormProvider>
    )
}


export function CreateFacetModal(props: {
    onSubmit: OnSubmitFunctionType,
}) {
    const [opened, { close, open }] = useDisclosure(false);
    const onSubmit = function (values: FacetFormValues) {
        props.onSubmit(values);
        close();
    }

    return (
        <>
            <Modal
                opened={opened}
                onClose={close}
                title="Create an aggregation"
            >
                <EditFacetForm
                    onSubmit={onSubmit}
                    onRemove={close}
                    values={newFacet()}
                />
            </Modal>
            <Group position="center">
                <Button onClick={open}>Add aggregation</Button>
            </Group>
        </>
    );
}

export function EditFacetModal(props: {
    values: FacetFormValues,
    onSubmit: OnSubmitFunctionType,
    onRemove: OnRemoveFunctionType,
}) {
    const [opened, { close, open }] = useDisclosure(false);
    const onSubmit = function (values: FacetFormValues) {
        props.onSubmit(values);
        close();
    }

    const color = props.values.active ? "blue" : "gray";

    const removeButton = (
        <Button
            radius="xl"
            color={color}
            variant="light"
            compact={true}
            onClick={() => props.onRemove()}
        >
            <IconX size={10} />
        </Button>
    );

    return (
        <>
            <Modal
                opened={opened}
                onClose={close}
                title="Edit facet"
            >
                <EditFacetForm
                    values={props.values}
                    onSubmit={onSubmit}
                    onRemove={close}
                />
            </Modal>
            <Button.Group>
                <Button
                    radius="xl"
                    color={color}
                    variant="light"
                    compact={true}
                    onClick={open}
                    pl={15}
                >
                    {createFacetTitle(props.values)}
                </Button>
                {removeButton}
            </Button.Group>
        </>
    );
}


export function FacetsInput(props: { facets: FacetFormValues[], handlers: any }) {
    const facets = props.facets;
    const handlers = props.handlers;

    return (
        <Group position="left">
            <CreateFacetModal
                onSubmit={(values: FacetFormValues) => handlers.append(values)}
            />
            {facets.map((facet, index: number) => <EditFacetModal
                key={facet.key}
                values={facet}
                onSubmit={(values: FacetFormValues) => handlers.setItem(index, values)}
                onRemove={() => handlers.remove(index)}
            />)}
        </Group>
    );
}
