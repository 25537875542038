import { ActionIcon } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";

export function DeleteIcon(props: {onClick: () => void}) {
    return <ActionIcon
        onClick={props.onClick}
        variant="transparent"
        color="red"
        size="xs"
        mb="xs"
    >
        <IconTrash />
    </ActionIcon>
}
