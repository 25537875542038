import { PolicyConfig } from "../../../models/RDLConfig";
import { RuleFormValues, useRuleFormContext } from "./rule-form-context";
import { Select } from "@mantine/core";

type PolicyToRuleMap = Record<string, { name: string, rules: RuleFormValues[] }>;

export function PolicyInput(props: { policies: PolicyConfig[] }) {
    const form = useRuleFormContext();
    const policyOptions = props.policies.map((policy) => ({ value: policy.id, label: policy.name }));
    const policyToRules: PolicyToRuleMap = props.policies.reduce((map: PolicyToRuleMap, policy) => {
        map[policy.id] = { name: policy.name, rules: policy.rules };
        return map;
    }, {});


    const handleSelectChange = (policyId: string | null) => {
        if (policyId === null) {
            return;
        }
        const policy = policyToRules[policyId]
        form.setFieldValue(
            "data.policyData",
            {
                policyId: policyId,
                policyName: policy.name,
                rules: policy.rules,
            }
        );
    }

    return (
        <Select
            withinPortal
            label="Select policy name"
            data={policyOptions}
            placeholder="Type a name"
            searchable
            {...{...form.getInputProps('data.policyData.name'), onChange: handleSelectChange}}
        />
    );
}
