import * as rdlTypes from "../models/RDLDataTypes";
import { Button, Group, Tooltip } from "@mantine/core"
import { ReactNode } from "react"
import { getEventIcon, getEventNameStr } from "../components/EventIconMapping"

export interface EventToggle { eventType: rdlTypes.EventType, toggleValue: boolean }

const EventToggleButton = function (props: {
    name: string,
    icon: ReactNode,
    onClick: () => void,
    show: boolean,
    count: number
}) {
    return (
        <Tooltip
            label={props.name}
            withArrow
            position="top"
        >
            <Button
                variant={props.show ? "light" : "subtle"}
                color={props.show ? "blue" : "gray"}
                onClick={props.onClick}
                leftIcon={props.icon}
            >
                {props.count}
            </Button>
        </Tooltip>
    )
}

export const EventToggleButtons = function(props: {
    eventToggles: EventToggle[],
    eventToggleCounts: Map<rdlTypes.EventType, number>,
    eventToggleHandlers: { setItem: (index: number, eventToggle: EventToggle) => void },
}) {
    return (
        <Group position="left" mb="md">
            {props.eventToggles.map((eventToggle, index) => {
                const EventIcon = getEventIcon(eventToggle.eventType)
                return (
                    <EventToggleButton
                        key={eventToggle.eventType}
                        name={getEventNameStr(eventToggle.eventType)}
                        icon={<EventIcon />}
                        onClick={() => props.eventToggleHandlers.setItem(index, { eventType: eventToggle.eventType, toggleValue: !eventToggle.toggleValue })}
                        show={eventToggle.toggleValue}
                        count={props.eventToggleCounts.get(eventToggle.eventType) ?? 0}
                    />
                );
            })}
        </Group>
    )
}
