import { Badge } from "@mantine/core";

export const envOptions = ["prod", "staging", "dev", "all environments"]
    .map(env => ({ value: env, label: env }));

export function EnvTag(props: { env: string }) {
    switch (props.env) {
    case "prod":
        return <Badge color="red">{props.env}</Badge>;
    case "staging":
        return <Badge color="yellow">{props.env}</Badge>;
    case "dev":
        return <Badge color="green">{props.env}</Badge>;
    default:
        return <Badge color="grey">{props.env}</Badge>;
    }
}
