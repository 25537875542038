import { MouseEvent, useMemo, useRef } from "react";

import 'chartjs-adapter-date-fns';
import { Bar } from 'react-chartjs-2';
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    ChartOptions,
    Title as ChartTitle,
    Filler,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    TimeScale,
    Tooltip,
} from 'chart.js';
import { FacetFormValues } from "../forms/facet/facet-form-context";
import { FacetedCards, chartTitleWithFacets } from "../../utilities/facets";
import { MantineTheme, useMantineTheme } from "@mantine/core";
import { MetricFormValues } from "../forms/metric/metric-form-context";
import { chartColorsList, mergeWithDefaultChartOptions } from "../../utilities/charts";
import { isMetricName, isRateMetricName } from "../../utilities/metric-calculations/metrics";
import _ from 'underscore';
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    BarElement,
    PointElement,
    LineElement,
    ChartTitle,
    Tooltip,
    Legend,
    zoomPlugin,
    Filler,
);


function getBucket(n: number) {
    return Math.floor(Math.log10(n));
}


const getLabel = (range: [number, number]) => {
    const [min, max] = range;
    if (min === max) return `${min}`;
    else if (min < 1 || max < 1) return `${min.toPrecision(1)} - ${max.toPrecision(1)}`;
    else return `${min} - ${max}`;
}

const getMinMaxFromBucket = (x: number, metric: MetricFormValues): [number, number] => {
    if (isNaN(x) || x === -Infinity) {
        return [0, 0];
    } else if (isRateMetricName(metric.name)) {
        return [Math.pow(10, x), Math.pow(10, x + 1)];
    } else {
        return [Math.pow(10, x), Math.pow(10, x + 1) - 1];
    }
}


const createDatasets = function(
    facetedCards: FacetedCards[],
    metric: MetricFormValues,
    metricIndex: number,
    chartConfig: MetricDistributionHistogramChartConfig,
    theme: MantineTheme,
) {
    const colors = chartColorsList(theme);

    const metricName = metric.name;
    if (!isMetricName(metricName)) {
        throw Error(`Invalid metric name: ${metricName}`);
    }

    const datasets = facetedCards.map((dataset) => {
        const data = _.chain(dataset.cards)
            .groupBy((d) => {
                return d.metrics === undefined || !(metricName in d.metrics)
                    ? 'undefined'
                    : getBucket(d.metrics[metricName].value)
            })
            .mapObject((cards) => cards.length)
            .mapObject((count) => chartConfig.showAsPercentage ? count / dataset.cards.length : count)
            .pairs()
            .sortBy(([bucket,]) => Number.parseFloat(bucket))
            .map(([bucket, count]) => {
                const range = getMinMaxFromBucket(Number.parseFloat(bucket), metric);
                const label = getLabel(range);
                return { bucket, count, range, label};
            })
            .value();
        return {
            label: dataset.facetName,
            data: data,
            borderColor: theme.fn.rgba(colors[metricIndex % colors.length][6], 0.5),
            backgroundColor: theme.fn.rgba(colors[metricIndex % colors.length][5], 0.5),
            categoryPercentage: 1.0,
            barPercentage: 1.0,
            parsing: {
                xAxisKey: 'label',
                yAxisKey: 'count',
            }
        };
    });
    datasets.sort((a, b) => (b.data[0]?.count ?? 0) - (a.data[0]?.count ?? 0));
    return datasets;
}

export interface MetricDistributionHistogramChartConfig {
    showAsPercentage: boolean,
}


// TODO(Alexandra) exponential by default, add option for linear at some point
export function MetricDistributionHistogramChart(props: {
    datasets: FacetedCards[],
    metric: MetricFormValues,
    metricIndex: number,
    facets: FacetFormValues[],
    chartConfig: MetricDistributionHistogramChartConfig,
    onClickItem: (range: [number, number]) => void,
}) {
    const chartRef = useRef<any>(null);
    const metric = props.metric;
    const theme = useMantineTheme();

    const datasets = useMemo(
        () => createDatasets(props.datasets, props.metric, props.metricIndex, props.chartConfig, theme),
        [props.datasets, props.metric, props.chartConfig, theme],
    );

    const chartOnClick = (event: MouseEvent<HTMLCanvasElement>) => {
        const elements =  chartRef.current.getElementsAtEventForMode(event, 'nearest', { intersect: false }, true);
        const first = _.first(elements);
        if (!_.isUndefined(first)) {
            const range = first.element.$context?.raw?.range;
            if (!_.isUndefined(range)) props.onClickItem(range);
        }
    }

    const data = {
        datasets,
    }

    const xLabels = _.chain(data.datasets)
        .map((dataset) => dataset.data)
        .flatten()
        .unique(({range}) => range)
        .sortBy(({range}) => range[0])
        .map(({label}) => label)
        .value();

    const options: ChartOptions = {
        aspectRatio: 2,
        scales: {
            x: {
                labels: xLabels,
                type: 'category',
                title: { display: true, text: `${metric.displayName}` },
            },
            y: {
                title: { display: true, text: 'Items' },
                ticks: props.chartConfig.showAsPercentage ? {
                    format: {
                        style: 'percent'
                    }
                }: {},

            }
        },
        plugins: {
            title: {
                display: true,
                text: chartTitleWithFacets(`Histogram of ${metric.displayName}`, props.facets),
            },
            tooltip: {
                intersect: false,
                callbacks: {
                    title: (context: any) => `${context[0].raw.label} ${metric.name}`,
                    afterTitle: () => isRateMetricName(metric.name) ? '(Right side not inclusive)' : '',
                    label: (context: any) => `${context.formattedValue}${props.chartConfig.showAsPercentage ? ' of' : ''} ${context.dataset.label}`,
                },
            },
            zoom: {
                zoom: {
                    wheel: { enabled: false },
                    pinch: { enabled: false },
                    drag: { enabled: false },
                },
                pan: { enabled: false },
            },
        },
    };

    return (
        <>
            <Bar
                ref={chartRef}
                options={mergeWithDefaultChartOptions(options)}
                data={data}
                onClick={chartOnClick}
            />
        </>
    );
}
