import {
    ActionIcon,
    Modal,
    Stack,
    Textarea,
    Tooltip
} from "@mantine/core"
import { IconCheck, IconCopy, IconShare } from "@tabler/icons-react";
import { Location } from "react-router-dom";
import { RuleFormValues } from "./forms/rule/rule-form-context";
import { TestDataFormValues } from "./forms/test-data/test-data-form-context";
import { createURLWithSearchParams } from "../utilities/url";
import { useClipboard, useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";

export const DrilldownPagesSharingModal = function (props: {
    location: Location,
    testDataInputLeft: TestDataFormValues | undefined,
    testDataInputRight: TestDataFormValues | undefined,
    rulesLeft: RuleFormValues[],
    rulesRight: RuleFormValues[],
}) {
    const [opened, { close, open }] = useDisclosure(false);
    const [sharingURL, setSharingURL] = useState('');
    const clipboard = useClipboard({ timeout: 500 });

    useEffect(() => {
        const newSharingURL = createURLWithSearchParams(props.location, {
            testDataInputLeft: props.testDataInputLeft,
            testDataInputRight: props.testDataInputRight,
            rulesLeft: props.rulesLeft,
            rulesRight: props.rulesRight,
        });
        setSharingURL(newSharingURL.href);
    });

    return (
        <>
            <Modal
                size="lg"
                opened={opened}
                onClose={close}
                title="Copy sharing URL"
            >
                <Stack>
                    <Textarea
                        minRows={4}
                        maxRows={10}
                        value={sharingURL}
                    />
                    <ActionIcon
                        color={clipboard.copied ? 'teal' : 'blue'}
                        onClick={() => clipboard.copy(sharingURL)}
                    >
                        {clipboard.copied ? <IconCheck /> : <IconCopy />}
                    </ActionIcon>
                </Stack>
            </Modal>
            <Tooltip label="Sharing URL">
                <ActionIcon
                    onClick={open}
                    variant="light"
                    color="blue"
                >
                    <IconShare />
                </ActionIcon>
            </Tooltip>
        </>
    );

}
