import * as rdlTypes from "../../models/RDLDataTypes";
import { Alert } from "../../components/Alert";
import {
    Box,
    Button,
    Card,
    Group,
    LoadingOverlay,
    Modal,
    Stack,
    Text,
    Title
} from "@mantine/core"
import { Link } from "react-router-dom";
import { RubberDuckyLabsApi } from "../../RDLApi";
import { isUndefinedOrNull } from "../../utilities/types";
import { useApiContext } from "../../App";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import CreatePipelineForm from "../../components/forms/pipelines/CreatePipelineForm";
import ExamplePipelineChart from "../../components/ExamplePipelineChart";
import _ from "underscore";

function PipelineCard(props: { rankingPipeline: rdlTypes.RankingPipeline }) {
    const { rankingPipeline } = props;
    const rankingPipelineStages = _.sortBy(rankingPipeline.stages, 'stage_order');

    return (
        <Card shadow="sm" p="lg" radius="md" withBorder component={Link} to={rankingPipeline.id.toString()}>
            <Group position="apart">
                <Text size="sm" weight={700}>{rankingPipeline.name}</Text>
                <Text size="sm" c="dimmed">Id: {rankingPipeline.id}</Text>
            </Group>
            <Text size="xs" c="dimmed">{"(Representation of pipeline stages)"}</Text>
            <Card.Section>
                <Box h={200}>
                    <ExamplePipelineChart rankingPipeline={rankingPipeline} />
                </Box>
                <Group align="flex-start" position="apart" noWrap>
                    {rankingPipelineStages.map(({name, id}) => <Text key={id} size="xs" weight={700} truncate>{name}</Text>)}
                </Group>
            </Card.Section>
        </Card>
    );
}

function PipelineCardList(props: { rankingPipelines: rdlTypes.RankingPipeline[] }) {
    const cards = props.rankingPipelines.map((rankingPipeline) => (
        <PipelineCard rankingPipeline={rankingPipeline} key={rankingPipeline.id} />
    ));
    return (
        <>
            {cards}
        </>
    )
}

export default function PipelinesListPage() {
    const apiContext = useApiContext();
    const [rankingPipelines, setRankingPipelines] = useState<rdlTypes.RankingPipeline[] | undefined | null>(undefined);
    const [apiErrorMessage, setApiErrorMessage] = useState<string|undefined>(undefined);
    const [opened, { open, close }] = useDisclosure(false);

    useEffect(() => {
        if (!_.isNull(apiContext)) {
            RubberDuckyLabsApi.getInstance(apiContext)
                .listRankingPipelines()
                .then(({data}) => setRankingPipelines(data))
                .catch(() => {
                    setApiErrorMessage('Error loading pipelines')
                    setRankingPipelines(null)
                });
        }

        return () => {
            setRankingPipelines(undefined);
        }
    }, [apiContext])

    return (
        <Stack>
            <Title order={1}>Pipelines</Title>
            <Modal opened={opened} onClose={close} title="Create a new pipeline">
                <CreatePipelineForm />
            </Modal>
            <Group position="apart">
                <Text c="dimmed">View your ranking pipelines.</Text>
                <Button variant="light" onClick={open}>Create new pipeline</Button>
            </Group>
            <LoadingOverlay visible={_.isUndefined(rankingPipelines)} />
            {!_.isUndefined(apiErrorMessage) && <Alert message={apiErrorMessage} onClose={() => setApiErrorMessage(undefined)} />}
            {!isUndefinedOrNull(rankingPipelines) && <PipelineCardList rankingPipelines={rankingPipelines} />}
        </Stack>
    )
}
