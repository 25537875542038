import {
    Card,
    Group,
    Modal,
    SegmentedControl,
    SimpleGrid,
    Stack,
    Text
} from "@mantine/core"
import { DistributionOfItemsChart } from "../charts/DistributionOfItemsCharts";
import { EditFilterForm } from "../forms/filter/FilterModals";
import { FacetFormValues } from "../forms/facet/facet-form-context";
import { FacetTypes, FacetedCards, chartAxisTitle, deconstructFacetName } from "../../utilities/facets";
import { HelpAlert, HelpIcon } from "../Help";
import { LAYOUT_TO_SIMPLE_GRID_PROPS } from "../../utilities/layout";
import { MetricAverageBarChart } from "../charts/MetricAverageBarChart";
import { MetricDistributionHistogramChart, MetricDistributionHistogramChartConfig } from "../charts/MetricDistributionHistogramChart";
import { MetricFormValues } from "../forms/metric/metric-form-context";
import { RuleFilter, newRangeFilter } from "../forms/rule/rule-form-context";
import { createFilterForFacet } from "../../utilities/filters";
import { useDisclosure } from "@mantine/hooks";
import { useForm } from "@mantine/form";
import { useState } from "react";
import _ from "underscore";

function CreateFilterModal(props: {
    onSubmit: (values: RuleFilter) => void,
    onClose: () => void,
    values: RuleFilter,
}) {
    const onSubmit = function (values: RuleFilter) {
        props.onSubmit(values);
        props.onClose();
    }

    return (
        <Modal
            opened={true}
            onClose={props.onClose}
            title={`We need a little more information!`}
        >
            <Text size="sm" c="dimmed">This feature is under development, and right now we need a little more information from you to add this filter. Thank you for your patience!</Text>
            <EditFilterForm
                onSubmit={onSubmit}
                onRemove={props.onClose}
                values={props.values}
            />
        </Modal>
    );
}

export default function MetricsChartsPanel(props: {
    facetedCards: FacetedCards[],
    facets: FacetFormValues[],
    metrics: MetricFormValues[],
    addFilter: (filter: RuleFilter) => void,
}) {
    const facets = props.facets;
    const facetedCards = props.facetedCards;

    const chartConfigForm = useForm({
        initialValues: {
            showAsPercentage: "count",
        },
    });

    // TODO(Alexandra): upgrade mantine and this is a built-in form function
    // https://mantine.dev/form/values/
    const transformValues = function(values: typeof chartConfigForm.values): MetricDistributionHistogramChartConfig {
        return {
            showAsPercentage: values.showAsPercentage === "percentage",
        };
    }

    const [opened, {close, toggle} ] = useDisclosure(true);

    const [draftFilter, setDraftFilter] = useState<RuleFilter | undefined>(undefined);

    const addFilterFromFacet = function(facetName: string) {
        const facetNames = deconstructFacetName(facetName);
        if (facetNames.length !== facets.length) return;
        _.zip(facets, facetNames).forEach(([facet, name]) => {
            const newFilter = createFilterForFacet(facet, name);
            if ([
                FacetTypes.Date,
                FacetTypes.NumberBucketsExponentialBase10,
                FacetTypes.NumberBucketsUniform,
                FacetTypes.NumberBucketsUniformIntegers,
            ].includes(facet.data.facetData.facetType)) {
                setDraftFilter(newFilter);
            } else {
                props.addFilter(newFilter);
            }
        });
    }

    return (
        <>
            {draftFilter !== undefined && <CreateFilterModal
                onSubmit={(values) => props.addFilter(values)}
                onClose={() => setDraftFilter(undefined)}
                values={draftFilter}
            />}
            <HelpAlert
                title="How do I use this panel?"
                message={ "Start by exploring questions like, How many items are receiving engagement?"
                    + " Does my recommender system have a winner-take-all effect?"
                    + " Then, aggregate data by different item attributes to find underperforming product segments."
                }
                opened={opened}
                close={close}
            />
            <Group mt="xs" position="apart" align="start">
                <Stack align="flex-start" spacing="xs">
                    <Text size="sm" weight="bold">Display items as</Text>
                    <SegmentedControl
                        data={["percentage", "count"]}
                        {...chartConfigForm.getInputProps('showAsPercentage')}
                    />
                </Stack>
                <HelpIcon toggle={toggle} />
            </Group>
            <SimpleGrid {...LAYOUT_TO_SIMPLE_GRID_PROPS.md}>
                {facetedCards.length <= 1 && <Card key={`item-count`}>
                    <Stack align="center" justify="space-between">
                        <Text size="xs" weight="bold" color="#666" mt="xs">
                            Count of Items
                        </Text>
                        <Text size={72} >{(facetedCards[0]?.cards ?? []).length} Items</Text>
                        {facets.length > 0 && <Text size="xs" color="#666">{`${chartAxisTitle(facets)} = ${facetedCards[0]?.facetName ?? ''}`}</Text>}
                    </Stack>
                </Card>
                }
                {facetedCards.length <= 1 && props.metrics.map((metric, index) => <Card key={`${metric.name}-histogram`}>
                    <MetricDistributionHistogramChart
                        datasets={facetedCards}
                        metric={metric}
                        metricIndex={index + 1}
                        facets={facets}
                        chartConfig={transformValues(chartConfigForm.values)}
                        onClickItem={(range) => props.addFilter(newRangeFilter(range, `metrics.${metric.name}.value`))}
                    />
                </Card>)}
                {facetedCards.length > 1 &&
                    <Card key={"distribution-items"}>
                        <DistributionOfItemsChart
                            datasets={facetedCards}
                            facets={props.facets}
                            colorIndex={0}
                            onClickItem={addFilterFromFacet}
                        />
                    </Card>
                }
                {facetedCards.length > 1 && props.metrics.map((metric, index) => <Card key={`${metric.name}-histogram`}>
                    <MetricAverageBarChart
                        datasets={facetedCards}
                        metric={metric}
                        metricIndex={index + 1}
                        facets={facets}
                        onClickItem={addFilterFromFacet}

                    />
                </Card>)}

            </SimpleGrid>
        </>
    )
}
