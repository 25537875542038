import * as rdlTypes from "../models/RDLDataTypes";
import { Breadcrumbs } from "@mantine/core";
import { Link, useSearchParams } from "react-router-dom";

export function createPipelineTitle(rankingPipeline: rdlTypes.RankingPipeline | undefined | null) {
    switch (rankingPipeline) {
    case undefined:
        return "Loading...";
    case null:
        return "Not found";
    default:
        return rankingPipeline.name;
    }
}


export function createTraceTitle(traceId: string | undefined) {
    switch(traceId) {
    case undefined:
    case "":
        return "Not found";
    default:
        return traceId;
    }
}


export function PipelineBreadcrumbs(props: { rankingPipeline?: rdlTypes.RankingPipeline | null }) {
    const { rankingPipeline } = props;
    const [searchParams] = useSearchParams();
    const links = [
        { title: 'Pipelines', to: '..' },
        { title: createPipelineTitle(rankingPipeline), to: { search: searchParams.toString() } }
    ];

    const items = links.map((link, index) => (
        <Link to={link.to} key={index}>
            {link.title}
        </Link>
    ));

    return (
        <Breadcrumbs separator={">"}>{items}</Breadcrumbs>
    );
}


export function PipelineTracesBreadcrumbs(props: { rankingPipeline?: rdlTypes.RankingPipeline | null }) {
    const { rankingPipeline } = props;
    const [searchParams] = useSearchParams();
    const links = [
        { title: 'Pipelines', to: '../..' },
        { title: createPipelineTitle(rankingPipeline), to: '..' },
        { title: 'Traces', to: { search: searchParams.toString() } }
    ];

    const items = links.map((link, index) => (
        <Link to={link.to} key={index}>
            {link.title}
        </Link>
    ));

    return (
        <Breadcrumbs separator={">"}>{items}</Breadcrumbs>
    );
}


export function PipelineTraceDetailBreadcrumbs(props: { rankingPipeline?: rdlTypes.RankingPipeline | null, traceId?: string }) {
    const { rankingPipeline, traceId } = props;
    const [searchParams] = useSearchParams();
    const links = [
        { title: 'Pipelines', to: '../../..' },
        { title: createPipelineTitle(rankingPipeline), to: '../..' },
        { title: 'Traces', to: '..' },
        { title: createTraceTitle(traceId), to: { search: searchParams.toString() } },
    ];

    const items = links.map((link, index) => (
        <Link to={link.to} key={index}>
            {link.title}
        </Link>
    ));

    return (
        <Breadcrumbs separator={">"}>{items}</Breadcrumbs>
    );
}


export function PipelinesMetricsBreadcrumbs(props: { rankingPipeline?: rdlTypes.RankingPipeline | null }) {
    const { rankingPipeline } = props;
    const [searchParams] = useSearchParams();
    const links = [
        { title: 'Pipelines', to: '../..' },
        { title: createPipelineTitle(rankingPipeline), to: '..' },
        { title: 'Metrics', to: { search: searchParams.toString() } }
    ];

    const items = links.map((link, index) => (
        <Link to={link.to} key={index}>
            {link.title}
        </Link>
    ));

    return (
        <Breadcrumbs separator={">"}>{items}</Breadcrumbs>
    );
}


export function CreatePipelinesBreadcrumbs() {
    const links = [
        { title: 'Pipelines', to: '..' },
        { title: 'Create New', to: '.' },
    ];

    const items = links.map((link, index) => (
        <Link to={link.to} key={index}>
            {link.title}
        </Link>
    ));

    return (
        <Breadcrumbs separator={">"}>{items}</Breadcrumbs>
    );
}
