import * as dateFNS from "date-fns";
import {
    Accordion,
    Badge,
    Button,
    Group,
    Paper,
    Space,
    Stack,
    Text,
    Title
} from '@mantine/core'
import { Alert } from '../../components/Alert';
import { EnvTag } from '../../components/EnvTag';
import { Link } from 'react-router-dom';
import { RankingDrilldownInputForm } from "../../components/forms/ranking-drilldown/RankingDrilldownInputForm";
import { RubberDuckyLabsApi } from '../../RDLApi';
import { TestDataset } from '../../models/RDLConfig';
import { TestDatasetsFormValues } from './test-dataset-form-context';
import { useApiContext } from '../../App';
import { useEffect, useState } from 'react';
import CreateTestDatasetModal from './CreateTestDatasetModal';
import ReactJson from 'react-json-view';
import _ from 'underscore';

function AccordionLabel(dataset: TestDataset) {
    const tags = dataset.tags.map((tag) => (
        <Badge key={tag} color="pink">{tag}<Space w="sm" /></Badge>
    ));
    return (
        <Group noWrap>
            <Text>{dataset.name}</Text>
            <EnvTag env={dataset.environment} />
            <Stack align='flex-start'>
                {tags}
            </Stack>
            <Text size="sm" c="dimmed" ml='auto'>
                {dateFNS.formatDistanceToNow(dateFNS.fromUnixTime(dataset.timestamp), { addSuffix: true })}
            </Text>
        </Group>
    );
}

function AccordionContent(dataset: TestDataset) {
    if (dataset.id === undefined) {
        return <></>;
    }

    return (
        <Paper shadow="xs" p="md">
            <Stack align='flex-start'>
                <ReactJson src={dataset} name={"dataset"} collapsed={true} />
                <Link to={dataset.id.toString()} style={{ textDecoration: 'none' }}>
                    <Button>Show Details</Button>
                </Link>
            </Stack>
        </Paper>
    );
}

export default function TestDatasetsListPage() {
    const apiContext = useApiContext();
    const [transactionId, setTransactionId] = useState<string | null>(null);
    const [testDatasets, setTestDatasets] = useState<TestDataset[]>([]);
    const [apiError, setApiError] = useState<string>('');

    useEffect(() => {
        if (!_.isNull(apiContext) && !_.isNull(transactionId)) {
            RubberDuckyLabsApi.getInstance(apiContext)
                .getTestDatasets([transactionId])
                .then(setTestDatasets)
                .catch((error) => setApiError(error.message));
        }

        return () => {
            setTestDatasets([]);
        }
    }, [apiContext, transactionId]);

    const shownDatasets = testDatasets.map((dataset) => (
        <Accordion.Item value={dataset.name} key={dataset.id}>
            <Accordion.Control>
                <AccordionLabel {...dataset} />
            </Accordion.Control>
            <Accordion.Panel>
                <AccordionContent {...dataset} />
            </Accordion.Panel>
        </Accordion.Item>
    ));

    const onCreateTestDatasetSubmit = function (values: TestDatasetsFormValues) {
        if (!_.isNull(apiContext)) {
            const { jsonData, ...valuesExceptJson } = values;
            const parsedJsonData = JSON.parse(jsonData)
            const itemData = parsedJsonData.item_data ?? [];
            delete parsedJsonData.item_data
            RubberDuckyLabsApi.getInstance(apiContext).createTestDataset({
                ...valuesExceptJson,
                item_data: itemData,
                additional_data: parsedJsonData,
            }).then((response) => {
                setTestDatasets([response, ...testDatasets]);
            });
        }
    }

    return (
        <Stack>
            <Title order={1}>Test Datasets</Title>
            <CreateTestDatasetModal onSubmit={onCreateTestDatasetSubmit} />
            <RankingDrilldownInputForm onSubmit={(values) => {
                setTransactionId(values.transactionId);
            }}
            values={{
                transactionId: transactionId ?? '',
                rankingPipelineId: '',
            }}
            />
            {!_.isEmpty(apiError) && <Alert onClose={() => setApiError('')} message={apiError} />}
            {transactionId !== null && <Accordion chevronPosition="right" variant="contained">{shownDatasets}</Accordion>}
        </Stack>
    );
}
