import {
    Anchor,
    Center,
    Loader,
    Table,
    useMantineTheme
} from "@mantine/core"
import _ from 'underscore';

import * as rdlTypes from "../models/RDLDataTypes";
import { Alert } from '../components/Alert';
import { InView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import { NO_SEGMENT, UserSegment } from "../models/user-segments";
import { RubberDuckyLabsApi } from '../RDLApi';
import { SegmentDisplay, UserSegmentMembershipActionsMenu } from "./users/SegmentDisplay";
import { UserFiltersFormValues } from './forms/user-filters/user-filters-form-context';
import { createNewSegments } from "../utilities/user-segments";
import { formatDistanceToNow, lightFormat } from "date-fns";
import { isUndefinedOrNull } from "../utilities/types";
import { useApiContext } from '../App';
import { useEffect, useMemo, useState } from 'react';
import { useListState } from "@mantine/hooks";

const SCROLL_PAGE_SIZE = 50;


interface UsersTableProps {
    users: rdlTypes.User[],
    userSegmentMemberships: rdlTypes.UserSegmentMembership[],
    userSegments: UserSegment[],
    removeUserFromSegment: (userId: string, segmentKey: rdlTypes.Id) => void,
    loadingSegments: boolean,
}


export function UsersTable({ users, userSegmentMemberships, userSegments, removeUserFromSegment, loadingSegments }: UsersTableProps ) {
    const userIdToSegmentsMap = useMemo(() => _.chain(userSegmentMemberships)
        .groupBy(({user_id}) => user_id)
        .mapObject((memberships) => _.pluck(memberships, 'segment_id'))
        .value(),
    [userSegmentMemberships]
    );

    const segmentKeyToSegmentMap = useMemo(
        () => _.indexBy(userSegments, 'key'),
        [userSegments]
    );

    const rows = users.map((user) => {
        const userId = user.user_id;
        const segments = userId in userIdToSegmentsMap
            ? userIdToSegmentsMap[userId]
            : [NO_SEGMENT];

        if (_.isNull(user)) {
            return (
                <tr key={userId}>
                    <td>
                        <UserSegmentMembershipActionsMenu userId={userId} segments={segments} segmentKeyToSegmentMap={segmentKeyToSegmentMap} removeUserFromSegment={removeUserFromSegment} />
                    </td>
                    <td>{loadingSegments && <Loader size="xs" />} {segments.map((segmentKey) => <SegmentDisplay key={segmentKey} segment={segmentKeyToSegmentMap[segmentKey]} />)}</td>
                    <td><Anchor component={Link} to={`/users/${userId}`}> {userId}</Anchor></td>
                    <td colSpan={7}><Loader /></td>
                </tr>
            )
        }

        return (
            <tr key={userId}>
                <td>
                    <UserSegmentMembershipActionsMenu userId={userId} segments={segments} segmentKeyToSegmentMap={segmentKeyToSegmentMap} removeUserFromSegment={removeUserFromSegment} />
                </td>
                <td>{segments.map((segmentKey) => <SegmentDisplay key={segmentKey} segment={segmentKeyToSegmentMap[segmentKey]} />)}</td>
                <td><Anchor component={Link} to={`/users/${userId}`}> {userId}</Anchor></td>
                <td>{lightFormat(new Date(user.user_attributes.created), 'MM/dd/yyyy')}</td>
                <td>{user.user_attributes.subscriber.toString()}</td>
                <td>{formatDistanceToNow(new Date(user.user_attributes.last_activity), { addSuffix: true })}</td>
                <td>{user.user_attributes.height ?? '-'}</td>
                <td>{user.user_attributes.bra ?? '-'}</td>
                <td>{_.isNull(user.user_attributes.age) ? '-' : user.user_attributes.age.toString()}</td>
                <td>{_.isNull(user.user_attributes.maternity) ? '-' : user.user_attributes.maternity.toString()}</td>
            </tr>
        )});


    return (
        <Table>
            <thead>
                <tr>
                    <th></th>
                    <th>Segment</th>
                    <th>User</th>
                    <th>Created</th>
                    <th>Subscriber</th>
                    <th>Last Active</th>
                    <th>Height</th>
                    <th>Bra</th>
                    <th>Age</th>
                    <th>Maternity</th>
                </tr>
            </thead>
            <tbody>{rows}</tbody>
        </Table>
    );
}


const PAGE_LIMIT = 100;

// TODO(Alexandra): include date range and user fitlers when making users requests
export function UsersTableWithData({ userFiltersForm }: { userFiltersForm: UserFiltersFormValues }) {
    const [users, setUsers] = useState<rdlTypes.User[]>([]);
    const [after, setAfter] = useState<rdlTypes.Id | null>(null);
    const [errorMessage, setErrorMessage] = useState('');
    const apiContext = useApiContext();
    const [loading, setLoading] = useState(false);
    const [userSegmentMemberships, setUserSegmentMemberships] = useListState<rdlTypes.UserSegmentMembership>([]);
    const [userSegments, setUserSegments] = useListState<UserSegment>([]);
    const [loadingSegments, setLoadingSegments] = useState(false);
    const theme = useMantineTheme();

    useEffect(() => {
        if (!_.isNull(apiContext) && !isUndefinedOrNull(userFiltersForm)) {
            const { userFilters, segment } = userFiltersForm;
            setLoading(true);
            RubberDuckyLabsApi.getInstance(apiContext).getUsers(userFilters, segment, 1000)
                .then((pagination) => {
                    setUsers(pagination.data)
                    setAfter(pagination.paging.after);
                })
                .catch((error) => setErrorMessage(error.message))
                .finally(() => setLoading(false));
        }

        return () => {
            setUsers([]);
            setAfter(null);
        }
    }, [userFiltersForm, apiContext]);

    useEffect(() => {
        if (!_.isNull(apiContext)) {
            setLoadingSegments(true);
            RubberDuckyLabsApi
                .getInstance(apiContext)
                .listUserSegmentsMetadata()
                .then(({data}) => {
                    const newSegments = createNewSegments(data, userSegments, theme);
                    setUserSegments.append(...newSegments);
                    return _.pluck(newSegments, 'key').filter((key) => key !== NO_SEGMENT);
                })
                .then((segmentKeys) => RubberDuckyLabsApi.getInstance(apiContext).listUsersInSegments(segmentKeys))
                .then(({data}) => setUserSegmentMemberships.append(...data))
                .finally(() => setLoadingSegments(false));
        }
    }, [apiContext]);

    const removeUserFromSegment = (userId: string, segmentKey: rdlTypes.Id) => {
        if (!_.isNull(apiContext)) {
            RubberDuckyLabsApi
                .getInstance(apiContext)
                .removeUserFromSegment(segmentKey, userId)
                .then(() => {
                    setUserSegmentMemberships.filter(({user_id, segment_id}) => !(user_id === userId && segment_id === segmentKey));
                    setUserSegments.applyWhere(
                        (segment) => (segment.key === segmentKey),
                        (segment) => ({...segment, countUsers: segment.countUsers - 1})
                    );
                });
        }
    }

    return (
        <>
            {!_.isEmpty(errorMessage) && <Alert message={errorMessage} onClose={() => setErrorMessage('')} />}
            <UsersTable
                loadingSegments={loadingSegments}
                users={users}
                userSegmentMemberships={userSegmentMemberships}
                userSegments={userSegments}
                removeUserFromSegment={removeUserFromSegment}
            />
            {loading && <Center><Loader /></Center>}
            {!loading && <InView onChange={(inView) => {
                if (inView && !_.isNull(after) && !_.isNull(apiContext) && isUndefinedOrNull(userFiltersForm)) {
                    setLoading(true);
                    const { userFilters, segment } = userFiltersForm;
                    RubberDuckyLabsApi.getInstance(apiContext).getUsers(userFilters, segment, PAGE_LIMIT, after)
                        .then((response) => {
                            setUsers((previousUsers) => previousUsers.concat(response.data));
                            setAfter(response.paging.after);
                        })
                        .then(() => setLoading(false))
                }
            }} />}
        </>
    );
}


interface UsersTableFromIdsProps {
    userIds: rdlTypes.Id[],
    userSegmentMemberships: rdlTypes.UserSegmentMembership[],
    userSegments: UserSegment[],
    removeUserFromSegment: (userId: string, segmentKey: rdlTypes.Id) => void,
    loadingSegments: boolean,
}


export function UsersTableFromIds({ userIds, userSegmentMemberships, userSegments, removeUserFromSegment, loadingSegments }: UsersTableFromIdsProps) {
    const apiContext = useApiContext();
    const [users, setUsers] = useState<rdlTypes.User[]>([]);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [scrollLimit, setScrollLimit] = useState(SCROLL_PAGE_SIZE);

    useEffect(() => {
        if (!_.isNull(apiContext)) {
            setLoading(true);
            RubberDuckyLabsApi.getInstance(apiContext).getUsersBatch(userIds)
                .then(({data}) => setUsers(data))
                .catch((error) => setErrorMessage(error.message))
                .finally(() => setLoading(false));
        }

        return () => {
            setUsers([]);
        }
    }, [userIds, apiContext]);

    return (
        <>
            {!_.isEmpty(errorMessage) && <Alert message={errorMessage} onClose={() => setErrorMessage('')} />}
            <UsersTable
                users={users.slice(0, scrollLimit)}
                userSegmentMemberships={userSegmentMemberships}
                userSegments={userSegments}
                removeUserFromSegment={removeUserFromSegment}
                loadingSegments={loadingSegments}
            />
            {loading && <Center><Loader /></Center>}
            {!loading && <InView onChange={(inView) => inView && setScrollLimit((previousLimit) => previousLimit + SCROLL_PAGE_SIZE)} />}
        </>
    );

}
