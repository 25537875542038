import { useEffect, useMemo, useState } from "react";

import * as rdlTypes from "../models/RDLDataTypes";
import {
    Accordion,
    Badge,
    Box,
    Button,
    Group,
    HoverCard,
    LoadingOverlay,
    SegmentedControl,
    Space,
    Stack,
    Text,
    Title
} from '@mantine/core'
import { Alert } from "../components/Alert";
import { ItemCardGrid } from "../components/ItemCardGrid";
import { ItemCardProps } from "../components/ItemCard";
import { PipelineStage } from "../utilities/ranked-cards";
import { PipelineTraceDetailBreadcrumbs, createTraceTitle } from "../components/PipelineBreadcrumbs";
import { RubberDuckyLabsApi } from '../RDLApi';
import { ShareButton } from "../components/ShareButton";
import { TestDataset as TestDatasetWithoutId } from "../models/RDLConfig";
import { isRDLId, isUndefinedOrNull } from "../utilities/types";
import { parseTestDatasetItems } from "./test-datasets/utils";
import { useApiContext } from "../App";
import { useParams, useSearchParams } from "react-router-dom";
import ItemRankingAccordionItem from "../components/ItemRankingDetails";
import PipelineChart from "../components/charts/PipelineChart";
import RankingChangesComparisonDashboard, { RankingChangesDataset } from "../components/charts/RankingChangesComparisonDashboard";
import _ from "underscore";

const RankingDisplay = function (props: {
    transactionId: string,
    rankingPipeline: rdlTypes.RankingPipeline,
    rankingPipelineData: Map<number, TestDataset[]>,
    selectedItems: rdlTypes.Id[],
    setSelectedItems: (itemIds: rdlTypes.Id[]) => void,
    rankingChangesDataset?: RankingChangesDataset,
    rankingChangesTestDatasets?: [TestDataset, TestDataset],
    rankingChangesStages?: [rdlTypes.RankingPipelineStage, rdlTypes.RankingPipelineStage],
}) {
    const {
        transactionId,
        rankingPipeline,
        rankingPipelineData,
        selectedItems,
        setSelectedItems,
        rankingChangesDataset,
        rankingChangesTestDatasets,
        rankingChangesStages,
    } = props;

    const rankingPipelineStages = _.sortBy(rankingPipeline.stages, 'stage_order');

    const [pipeline, setPipeline] = useState<PipelineStage[]>([]);

    const [pipelineStageLabelForColors, setPipelineStageLabelForColors] = useState<string | undefined>(undefined);

    useEffect(() => {
        setPipelineStageLabelForColors(_.last(pipeline)?.label);
    }, [pipeline]);

    const pipelineStageForColors = useMemo(() => (
        _.findWhere(pipeline, { label: pipelineStageLabelForColors })
    ), [pipelineStageLabelForColors]);

    useEffect(() => {
        const newPipeline = rankingPipelineStages.map((stage) => {
            const cards = rankingPipelineData.get(stage.id)
                ?.map((testDataset) => parseTestDatasetItems(testDataset))
                .flat();

            return {
                type: stage.stage_type,
                cards: cards ?? [],
                label: stage.name,
                key: stage.id,
            }
        })
        setPipeline(newPipeline);

        return () => {
            setPipeline([]);
        }
    }, [rankingPipeline, rankingPipelineData]);

    const resetSelectedItemButton = (
        <Button onClick={() => setSelectedItems([])}
            color="blue"
            disabled={_.isEmpty(selectedItems)}
        >
            Deselect Items
        </Button>
    )

    const optionalRankedCards = _.chain(pipeline).last().value();
    const rankedCards = optionalRankedCards === undefined ? { cards: [] } : optionalRankedCards;
    const itemCardGrid = <Stack>
        <Title order={2}>Recommendation Output</Title>
        <ItemCardGrid
            cards={
                _.isEmpty(selectedItems)
                    ? rankedCards.cards
                    : rankedCards.cards.filter(({ item }) => item?.item_id === selectedItems.at(0))
            }
            size="xl"
            onClick={
                (item: ItemCardProps) => item.id !== undefined && setSelectedItems([item.id])
            }
        />
    </Stack>;

    const columns = rankingPipelineStages.map((stage) => {
        const testDataset: TestDataset | undefined = rankingPipelineData.get(stage.id)?.at(0);
        if (_.isUndefined(testDataset)) {
            return (
                <HoverCard key={stage.id}>
                    <HoverCard.Target>
                        <Text weight={700} size="xs">{stage.name}</Text>
                    </HoverCard.Target>
                    <HoverCard.Dropdown>
                        <Stack align="flex-start">
                            <Text weight={700} size="xs">
                                No additional data
                            </Text>
                        </Stack>
                    </HoverCard.Dropdown>
                </HoverCard>
            )
        }

        return (
            <HoverCard key={stage.id}>
                <HoverCard.Target>
                    <Text weight={700} size="xs">{stage.name}</Text>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                    <Stack align="flex-start">
                        <Text weight={700} size="xs">
                            {stage.name}
                            {
                                !_.isNull(testDataset.additional_data)
                                && "recsModelName" in testDataset.additional_data
                                && testDataset.additional_data.recsModelName !== null
                                && ` - Model Name: ${testDataset.additional_data.recsModelName}`
                            }
                        </Text>
                        <Text weight={500} size="xs">{testDataset.name}</Text>
                        {testDataset.tags.filter((tag) => tag !== transactionId).map((tag) => <Badge color={"pink"} key={tag}>{tag}<Space w="sm" /></Badge>)}
                    </Stack>
                </HoverCard.Dropdown>
            </HoverCard>
        )
    });
    return (
        <>
            <Stack spacing="md">
                <Group position="left">
                    {resetSelectedItemButton}
                    <ShareButton />
                </Group>
                <Stack spacing={3}>
                    <Text weight={700} size="xs">Color by</Text>
                    <SegmentedControl
                        fullWidth
                        data={pipeline.map(({ label }) => label)}
                        value={pipelineStageLabelForColors}
                        onChange={setPipelineStageLabelForColors}
                    />
                </Stack>
                <Box h="400px" w="100%">
                    <PipelineChart
                        pipelineStageForColors={pipelineStageForColors}
                        pipeline={pipeline}
                        selectedItems={selectedItems}
                        onClickItem={
                            (itemId) => selectedItems.at(0) === itemId
                                ? setSelectedItems([])
                                : setSelectedItems([itemId])
                        }
                    />
                </Box>
            </Stack>
            <Group align="flex-start" position="apart" >
                {columns}
            </Group>
            {
                !_.isUndefined(rankingChangesDataset)
                && !_.isUndefined(rankingChangesStages)
                && !_.isUndefined(rankingChangesTestDatasets)
                && <RankingChangesComparisonDashboard
                    dataset={rankingChangesDataset}
                    datasetMetadata={{
                        dataset1: { displayName: rankingChangesStages[0].name, tags: rankingChangesTestDatasets[0].tags },
                        dataset2: { displayName: rankingChangesStages[1].name, tags: rankingChangesTestDatasets[1].tags },
                    }}
                    setSelectedItems={setSelectedItems}
                    selectedItems={selectedItems}
                />
            }
            <Space h="lg" />
            {
                !_.isEmpty(pipeline) && _.isEmpty(selectedItems) && itemCardGrid
            }
            {
                !_.isEmpty(selectedItems) &&
                <Stack>
                    <Title order={2}>Item Details</Title>
                    <Accordion chevronPosition="left">
                        {
                            selectedItems.map((selectedItemId) =>
                                <ItemRankingAccordionItem
                                    key={selectedItemId}
                                    rankingPipeline={rankingPipeline}
                                    rankingPipelineData={rankingPipelineData}
                                    transactionId={transactionId}
                                    item_id={selectedItemId}
                                    testDatasets={Array.from(rankingPipelineData.values()).flat()}
                                />
                            )
                        }
                    </Accordion>
                </Stack>
            }
        </>
    );
}


type TestDataset = TestDatasetWithoutId & { id: number, stage_info?: rdlTypes.RankingPipelineStage };

export default function RankingDrilldownPage() {
    const { rankingPipelineId, traceId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const selectedItems: rdlTypes.Id[] = searchParams.get('selectedItems')?.split(',') ?? [];
    const setSelectedItems = (selectedItems: rdlTypes.Id[]) => {
        setSearchParams((oldSearchParams) => {
            if (_.isEmpty(selectedItems)) {
                oldSearchParams.delete('selectedItems');
            } else {
                const newSelectedItems = selectedItems.join(',');
                oldSearchParams.set('selectedItems', newSelectedItems);
            }

            return oldSearchParams;
        });
    }

    const [apiError, setApiError] = useState('');
    const apiContext = useApiContext();

    const [rankingPipeline, setRankingPipeline] = useState<rdlTypes.RankingPipeline | null>(null);
    const [rankingPipelineData, setRankingPipelineData] = useState<Map<number, TestDataset[]> | undefined>(undefined);

    const [rankingChangesStages, setRankingChangesStages] = useState<[rdlTypes.RankingPipelineStage, rdlTypes.RankingPipelineStage] | undefined>(undefined);
    const [rankingChangesTestDatasets, setRankingChangesTestDatasets] = useState<[TestDataset, TestDataset] | undefined>(undefined);
    const [rankingChangesDataset, setRankingChangesDataset] = useState<RankingChangesDataset | undefined>(undefined);

    const [loading, setLoading] = useState(false);
    const apiErrorAlert = (
        !_.isEmpty(apiError) && <Alert message={apiError} onClose={() => setApiError('')} />
    );

    useEffect(() => {
        if (!_.isNull(apiContext) && !_.isUndefined(rankingPipelineId)) {
            const integerRankingPipelineId = parseInt(rankingPipelineId);
            setLoading(true);
            RubberDuckyLabsApi.getInstance(apiContext)
                .getRankingPipeline(integerRankingPipelineId)
                .then(setRankingPipeline)
                .catch((error) => setApiError(error.message))
        }

        return () => {
            setRankingPipeline(null);
        }
    }, [apiContext, rankingPipelineId]);

    useEffect(() => {
        if (!isUndefinedOrNull(rankingPipeline)) {
            const stageIds = rankingPipeline.stages.filter(({ stage_type }) => ![rdlTypes.RankingPipelineStageType.CandidateGeneration, rdlTypes.RankingPipelineStageType.UserInteractions].includes(stage_type));
            if (stageIds.length >= 2) {
                setRankingChangesStages([stageIds[0], stageIds[1]]);
            } else {
                setRankingChangesStages(undefined);
            }
        }

        return () => {
            setRankingChangesStages(undefined);
        }
    }, [rankingPipeline]);

    useEffect(() => {
        if (!isUndefinedOrNull(rankingPipelineData) && !isUndefinedOrNull(rankingChangesStages)) {
            const [stage1, stage2] = rankingChangesStages;
            const testdataset1 = rankingPipelineData.get(stage1.id)?.at(0);
            const testdataset2 = rankingPipelineData.get(stage2.id)?.at(0);
            if (!_.isUndefined(testdataset1) && !_.isUndefined(testdataset2)) {
                setRankingChangesTestDatasets([testdataset1, testdataset2]);
            } else {
                setRankingChangesTestDatasets(undefined);
            }
        }

        return () => {
            setRankingChangesTestDatasets(undefined);
        }
    }, [rankingPipelineData, rankingChangesStages])

    useEffect(() => {
        if (!_.isNull(apiContext) && !_.isUndefined(traceId) && !_.isEmpty(traceId) && !_.isNull(rankingPipeline)) {
            RubberDuckyLabsApi.getInstance(apiContext)
                .getTestDatasets([traceId])
                .then((testDatasets) => testDatasets.filter((testDataset): testDataset is TestDataset => isRDLId(testDataset.id)))
                .then((testDatasets) => {
                    setRankingPipelineData(new Map(rankingPipeline.stages.map((stage) => ([
                        stage.id,
                        testDatasets.filter(({ tags }) => stage.output_config.tags.every((tag) => tags.includes(tag))),
                    ]))));
                })
                .catch((error) => setApiError(error.message))
                .finally(() => setLoading(false));
        }

        return () => {
            setRankingPipelineData(undefined);
        }
    }, [apiContext, traceId, rankingPipeline]);

    useEffect(() => {
        if (!_.isNull(apiContext) && !_.isUndefined(rankingChangesTestDatasets)) {
            const [testdataset1, testdataset2] = rankingChangesTestDatasets;
            RubberDuckyLabsApi.getInstance(apiContext)
                .getRankingChanges(testdataset1.id, testdataset2.id)
                .then(setRankingChangesDataset)
                .catch((error) => setApiError(error.message))
        }

        return () => {
            setRankingChangesDataset(undefined);
        }
    }, [apiContext, rankingChangesTestDatasets]);

    return (
        <Stack>
            {apiErrorAlert}
            <LoadingOverlay visible={loading} />
            <PipelineTraceDetailBreadcrumbs rankingPipeline={rankingPipeline} traceId={traceId} />
            <Title order={1}>{createTraceTitle(traceId)}</Title>
            <Text c="dimmed">Drilldown into how item ranks change across a ranking pipeline</Text>
            {!_.isUndefined(traceId) && !isUndefinedOrNull(rankingPipeline) && !isUndefinedOrNull(rankingPipelineData) && <>
                <RankingDisplay
                    transactionId={traceId}
                    rankingPipeline={rankingPipeline}
                    rankingPipelineData={rankingPipelineData}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    rankingChangesDataset={rankingChangesDataset}
                    rankingChangesStages={rankingChangesStages}
                    rankingChangesTestDatasets={rankingChangesTestDatasets}
                />
            </>}
        </Stack>
    );

}
