import * as rdlTypes from "../models/RDLDataTypes";
import { Breadcrumbs } from "@mantine/core";
import { Link, To, useSearchParams } from "react-router-dom";

export function createUsersTitle(user: rdlTypes.User | undefined | null) {
    switch (user) {
    case undefined:
        return "Loading...";
    case null:
        return "Not found";
    default:
        return `User ${user.user_id}`
    }
}


export function UsersBreadcrumbs(props: { user: rdlTypes.User | undefined | null }) {
    const { user } = props;
    const [searchParams] = useSearchParams();
    const links: {title: string, to: To}[] = [
        { title: 'Users', to: '..' },
        { title: createUsersTitle(user), to: { search: searchParams.toString() } }
    ];

    const items = links.map((link, index) => (
        <Link to={link.to} key={index}>
            {link.title}
        </Link>
    ));

    return (
        <Breadcrumbs separator={">"}>{items}</Breadcrumbs>
    );
}
