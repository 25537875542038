import * as rdlTypes from "../../../models/RDLDataTypes";
import { MaybeDateRange, isDateRange } from "../../../utilities/date-range";
import { TestDataTypes } from "./testData";
import { createFormContext } from "@mantine/form";
import { isRDLId } from '../../../utilities/types';
import { randomId } from "@mantine/hooks";
import _ from 'underscore';


type RDLJSONDataType = (rdlTypes.Id | { id: rdlTypes.Id } | { item_id: rdlTypes.Id })[];


export interface TestDataFormValues {
    key: string,
    type: TestDataTypes,
    jsonStr: string,
    jsonData: RDLJSONDataType,
    historicalUserClientId: rdlTypes.Id | undefined,
    historicalUserDateRange: MaybeDateRange,
    testDatasetName: string,
}

export const newTestData = function(type: TestDataTypes): TestDataFormValues {
    return {
        key: randomId(),
        type: type,
        jsonStr: '',
        jsonData: [],
        historicalUserClientId: 5841866419,
        // TODO(georgia): This "past week" default normally makes sense,
        // but we need to change it for the Wayfair demo
        historicalUserDateRange: [new Date(2023, 5, 1), new Date(2023, 5, 1)],
        testDatasetName: '',
    };
};


export const isValidJSONData = function(value: any): value is RDLJSONDataType {
    if (!_.isArray(value)) {
        return false;
    }

    if (value.every(isRDLId)) {
        return true;
    }

    if (!value.every(_.isObject)) {
        return true;
    }

    if (!value.every((v) => 'id' in v || 'item_id' in v)) {
        return true;
    }

    return false;
}

export const validateTestFormValues = {
    jsonStr: (value: string, values: TestDataFormValues) => {
        if (values.type !== TestDataTypes.JSON) return undefined;

        if (value.length === 0) {
            return 'JSON Data is required';
        }

        try {
            JSON.parse(value);
        } catch (error) {
            return 'Invalid JSON';
        }

        if (!isValidJSONData(values.jsonData)) {
            return 'JSON Data must be an array of ids, or an array of objects with an id or item_id field';
        }
    },
    historicalUserClientId: (value: rdlTypes.Id | undefined, values: TestDataFormValues) => {
        if (values.type !== TestDataTypes.HistoricalUserData) return undefined;

        if (value === undefined) return 'Please enter a client id';
    },
    historicalUserDateRange: (value: MaybeDateRange, values: TestDataFormValues) => {
        if (values.type !== TestDataTypes.HistoricalUserData) return undefined;

        if (!isDateRange(value)) return 'Please enter a date range';
    },
    testDatasetName: (value: string, values: TestDataFormValues) => {
        if (values.type !== TestDataTypes.TestDataset) return undefined;

        if (value.length === 0) return 'Please enter a test dataset name';
    },
};

export const [TestDataFormProvider, useTestDataFormContext, useTestDataForm] =
  createFormContext<TestDataFormValues>();
