import * as immutable from "object-path-immutable";
import * as math from "mathjs";
import { ItemCardProps } from "../components/ItemCard";
import { RuleDataWeightedScore } from "../components/forms/rule/rule-form-context";


const calculateWeightedScoredScore = function(weightedscoreData: RuleDataWeightedScore, card: ItemCardProps): number {
    return math.sum(weightedscoreData.columnWeights.map(({columnName, coefficient}) => {
        if (coefficient === undefined) return 0;
        return coefficient * immutable.get(card, columnName);
    }))
}

export function applyWeightedScore(weightedscoreData: RuleDataWeightedScore, cards: ItemCardProps[]): ItemCardProps[] {
    const columnName = weightedscoreData.weightedscoreName;
    return cards.map((card) => {
        const columnValue = calculateWeightedScoredScore(weightedscoreData, card);
        return immutable.set(card, columnName, columnValue);
    });
}
