import * as rdlTypes from "../../models/RDLDataTypes";
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Tick,
    TimeScale,
    Title,
    Tooltip,
    TooltipItem,
} from 'chart.js';
import { Line, getDatasetAtEvent } from 'react-chartjs-2';
import { MouseEvent, useRef } from "react";
import _ from 'underscore';
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin,
    Filler,
);

import { ItemCardProps } from "../../components/ItemCard";
import { MantineTheme, useMantineTheme } from '@mantine/core'
import { PipelineStage } from '../../utilities/ranked-cards';
import { chartColorsList, mergeWithDefaultChartOptions } from '../../utilities/charts';
import { getEventNameStr } from '../EventIconMapping';


type DatapointLabel = string;
type DatapointPosition = number | string;
interface Datapoint {
    label: DatapointLabel,
    position: DatapointPosition,
    rescaledPosition: number,
    colorIndex: number,
}
type DatapointLabelToPositionMap = Record<DatapointLabel, DatapointPosition[]>;


const tooltipLabelCallback = function (context: any) {
    let label = context.dataset.label || '';

    if (label) {
        label += ': ';
    }

    if (context.raw.position !== null) {
        label += `${context.raw.position}`
    }

    return label;
}


function tooltipFilter(element: TooltipItem<any>, index: number, array: TooltipItem<any>[]) {
    const firstIndex = array.findIndex((e) => e.dataset.itemId === element.dataset.itemId);
    return firstIndex === index;
}


const createEventsTypesMap = function (cards: ItemCardProps[]): Map<rdlTypes.EventType, [number, string]> {
    const events = cards
        .map(({ event }) => event)
        .filter((event): event is rdlTypes.Event => event !== undefined);
    const eventTypes = _.chain(events)
        .map(({ event_type }) => event_type)
        .uniq()
        .value();
    return new Map(
        eventTypes.map(
            (eventType, index) => [eventType, [index, getEventNameStr(eventType)]]
        )
    );
}


/**
 * This function rescales datapoints along a 0 to 1 scale
 */
const createDatapointsList = function (pipelineStage: PipelineStage): [string, Datapoint[]][] {
    const { label, cards, type } = pipelineStage;

    if (type === rdlTypes.RankingPipelineStageType.UserInteractions) {
        const eventTypesMap = createEventsTypesMap(cards);
        const eventTypesSize = Math.max(eventTypesMap.size, 2);
        const rescaleFactor = 1 / (eventTypesSize - 1);
        const idDatapointPairs: [rdlTypes.Id, Datapoint][] = cards
            .filter((card): card is { event: rdlTypes.Event, id: rdlTypes.Id } => card.event !== undefined && card.id !== undefined)
            .map((card) => {
                const [position, positionLabel] = eventTypesMap.get(card.event.event_type) ?? [-1, 'Unknown metric type'];
                const datapoint = {
                    label: label,
                    position: positionLabel,
                    rescaledPosition: position * rescaleFactor,
                    colorIndex: getColorIndex(position, pipelineStage),
                };
                return [card.id, datapoint];
            });
        return _.chain(idDatapointPairs)
            .groupBy(([itemId]) => itemId)
            .mapObject((datapoints, itemId) => [itemId, datapoints.map(([, datapoint]) => datapoint)])
            .values()
            .value();
    } if (cards.length === 1) {
        if (cards[0].id === undefined) return [];
        const rescaledPosition = 0.5;
        return [[cards[0].id.toString(), [{
            label: label,
            position: 1,
            rescaledPosition: rescaledPosition,
            colorIndex: getColorIndex(1, pipelineStage),
        }]]];
    } else {
        const rescaleFactor = 1 / (cards.length - 1);
        const idDatapointPairs: [rdlTypes.Id, Datapoint][] = cards
            .filter((card): card is { id: rdlTypes.Id } => card.id !== undefined)
            .map((card, index) => {
                const datapoint = {
                    label: label,
                    position: index + 1,
                    rescaledPosition: index * rescaleFactor,
                    colorIndex: getColorIndex(index + 1, pipelineStage),
                };
                return [card.id, datapoint];
            });
        return _.chain(idDatapointPairs)
            .groupBy(([itemId]) => itemId)
            .mapObject((datapoints, itemId) => [itemId, datapoints.map(([, datapoint]) => datapoint)])
            .values()
            .value();
    }
}

const createDatapointsListofListsMap = function (pipeline: PipelineStage[]): Map<rdlTypes.Id, Datapoint[][]> {
    const nonEmptyPipeline = pipeline.filter(({ cards }) => cards.length > 0);
    if (nonEmptyPipeline.length < 1) return new Map();

    const datapointsListofListsMap = new Map<rdlTypes.Id, Datapoint[][]>();
    nonEmptyPipeline.forEach((pipelineStage) => {
        const newDatapointsList = createDatapointsList(pipelineStage);
        newDatapointsList.forEach(([itemId, newDatapoints]) => {
            const oldDatapointsList = datapointsListofListsMap.get(itemId) ?? [[]];
            if (pipelineStage.type === rdlTypes.RankingPipelineStageType.CandidateGeneration) {
                const datapointsList = oldDatapointsList.concat(newDatapoints.map((newDatapoint) => [newDatapoint]));
                datapointsListofListsMap.set(itemId, datapointsList);
            } else {
                const datapointsList = oldDatapointsList
                    .map((oldDatapoints) => (
                        newDatapoints.map((newDatapoint) => [...oldDatapoints, newDatapoint])
                    ))
                    .flat();
                datapointsListofListsMap.set(itemId, datapointsList);
            }
        });
    });

    return datapointsListofListsMap;
}


const createScales = function (pipeline: PipelineStage[], selectedItemDatapoints: DatapointLabelToPositionMap) {
    const xScale = {
        display: false,
        labels: pipeline.map(({ label }) => label),
        type: 'category',
    };

    const originalYScale = {
        type: 'linear',
        display: true,
        position: 'left',
        title: { display: true, text: 'Rank' },
        grid: {
            drawOnChartArea: false,
        },
        min: 0,
        max: 1,
        reverse: true,
        ticks: { display: false },
    };

    const yScales = pipeline.map(({ label, cards, type }) => {
        if (type === rdlTypes.RankingPipelineStageType.UserInteractions) {
            const eventsTypesMap = createEventsTypesMap(cards);
            return {
                type: 'category',
                labels: Array.from(eventsTypesMap.values()).map(([, positionLabel]) => positionLabel),
                display: true,
                position: { x: label },
                title: { display: false },
                grid: {
                    drawOnChartArea: false,
                },
                ticks: {
                    stepSize: 1,
                    padding: 3,
                    font: {
                        weight: 700,
                    },
                    z: 100,
                    showLabelBackdrop: true,
                }
            }
        } else if (cards.length > 0) {
            return {
                type: 'linear',
                display: true,
                position: { x: label },
                title: { display: false },
                grid: {
                    drawOnChartArea: false,
                },
                min: 1,
                max: Math.max(cards.length, 1),
                reverse: true,
                ticks: {
                    autoSkip: false,
                    stepSize: 1,
                    padding: 3,
                    font: {
                        weight: 700,
                    },
                    z: 100,
                    showLabelBackdrop: true,
                    callback: (value: number, index: number, ticks: Tick[]) => {
                        if ([0, 1, ticks.length].includes(value)) return value.toString();
                        if (label in selectedItemDatapoints && selectedItemDatapoints[label].includes(value)) {
                            return value.toString();
                        }
                        return undefined;
                    }
                }
            };
        } else {
            return {
                type: 'category',
                labels: ['No data'],
                display: true,
                position: { x: label },
                title: { display: false },
                grid: {
                    drawOnChartArea: false,
                },
                ticks: {
                    stepSize: 1,
                    padding: 3,
                    font: {
                        weight: 700,
                    },
                    z: 100,
                    showLabelBackdrop: true,
                }
            };
        }
    });

    const scales: any = {
        x: xScale,
        y: originalYScale,
    };

    yScales.forEach((yScale, index) => {
        scales[`y${index}`] = yScale;
    });

    return scales;
};


function getColorIndex(position: number, pipelineStage: PipelineStage) {
    if (pipelineStage.type === rdlTypes.RankingPipelineStageType.UserInteractions) {
        return position;
    }
    return Math.floor(Math.log2(position + 7) - 1);
}


function getThemeColor(
    theme: MantineTheme,
    itemId: rdlTypes.Id,
    selectedItems: rdlTypes.Id[],
    datapointsList: Datapoint[][],
    pipelineStageForColors?: PipelineStage
): string {
    const colorIndex = _.chain(datapointsList)
        .flatten()
        .sortBy(({ position }) => position)
        .findWhere({ label: pipelineStageForColors?.label })
        .value()?.colorIndex;
    const isSelected = selectedItems.includes(itemId);

    if (_.isUndefined(colorIndex) || _.isUndefined(pipelineStageForColors)) {
        const color = theme.colors.gray;
        if (_.isEmpty(selectedItems)) return color[4];
        else if (isSelected) return color[9];
        return color[1];
    }

    const colors = chartColorsList(theme);
    const color = colors[colorIndex];
    if (_.isEmpty(selectedItems)) return color[6];
    else if (isSelected) return color[9];
    return color[1];
}


function getColor(
    theme: MantineTheme,
    itemId: rdlTypes.Id,
    selectedItems: rdlTypes.Id[],
    datapointsList: Datapoint[][],
    pipelineStageForColors?: PipelineStage
) {
    const color = getThemeColor(theme, itemId, selectedItems, datapointsList, pipelineStageForColors);
    return theme.fn.rgba(color, 0.5);
}


export default function PipelineChart(props: {
    pipeline: PipelineStage[],
    selectedItems: rdlTypes.Id[],
    onClickItem: (itemId: rdlTypes.Id) => void,
    pipelineStageForColors?: PipelineStage,
    disableInteractions?: boolean,
}) {
    const theme = useMantineTheme();
    const { disableInteractions } = props;
    const datapointsListofListsMap = createDatapointsListofListsMap(props.pipeline);

    const datasets: any[] = [];
    const selectedItemDatapoints: DatapointLabelToPositionMap = {};
    datapointsListofListsMap.forEach((datapointsList, itemId) => {
        const color = getColor(
            theme,
            itemId,
            props.selectedItems,
            datapointsList,
            props.pipelineStageForColors,
        );

        datapointsList.forEach((datapoints) => {
            if (props.selectedItems.includes(itemId)) {
                datapoints.forEach((datapoint) => {
                    if (datapoint.label in selectedItemDatapoints) {
                        selectedItemDatapoints[datapoint.label].push(datapoint.position);
                    } else {
                        selectedItemDatapoints[datapoint.label] = [datapoint.position];
                    }
                });
            }

            datasets.push({
                itemId: itemId,
                label: `Item ${itemId}`,
                data: datapoints,
                parsing: {
                    xAxisKey: 'label',
                    yAxisKey: 'rescaledPosition',
                },
                borderColor: color,
                backgroundColor: color,
                pointRadius: 3,
                pointHoverRadius: 6,
                order: props.selectedItems.includes(itemId) ? 0 : 1,
            });
        });
    });

    const chartRef = useRef<any>(null);
    const chartOnClick = (event: MouseEvent<HTMLCanvasElement>) => {
        const elements = getDatasetAtEvent(chartRef.current, event);
        if (elements.length > 0) {
            const element = elements[0];
            const index = element.datasetIndex;
            const dataset = datasets[index % datasets.length];
            props.onClickItem(dataset.itemId);
        }
    }

    const scales: any = createScales(props.pipeline, selectedItemDatapoints);

    const options = mergeWithDefaultChartOptions({
        events: disableInteractions ? [] : ['mousemove', 'mouseout', 'click', 'touchstart', 'touchmove'],
        scales: scales,
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: false,
            },
            tooltip: {
                intersect: false,
                filter: tooltipFilter,
                callbacks: {
                    label: tooltipLabelCallback,
                }
            },
            legend: {
                display: false,
            },
            zoom: {
                zoom: {
                    pinch: {
                        enabled: false
                    },
                    drag: {
                        enabled: false,
                    },
                    mode: 'y' as const,
                },
                pan: {
                    enabled: false,
                    modifierKey: 'shift' as const,
                    mode: 'y' as const,
                },
                limits: {
                    y: { min: 'original', max: 'original' },
                    y1: { min: 'original', max: 'original' },
                }
            },
        },
    });

    const data = {
        datasets,
    }

    return (
        <Line
            ref={chartRef}
            options={options}
            data={data}
            onClick={chartOnClick}
        />
    );
}
