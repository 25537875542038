import {
    Box,
    Button,
    Group,
    Tabs,
    Textarea
} from '@mantine/core'
import { Prism } from '@mantine/prism';
import { PrismProps } from "@mantine/prism";
import { useState } from 'react';
import Markdown from 'react-markdown'


interface EditableMarkdownProps {
    value: string,
    onSave: (value: string) => void,
    onClose: () => void,
    editing: boolean
}


interface PreviewableMarkdownProps {
    value: string,
    placeholder?: string,
    onChange: (value: string) => void,
    error?: string
    disabled?: boolean
}

type PreviewableCodeProps = PreviewableMarkdownProps & Omit<PrismProps, "children">;

export function EditableMarkdown({ value, onSave, onClose, editing }: EditableMarkdownProps) {
    const [draft, setDraft] = useState(value);

    if (editing) {
        return (
            <Box>
                <PreviewableMarkdown value={draft} onChange={setDraft} />
                <Group position="right" mt="xs">
                    <Button
                        onClick={() => {
                            setDraft(value);
                            onClose();
                        }}
                        color="gray"
                        variant="outline">
                    Cancel
                    </Button>
                    <Button onClick={() => {
                        onSave(draft);
                        onClose();
                    }}>
                    Save
                    </Button>
                </Group>
            </Box>
        )
    }

    return (
        <Markdown>{value}</Markdown>
    )

}

export function PreviewableMarkdown({ value, placeholder, onChange, error, disabled }: PreviewableMarkdownProps) {
    return (
        <Tabs defaultValue="write">
            <Tabs.List>
                <Tabs.Tab value="write" disabled={disabled}>Write</Tabs.Tab>
                <Tabs.Tab value="preview" disabled={disabled}>Preview</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="write" pt="xs">
                <Textarea placeholder={placeholder} disabled={disabled} value={value} onChange={(e) => onChange(e.currentTarget.value)} error={error} />
            </Tabs.Panel>
            <Tabs.Panel value="preview" pt="xs">
                <Markdown>{value}</Markdown>
            </Tabs.Panel>
        </Tabs>
    );
}


export function PreviewableCode({ value, placeholder, onChange, error, disabled, ...prismProps }: PreviewableCodeProps) {
    return (
        <Tabs defaultValue="write">
            <Tabs.List>
                <Tabs.Tab value="write" disabled={disabled}>Write</Tabs.Tab>
                <Tabs.Tab value="preview" disabled={disabled}>Preview</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="write" pt="xs">
                <Textarea placeholder={placeholder} disabled={disabled} value={value} onChange={(e) => onChange(e.currentTarget.value)} error={error} />
            </Tabs.Panel>
            <Tabs.Panel value="preview" pt="xs">
                <Prism {...prismProps}>{value}</Prism>
            </Tabs.Panel>
        </Tabs>
    );
}
