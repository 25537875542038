import { LAYOUT_TO_SIMPLE_GRID_PROPS } from '../utilities/layout';
import { ProjectsQuickLinks } from "./data-onboarding/Components";
import { SimpleGrid, Stack, Text, Title } from '@mantine/core'
import { mainLinks } from '../mainLink';
import { useAuth0 } from '@auth0/auth0-react';
import { useRDLConfig, useRDLUser } from '../App';
import PageIntroCard from '../components/PageIntroCard';

function PersonalizedWelcome() {
    const { user, isAuthenticated } = useAuth0();

    if (isAuthenticated && user !== undefined) {
        const name = user.name !== undefined ? user.name : user.email;
        return <Title order={1}>Welcome, {name}!</Title>
    }

    return <Title order={1}>Welcome!</Title>;
}


export default function HomePage() {
    const config = useRDLConfig();
    const rdlUser = useRDLUser();
    const links = mainLinks(rdlUser?.is_site_admin ?? false, config);
    const cards = links.map((mainLink) => <PageIntroCard key={mainLink.linkTo} {...mainLink} />)

    return (
        <Stack>
            <PersonalizedWelcome />
            <Text c="dimmed">Debug, analyze, and improve your recommender system with Rubber Ducky Labs.</Text>
            <ProjectsQuickLinks />
            <Title order={2}>Navigate</Title>
            <SimpleGrid {...LAYOUT_TO_SIMPLE_GRID_PROPS.md}>
                {cards}
            </SimpleGrid>
        </Stack>
    );
}
