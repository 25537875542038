import { Group, Stack, Text, useMantineTheme } from "@mantine/core";
import {
    IconArrowsShuffle,
    IconCalculator,
    IconFilter,
    IconList,
    IconSlice,
    IconSortAscending,
    IconSortDescending,
} from "@tabler/icons-react";
import {
    RuleFormValues,
    isRuleFilter,
    isRuleLimit,
    isRulePolicy,
    isRuleShuffle,
    isRuleSort,
    isRuleWeightedScore
} from "./rule-form-context"
import { createFilterTitle } from "../../../utilities/filters";

const DOT_OPERATOR = String.fromCharCode(8901);

export function RuleTitle(props: { rule: RuleFormValues }) {
    const rule = props.rule;

    const theme = useMantineTheme();
    const color = rule.active ? 'black' : theme.colors.gray[4];

    if (isRuleShuffle(rule)) {
        return (
            <Group>
                <IconArrowsShuffle color={color} size={18} />
                <Text size="xs" color={color}>{"shuffle"}</Text>
            </Group>
        );
    } else if (isRuleLimit(rule)) {
        return (
            <Group>
                <IconSlice color={color} size={18} />
                <Text size="xs" color={color}>{`limit ${rule.data.limitData.limit}`}</Text>
            </Group>
        );
    } else if (isRuleFilter(rule)) {
        return (
            <Group>
                <IconFilter color={color} size={18} />
                <Text size="xs" color={color}>{createFilterTitle(rule.data.filterData)}</Text>
            </Group>
        );
    } else if (isRuleSort(rule)) {
        return (
            <Stack>
                {rule.data.sortData.map((sort, index) => (
                    <Group key={index}>
                        {sort.direction === 'Ascending' ? <IconSortAscending color={color} size={18} /> : <IconSortDescending color={color} size={18} />}
                        <Text size="xs" color={color}>{index === 0 ? 'sort by' : 'then'} {sort.columnName}</Text>
                    </Group>
                ))}
            </Stack>
        );
    } else if (isRuleWeightedScore(rule)) {
        return (
            <Stack>
                <Group>
                    <IconCalculator color={color} size={18} />
                    <Text size="xs" color={color}>{`weighted score ${rule.data.weightedscoreData.weightedscoreName}`}</Text>
                </Group>
                <Stack pl="xl">
                    {rule.data.weightedscoreData.columnWeights
                        .map((columnWeight) => `${columnWeight.coefficient} ${DOT_OPERATOR} ${columnWeight.columnName}`)
                        .map((text, i) => <Text size="xs" color={color} key={i}>{i > 0 ? '+ ' : ''}{text}</Text>)
                    }
                </Stack>
            </Stack>
        );
    } else if (isRulePolicy(rule)) {
        return (
            <Stack>
                <Group>
                    <IconList color={color} size={18} />
                    <Text size="xs" color={color}>{`policy ${rule.data.policyData.policyName}`}</Text>
                </Group>
                <Stack pl="xl">
                    {rule.data.policyData.rules.map((rule) => (<RuleTitle key={rule.key} rule={rule} />))}
                </Stack>
            </Stack>
        );
    }

    throw Error("Unknown rule type");
}
