import * as DateFNS from 'date-fns';
import { ColumnTypes, FacetData } from "../models/RDLConfig";
import { FacetFormValues } from "../components/forms/facet/facet-form-context";
import { ItemCardProps } from '../components/ItemCard';
import { categoryLabel } from './categories';
import { isUndefinedOrNull } from './types';
import _ from 'underscore';
import objectPath from "object-path";


export const NO_FACETS_NAME = 'All items';


export enum FacetTypes {
    BooleanValue = "boolean-value",
    NumberBucketsUniformIntegers = "number-buckets-uniform-integers",
    NumberBucketsUniform = "number-buckets-uniform",
    NumberBucketsExponentialBase10 = "number-buckets-base10",
    ListOfCategories = "list-of-categories",
    Date = "date",
    NonMECEListOfCategories = "non-mece-list-of-categories",
}

export const ACCEPTABLE_FACET_TYPES_MAP: Record<ColumnTypes, FacetTypes[]> = {
    [ColumnTypes.Float]: [
        FacetTypes.NumberBucketsUniform,
        FacetTypes.NumberBucketsExponentialBase10,
    ],
    [ColumnTypes.Integer]: [
        FacetTypes.NumberBucketsUniformIntegers,
        FacetTypes.NumberBucketsExponentialBase10,
    ],
    [ColumnTypes.Boolean]: [
        FacetTypes.BooleanValue,
    ],
    [ColumnTypes.Category]: [
        FacetTypes.ListOfCategories,
    ],
    [ColumnTypes.Date]: [
        FacetTypes.Date,
    ],
    [ColumnTypes.Undefined]: [],
    [ColumnTypes.Null]: [],
    [ColumnTypes.ListOfCategories]: [
        FacetTypes.NonMECEListOfCategories,
    ]
};


export interface FacetedCards {
    facetName: string,
    cards: ItemCardProps[],
}

// const LEQ = String.fromCharCode(8804);
// const LE = "<";


export function parseFirstNumber(value: string): number {
    const matches = value.match(/[\d.-]+/);
    if (matches === null || matches.length < 1) return Infinity;
    return Number.parseFloat(matches[0]);
}


export function getExponentialBucket(value: number, isInteger: boolean): string {
    if (value === 0) {
        return `0`;
    }

    const x = Math.floor(Math.log10(Math.abs(value)));

    if (x < 0) {
        const lowerBound = Math.pow(10, x);
        const upperBound = Math.pow(10, x + 1);
        if (value > 0) return `${lowerBound.toPrecision(1)} - ${upperBound.toPrecision(1)} (right side exclusive)`;
        return `${(-1 * upperBound).toPrecision(1)} - ${(-1 * lowerBound).toPrecision(1)} (left side exclusive)`;
    } else if (isInteger) {
        const lowerBound = Math.pow(10, x);
        const upperBound = Math.pow(10, x + 1) - 1;
        if (value > 0) return `${lowerBound} - ${upperBound}`;
        return `${-1 * upperBound} - ${-1 * lowerBound}`;

    } else {
        const lowerBound = Math.pow(10, x);
        const upperBound = Math.pow(10, x + 1);
        if (value > 0) return `${lowerBound} - ${upperBound} (right side exlusive)`;
        return `${-1 * upperBound} - ${-1 * lowerBound} (left side exclusive)`;
    }

}

export const getBucketSize = function(min: number, max: number): number {
    if (max < min) {
        throw new Error('Max is less than min');
    } else if (max === min) {
        return 0;
    }

    const intervalSize = max - min;
    const logSmallerBucketSize = Math.floor(Math.log10(intervalSize)) - 1;

    const smallerBucketSize = Math.pow(10, logSmallerBucketSize);
    if (intervalSize / smallerBucketSize < 20) return smallerBucketSize;
    return Math.pow(10, logSmallerBucketSize + 1);
}

export const getLinearBucket = function(value: number, bucketSize: number, isInteger: boolean): string {
    if (bucketSize < 0) {
        throw new Error ('Bucket size is negative');
    } else if (bucketSize === 0) {
        return `${value}`;
    }

    const lowerBound = Math.floor(value / bucketSize) * bucketSize;
    const upperBound = lowerBound + bucketSize;

    if (isInteger && bucketSize === 1) {
        return `${lowerBound}`;
    } else if (isInteger && bucketSize > 1) {
        return `${lowerBound} - ${upperBound - 1}`;
    } else {
        return `${lowerBound} - ${upperBound} (right side exclusive)`;
    }
}


const applyNumberBucketsFacetToCards = function (cards: ItemCardProps[], facetData: FacetData): FacetedCards[] {
    const values = cards.map((card) => objectPath.get(card, facetData.columnName));
    const [min, max] = [Math.min(...values), Math.max(...values)];

    return _.chain(cards)
        .groupBy((card) => {
            const value = objectPath.get(card, facetData.columnName);
            switch (facetData.facetType) {
            case FacetTypes.NumberBucketsUniformIntegers:
            case FacetTypes.NumberBucketsUniform: {
                const bucketSize = facetData.facetValues.numberBucketData ?? getBucketSize(min, max);
                const isInteger = facetData.facetType === FacetTypes.NumberBucketsUniformIntegers;
                return getLinearBucket(value, bucketSize, isInteger);
            }
            case FacetTypes.NumberBucketsExponentialBase10:
                return getExponentialBucket(value, false);
            default:
                throw Error(`Invalid facet type ${facetData.facetType}`);
            }
        })
        .pairs()
        .map(([facetName, cards]) => ({facetName, cards}))
        .sortBy(({facetName}) => parseFirstNumber(facetName))
        .value();
}

const computeTopNCategoriesCutoff = function(sortedCategories: string[], uniqueCategories: _.Dictionary<number>): number {
    if (sortedCategories.length <= 20) return sortedCategories.length;

    const countOfOtherCategoryByIndex = sortedCategories
        .reduceRight((accumulator: number[], currentValue) => {
            const currentCount = uniqueCategories[currentValue]
            const prevCount = accumulator.at(0) ?? 0;
            return [currentCount + prevCount].concat(accumulator);
        }, [0]);

    const indexOfLastCategoryThatIsBiggerThanOther = _.findLastIndex(
        sortedCategories.slice(0, -1),
        (category, i) => uniqueCategories[category]  > countOfOtherCategoryByIndex[i + 1]
    );

    if (indexOfLastCategoryThatIsBiggerThanOther === -1) {
        return 10;
    }

    return Math.max(Math.min(indexOfLastCategoryThatIsBiggerThanOther, 20) + 1, 10);
}


const applyListOfCategoriesToCards = function (cards: ItemCardProps[], facetData: FacetData): FacetedCards[] {
    const categories = cards.map(card => objectPath.get(card, facetData.columnName));
    const uniqueCategories = _.countBy(categories);
    const sortedCategories = _.chain(uniqueCategories)
        .pairs()
        .sortBy(([, frequency]) => -frequency)
        .pluck(0)
        .without('')
        .value();

    const N = computeTopNCategoriesCutoff(sortedCategories, uniqueCategories)

    const groupedCards = _.groupBy(cards, (card) => objectPath.get(card, facetData.columnName));
    const topCategoriesFacets = sortedCategories.slice(0, N).map((category) => ({
        facetName: category,
        cards: category in groupedCards ? groupedCards[category] : [],
    }));

    const emptyStringFacet = {
        facetName: categoryLabel(''),
        cards: '' in groupedCards ? groupedCards[''] : [],
    }

    const bottomCategoriesFacet = {
        facetName: `Bottom ${sortedCategories.length - N} categories`,
        cards: sortedCategories.slice(N).flatMap((category) => category in groupedCards ? groupedCards[category] : []).flat(),
    };

    const facets = topCategoriesFacets;

    if (bottomCategoriesFacet.cards.length > 0) {
        facets.push(bottomCategoriesFacet);
    }

    if (emptyStringFacet.cards.length > 0) {
        facets.unshift(emptyStringFacet);
    }

    return facets;
}


const applyBooleanFacetToCards = function (cards: ItemCardProps[], facetData: FacetData): FacetedCards[] {
    const [trueCards, falseCards] = _.partition(cards, (card) => objectPath.get(card, facetData.columnName));

    return [
        {
            facetName: 'True',
            cards: trueCards,
        },
        {
            facetName: 'False',
            cards: falseCards,
        },
    ];
}


const applyDateFacetToCards = function (cards: ItemCardProps[], facetData: FacetData): FacetedCards[] {
    const dateData = facetData.facetValues.dateData;
    const formatDate = function (dateJSON: string): string {
        const date = DateFNS.parseJSON(dateJSON);
        switch(dateData) {
        case "day":
            return DateFNS.lightFormat(date, "yyyy-MM-dd");
        case "week":
            return `${DateFNS.lightFormat(date, "yyyy")}, week ${DateFNS.getWeek(date)}`
        case "month":
            return DateFNS.lightFormat(date, "yyyy-MM");
        case "year":
            return DateFNS.lightFormat(date, "yyyy");
        default:
            throw Error(`Unsupported date facet type: ${dateData}`);
        }
    }

    return _.chain(cards)
        .groupBy((card) => formatDate(objectPath.get(card, facetData.columnName)))
        .pairs()
        .map(([facetValue, facetCards]) => ({
            facetName: facetValue,
            cards: facetCards,
        }))
        .sortBy(({facetName}) => facetName)
        .value();
}


const applyNonMECEListOfCategoriesFacetToCards = function (cards: ItemCardProps[], facetData: FacetData): FacetedCards[] {
    const categories = cards.map(card => objectPath.get(card, facetData.columnName)).filter((v) => !isUndefinedOrNull(v)).flat();
    const uniqueCategories = _.countBy(categories);
    const sortedCategories = _.chain(uniqueCategories)
        .pairs()
        .sortBy(([, frequency]) => -frequency)
        .pluck(0)
        .value();

    const N = computeTopNCategoriesCutoff(sortedCategories, uniqueCategories)
    const topNCategories = sortedCategories.slice(0, N);

    return topNCategories.map((category) => ({
        facetName: category,
        cards: cards.filter((card) => {
            const value = objectPath.get(card, facetData.columnName);
            return !isUndefinedOrNull(value) && value.includes(category);
        })
    }));
}


const applyOneFacetWithoutNull = function (cards: ItemCardProps[], facetData: FacetData): FacetedCards[] {
    if (facetData.facetType === undefined) {
        throw Error(`Invalid facet type ${facetData.facetType}`)
    }

    switch(facetData.facetType) {
    case FacetTypes.NumberBucketsUniform:
    case FacetTypes.NumberBucketsUniformIntegers:
    case FacetTypes.NumberBucketsExponentialBase10:
        return applyNumberBucketsFacetToCards(cards, facetData);
    case FacetTypes.ListOfCategories:
        return applyListOfCategoriesToCards(cards, facetData);
    case FacetTypes.BooleanValue:
        return applyBooleanFacetToCards(cards, facetData);
    case FacetTypes.Date:
        return applyDateFacetToCards(cards, facetData);
    case FacetTypes.NonMECEListOfCategories:
        return applyNonMECEListOfCategoriesFacetToCards(cards, facetData);
    }
}


export const UNDEFINED_OR_NULL_FACET_NAME = 'undefined or null';

const applyOneFacet = function (cards: ItemCardProps[], facetData: FacetData): FacetedCards[] {
    const [nullOrUndefinedCards, nonNullOrUndefinedCards] = _.partition(cards, (card) => isUndefinedOrNull(objectPath.get(card, facetData.columnName)));
    const facets = applyOneFacetWithoutNull(nonNullOrUndefinedCards, facetData);
    if (nullOrUndefinedCards.length > 0) {
        facets.push({ facetName: UNDEFINED_OR_NULL_FACET_NAME, cards: nullOrUndefinedCards });
    }
    return facets;
}

const constructFacetName = function(facetNames: string[]): string {
    return facetNames.length === 0 ?  NO_FACETS_NAME : facetNames.join(', ');
}

export const deconstructFacetName = function(facetName: string): string[] {
    return facetName === NO_FACETS_NAME ? [] : facetName.split(', ');
}

const constructFacetedDatasetsRecursive = function (cards: ItemCardProps[], facets: FacetFormValues[], facetNames: string[]): FacetedCards[] {
    if (facets.length === 0) {
        return [{
            facetName: constructFacetName(facetNames),
            cards: cards,
        }];
    }

    const [initial, last] = [facets.slice(0, -1), facets[facets.length - 1]];

    const facetedDatasets = applyOneFacet(cards, last.data.facetData);
    return facetedDatasets.flatMap((dataset) => constructFacetedDatasetsRecursive(dataset.cards, initial, [dataset.facetName, ...facetNames]));
}


export const constructFacetedDatasets = function (items: ItemCardProps[], facets: FacetFormValues[]): FacetedCards[] {
    return constructFacetedDatasetsRecursive(items, facets, []);
}


export const chartAxisTitle = function(facets: FacetFormValues[]): string {
    if (_.size(facets) < 1) return NO_FACETS_NAME;

    return facets.map(createFacetTitle).join(', ');
}


export const chartTitleWithFacets = function(chartTitle: string, facets: FacetFormValues[]): string {
    if (_.size(facets) < 1) return chartTitle;

    return `${chartTitle} by ${chartAxisTitle(facets)}`;
}


export const createFacetTitle = function(facet: FacetFormValues): string {
    return facet.data.facetData.columnName
        .replace('item.item_attributes.', '')
        .replace('scores.', '')
        .replace('metrics.', '');
}
