import { createFormContext } from "@mantine/form";

export interface RankingDrilldownFormValues {
    transactionId: string,
    rankingPipelineId: string,
}


export const validateRankingDrilldownFormValues = {
    transactionId: (value: string) => value.length > 0 ? undefined : 'Please enter a transaction id',
    rankingPipelineId: (value: string) => value.length > 0 ? undefined : 'Please select a ranking pipeline',
};


export const [RankingDrilldownFormProvider, useRankingDrilldownFormContext, useRankingDrilldownForm] =
    createFormContext<RankingDrilldownFormValues>();
