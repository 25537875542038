import {
    Avatar,
    Button,
    Group,
    Modal,
    Stack,
    Text,
    TextInput,
    Title
} from "@mantine/core"
import { IconUserSearch } from "@tabler/icons-react";
import { Link, To } from "react-router-dom";
import { UserFiltersForm } from "../../components/forms/user-filters/UserFiltersForm";
import { UserFiltersFormValues, newUserFilters } from "../../components/forms/user-filters/user-filters-form-context";
import { UsersTableWithData } from "../../components/UsersTable";
import { useDisclosure } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useRDLConfig } from "../../App";
import _ from "underscore";


function FindByUserIdModal() {
    const [opened, { open, close }] = useDisclosure(false);
    const [userId, setUserId] = useState<string | undefined>(undefined);

    const linkTo: To = { pathname: `/users/${userId}` };

    return (
        <>
            <Modal opened={opened} onClose={close} title="Find User by Id">
                <Stack align="flex-start">
                    <TextInput label="User id" placeholder="User id" value={userId} onChange={(e) => setUserId(e.target.value)} />
                    <Button
                        component={Link}
                        to={linkTo}
                        variant="light"
                        leftIcon={<IconUserSearch />}
                        disabled={_.isUndefined(userId)}
                    >
                        Find user
                    </Button>
                </Stack>
            </Modal>

            <Button onClick={open} variant="light" leftIcon={<IconUserSearch />}>Find by Id</Button>
        </>
    );
}


export function UsersIndexPage() {
    const [userFilters, setUserFilters] = useState<UserFiltersFormValues>({userFilters: [], segment: null});
    const config = useRDLConfig();

    useEffect(() => {
        if (config.loaded) {
            setUserFilters((prev) => (
                { userFilters: newUserFilters(config), segment: prev.segment}
            ));
        }
    }, [config]);

    return (
        <Stack>
            <Group>
                <Avatar color="yellow" />
                <Title order={1}>Users</Title>
            </Group>
            <Text c="dimmed">Select a user to begin exploring behavior, use the filters to search for users</Text>
            <Group position="apart" align="end">
                {!_.isNull(userFilters) && <UserFiltersForm
                    values={userFilters}
                    onSubmit={setUserFilters}
                />}
                <FindByUserIdModal />
            </Group>
            <UsersTableWithData userFiltersForm={userFilters} />
        </Stack>
    );
}
