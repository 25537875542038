export const NO_SEGMENT = "No segment";


export interface UserSegment {
    name: string,
    key: string,
    color: string[],
    countUsers: number, // TODO(alexandra): remove this, this is only used in the chart
    hidden: boolean,
}
