import 'chartjs-adapter-date-fns';
import * as rdlTypes from "../../models/RDLDataTypes";
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Title as ChartTitle,
    Filler,
    Interaction,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    TimeScale,
    Tooltip,
} from 'chart.js';
import { NO_SEGMENT, UserSegment } from '../../models/user-segments';
import { Scatter } from 'react-chartjs-2';
import { getRelativePosition } from 'chart.js/helpers';
import { mergeWithDefaultChartOptions } from "../../utilities/charts";
import { useMantineTheme } from "@mantine/core";
import _ from "underscore";
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    BarElement,
    PointElement,
    LineElement,
    ChartTitle,
    Tooltip,
    Legend,
    zoomPlugin,
    Filler,
);


declare module 'chart.js' {
    interface InteractionModeMap {
        draggableBoxMode: InteractionModeFunction;
    }
}

function interactionModeFunctionFactory() {
    let start: undefined | { x: number, y: number } = undefined;
    let end: undefined | { x: number, y: number } = undefined;
    const dragging = { inProgress: false };

    return (chart: any, e: any, options: any, useFinalPosition: any) => {
        const position = getRelativePosition(e, chart);
        const isDragStart = e.type === 'mousedown';
        const isDragging = e.type === 'mousemove' && dragging.inProgress;
        const isDragEnd = e.type === 'mouseup' && dragging.inProgress;

        if (isDragStart) {
            start = position;
            end = undefined;
            dragging.inProgress = true;
        } else if (isDragging) {
            end = position;
        } else if (isDragEnd) {
            end = position;
            dragging.inProgress = false;
        }

        const isBetween = (start: number, end: number, value: number) => {
            return (start < value && value < end) || (end < value && value < start);
        }

        const isWithinBox = (element: any) => {
            const elementPosition = element.getCenterPoint(useFinalPosition);
            if (!_.isUndefined(start) && !_.isUndefined(end)) {
                return isBetween(start.x, end.x, elementPosition.x) && isBetween(start.y, end.y, elementPosition.y);
            }
        }

        const items: any[] = [];
        Interaction.evaluateInteractionItems(chart, 'x', position, (element, datasetIndex, index) => {
            if (isWithinBox(element)) {
                items.push({element, datasetIndex, index});
            }
        });
        return items;
    };
}


Interaction.modes.draggableBoxMode = interactionModeFunctionFactory();


export function EmbeddingsChart({ data, segments, userIdToSegmentMap, onSelect }: {
    data: {user_id: string, x: number, y: number}[],
    userIdToSegmentMap: Record<string, rdlTypes.Id>,
    segments: UserSegment[],
    onSelect: (event: MouseEvent, chartElements: any[]) => void,
}) {
    const embeddingsBySegment = _.groupBy(data, ({user_id}) => (user_id in userIdToSegmentMap) ? userIdToSegmentMap[user_id] : NO_SEGMENT);
    const theme = useMantineTheme();

    const datasets = segments.map((segment) => (
        {
            label: segment.name,
            data: segment.key in embeddingsBySegment ? embeddingsBySegment[segment.key] : [],
            backgroundColor: theme.fn.rgba(segment.color[4], 0.5),
            borderColor: theme.fn.rgba(segment.color[6], 0.5)
        }
    ));

    const options = mergeWithDefaultChartOptions({
        aspectRatio: 3,
        scales: {
            x: { display: false },
            y: { display: false },
        },
        legend: { display: false },
        interaction: { mode: 'draggableBoxMode' },
        events: ['mousedown', 'mouseup', 'mousemove'], // TODO Alexandra: add touch events
        plugins: {
            title: {
                display: true,
                text: "User segments clustered by activity",
            },
            tooltip: {
                enabled: false,
            },
            zoom: {
                zoom: {
                    wheel: {
                        enabled: false,
                    },
                    pinch: {
                        enabled: false
                    },
                    drag: {
                        enabled: false,
                    },
                    mode: 'xy' as const,
                },
                pan: {
                    enabled: true,
                    modifierKey: 'shift' as const,
                    mode: 'xy' as const,
                }
            },
        },
        onClick: onSelect,
    });

    return (
        <Scatter options={options} data={{ datasets, }} />
    );
}
