export enum TestDataTypes {
    JSON = "json",
    ModelAPI = "model-api",
    HistoricalUserData = "historical-user-data",
    TestDataset = "test-dataset",
    ProductCatalog = "product-catalog",
    HistoricalUserDataRecommendationsOnly = "historical-user-data-recommendations-only",
}

export const TEST_DATA_TITLE_MAP: Record<TestDataTypes, string> = {
    [TestDataTypes.JSON]: "JSON",
    [TestDataTypes.ModelAPI]: "Model API",
    [TestDataTypes.HistoricalUserData]: "Historical User Data",
    [TestDataTypes.TestDataset]: "Test Dataset",
    [TestDataTypes.ProductCatalog]: "Product Catalog",
    [TestDataTypes.HistoricalUserDataRecommendationsOnly]: "Historical User Data (Recommendations Only)",
};
