import {
    Box,
    Button,
    Group,
    Modal,
    Stack
} from "@mantine/core"
import {
    DragDropContext,
    Draggable,
    DraggableProvidedDragHandleProps,
    DropResult,
    Droppable
} from 'react-beautiful-dnd'
import { EventType } from "../../../models/RDLDataTypes";
import { IconX } from "@tabler/icons-react";
import {
    MetricFormProvider,
    MetricFormValues,
    newMetric,
    useMetricForm,
    validateMetricFormValues
} from "../metric/metric-form-context"
import { SearchableNameInput } from "./SearchableNameInput";
import { createMetricTitle } from "../../../utilities/metric-calculations/metrics";
import { useDisclosure } from "@mantine/hooks";
import { useRDLConfig } from "../../../App";


type OnSubmitFunctionType = (values: MetricFormValues) => void;
type OnRemoveFunctionType = () => void;


function EditMetricForm(
    props: {
        onSubmit: OnSubmitFunctionType,
        values: MetricFormValues,
        onRemove: OnRemoveFunctionType,
    }
) {
    const config = useRDLConfig();
    const form = useMetricForm({
        initialValues: props.values,
        validate: validateMetricFormValues,
    });
    const metricNames = config.metricTypes
        .concat(config.metricCalculations
            .map(calculation => calculation.eventType))
        .map(eventType => EventType[eventType]);

    return (
        <MetricFormProvider form={form}>
            <form onSubmit={form.onSubmit((values) => props.onSubmit(values))}>
                <Stack>
                    <SearchableNameInput names={metricNames} />
                    <Group position="right">
                        <Button variant="light" color="gray" onClick={() => props.onRemove()}>Discard</Button>
                        <Button type="submit">Save</Button>
                    </Group>
                </Stack>
            </form>
        </MetricFormProvider>
    )
}

// Submitting this form will unfortunately trigger submitting the outside form, don't know why yet
export function CreateMetricModal(props: { onSubmit: OnSubmitFunctionType }) {
    const [opened, { close, open }] = useDisclosure(false);
    const onSubmit = function (values: MetricFormValues) {
        props.onSubmit(values);
        close();
    }

    return (
        <>
            <Modal
                opened={opened}
                onClose={close}
                title="Create a metric"
            >
                <EditMetricForm onSubmit={onSubmit} onRemove={close} values={newMetric()} />
            </Modal>
            <Group position="center">
                <Button onClick={open}>Add a metric</Button>
            </Group>
        </>
    );
}


export function EditMetricModal(
    props: {
        onSubmit: OnSubmitFunctionType,
        values: MetricFormValues,
        onRemove: OnRemoveFunctionType,
        dragHandleProps: DraggableProvidedDragHandleProps | undefined,
    }
) {
    const [opened, { close, open }] = useDisclosure(false);
    const onSubmit = function (values: MetricFormValues) {
        props.onSubmit(values);
        close();
    }

    const color = "blue";
    const dragHandleProps = props.dragHandleProps ?? {};

    return (
        <>
            <Modal
                opened={opened}
                onClose={close}
                title="Edit event"
            >
                <EditMetricForm onSubmit={onSubmit} onRemove={close} values={props.values} />
            </Modal>
            <Button.Group {...dragHandleProps}>
                <Button
                    radius="xl"
                    color={color}
                    variant="light"
                    compact={true}
                    onClick={open}
                    pl={15}
                >
                    {createMetricTitle(props.values)}
                </Button>
                <Button
                    radius="xl"
                    color={color}
                    variant="light"
                    compact={true}
                    onClick={() => props.onRemove()}
                >
                    <IconX size={10} />
                </Button>
            </Button.Group>
        </>
    );
}


export function MetricsInput(props: {
    metrics: MetricFormValues[],
    handlers: any,
}) {
    const metrics = props.metrics;
    const handlers = props.handlers;


    const onDragEnd = function ({ destination, source }: DropResult) {
        destination && handlers.reorder({ from: source.index, to: destination.index });
    }

    const fields = metrics.map((metric: MetricFormValues, index: number) => (
        <Draggable key={metric.key} index={index} draggableId={metric.key} disableInteractiveElementBlocking>
            {(provided) => (
                <Box ref={provided.innerRef} {...provided.draggableProps} pr='xs'>
                    <EditMetricModal
                        key={metric.key}
                        onSubmit={(values: MetricFormValues) => handlers.setItem(index, values)}
                        values={metric}
                        onRemove={() => handlers.remove(index)}
                        dragHandleProps={provided.dragHandleProps}
                    />
                </Box>)}
        </Draggable>));

    return (
        <Group position="left" mt="md">
            <CreateMetricModal onSubmit={(values: MetricFormValues) => handlers.append(values)} />
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="dnd-list" direction="horizontal">
                    {(provided) => (
                        <Box {...provided.droppableProps} ref={provided.innerRef} sx={{ display: 'flex' }}>
                            {fields}
                            {provided.placeholder}
                        </Box>
                    )}
                </Droppable>
            </DragDropContext>
        </Group>
    );
}
