import { MaybeDateRange } from '../../../utilities/date-range';
import { UserFilter } from '../user-filters/user-filters-form-context';
import { createFormContext } from '@mantine/form';

export interface DataExploreApiRequestFormValues {
    dateRange: MaybeDateRange,
    userFilters: UserFilter[],
    segment: null | string,
}

export const [DataExploreFormProvider, useDataExploreFormContext, useDataExploreForm] =
    createFormContext<DataExploreApiRequestFormValues>();
