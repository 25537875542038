import 'chartjs-adapter-date-fns';
import * as rdlTypes from "../../models/RDLDataTypes";
import { Bar } from 'react-chartjs-2';
import {
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    ChartOptions,
    Title as ChartTitle,
    Filler,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    TimeScale,
    Tooltip,
    TooltipItem,
} from 'chart.js';
import { MetricFormValues } from "../forms/metric/metric-form-context";
import { chartColorsList, mergeWithDefaultChartOptions } from "../../utilities/charts";
import { useMantineTheme } from "@mantine/core";
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    BarElement,
    PointElement,
    LineElement,
    ChartTitle,
    Tooltip,
    Legend,
    zoomPlugin,
    Filler,
);


export function EventStatisticsBarChart(props: {
    stats: rdlTypes.EventStatistic[],
    aggregation: string,
    colorIndex: number,
    metric: MetricFormValues,
}) {
    const { stats, aggregation, colorIndex, metric } = props;
    const theme = useMantineTheme();
    const colors = chartColorsList(theme);
    const formattedAggregation = aggregation.replace('event.event_attributes.', '')

    const dataset = {
        data: stats,
        borderColor: theme.fn.rgba(colors[colorIndex % colors.length][6], 0.5),
        backgroundColor: theme.fn.rgba(colors[colorIndex % colors.length][5], 0.5),
        parsing: {
            yAxisKey: 'count',
            xAxisKey: `aggregation.${aggregation.replaceAll('.', '\\.')}`,
        }
    };

    const data = {
        datasets: [dataset],
    }

    const options: ChartOptions = {
        scales: {
            y: {
                type: 'linear',
                title: { display: false, text: `Count of ${metric.displayName}` },
                ticks: {
                    callback: (value: any) => Number.isInteger(value) ? value : null,
                },
                beginAtZero: true,
                grid: { display: false }
            },
            x: {
                title: { display: true, text: formattedAggregation },
                type: 'linear',
                grid: { display: false }
            }
        },
        plugins: {
            title: {
                display: false,
                text: `Count of ${metric.displayName} by ${aggregation}`
            },
            tooltip: {
                intersect: false,
                callbacks: {
                    title: (context: TooltipItem<any>[]) => context.map((ctx) => `${formattedAggregation} is ${ctx.parsed.x}`),
                    label: (context: TooltipItem<any>) => `${context.parsed.y.toLocaleString()} ${metric.displayName}`
                },
            },
            zoom: {
                zoom: {
                    wheel: { enabled: false },
                    pinch: { enabled: false },
                    drag: { enabled: false },
                },
                pan: { enabled: false },
            },
            legend: {
                display: false,
            },
        },
    };

    return (
        <>
            <Bar
                options={mergeWithDefaultChartOptions(options)}
                data={data}
            />
        </>
    );
}
