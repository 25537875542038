import { useEffect, useMemo, useState } from "react";

import {
    Divider,
    Group,
    Loader,
    ScrollArea,
    Skeleton,
    Space,
    Stack,
    Tabs,
    Text,
    Title
} from '@mantine/core'
import { FacetsInput } from "../components/forms/facet/FacetModals";
import { FiltersInput } from "../components/forms/filter/FilterModals";
import { RDLApiContext, RubberDuckyLabsApi } from '../RDLApi';
import { useListState } from "@mantine/hooks";
import _ from 'underscore';

import * as dateFNS from "date-fns";
import * as math from "mathjs";
import * as rdlTypes from "../models/RDLDataTypes";
import { Alert } from "../components/Alert";
import { ColumnProvider } from "../context/ColumnContext";
import { DataExploreApiRequestForm, SkeletonDataExploreForm } from "../components/forms/data-explore-api/DataExploreApiRequestForm";
import { DataExploreApiRequestFormValues } from "../components/forms/data-explore-api/data-explore-request-form-context";
import { EventsData, filterEventsToTimeScale } from "../utilities/events";
import { FacetFormValues } from "../components/forms/facet/facet-form-context";
import { FacetedCards, constructFacetedDatasets } from "../utilities/facets";
import { IconChartLine, IconPictureInPicture } from "@tabler/icons-react";
import { ItemCardProps } from "../components/ItemCard";
import { MaybeDateRange, getDateRangesWithoutData, isDateRange } from "../utilities/date-range";
import { MetricFormValues, getMetricsFromConfig } from "../components/forms/metric/metric-form-context";
import { MetricsInput } from "../components/forms/metric/MetricModals";
import { RDLConfigType } from "../models/RDLConfig";
import { RuleFilter } from "../components/forms/rule/rule-form-context";
import { UserFilter, newUserFilters } from "../components/forms/user-filters/user-filters-form-context";
import { applyFilter } from "../utilities/filters";
import { batchRequests } from "../utilities/batch-requests";
import { calculateMetrics } from "../utilities/metric-calculations/apply-calculations";
import { isRateMetric } from "../utilities/metric-calculations/metrics";
import { useApiContext, useItems, useLoadAndCacheItems, useRDLConfig } from "../App";
import MetricsChartsPanel from "../components/data-explore-panels/MetricsChartsPanel";
import OverviewPanel from "../components/data-explore-panels/OverviewPanel";


type CountsMap = Record<rdlTypes.Id, number>;
interface MetricStats {
    metricType: rdlTypes.EventType,
    countsMap: CountsMap,
    counts: number[],
    mean: number,
    std: number,
}

function SkeletonDataAggregationInput() {
    return (
        <Stack spacing="md">
            <Space h="xs" />
            <Divider />
            <Group>
                <Skeleton width={100} height={40} />
                <Skeleton width={120} height={20} />
                <Skeleton width={120} height={20} />
                <Skeleton width={120} height={20} />
                <Skeleton width={120} height={20} />
            </Group>
            <Group>
                <Skeleton width={100} height={40} />
                <Skeleton width={120} height={20} />
            </Group>
            <Group>
                <Skeleton width={100} height={40} />
                <Skeleton width={120} height={20} />
                <Skeleton width={120} height={20} />
            </Group>
            <Divider />
        </Stack>
    )
}


const requestEventsData = function (
    onSuccess: (params: [rdlTypes.EventType, rdlTypes.EventCount[]]) => void,
    onError: (message: string) => void,
    apiContext: RDLApiContext,
    values: DataExploreApiRequestFormValues,
    eventsData: EventsData[],
): Promise<void> {
    if (!isDateRange(values.dateRange)) {
        return Promise.resolve();
    }

    const [start, end] = values.dateRange;

    const eventsRequestsParamsList: { start: Date, end: Date, eventType: rdlTypes.EventType }[] = eventsData.map(({ eventType, data }) => {
        const datesWithData = new Set(_.pluck(data, 'date'));
        return getDateRangesWithoutData(datesWithData, [start, end])
            .map((dates) => ({ start: dates[0], end: dates[1], eventType: eventType }));
    }).flat();
    eventsRequestsParamsList.sort((a, b) => dateFNS.compareAsc(a.start, b.start));
    const requests = eventsRequestsParamsList.map((params) => {
        const makeRequest = function () {
            return RubberDuckyLabsApi.getInstance(apiContext).getEventsByItemAndDate(
                params.eventType,
                params.start,
                params.end,
                values.userFilters,
                values.segment,
            ).then((response) => [params.eventType, response]);
        }
        return makeRequest;
    });

    return batchRequests(requests, onSuccess, onError)
        .catch((error) => {
            onError(error.message);
        });
}


const generateDefaultEventsData = function (config: RDLConfigType): EventsData[] {
    return config.metricTypes.map(
        (eventType) => ({ eventType: eventType, data: [] })
    );
}


const createItemCards = function (
    filteredEventsAndCalculationsData: EventsData[],
    items: rdlTypes.Item[],
): ItemCardProps[] {
    /**
         * The way that numbers and cards are handled here will have to fundamentally change.
         * In the future we should show representative cards by facet.
         * The view / like counts will be all off
         * But actually if we're grouping by id then the view like counts will at least be the
         * representative total
         */
    const metricsMaps: Map<rdlTypes.EventType, MetricStats> = new Map<rdlTypes.EventType, MetricStats>(
        filteredEventsAndCalculationsData.map((eventData) => {
            const metricMap: CountsMap = _.chain(eventData.data)
                .groupBy('item_id')
                .mapObject((value) => {
                    if (isRateMetric(eventData.eventType)) {
                        // TODO(Alexandra): we should really be checking the operation of the metric here
                        const totalDenom = value.reduce((prev, curr) => prev + curr.count, 0);
                        const totalNom = value.reduce((prev, curr) => prev + (curr.rate ? curr.rate * curr.count : 0), 0);
                        return totalNom / totalDenom;
                    } else {
                        return value.reduce((prev, curr) => prev + curr.count, 0);
                    }
                })
                .value();
            const counts = Object.values(metricMap)
            const metricType = eventData.eventType
            return [
                metricType,
                {
                    metricType: metricType,
                    countsMap: metricMap,
                    counts: counts,
                    mean: counts.length > 0 ? math.mean(...counts) : 0,
                    std: counts.length > 0 ? math.std(...counts) : 1,
                }
            ]
        })
    );

    return items
        .map((item) => {
            const itemMetrics = Array.from(metricsMaps.values()).reduce((result, curr: MetricStats) => {
                const metricName = rdlTypes.EventType[curr.metricType];
                return {
                    ...result,
                    [metricName]: {
                        metricType: curr.metricType,
                        value: item.item_id in curr.countsMap ? curr.countsMap[item.item_id] : 0,
                        mean: curr.mean,
                        std: curr.std,
                    }
                }
            }, {});
            return {
                item: item,
                metrics: itemMetrics,
            };
        });
}

export default function DataExplorePage() {
    const [loading, setLoading] = useState<boolean>(false);
    const [facets, facetsHandlers] = useListState<FacetFormValues>([]);
    const [filters, filtersHandlers] = useListState<RuleFilter>([]);

    const config = useRDLConfig();
    const apiContext = useApiContext();

    const [metrics, metricsHandlers] = useListState<MetricFormValues>([]);
    const [eventsData, eventsDataHandlers] = useListState<EventsData>([]);
    const [userFilters, setUserFilters] = useState<UserFilter[] | null>(null);
    const [segment, setSegment] = useState<string | null>(null);

    const items = useItems();
    const loadAndCacheItems = useLoadAndCacheItems();
    useEffect(() => {
        loadAndCacheItems(apiContext);
    }, [apiContext]);

    useEffect(() => {
        metricsHandlers.setState(getMetricsFromConfig(config));
        eventsDataHandlers.setState(generateDefaultEventsData(config));
        if (config.loaded) {
            setUserFilters(newUserFilters(config));
        }
    }, [config]);

    const [apiError, setApiError] = useState<string>('');
    const [sharedTimeScale, setSharedTimeScale] = useState<MaybeDateRange>([null, null]);

    const cardData: ItemCardProps[] = useMemo(
        () => {
            const filteredEventsData = filterEventsToTimeScale(eventsData, sharedTimeScale);
            const calculationsData = calculateMetrics(config.metricCalculations, filteredEventsData);
            return createItemCards(filteredEventsData.concat(calculationsData), items);
        },
        [eventsData, sharedTimeScale, items, config],
    );

    const cardsWithMetrics: ItemCardProps[] = useMemo(
        () => {
            const displayMetrics = _.pluck(metrics, 'name');
            return cardData.map((card) => ({ ...card, displayMetrics }));
        },
        [metrics, cardData],
    );

    const filteredCards: ItemCardProps[] = useMemo(
        () => {
            let newFilteredCards: ItemCardProps[] = cardsWithMetrics;
            const activeFilters = filters.filter((filter) => filter.active);
            activeFilters.forEach((filter) => {
                newFilteredCards = applyFilter(filter.data.filterData, newFilteredCards);
            });
            return newFilteredCards;
        },
        [filters, cardsWithMetrics],
    );

    const facetedCards: FacetedCards[] = useMemo(
        () => {
            const activeFacets = facets.filter((facet) => facet.active);
            return constructFacetedDatasets(filteredCards, activeFacets);
        },
        [facets, filteredCards],
    );


    const isApiRequestDisabled = eventsData.length === 0;

    const onSubmitApiRequestForm = function (apiContext: RDLApiContext, values: DataExploreApiRequestFormValues) {
        setLoading(true);
        const onSuccess = function (params: [rdlTypes.EventType, rdlTypes.EventCount[]]) {
            const [eventType, data] = params;
            eventsDataHandlers.applyWhere(
                (ed) => ed.eventType == eventType,
                (ed) => ({ eventType: ed.eventType, data: ed.data.concat(data) })
            );
        }

        if (!_.isEqual(values.userFilters, userFilters)) {
            eventsDataHandlers.setState(generateDefaultEventsData(config));
        }

        const requiresRefresh = !(_.isEqual(values.userFilters, userFilters) && values.segment === segment);

        return requestEventsData(
            onSuccess,
            setApiError,
            apiContext,
            values,
            // We re-use generateDefaultEventsData(config) here to avoid a race condition in setState
            requiresRefresh ? generateDefaultEventsData(config) : eventsData,
        )
            .then(() => {
                setSharedTimeScale(values.dateRange);
                setUserFilters(values.userFilters);
                setSegment(values.segment);
                setLoading(false);
            });
    }

    const hasData = eventsData.length > 0 && eventsData.some(({ data }) => data.length > 0);
    const [activeTab, setActiveTab] = useState<string | null>('overview');

    return (
        <ColumnProvider cards={cardsWithMetrics}>
            <Stack spacing="xs">
                {!_.isEmpty(apiError) && <Alert onClose={() => setApiError('')} message={apiError} />}
                <Title order={1}>Data Explore</Title>
                <Text c="dimmed">Select the time range and user group being investigated.</Text>
                {_.isNull(userFilters) || _.isNull(apiContext) || !config.loaded
                    ? <SkeletonDataExploreForm />
                    : <DataExploreApiRequestForm
                        loading={loading}
                        config={config}
                        values={{ dateRange: sharedTimeScale, userFilters, segment }}
                        onSubmit={(values) => onSubmitApiRequestForm(apiContext, values)}
                        disabled={isApiRequestDisabled}
                    />}
                {loading && <SkeletonDataAggregationInput />}
                {
                    !loading && hasData && <Stack>
                        <Space h="xs" />
                        <Divider />
                        <MetricsInput metrics={metrics} handlers={metricsHandlers} />
                        <FiltersInput filters={filters} handlers={filtersHandlers} />
                        <Space h="xs" />
                        <FacetsInput facets={facets} handlers={facetsHandlers} />
                        <Divider />
                    </Stack>
                }
                <Tabs value={activeTab} onTabChange={setActiveTab} keepMounted={false} >
                    <ScrollArea>
                        <Tabs.List>
                            <Tabs.Tab disabled={!hasData} rightSection={loading && <Loader size="xs" />} value="overview" icon={<IconPictureInPicture />}>Overview</Tabs.Tab>
                            <Tabs.Tab disabled={!hasData} rightSection={loading && <Loader size="xs" />} value="metrics" icon={<IconChartLine />}>Metrics</Tabs.Tab>
                        </Tabs.List>
                    </ScrollArea>
                    <Tabs.Panel value="overview" pt="xs">
                        <OverviewPanel
                            loading={loading}
                            facetedCards={facetedCards}
                            metrics={metrics}
                            facets={facets}
                            sharedTimeScale={sharedTimeScale}
                            setSharedTimeScale={setSharedTimeScale}
                            eventsData={eventsData}
                        />
                    </Tabs.Panel>
                    <Tabs.Panel value="metrics" pt="xs">
                        <MetricsChartsPanel
                            facetedCards={facetedCards}
                            facets={facets}
                            metrics={metrics}
                            addFilter={(filter) => filtersHandlers.append(filter)}
                        />
                    </Tabs.Panel>
                </Tabs>
            </Stack>
        </ColumnProvider>
    );
}
