import * as rdlTypes from "../../../models/RDLDataTypes";
import { EventName, isMetricName } from "../../../utilities/metric-calculations/metrics";
import { RDLConfigType } from "../../../models/RDLConfig";
import { createFormContext } from "@mantine/form";
import { getEventNameStr } from "../../EventIconMapping";
import { randomId } from "@mantine/hooks";

export interface MetricFormValues {
    key: string,
    name: EventName,
    displayName: string,
}

export const newMetric = function(): MetricFormValues {
    return {
        key: randomId(),
        name: 'Like',
        displayName: '',
    };
};

export const getMetricsFromConfig = function (config: RDLConfigType): MetricFormValues[] {
    return config.metricTypes
        .concat(config.metricCalculations.map((calculation) => calculation.eventType))
        .map((eventType) => ({
            ...newMetric(),
            name: rdlTypes.EventType[eventType] as EventName,
            displayName: getEventNameStr(eventType),
        }));
}


export const validateMetricFormValues = {
    name: (value: string) => value.length > 0 ? (isMetricName(value) ? null : `Invalid metric name`) : 'Please enter a name',
};

export const [MetricFormProvider, useMetricFormContext, useMetricForm] =
  createFormContext<MetricFormValues>();
