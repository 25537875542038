import { Stack, Text } from "@mantine/core";
import { formatPercentage, formatValue } from "../utilities/format-values";

function format(value: number | string, isPercentage?: boolean) {
    if (typeof value === "string") {
        return value;
    }
    if (isPercentage || (isPercentage === undefined && value > 0 && value <= 1)) {
        return formatPercentage(value, 2);
    }
    return formatValue(value);
}

export function StatisticDisplay(props: {
    title: string,
    value: number | string,
    isPercentage?: boolean
}) {
    return (
        <Stack>
            <Text size="xs" weight="bold" color="#666" mt="xs">
                {props.title}
            </Text>
            <Text size="lg">{format(props.value, props.isPercentage)}</Text>
        </Stack>
    )
}
