import * as rdlTypes from "../../models/RDLDataTypes";
import {
    Button,
    Group,
    Image,
    Loader,
    Stack,
    Text,
    ThemeIcon,
    Title,
    Tooltip
} from '@mantine/core'
import { IconCircle, IconCircleCheck } from "@tabler/icons-react";
import { RubberDuckyLabsAdminApi } from '../../RDLApi';
import { isUndefinedOrNull } from "../../utilities/types";
import { useApiContext, useRDLUser } from '../../App';
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from 'react';
import { useLocalStorage } from "@mantine/hooks";
import LogoutButton from "../../components/LogoutButton";


export const SettingsProfilePage = () => {
    const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const rdlUser = useRDLUser();
    const [organizations, setOrganizations] = useState<rdlTypes.Organization[]>([]);
    const [, setActiveOrganization] = useLocalStorage<rdlTypes.Organization | undefined>({key: 'organization', defaultValue: undefined});
    const apiContext = useApiContext();

    useEffect(() => {
        if (!isUndefinedOrNull(rdlUser)) {
            if (rdlUser.is_site_admin) {
                RubberDuckyLabsAdminApi.getInstance(getAccessTokenSilently)
                    .getOrganizations()
                    .then(({data}) => setOrganizations(data));
            } else {
                setOrganizations(rdlUser.organizations);
            }
        }
    }, [rdlUser, getAccessTokenSilently]);


    if (isLoading) {
        return <Loader />;
    }

    if (isAuthenticated && user) {
        return (
            <Stack>
                <Title order={1}>My Profile</Title>
                <Group>
                    <Image
                        width={96}
                        fit="contain"
                        src={user.picture}
                        alt={user.name} />
                    <Stack>
                        <Text size="sm" weight={500}>{user.name}</Text>
                        {!isUndefinedOrNull(rdlUser) && rdlUser.is_site_admin &&
                            <Text weight={500} size="xs">Site Admin</Text>}
                        <Text color="dimmed" size="xs">{user.email}</Text>
                    </Stack>
                </Group>
                <Title order={2}>Organizations</Title>
                {organizations.map((organization) => (
                    <Group key={organization.id}>
                        {organization.id === apiContext?.organization.id
                            ? <ThemeIcon variant="light" color='blue'>
                                <Tooltip label={'Active organization'} withArrow >
                                    <IconCircleCheck />
                                </Tooltip>
                            </ThemeIcon>
                            : <ThemeIcon variant="light" color="gray">
                                <Tooltip label={'Inactive organization'} withArrow >
                                    <IconCircle />
                                </Tooltip>
                            </ThemeIcon>}
                        <Text>{organization.name}</Text>
                    </Group>
                ))}
                {(!isUndefinedOrNull(rdlUser) && organizations.length === 0) && <Text c="dimmed">No organizations</Text>}
                <Group>
                    {organizations.length > 1 && <Button variant="outline" onClick={() => setActiveOrganization(undefined)}>Switch Organization</Button>}
                    <LogoutButton />
                </Group>
            </Stack>
        );

    }

    return <div>No user data, try logging in?</div>;

}
