import {
    Card,
    Group,
    Stack,
    Text,
    ThemeIcon
} from "@mantine/core";
import { Link } from "react-router-dom";
import { MainLink } from "../mainLink";

export default function PageIntroCard(props: MainLink) {
    const PageIcon = props.icon;
    return (
        <Card p="lg" radius="md" withBorder component={Link} to={props.linkTo}>
            <Stack spacing="xs">
                <Group>
                    <ThemeIcon color={props.color} variant="light" size="lg" >
                        <PageIcon />
                    </ThemeIcon>
                    <Text weight="bold">{props.label}</Text>
                </Group>
                {props.description && <Text size="sm" c="dimmed">{props.description}</Text>}
            </Stack>
        </Card>
    );
}
