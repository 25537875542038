import { DatePickerInput, DatePickerInputProps } from "@mantine/dates"
import { IconCalendar } from "@tabler/icons-react";
import { addDays, isEqual } from "date-fns";
import { isDate, isWithinMaybeDateRange, parseDateRangeFromConfig } from "../../utilities/date-range"
import { useMemo } from "react";
import { useRDLConfig } from "../../App";


export default function RDLEventDatePicker(props: DatePickerInputProps<"range">) {
    const config = useRDLConfig();

    // Note(Alexandra): We do some amount of munging because Mantine doesn't handle
    // having minDate and maxDate as the same date. We should remove this code if they
    // ever update that functionality.
    const [allowedDateRange, excludeDate] = useMemo(
        () => {
            const [start, end] = parseDateRangeFromConfig(config);
            if (isDate(start) && isDate(end) && isEqual(start, end)) {
                const newAllowedDateRange = [start, addDays(end, 1)]
                const newExcludeDate = (date: Date) => !isWithinMaybeDateRange(date, [start, end])
                return [newAllowedDateRange, newExcludeDate];
            }

            const newAllowedDateRange = [start, end];
            const newExcludeDate = () => false;
            return [newAllowedDateRange, newExcludeDate];
        },
        [config],
    );

    const [minDate, maxDate] = allowedDateRange;

    return <DatePickerInput
        popoverProps={{withinPortal: true}}
        type="range"
        icon={<IconCalendar size="1.1rem" stroke={1.5} />}
        label="Date range"
        placeholder="start date - end date"
        allowSingleDateInRange
        minDate={minDate ? minDate : undefined}
        maxDate={maxDate ? maxDate : new Date()}
        excludeDate={excludeDate}
        {...props}
    />;
}
