import {
    Alert,
    Anchor,
    Breadcrumbs,
    Stack,
    Title
} from '@mantine/core'
import { IconAlertTriangle } from '@tabler/icons-react';
import { Link } from 'react-router-dom';


export function ItemNotFoundPage(props: {
    itemName: string,
    itemId: string | undefined,
    errorMessage?: string,
}) {
    const items = [
        { title: `${props.itemName}s`, route: '..' },
        { title: `${props.itemName} Not Found`, route: '#' },
    ].map((item, index) => (
        <Anchor key={index} component={Link} to={item.route}>
            {item.title}
        </Anchor>
    ));

    return (
        <Stack>
            <Breadcrumbs mt="md">{items}</Breadcrumbs>
            <Title order={1} mt="sm">{props.itemName} Not Found</Title>
            <Alert icon={<IconAlertTriangle size={16} />} title={`${props.itemName} not found`} color="red">
                Oops! We cannot locate {props.itemName.toLowerCase()} {props.itemId}!
                {props.errorMessage && <p>{props.errorMessage}</p>}
            </Alert>
        </Stack>
    )
}
