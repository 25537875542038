import {
    Button,
    Group,
    Select,
    SelectItem,
    Stack,
} from "@mantine/core"
import {
    CreateUserSegmentFormValues,
    OnAddUsersToSegmentType,
    OnCreateSegmentType,
    UserSegmentFormProvider,
    useUserSegmentForm,
    validateUserSegmentFormValues
} from "../user-segment/user-segment-form-context"
import { RubberDuckyLabsApi } from "../../../RDLApi";
import { useApiContext } from "../../../App";
import { useEffect, useState } from "react";
import _ from "underscore";


export default function CreateUserSegmentForm({ userIds, onCreateSegment, onAddUsersToSegment }: { userIds: string[], onCreateSegment: OnCreateSegmentType, onAddUsersToSegment: OnAddUsersToSegmentType }) {
    const apiContext = useApiContext();
    const [selectData, setSelectData] = useState<SelectItem[]>([]);
    const [loading, setLoading] = useState(true);

    const form = useUserSegmentForm(
        {
            initialValues: {
                userSegmentId: undefined,
                userIds: userIds,
            },
            validate: validateUserSegmentFormValues,
        }
    );

    useEffect(() => {
        if (!_.isNull(apiContext)) {
            RubberDuckyLabsApi.getInstance(apiContext)
                .listUserSegmentsMetadata()
                .then(({data}) => data.map(({id, name}) => ({value: id.toString(), label: name})))
                .then((selectItems) => setSelectData(selectItems))
                .then(() => setLoading(false));
        }
    }, [apiContext]);

    const onCreateSelectItem = (query: string) => {
        setSelectData((prev) => [...prev, {value: query, label: query}]);

        if (!_.isNull(apiContext)) {
            setLoading(true);
            RubberDuckyLabsApi.getInstance(apiContext)
                .createUserSegmentMetadata({name: query})
                .then((userSegmentMetdata) => {
                    const selectItem = ({value: userSegmentMetdata.id.toString(), label: userSegmentMetdata.name});
                    setSelectData((prev) => [...prev.filter(({value}) => value !== query), selectItem]);
                    form.setFieldValue('userSegmentId', userSegmentMetdata.id.toString());
                    return userSegmentMetdata;
                })
                .then((userSegmentMetdata) => onCreateSegment(userSegmentMetdata))
                .then(() => setLoading(false))
        }

        return query;
    };

    const onSubmitForm = (values: CreateUserSegmentFormValues) => {
        if (!_.isNull(apiContext) && !_.isUndefined(values.userSegmentId)) {
            onAddUsersToSegment(values.userSegmentId, values.userIds);
        }
    }

    return (
        <UserSegmentFormProvider form={form}>
            <form onSubmit={form.onSubmit((values) => onSubmitForm(values))}>
                <Stack>
                    <Select
                        withinPortal
                        label="User Segment"
                        placeholder="Find or create a user segment"
                        nothingFound="No user segments found"
                        data={selectData}
                        creatable
                        searchable
                        getCreateLabel={(query) => `+ Create segment: ${query}`}
                        onCreate={onCreateSelectItem}
                        {...form.getInputProps('userSegmentId')}
                    />
                    <Group position="right">
                        <Button type="submit" disabled={_.isNull(apiContext) || loading} loading={loading}>Save</Button>
                    </Group>
                </Stack>
            </form>
        </UserSegmentFormProvider>
    );
}
