import { ColumnTypes } from "../models/RDLConfig";
import { FacetFormValues } from "../components/forms/facet/facet-form-context";
import { FacetTypes, UNDEFINED_OR_NULL_FACET_NAME } from "./facets";
import {
    FilterData,
    FilterInclusivityType,
    RuleFilter,
    RuleTypes,
    newRule,
    shouldHideAdditionalFilterInputs
} from "../components/forms/rule/rule-form-context"
import { ItemCardProps } from "../components/ItemCard";
import { categoryLabel } from "./categories";
import { isDateRange, isWithinMaybeDateRange } from "./date-range";
import { isUndefinedOrNull } from "./types";
import { lightFormat, parseJSON } from "date-fns";
import _ from "underscore";
import objectPath from "object-path";


export enum FilterTypes {
    Boolean = "boolean",
    NumericalRange = "numerical-range",
    ListOfCategories = "list-of-categories",
    DateRange = "date-range",
}


function _createFilterTitle(filterData: FilterData): string {
    const filterType = filterData.filterType;
    const readableName = filterData.columnName
        .replace('item.item_attributes.', '')
        .replace('scores.', '')
        .replace('metrics.', '');

    if (shouldHideAdditionalFilterInputs(filterData)) {
        switch (filterData.inclusivity) {
        case FilterInclusivityType.IsUndefinedOrNull:
            return `${readableName} is undefined or null`;
        case FilterInclusivityType.IsNotUndefinedOrNull:
            return `${readableName} is not undefined or null`;
        }
    }

    if (filterType === FilterTypes.NumericalRange) {
        const min = filterData.filterValues.numberData.min;
        const max = filterData.filterValues.numberData.max;

        if (min !== undefined && max !== undefined) {
            return `${min} <= ${readableName} <= ${max}`
        } else if (max !== undefined) {
            return `${readableName} <= ${max}`
        } else if (min !== undefined) {
            return `${min} <= ${readableName}`
        }

        throw Error(`Min and max are undefined (this is a bug)`);
    } else if (filterType === FilterTypes.Boolean) {
        return `${readableName} is ${filterData.filterValues.booleanData}`;
    } else if (filterType === FilterTypes.ListOfCategories) {
        if (filterData.filterValues.categoryData.length <= 2) {
            const categories = filterData.filterValues.categoryData;
            const categoryLabels = categories.map(categoryLabel);
            return `${readableName}: ${categoryLabels.join(', ')}`
        }

        return `${readableName} (${filterData.filterValues.categoryData.length})`;
    } else if (filterType === FilterTypes.DateRange) {
        if (!isDateRange(filterData.filterValues.dateData)) {
            return `${readableName} has no date range`;
        }

        const start = filterData.filterValues.dateData[0];
        const end = filterData.filterValues.dateData[1];

        return `${readableName} ${lightFormat(start, 'yyyy/MM/dd')} - ${lightFormat(end, 'yyyy/MM/dd')}`
    }

    throw Error(`Unknown filter type: ${filterType}`)
}

export function createFilterTitle(filterData: FilterData): string {
    const title = _createFilterTitle(filterData);
    return filterData.inclusivity === FilterInclusivityType.IsNot ? `Not ${title}` : title;
}


function passesNumericalRangeFilter(value: number | undefined, filterData: FilterData): boolean {
    if (value === undefined) {
        return false;
    }

    const min = filterData.filterValues.numberData.min;
    const max = filterData.filterValues.numberData.max;

    if (min !== undefined && max !== undefined) {
        return min <= value && value <= max;
    } else if (max !== undefined) {
        return value <= max;
    } else if (min !== undefined) {
        return min <= value;
    }

    return true;
}

function passesListOfCategoriesFilters(value: string | string[] | undefined, filterData: FilterData): boolean {
    if (isUndefinedOrNull(value)) return false;
    if (_.isString(value)) return filterData.filterValues.categoryData.includes(value);
    return value.some((v) => filterData.filterValues.categoryData.includes(v));
}

function passesBooleanFilters(value: boolean | number, filterData: FilterData): boolean {
    if (filterData.filterValues.booleanData === "true") {
        return !!value;
    } else {
        return !value;
    }
}

function isIncludedByFilter(filterData: FilterData, card: ItemCardProps): boolean {
    const value = objectPath.get(card, filterData.columnName);

    if (filterData.filterType === FilterTypes.NumericalRange) {
        return passesNumericalRangeFilter(value, filterData);
    } else if (filterData.filterType === FilterTypes.ListOfCategories) {
        return passesListOfCategoriesFilters(value, filterData);
    } else if (filterData.filterType === FilterTypes.Boolean) {
        return passesBooleanFilters(value, filterData);
    } else if (filterData.filterType === FilterTypes.DateRange) {
        return isWithinMaybeDateRange(parseJSON(value), filterData.filterValues.dateData);
    }

    throw Error(`Unsupported filter type: ${filterData.filterType}`);
}


export const ACCEPTABLE_FILTER_TYPES_MAP: Record<ColumnTypes, FilterTypes[]> = {
    [ColumnTypes.Float]: [
        FilterTypes.NumericalRange,
    ],
    [ColumnTypes.Integer]: [
        FilterTypes.NumericalRange,
    ],
    [ColumnTypes.Boolean]: [
        FilterTypes.Boolean,
    ],
    [ColumnTypes.Category]: [
        FilterTypes.ListOfCategories
    ],
    [ColumnTypes.Date]: [
        FilterTypes.DateRange
    ],
    [ColumnTypes.Undefined]: [],
    [ColumnTypes.Null]: [],
    [ColumnTypes.ListOfCategories]: [
        FilterTypes.ListOfCategories,
    ],
};


export function applyFilter(filterData: FilterData, cards: ItemCardProps[]): ItemCardProps[] {
    // TODO(Alexandra): revisit how we handle null and undefined filters
    const [undefinedOrNullCards, nonUdefinedOrNullCards] = _.partition(cards, (card) => isUndefinedOrNull(objectPath.get(card, filterData.columnName)));

    const [included, excluded] = _.chain(nonUdefinedOrNullCards)
        .partition((card) => isIncludedByFilter(filterData, card))
        .value();

    switch (filterData.inclusivity) {
    case FilterInclusivityType.IsUndefinedOrNull:
        return undefinedOrNullCards;
    case FilterInclusivityType.IsNotUndefinedOrNull:
        return nonUdefinedOrNullCards;
    case FilterInclusivityType.Is:
        return included;
    case FilterInclusivityType.IsNot:
        return excluded;
    }
}


export function createFilterForFacet(facet: FacetFormValues, facetName: string) {
    const newFilter: RuleFilter = {
        ...newRule(),
        type: RuleTypes.Filter,
    };
    newFilter.data.filterData.columnName = facet.data.facetData.columnName;

    if (facetName === UNDEFINED_OR_NULL_FACET_NAME) {
        newFilter.data.filterData.inclusivity = FilterInclusivityType.IsUndefinedOrNull;
        newFilter.data.filterData.filterType = FilterTypes.NumericalRange;
        return newFilter;
    }

    newFilter.data.filterData.inclusivity = FilterInclusivityType.Is;

    switch (facet.data.facetData.facetType) {
    case FacetTypes.BooleanValue: {
        newFilter.data.filterData.filterType = FilterTypes.Boolean;
        const isTrue = facetName.toLowerCase() === true.toString().toLowerCase();
        newFilter.data.filterData.filterValues.booleanData = isTrue ? "true" : "false";
        break;
    }
    case FacetTypes.NonMECEListOfCategories:
    case FacetTypes.ListOfCategories:
        newFilter.data.filterData.filterType = FilterTypes.ListOfCategories;
        newFilter.data.filterData.filterValues.categoryData = [facetName];
        break;
        // TODO(Alexandra): complete this filter
    case FacetTypes.NumberBucketsUniformIntegers:
    case FacetTypes.NumberBucketsUniform:
    case FacetTypes.NumberBucketsExponentialBase10: {
        const [min, max] = [undefined, undefined];
        newFilter.data.filterData.filterType = FilterTypes.NumericalRange;
        newFilter.data.filterData.filterValues.numberData = {
            min, max,
        };
        break;
    }
    // TODO(Alexandra): complete this filter
    case FacetTypes.Date:
        newFilter.data.filterData.filterType = FilterTypes.DateRange;
        newFilter.data.filterData.filterValues.dateData = [null, null];
        break;
    }

    return newFilter;
}
