import { SimpleGridProps } from "@mantine/core";

export type LayoutSizes = "xl" | "lg" | "md" | "sm" | "xs";
export const LAYOUT_TO_SIMPLE_GRID_PROPS: Record<LayoutSizes, SimpleGridProps> = {
    xl: {
        cols: 6,
        breakpoints: [
            { maxWidth: 'xl', cols: 4, spacing: 'xl' },
            { maxWidth: 'lg', cols: 3, spacing: 'lg' },
            { maxWidth: 'md', cols: 2, spacing: 'md' },
            { maxWidth: 'sm', cols: 2, spacing: 'sm' },
            { maxWidth: 'xs', cols: 1, spacing: 'sm' },
        ],
    },
    lg: {
        cols: 4,
        breakpoints: [
            { maxWidth: 'xl', cols: 4, spacing: 'xl' },
            { maxWidth: 'lg', cols: 3, spacing: 'lg' },
            { maxWidth: 'md', cols: 2, spacing: 'md' },
            { maxWidth: 'sm', cols: 2, spacing: 'sm' },
            { maxWidth: 'xs', cols: 1, spacing: 'sm' },
        ],
    },
    md: {
        cols: 3,
        breakpoints: [
            { maxWidth: 'xl', cols: 2, spacing: 'xl' },
            { maxWidth: 'lg', cols: 2, spacing: 'lg' },
            { maxWidth: 'md', cols: 1, spacing: 'md' },
            { maxWidth: 'sm', cols: 1, spacing: 'sm' },
            { maxWidth: 'xs', cols: 1, spacing: 'sm' },
        ],
    },
    sm: {
        cols: 2,
        breakpoints: [
            { maxWidth: 'xl', cols: 2, spacing: 'xl' },
            { maxWidth: 'lg', cols: 1, spacing: 'lg' },
            { maxWidth: 'md', cols: 1, spacing: 'md' },
            { maxWidth: 'sm', cols: 1, spacing: 'sm' },
            { maxWidth: 'xs', cols: 1, spacing: 'sm' },
        ],
    },
    xs: {
        cols: 1,
    },
};

export const numberOfColumnsToLayoutSize = function (numberOfColumns: number): LayoutSizes {
    if (numberOfColumns >= 4) return "xs";
    if (numberOfColumns >= 3) return "sm";
    if (numberOfColumns >= 2) return "md";
    return "lg";
}
