import { Button } from "@mantine/core";

export default function ContactSupportButton() {
    return (
        <Button
            component="a"
            target="_blank"
            href="mailto:support@rubberduckylabs.io"
            color="gray"
        >
            Contact Support
        </Button>
    )
}
