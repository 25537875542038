import * as DateFNS from 'date-fns';
import * as math from "mathjs";
import { ColumnTypes } from "../models/RDLConfig";
import { categoryLabel } from "./categories";
import { formatPercentage } from "./format-values";
import _ from "underscore";


const calculateBooleanBreakdown = function(values: boolean[]) {
    const [trueValues, falseValues] = _.partition(values, _.identity);
    return `True ${formatPercentage(trueValues.length / (values.length))} / False ${formatPercentage(falseValues.length / (values.length))}`;
}


const calculateFloatBreakdown = function(values: number[]) {
    const min = math.min(values);
    const max = math.max(values);
    return `[${min}, ${max}]`;
}


const calculateCategoryBreakdown = function(values: string[]) {
    const categories: [string, number][] = _.chain(values)
        .groupBy(_.identity)
        .mapObject((value) => value.length)
        .pairs()
        .value();
    categories.sort((a, b) => b[1] - a[1]);

    if (categories.every(([c,]) => c === '' || c.startsWith("https://") || c.startsWith("http://"))) {
        const countURLs = categories.filter(([c,]) => c.startsWith("https://") || c.startsWith("http://")).length;
        const emptyStringCategory = categories.find(([c,]) => c === '');
        const countEmptyString = emptyStringCategory !== undefined ? emptyStringCategory[1] : 0;
        return `${countEmptyString} empty strings, ${countURLs} unique URLs`;
    }

    const categoriesStr = categories.map(([category]) => categoryLabel(category)).join(', ');
    return `${categories.length} categories: ${categoriesStr}`;
}


// TODO(Alexandra): what type is this?
const calculateDateBreakdown = function(values: (string | number | Date)[]) {
    const dates = values.map((v) => DateFNS.parseJSON(v));
    const dateMin = DateFNS.min(dates);
    const dateMax = DateFNS.max(dates);
    return `${DateFNS.formatDistanceToNow(dateMin, {addSuffix: true})} - ${DateFNS.formatDistanceToNow(dateMax, {addSuffix: true})}`;
}


const calculateListOfCategoriesBreakdown = function(values: string[][]) {
    return calculateCategoryBreakdown(values.flat());
}


export const calculateBreakdown = function(values: any[], type: ColumnTypes): string {
    switch(type) {
    case ColumnTypes.Undefined:
        return `${undefined}`;
    case ColumnTypes.Null:
        return `${null}`;
    case ColumnTypes.Boolean:
        return calculateBooleanBreakdown(values);
    case ColumnTypes.Float:
    case ColumnTypes.Integer:
        return calculateFloatBreakdown(values);
    case ColumnTypes.Category:
        return calculateCategoryBreakdown(values);
    case ColumnTypes.Date:
        return calculateDateBreakdown(values);
    case ColumnTypes.ListOfCategories:
        return calculateListOfCategoriesBreakdown(values);
    }
}
