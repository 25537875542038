export type Id = number | string;

//  Keep in sync with rdl_data_types.py
export enum EventType {
    Unknown = 0,

    // Armoire
    View = 1,
    Like = 2,
    Dislike = 3,
    AddToPackage = 4,

    // StockX
    ProductViewed = 5,
    BuyOrBidButtonClicked = 6,
    ViewAllDataClicked = 7,
    ProductFollowed = 8,
    SellOrAskButtonClicked = 9,
    BuyCompleted = 10,
    BidCreated = 11,
    SellCompleted = 12,
    ProductAddedToPortfolio = 13,
    AskCreated = 14,

    // Shared
    Recommendation = 15,

    // Wayfair
    DirectPDP = 16,
    DirectATC = 17,
    DirectConversion = 18,

    // StockX - Metrics
    LikeRate = 1001,
    BuyOrBidClickRate = 1002,
    SellOrAskClickRate = 1003,
    BuyCompletedRate = 1004,
    SellCompletedRate = 1005,

    // Wayfair - Metrics
    DirectPDPRate = 1006,
    DirectATCRate = 1007,
    DirectConversionRate = 1008,

    // Armoire - Metrics
    DislikeRate = 1009,
    AddToPackageRate = 1010,

    // Armoire - More metrics
    AddToCollection = 19,
    PositiveOverallRating = 20,
    NegativeOverallRating = 21,
}

export interface User {
    user_id: Id;
    user_attributes: any;
}

export interface Item {
    item_id: Id;
    item_attributes: any;
}

export interface Event {
    event_id: Id;
    user_id: Id;
    item_id: Id;
    event_type: EventType;
    timestamp: number;
    event_attributes: any;
}

export interface Paging {
    after: Id | null,
}

export interface Pagination<T> {
    count: number;
    data: T[];
    paging: Paging;
}


export interface EventCount {
    item_id: Id;
    count: number;
    rate?: number;
    date: string;
    source?: string;
    event_type: EventType;
}


export type Aggregation = Record<string, number>;

export interface EventStatistic {
    count: number,
    aggregation?: Aggregation,
}

export interface EventStatisticsResponse {
    data: EventStatistic[],
}

export interface TraceItemMetadata {
    trace_id: string;
    item_id: Id;
    scores: Record<string, any>;
    metadata: Record<string, any>;
}

export enum RankingPipelineStageType {
    CandidateGeneration = "candidate generation",
    RankedList = "ranked list",
    UserInteractions = "user interactions",
}

export enum RankingPipelineStageDataSource {
    TestDataset = "test dataset",
}

interface RankingPipelineStageInput {
    stage_ids: number[];
}

interface RankingPipelineStageOutput {
    data_source: RankingPipelineStageDataSource;
    tags: string[];
}

export interface RankingPipelineStage {
    id: number;
    name: string;
    stage_type: RankingPipelineStageType;
    stage_order: number;
    input_config: RankingPipelineStageInput;
    output_config: RankingPipelineStageOutput;
}

export interface RankingPipeline {
    id: number;
    name: string;
    stages: RankingPipelineStage[];
}

export interface Organization {
    id: Id,
    name: string,
}

export type OrganizationCreateData = Omit<Organization, "id">;

export type RankingPipelineStageCreateData = Omit<RankingPipelineStage, "id" | "stage_order"> & {
    input_config: { ranking_pipeline_stages: string[], stage_ids: number[] };
};

export type RankingPipelineCreateData = Omit<RankingPipeline, "id" | "stages">;

export interface UserStyleProfile {
    user_id: Id,
    style_profile: Record<string, any>,
}

export interface RDLUser {
    id: Id,
    organizations: Organization[],
    is_site_admin: boolean,
    auth0_subclaim: string,
}

export interface RDLUserCreateData {
    is_site_admin?: boolean,
    auth0_subclaim: string,
}

export interface RDLUserUpdateData {
    is_site_admin?: boolean,
    auth0_subclaim?: string,
}

export interface UserSegmentMetadata {
    id: Id,
    name: string,
    count_users: number,
}

export type UserSegmentMetadataCreateData = Omit<UserSegmentMetadata, "id" | "count_users">;

export interface UserSegmentMembership {
    user_id: Id,
    segment_id: Id,
}

export type UserSegmentMembershipCreateData = Id[];
