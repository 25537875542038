import { ColumnProvider } from '../../context/ColumnContext';
import {
    Container,
    Flex,
    SimpleGrid,
    Stack,
    Text,
    Title
} from "@mantine/core"
import { DraggableRules } from "../../components/forms/rule/RuleModals";
import { InView } from "react-intersection-observer";
import { ItemCard, ItemCardProps } from "../../components/ItemCard";
import { LAYOUT_TO_SIMPLE_GRID_PROPS } from "../../utilities/layout";
import { RuleFormValues } from "../../components/forms/rule/rule-form-context";
import { applyRule } from "../../utilities/rules";
import { useApiContext, useItems, useLoadAndCacheItems, useRDLConfig } from "../../App";
import { useEffect, useMemo, useState } from "react";
import { useListState } from "@mantine/hooks";


const SCROLL_PAGE_SIZE = 10;


export default function ProductCatalogItemsPage() {
    const apiContext = useApiContext();
    const items = useItems();
    const loadAndCacheItems = useLoadAndCacheItems();
    const config = useRDLConfig();

    useEffect(() => {
        loadAndCacheItems(apiContext);
    }, [apiContext]);

    const [scrollLimit, setScrollLimit] = useState(SCROLL_PAGE_SIZE);
    // TODO(Alexandra): make this page nicer by auto-adding a sort rule here
    const [rules, rulesHandlers] = useListState<RuleFormValues>([]);

    const displayCards: ItemCardProps[] = useMemo(() => {
        let newDisplayCards: ItemCardProps[] = items.map((item) => ({item: item}));
        const activeRules = rules.filter((rule) => rule.active);
        for (const rule of activeRules) {
            newDisplayCards = applyRule(rule, newDisplayCards, config);
        }
        return newDisplayCards;
    }, [rules, items]);


    return (
        <Stack>
            <Title order={1}>Product Catalog Items Page</Title>
            <Text c="dimmed">Browse all of the items in your product catalog.</Text>
            <ColumnProvider cards={displayCards}>
                <Container>
                    <Flex justify="center" mb="lg" direction="column">
                        <DraggableRules rules={rules} ruleHandlers={rulesHandlers} config={config} />
                    </Flex>
                    {displayCards.length > 0 && <SimpleGrid {...LAYOUT_TO_SIMPLE_GRID_PROPS.lg}>
                        {displayCards.slice(0, scrollLimit).map((card, i) => <ItemCard {...card} key={card.item === undefined ? i : card.item.item_id} />)}
                        <InView onChange={(inView) => inView && setScrollLimit(Math.min(scrollLimit + SCROLL_PAGE_SIZE, items.length))} />
                    </SimpleGrid>}
                </Container>
            </ColumnProvider>
        </Stack>
    )
}
