import { Group, Skeleton, Stack } from "@mantine/core";


export default function LoadingDetailsPage() {
    return (
        <Stack spacing="xl">
            <Group>
                <Skeleton height={30} width={100} />
                <Skeleton height={30} width={100} />
                <Skeleton height={30} width={100} />
            </Group>
            <Skeleton height={70} width={400} />
            <Group>
                <Skeleton height={20} width={75} />
                <Skeleton height={20} width={75} />
                <Skeleton height={20} width={75} />
                <Skeleton height={20} width={75} />
                <Skeleton height={20} width={75} />
            </Group>
            <Stack spacing="sm">
                <Skeleton height={8} width={800} />
                <Skeleton height={8} width={800} />
                <Skeleton height={8} width={800} />
                <Skeleton height={8} width={800} />
                <Skeleton height={8} width={500} />
            </Stack>
            <Skeleton height={800} width={800} />
        </Stack>
    );
}
