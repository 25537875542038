import { NumberInput } from "@mantine/core";
import { useRuleFormContext } from "./rule-form-context";


export function LimitInput() {
    const form = useRuleFormContext();

    return (
        <NumberInput
            withAsterisk
            placeholder="Enter a limit"
            label="Limit"
            min={1}
            {...form.getInputProps('data.limitData.limit')}
        />
    )
}
