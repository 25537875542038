import {
    Chart as ChartJS,
    Filler,
    LineElement,
    LinearScale,
    PointElement,
    TimeScale,
    Title,
    Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Metric } from "../../pages/health-checks/HealthChecksMetricsPage";
import { chartColors, mergeWithDefaultChartOptions } from "../../utilities/charts";
import { useMantineTheme } from "@mantine/core";
import { useRef } from "react";
import HealthCheckService, { HealthCheckTimeRange } from '../../services/health-checks';
import _ from 'underscore';
import zoomPlugin from 'chartjs-plugin-zoom';
ChartJS.register(
    LinearScale,
    TimeScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    zoomPlugin,
    Filler,
);

export interface MetricsChartDatapoint {
    date: Date | string,
    count: number,
}

export interface MetricsChartProps {
    data: MetricsChartDatapoint[],
    metric: Metric,
    healthCheckTimeRange: HealthCheckTimeRange | null,
}

export default function HealthChecksMetricsChart({data, metric, healthCheckTimeRange}: MetricsChartProps) {
    const chartRef = useRef<any>(null);

    // const onPanOrZoom = function (chart: any) {
    //     const { min, max } = chart.chart.scales.x;
    //     props.onXScaleChange([new Date(min), new Date(max)]);
    // }

    const theme = useMantineTheme();
    const colors = chartColors(theme);

    const chartData = {
        datasets: [{
            label: `${metric.displayName}`,
            data: data,
            parsing: {
                xAxisKey: 'date',
                yAxisKey: 'count',
            },
            ...colors[0],
        }]
    };

    const [startDate, endDate] =
        !_.isNull(healthCheckTimeRange)
            ? HealthCheckService.getTimescaleAndLimitForTimeRange(healthCheckTimeRange)
            : [undefined, undefined, undefined];

    const options = mergeWithDefaultChartOptions({
        aspectRatio: 5,
        scales: {
            x: {
                type: 'time',
                min: startDate,
                max: endDate,
                time: {
                    minUnit: 'day',
                },
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                title: { display: true, text: 'Event count' },
            }
        },
        plugins: {
            title: {
                display: false,
            },
            tooltip: {
                intersect: false,
                callbacks: {
                    //     beforeBody: (context: any) => props.data[context[0].dataIndex].id,
                    //     label: (context: any) => `${context.parsed.y} ${context.dataset.label}`,
                },
            },
            legend: { display: false },
            zoom: {
                zoom: {
                    pinch: {
                        enabled: true
                    },
                    drag: {
                        enabled: true,
                    },
                    mode: 'x' as const,
                    // onZoom: onPanOrZoom,
                },
                pan: {
                    enabled: true,
                    modifierKey: 'shift' as const,
                    mode: 'x' as const,
                    // onPan: onPanOrZoom,
                },
                limits: {
                    x: {
                        max: new Date()
                    },
                },
            },
        },
    });

    return (
        <Line
            ref={chartRef}
            options={options}
            data={chartData}
            // onClick={chartOnClick}
        />
    );
}
