import jStat from "jstat";


export function Z(p: number): number {
    return jStat.normal.inv(p, 0, 1);
}

export function standardDeviationOfPercent(p: number): number {
    return Math.sqrt(p * (1-p));
}

export function calculateMinimumSampleSize(
    baselineConversionRate: number,
    effectSize: number,
    confidenceLevel: number,
    power = 0.8,
): number {
    // For two-sided test, adjust alpha
    const alpha = (1 - confidenceLevel) / 2;
    const beta = 1 - power;

    const zAlpha = Z(alpha);
    const zBeta = Z(beta);

    const mu2 = baselineConversionRate;
    const mu1 = mu2 * (1 + effectSize);
    const delta = mu1 - mu2;

    const sigma1 = Math.sqrt(2 * mu2 * (1 - mu2));
    const sigma2 = Math.sqrt(mu2 * (1 - mu2) + mu1 * (1 - mu1));
    return Math.round(Math.pow(zAlpha * sigma1 + zBeta * sigma2, 2) / Math.pow(delta, 2));
}

export function isStatisticallySignificant(
    baselineConversion: number,
    variationConversion: number,
    sampleSize: number,
    confidenceLevel: number
): boolean {
    const mu2 = baselineConversion;
    const mu1 = variationConversion;
    const numerator = mu1 - mu2;
    const denominator = Math.sqrt(baselineConversion * (1 - baselineConversion) / sampleSize);
    const zScore = numerator / denominator;

    // For two-sided test, adjust alpha
    const alpha = (1 - confidenceLevel) / 2;

    // Get the critical value for the given confidence level (two-sided)
    const criticalValue = Z(1 - alpha);

    // Determine if the observed z-score is greater than the critical value (in absolute terms)
    const isStatisticallySignificant = Math.abs(zScore) > criticalValue;

    return isStatisticallySignificant;
}
