import {
    Button,
    Group,
    SegmentedControl,
    Select,
    SelectItem,
    Stack
} from "@mantine/core"
import { DeleteIcon } from "../../DeleteActionIcon";
import { createSelectItemsFromColumns } from "../../../utilities/select-items";
import { useColumns } from "../../../context/ColumnContext";
import { useEffect, useMemo } from "react";
import { useRuleFormContext } from "./rule-form-context";


function SortInput(props: { searchableColumns: SelectItem[], index: number }) {
    const form = useRuleFormContext();
    const columnName = form.values.data.sortData[props.index].columnName;
    const columns = useColumns();

    useEffect(() => {
        const columnType = columns[columnName];
        form.setFieldValue(`data.sortData.${props.index}.columnType`, columnType);
    }, [form.values.data.sortData, columnName, columns]);

    return (
        <Group align='end'>
            <Select
                withinPortal
                label="Column to sort by"
                data={props.searchableColumns}
                placeholder="Type a name"
                searchable
                {...form.getInputProps(`data.sortData.${props.index}.columnName`)}
            />
            <SegmentedControl
                data={["Ascending", "Descending"]}
                {...form.getInputProps(`data.sortData.${props.index}.direction`)}
            />
            {
                props.index !== 0 &&
                <DeleteIcon onClick={() => { form.removeListItem('data.sortData', props.index) }} />
            }
        </Group>
    );
}

export function SortInputList() {
    const form = useRuleFormContext();
    const columns = useColumns();
    const searchableColumns = useMemo(
        () => createSelectItemsFromColumns(columns),
        [columns],
    );
    const sortDatas = form.values.data.sortData;

    return (
        <Stack align='flex-start'>
            {
                sortDatas.map((_, index) => (
                    <SortInput
                        searchableColumns={searchableColumns}
                        index={index}
                        key={index}
                    />
                ))
            }
            <Button onClick={
                () => {
                    form.setFieldValue('data.sortData', [...sortDatas, { columnName: '', direction: 'Ascending' }]);
                }
            }>
                Add Column
            </Button>
        </Stack>
    )
}
