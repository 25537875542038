import { ActionIcon, Alert as MantineAlert, Tooltip, Transition } from '@mantine/core';
import { IconHelpCircle } from "@tabler/icons-react";


const scaleY = {
    in: { opacity: 1, transform: 'scaleY(1)' },
    out: { opacity: 0, transform: 'scaleY(0)' },
    common: { transformOrigin: 'top' },
    transitionProperty: 'transform, opacity',
};

export function HelpIcon(props: { toggle: () => void }) {
    return (
        <Tooltip label="Help">
            <ActionIcon onClick={props.toggle} size="lg"><IconHelpCircle /></ActionIcon>
        </Tooltip>
    )
}

export function HelpAlert(props: { title: string, message: string | React.ReactNode, opened: boolean, close: () => void }) {
    return (
        <Transition mounted={props.opened} transition={scaleY} duration={300} timingFunction="ease">
            {(styles) => (
                <MantineAlert
                    withCloseButton
                    onClose={props.close}
                    icon={<IconHelpCircle size={16} />}
                    title={props.title}
                    color="blue"
                    style={styles}
                >
                    {props.message}
                </MantineAlert>
            )}
        </Transition>
    );
}
